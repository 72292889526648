import ACTIONS from "./actions";

const setDetailsTableMode = (mode) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.updateDetailsTableMode(mode));
    } catch (e) {
      console.log(e);
    }
  };
};

const setDetailsTableLimits = (limits) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.updateDetailsTableLimits(limits));
    } catch (e) {
      console.log(e);
    }
  };
};

const setDetailsTableFocusedRow = (row) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.updateDetailsTableFocusedRow(row));
    } catch (e) {
      console.log(e);
    }
  };
};

const setDefaultDetailsTableFocusedRow = () => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setDefaultDetailsTableFocusedRow());
    } catch (e) {
      console.log(e);
    }
  };
};

const setConstruction = ({ construction }) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.ConstructionProject({ construction: construction }));
    } catch (e) {
      console.log(e);
    }
  };
};

const setDetail = ({ detail }) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setDetail({ detail: detail }));
    } catch (e) {
      console.log(e);
    }
  };
};

const setTreatment = ({ Treatment }) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setTreatment({ Treatment: Treatment }));
    } catch (e) {
      console.log(e);
    }
  };
};
const setIsArrowMaterial = ({ IsArrowMaterial }) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setIsArrowMaterial({ IsArrowMaterial }));
    } catch (e) {
      console.log(e);
    }
  };
};
const setIsArrowEdge = ({ IsArrowEdge }) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setIsArrowEdge({ IsArrowEdge }));
    } catch (e) {
      console.log(e);
    }
  };
};

const setsceneModel = ({ sceneModel }) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.setsceneModel({ sceneModel }));
    } catch (e) {
      console.log(e);
    }
  };
};

const errorProject = (message, isShow) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.errorProject({ message, isShow }));
    } catch (e) {
      console.log(e);
    }
  };
};

export default {
  setConstruction,
  setDetail,
  setsceneModel,
  setIsArrowMaterial,
  setIsArrowEdge,
  setTreatment,
  errorProject,
  setDetailsTableMode,
  setDetailsTableLimits,
  setDetailsTableFocusedRow,
  setDefaultDetailsTableFocusedRow
};
