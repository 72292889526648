import {memo} from "react";

const Checkbox = ({isSelected, onChange, isDisabled = false}) => {
    const generateClasses = (isSelected, isDisabled) => {
        let classes = "detailsTab__checkbox";

        if (isSelected) {
            classes += " detailsTab__checkbox_active";
        }

        if (isDisabled) {
            classes += " detailsTab__checkbox_disabled";
        }

        return classes;
    }

    return (
        <label className={generateClasses(isSelected, isDisabled)}>
            <input
                name="select"
                className="visually-hidden"
                type="checkbox"
                onChange={onChange}
                checked={isSelected}
                value={isSelected}
                disabled={isDisabled}
            />
            <i className={isSelected ? "checkbox__icon checkbox__icon_active" : 'checkbox__icon'}></i>
        </label>
    )
};

export default memo(Checkbox);