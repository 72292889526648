import React, { useEffect, useRef, useState } from "react";
import Requests from "api/API";
import { useDispatch } from "react-redux";
import { modalsOperations, modalsSelectors } from "redux/modals";
import { useSelector } from "react-redux";
import { projectSelectors } from "redux/project";
import { useNavigate } from "react-router-dom";
import { empty, materialTitle } from "../../../../helpers/helper";

import CloseBtn from "components/UI/Buttons/CloseBtn";
import Languages from "translation/Languages";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import Checkbox from "components/UI/Inputs/Input/Checkbox";

import "./style.scss";
import img2 from "../../../../media/addEdge-test.png";
import Loader from "../../Loader";
import { errorMessageOperations } from '../../../../redux/errors'
import Errors from "../../../../models/Errors";
import AddCurrentMaterial from "./addCurrentMaterial";
import SubmitModal from "../../../UI/Modal/SubmitModal";
import DetailsForDelete from "../../DetailsForDelete";
import {userSelectors} from "redux/user";
import {toastWarning} from "../../../../helpers/toasts";
import img from "../../../../media/material-add-img-test.svg";

const _errors = new Errors([])

const AddMaterialAndEdges = () => {
    const construction = useSelector(projectSelectors.getConstruction);
    const user = useSelector(userSelectors.User)
    const navigate = useNavigate();
    const [materials, setMaterials] = useState([]);
    const [selectedMaterials, setSelectedMaterial] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const dispatch = useDispatch();
    const [code, setCode] = useState("");
    const [debouncedValue, setDebouncedValue] = useState('');
    const typeModal = useSelector(modalsSelectors.getModalTypeState);
    const deletedMaterial = useSelector(modalsSelectors.getModalIsEdit);
    const [currentMaterial, setCurrentMaterial] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [edges, setEdges] = useState([]);
    const [selectedEdges, setSelectedEdges] = useState([]);
    const input = useRef(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const currentMaterialLink = useSelector(modalsSelectors.getModalIsEdit);
    const [isMaterialFromLink, setIsMaterialFromLink] = useState(false)
    const submitModalText = Languages.getTranslation('delete-chosen-details', true)
    const currentDetails = construction.details;
    const filteredDetailsWithMultiplicity = currentDetails.filter((detail) => detail.material === deletedMaterial.index || detail?.multiplicity?.material === deletedMaterial.index);
    const search = async () => {
        setMaterials([]);
        setCurrentMaterial(null)
        setSelectedMaterial([])
        setEdges([]);
        if (code.length > 2) {
            setIsLoaded(true)

            const request = await Requests.Materials.getSearchMaterials(code).catch(error => {
                _errors.setError(error.message, 'error', true)
            });
            if (empty(request)) {
                setIsLoaded(false)
                dispatch(errorMessageOperations.switchStateError({
                    message: Languages.getTranslation('material-search-none', true), type: 'warning', show: true
                }))
            }
            if (!empty(request)) {
                setIsLoaded(false)
                setMaterials([]);
                const filteredMaterials = request.filter(({ thickness, w, l, responsetype }) => {
                    return !empty(thickness) && !empty(w) && !empty(l) && (responsetype === 'sheet' || responsetype === 'Sheet');
                });
                    setMaterials((materials) => [...materials, ...filteredMaterials]);
            }
        }
    };

    useEffect(() => {
        if(!empty(currentMaterialLink) && !!currentMaterialLink ){
            const material = currentMaterialLink[0]
            setCurrentMaterial(material)
            setIsMaterialFromLink(true)
        }
        const delay = 500;
        const debounceTimer = setTimeout(() => {
            setDebouncedValue(code);
        }, delay);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [code]);

    useEffect(() => {
        if (debouncedValue !== '') {
            search()
        }
    }, [debouncedValue]);

    useEffect(() => {
        if (typeModal !== "LINK_MATERIAL") {
            input.current.focus();
        }
    }, []);

    const deleteMaterialAndDetails = () => {
        setIsLoaded(true)
        filteredDetailsWithMultiplicity.forEach(det => construction.deleteDetail(det.id))
        if (!empty(deletedMaterial.parts)) {
            async function deleteReservingMaterialsForKronasElements(material, construction) {
                for (const part of material.parts) {
                    if (part.typeParts === 'kronasElement') {
                        await construction.deleteReservingMaterials(construction.projectData.id, part.id);
                    }
                }
            }

            try {
                deleteReservingMaterialsForKronasElements(deletedMaterial, construction)
                  .then(() => construction.deleteMaterial(deletedMaterial.index)
                    .then(() => {
                        close()
                        window.location.reload()
                    }).catch(err => console.log(err)))
            } catch (error) {
                toastWarning(Languages.getTranslation('defaultError', true))
            }
        } else {
            construction.deleteMaterial(deletedMaterial.index)
              .then(() => {
                  close()
                  window.location.reload()
              }).catch(err => console.log(err))
        }
    }

    const submit = () => {
        const sortedMaterials = [...selectedMaterials].map((material) => {
            if (material.edges) {
                delete material.edges
            }
            return material
        })
        if (empty(sortedMaterials) && empty(selectedEdges)) {
            _errors.setError(`${Languages.getTranslation("material-not-chos", true)}`, 'error', true)
            return;
        }

        if (!empty(sortedMaterials) && construction._materials.find((mat) => mat.article === sortedMaterials[0].article)) {
            _errors.setError(`${Languages.getTranslation("material-already-have", true)}`, 'error', true)
            setSelectedMaterial([])
            return;
        }

        setIsDisabled(true)
        construction
            .setProjectMaterials([...sortedMaterials])
            .then(() => {
                const addedEdges = selectedEdges.filter((el) => !construction.edges.find((el1) => el1.article === el.article));
                return construction.setProjectEdges(addedEdges);
            })
            .then(() => {
                if (empty(sortedMaterials) && selectedEdges.length === 1) {
                    dispatch(errorMessageOperations.switchStateError({
                        message: Languages.getTranslation("edge-success", true), type: 'success', show: true
                    }))
                } else if (selectedEdges.length > 1) {
                    dispatch(errorMessageOperations.switchStateError({
                        message: Languages.getTranslation("edges-success", true), type: 'success', show: true
                    }))
                } else {
                    dispatch(errorMessageOperations.switchStateError({
                        message: Languages.getTranslation("material-success", true), type: 'success', show: true
                    }))
                }
                setIsDisabled(false)
                setSelectedMaterial([]);
                setSelectedEdges([]);
                navigate("/details");
                close();
            });
    };

    const setEdge = (edge) => {
        if (selectedEdges.find((edgeV) => edgeV === edge)) {
            setSelectedEdges(selectedEdges.filter((edgeV) => edgeV !== edge));
        } else {
            setSelectedEdges([...selectedEdges, edge]);
        }
    };

    const close = () => {
        setEdge(null)
        setCurrentMaterial(null)

        dispatch(modalsOperations.switchStateForModal({
            show: false, type: null,
        }));
    };
    const getCurrentMaterial = async (article) => {
        const request = await Requests.Materials.getMaterialsCurrent(article, user?.filial?.ttid).catch(error => {
            _errors.setError(error.message, 'error', true)
        });
        if (empty(request)) {
            dispatch(errorMessageOperations.switchStateError({
                message: Languages.getTranslation('material-search-none', true), type: 'warning', show: true
            }))
        } else {
            if (request[0].responsetype === 'Sheet' || request[0].responsetype === 'sheet') {
                setCurrentMaterial(request[0])
                const filteredEdges = request[0]?.edges.filter(({languages}) => languages.uk)

                if (!empty(request[0].edges)) {
                    setEdges(filteredEdges);
                }
            } else if (request[0].responsetype === 'Band') {
                setEdges([filteredEdges]);
            }
        }
    }
    const openConfirmModal = () => {
        setShowConfirmDeleteModal(true)
    }
    const disabled = empty(selectedMaterials) && empty(selectedEdges) || isDisabled
    if (typeModal === "LINK_MATERIAL") {
        return (
            <>
                {!empty(filteredDetailsWithMultiplicity) && (
                    <div className="addMaterial">
                        <div className="addMaterial__header">
                            <h3 className="addMaterial__header-title">
                                {Languages.getTranslation("materials", true)}
                            </h3>

                            <CloseBtn handler={close} />
                        </div>
                        <div className="deleteMaterial__text">
                            {Languages.getTranslation("delete-material-text")}
                        </div>
                        { isLoaded ? <Loader /> : <DetailsForDelete details={filteredDetailsWithMultiplicity}/>}
                        <div className="addMaterial__btns">
                            <div className="addMaterial__actions">
                                <DefaultBtn
                                    title={Languages.getTranslation("cancel", true)}
                                    handler={close}
                                />
                            </div>
                            <DefaultBtn
                                title={Languages.getTranslation("delete", true)}
                                handler={openConfirmModal}
                            />

                        </div>
                        {showConfirmDeleteModal && <SubmitModal
                            submitModalText={submitModalText}
                            setShowSubmitModal={setShowConfirmDeleteModal}
                            isRemoveDetails={true}
                            submitAction={deleteMaterialAndDetails}
                        />}
                    </div>
                )}
            </>
            );
    }
    return (
        <div className="addMaterial">
            <div className="addMaterial__header">
                <h3 className="addMaterial__header-title">
                    {Languages.getTranslation("materials", true)}
                </h3>
                <div className="addMaterial__header-searchField">
                    <i className="addMaterial__input-icon"></i>
                    <input
                        className="addMaterial__input"
                        onChange={(e) => setCode(e.target.value)}
                        ref={input}
                        type="text"
                        placeholder={Languages.getTranslation("add-name-or-article-material", true)}
                        autoComplete='off'
                    />

                </div>
                <CloseBtn handler={close} />
            </div>

            <div className="addMaterial__body">
                 {isLoaded && <Loader />}
                {(!empty(materials) && empty(currentMaterial)) && (

                <div className="addMaterial__body-block">
                 <div className="addMaterial__body-list" >
                    {materials.map((item, index) => {
                        return (
                            <div className="addMaterial__body-list-item" key={index} onClick={() =>getCurrentMaterial(item.article)}>
                                <span className="addMaterial__body-list-item-text">
                                    {item.article || item.code}
                                </span>
                                <span className="addMaterial__body-list-item-text">
                                     {Languages.getAvaliableTranslation(item.languages)}
                                </span>
                                <span className="addMaterial__body-list-item-text">
                                    {item.l}x{item.w}x{item.thickness}
                                </span>
                            </div>
                        )
                    })}
                 </div>
                </div>
                )}
                     {!empty(currentMaterial)  && (<div>
                              <AddCurrentMaterial
                                 currentMaterial={currentMaterial}
                                 isShowCheckbox={true}
                                 setMaterial={setSelectedMaterial}
                                 selectedMaterials={selectedMaterials}
                                 getCurrentMaterial={getCurrentMaterial}
                                 isMaterialFromLink={isMaterialFromLink}
                             />
                             {edges.length ? (
                                 <div className="edges-header">
                                 <div className="edges-header__checkbox">{/* <Checkbox /> */}</div>
                                 <div className="edges-header__title-container">
                                     <h3 className="edges-header__title">{Languages.getTranslation('type-edge', true)}</h3>
                                 </div>

                                 <div className="edges__table">
                                     <div className="edges__data-container">
                                         <div className="edges__data">
                                             {Languages.getTranslation("article", true)}
                                         </div>
                                         <div className="edges__data">
                                             {Languages.getTranslation("width", true)}
                                         </div>
                                         <div className="edges__data">
                                             {Languages.getTranslation("thickness", true)}
                                         </div>
                                     </div>
                                     <div className="edges__table-name">
                                         {Languages.getTranslation("name", true)}
                                     </div>
                                     <div className="edges__data-container-second">
                                         <div className="edges__data">
                                             {Languages.getTranslation("article", true)}
                                         </div>
                                         <div className="edges__data">
                                             {Languages.getTranslation("width", true)}
                                         </div>
                                         <div className="edges__data">
                                             {Languages.getTranslation("thickness", true)}
                                         </div>
                                     </div>
                                 </div>
                             </div>) : (<></>)}
                             <div className="edges">
                                 {edges.length ? edges.map((edge, key) => (<div className="edges__item">
                                     <div key={edge.guid} className="edges__checkbox">
                                         <Checkbox type="edge" value={edge} setEdge={setEdge}/>
                                     </div>
                                     <div className="edges__item-number">{`${key + 1}`}</div>
                                     <div className="edges__title-container">

                                         <img
                                             src={edge?.images?.length > 0 && edge.images[0].hasOwnProperty('small') ? edge.images[0].small : img}
                                             alt="55" className="material__img" />
                                         <h3 className="edges__name">
                                             {Languages.getAvaliableTranslation(edge.languages)}
                                         </h3>
                                     </div>
                                     <div className="edges__data-container">
                                         <div className="edges__data-item"> {edge.article}</div>
                                         <div className="edges__data-item"> {edge.w}</div>
                                         <div className="edges__data-item"> {edge.thickness}</div>
                                     </div>

                                     </div>
                                 )) : <div className="edges__none"></div>}
                             </div>
                         </div>)}
            </div>


            <div className="addMaterial__btns">
                <div className="addMaterial__actions">
                    <DefaultBtn
                        title={Languages.getTranslation("cancel2", true)}
                        handler={close}
                    />
                </div>
                <DefaultBtn
                    title={Languages.getTranslation("save", true)}
                    handler={submit}
                    disabled={disabled}
                />

            </div>
        </div>);
};

export default AddMaterialAndEdges;
