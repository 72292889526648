import {List} from "react-virtualized";
import {Detail} from "./Detail";
import {atLeastOneHasErrors} from "../helper";
import {useCallback} from "react";
import _Details from "../../../../../db/_Details";
import {useProjectTableContext} from "../../../../../hooks/useContexts";

const detailsDb = new _Details();

const Table = ({ selectDetail, selectedDetails, remove, details, setDetails, height, isScrolling, onChildScroll, scrollTop, width}) => {
    const {tableRef} = useProjectTableContext();
    const handleDragStart = useCallback((e, detail) => {
        e.dataTransfer.setData('order', detail?.order);
        e.dataTransfer.setData('dragId', detail.id);
    }, []);

    const handleDrop = useCallback((e, targetOrderId) => {
        e.preventDefault();
        const draggingOrderId = e.dataTransfer.getData('order');

        setDetails((prevDetails) => {
            const draggingItem = prevDetails.find(detail => detail.order === Number(draggingOrderId));
            const targetItem = prevDetails.find(detail => detail.order === targetOrderId);

            const draggingIndex = prevDetails.indexOf(draggingItem);
            const targetIndex = prevDetails.indexOf(targetItem);

            if (draggingIndex !== -1 && targetIndex !== -1) {
                const newDetails = [...prevDetails];

                const [removed] = newDetails.splice(draggingIndex, 1);
                newDetails.splice(targetIndex, 0, removed);

                newDetails.forEach((item, index) => {
                    item.order = index + 1;
                });

                Promise.all(newDetails.map((item, index) =>
                    detailsDb.updateDetail(item.id, "order", index + 1)))

                return newDetails;
            }

            return prevDetails;
        });
    }, [setDetails]);

    const dragOverHandler = useCallback((e) => {
        e.preventDefault()
    }, []);

    const rowRenderer = ({index, style}) => {
        const detail = details[index];
        const hasErrors = atLeastOneHasErrors(details);

        return (
            <Detail
                key={detail.id}
                style={style}
                selectDetailHandler={selectDetail}
                detail={detail}
                index={index}
                hasErrors={hasErrors}
                remove={remove}
                isSelected={selectedDetails.includes(detail)}
                dragEndHandler={dragOverHandler}
                dragOverHandler={dragOverHandler}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
            />
        )
    }

    return (
        <List
            ref={tableRef}
            autoHeight
            className="default-details-table"
            height={height}
            isScrolling={isScrolling}
            onScroll={onChildScroll}
            overscanRowCount={3}
            rowCount={details.length}
            rowHeight={70}
            rowRenderer={rowRenderer}
            scrollTop={scrollTop}
            width={width}
        />
    )
};

export default Table;