import React, {useEffect, useId, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations} from "redux/project";
import {modalsOperations, modalsSelectors} from "redux/modals";
import {empty} from "../../../helpers/helper";
import Languages from "../../../translation/Languages";
import {panelOperations, panelSelectors} from "redux/panel";
import {Tooltip} from "react-tooltip";
import './style.scss';

const DeleteItem = ({ deleteAll, param, idx, paramMain, handlerRemove }) => {
	const uId = useId()
	const [headerValues, setHeaderValues] = useState([])
	const [bodyValues, setBodyValues] = useState([])
	const [isShow, setIsShow] = useState(false)

	useEffect(() => {
		if (param) {
			setHeaderValues([...Object.keys(createParamsHandler(param))])
			setBodyValues([...Object.values(createParamsHandler(param))])
		}
	}, [param, paramMain, param.isErrorText])

	useEffect(() => {
		setIsShow(deleteAll)
	}, [deleteAll]);

	const edgeIcons = (item) => {
		if (item === "right_top") {
			return 'chosProc-icon chosProc-icon__angle-right-top'
		}
		if (item === "right_bottom") {
			return 'chosProc-icon chosProc-icon__angle-right-bottom'
		}
		if (item === "left_top") {
			return 'chosProc-icon chosProc-icon__angle-left-top'
		}
		if (item === "left_bottom") {
			return 'chosProc-icon chosProc-icon__angle-left-bottom'
		}
		if (item === "ver") {
			return 'chosProc-icon chosProc-icon__vert-proc'
		}
		if (item === "hor") {
			return 'chosProc-icon chosProc-icon__horiz-proc'
		}
		if (item === 'front') {
			return 'chosProc-icon chosProc-icon__face-platform'
		}
		if (item === 'back') {
			return 'chosProc-icon chosProc-icon__back-platform '
		}
		if (item === 'right') {
			return 'chosProc-icon chosProc-icon__right-platform'
		}
		if (item === 'left') {
			return 'chosProc-icon chosProc-icon__left-platform'
		}
		if (item === 'top') {
			return 'chosProc-icon chosProc-icon__top-platform '
		}
		if (item === 'bottom') {
			return 'chosProc-icon chosProc-icon__bottom-platform'
		}
		return errorClassNameData
	}

	const textStyle = (item) => {
		if (sidesArr.includes(item)) {
			return ''
		}
		return typeof item === 'number' ? item.toFixed(1) : item
	}

	const itemAxis = (item1, item2) => {
		return `${item1}_${item2}`
	}

	const createParamsHandler = (item) => {
		switch (item.subType || item.type) {
			case "corner":
			case "radius":
			case "line":
				return {
					[Languages.getTranslation("corner", true)]: item.angle,
					[Languages.getTranslation("proce", true)]: item.type === 'line' && item.type !== 0
						? Languages.getTranslation("slice", true)
						: Languages.getTranslation("radius", true),
					[Languages.getTranslation("radius", true)]: item.r,
					'x': item.x,
					'y': item.y,
					[Languages.getTranslation("edge", true)]: !empty(item.edge)
						? Languages.getTranslation("yes", true)
						: Languages.getTranslation("no", true),
				}

			case "Groove":
				return {
					[Languages.getTranslation("side", true)]: item.side,
					[Languages.getTranslation("height", true)]: item.width,
					[Languages.getTranslation("width", true)]: item.height,
					[Languages.getTranslation("depth", true)]: item.depth,
					"х": item.x,
					"у": item.y,
					[Languages.getTranslation("radius", true)]: item.r,
				};

			case "Rabbet":
				return {
					[Languages.getTranslation("side", true)]: item.side,
					[Languages.getTranslation("height", true)]: item.width,
					[Languages.getTranslation("width", true)]: item.height,
					[Languages.getTranslation("depth", true)]: item.depth,
				};

			case "Circle":
				return {
					[Languages.getTranslation("type", true)]: Languages.getTranslation("circle-proc", true),
					[Languages.getTranslation("bind", true)]: item.side,
					"Х": item.x,
					"У": item.y,
					[Languages.getTranslation("radius", true)]: item.r,
				};

			case "Rectangle":
				return {
					[Languages.getTranslation("type", true)]: Languages.getTranslation("rectangle-proc", true),
					[Languages.getTranslation("bind", true)]: item.side,
					"Х": item.x,
					"У": item.y,
					"L": item.height,
					"W": item.width,
					[Languages.getTranslation("radius", true)]: item.r
				};

			case "uShape":
				return {
					[Languages.getTranslation("tip", true)]: item.edgeSide,
					[Languages.getTranslation("displacement", true)]: item.offset,
					[Languages.getTranslation("height", true)]: item.width,
					[Languages.getTranslation("width", true)]: item.height,
					[Languages.getTranslation("radius", true)]: item.r,
					[Languages.getTranslation("edge", true)]: !empty(item.edge)? Languages.getTranslation("yes", true)
						: Languages.getTranslation("no", true),
				}

			case 'bevel':
				return {
					[Languages.getTranslation("side", true)]: item.side,
					[Languages.getTranslation("tip", true)]: item.start,
					[Languages.getTranslation("displacement", true)]: item.start,
					[Languages.getTranslation("corner", true)]: item.alpha,
				}

			case "hole":
				return {
					[Languages.getTranslation("side", true)]: param.side,
					"Х": param.x,
					"У": param.y,
					[Languages.getTranslation("diam", true)]: param.diam,
					[Languages.getTranslation("depth", true)]: param.depth,
				}
			case 'mill':
				switch (item.type) {
					case 'uShape' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("U-shaped-neckline", true),
							[Languages.getTranslation("tip", true)]: item.edgeSide,
							[Languages.getTranslation("displacement", true)]: item.offset,
							[Languages.getTranslation("height", true)]: item.width,
							[Languages.getTranslation("width", true)]: item.height,
							[Languages.getTranslation("radius", true)]: item.r,
							[Languages.getTranslation("edge", true)]: !empty(item.edge) ? Languages.getTranslation("yes", true)
								: Languages.getTranslation("no", true),
						}
					case 'gEdge' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("G-shaped-neckline", true),
							[Languages.getTranslation("corner", true)]: item.angle,
							[Languages.getTranslation("width", true)]: item.height,
							[Languages.getTranslation("height", true)]: item.width,
							"r": item.r,
							[Languages.getTranslation("edge", true)]: !empty(item.edge) ? Languages.getTranslation("yes", true)
								: Languages.getTranslation("no", true),
						}
					case 'smile' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("smile", true),
							[Languages.getTranslation("tip", true)]: item.edgeSide,
							[Languages.getTranslation("width", true)]: item.width,
							[Languages.getTranslation("displacement", true)]: item.offset,
							[Languages.getTranslation("edge", true)]: !empty(item.edge) ? Languages.getTranslation("yes", true)
								: Languages.getTranslation("no", true),
						}
					case 'tableTop' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("Lock-tabletop", true),
							[Languages.getTranslation("tip", true)]: item.edgeSide,
							[Languages.getTranslation("width", true)]: item.width,
						}
					case 'tableCorner' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("Radius-angle-table-top", true),
							"кут": item.angle,
							"r": item.r,
							[Languages.getTranslation("edge", true)]: !empty(item.edge) ? Languages.getTranslation("yes", true)
								: Languages.getTranslation("no", true),
						}
				}
				break
			default:
				return item;
		}
	};

	const deleteParam = () => {
		handlerRemove(param)
		setIsShow(prevState => !prevState)
	};

	const millClassForItem = param.subType==='mill' ? 'millClassForItem' : ''
	const millClassForHeader = param.subType==='mill' ? 'millClassForHeader' : ''

	const errorClassName = `deleteProcesses__item ${millClassForItem}`
	const errorClassNameData = `deleteProcesses__data `

	const sidesArr = ['right_top', 'right_bottom', 'left_top', 'left_bottom', 'ver', "hor", 'front', 'back', 'right', 'left', 'top', 'bottom']
	const grooveStyle = param.subType === 'groove' ? 'deleteProcesses__item-header-groove' : 'deleteProcesses__item-header'

	return <div
				className={`${errorClassName}`}
				key={idx}
				id={idx}
				style={{height: param.subType === 'groove' ? 71 : ''}}>
		<div
			className="deleteProcesses__number">
			<div className="deleteProcesses__item-number1" style={{fontSize: 14}}>№</div>
			<div className="deleteProcesses__item-number deleteProcesses__data"
				style={{background: "transparent", minWidth: 15, height: 21}}>{idx + 1}</div>
		</div>
		<div
			className={`deleteProcesses__item-datas ${isShow ? 'deleteProcesses__item_deleted' : ''}`}>
			<div className={`${grooveStyle} ${millClassForHeader}`}>
				{headerValues.map((el, id) => <div className={`deleteProcesses__item-header-title`} key={id}>{el}</div>)}
			</div>
			<div className="deleteProcesses__item-body">
				{bodyValues.map((el, id) => <div className={`${edgeIcons(el)}`} key={id}>{textStyle(el)}</div>)}
			</div>
		</div>
		<div className="deleteProcesses__btns">

			<div className="deleteProcesses__btns-container">
				<button className="deleteProcesses__btn-delete" onClick={deleteParam}
					title={Languages.getTranslation('delete-proc', true)}></button>
			</div>
		</div>
		{/*{param.isErrorText !== '' &&*/}
		{/*	<Tooltip*/}
		{/*		className='deleteProcesses__item_tooltip-error'*/}
		{/*		id={`error-${uId}`}*/}
		{/*		place="bottom-center"*/}
		{/*		variant='light'*/}
		{/*		// openOnClick={true}*/}
		{/*	>*/}
		{/*		<div className='deleteProcesses__item_tooltip-error_body'>*/}
		{/*			{`${param.isErrorText}`}*/}
		{/*		</div>*/}
		{/*	</Tooltip>*/}
		{/*}*/}
	</div>

}

export default DeleteItem