import {useEffect, useState} from "react";
import "./style.scss";

const Checkbox = (props) => {

    const [isClicked, setIsClicked] = useState(props.isChecked || false);

	const activeCheckbox = isClicked ? "detailsTab__checkbox detailsTab__checkbox_active" : "detailsTab__checkbox";
	const activeIcon = isClicked ? "checkbox__icon checkbox__icon_active" : "checkbox__icon";

    useEffect(() => {
        if (props.materials && !props.materials.length && !props.isChecked) {
            setIsClicked(false)
        }
    }, [props])

    useEffect(() => {
        if (props.isChecked) {
            props.setMaterial(prevArray => [...prevArray, props.value]);
        }

    }, [])


    const changeProp = () => {
        setIsClicked(!isClicked);

        if (props.type && props.type == 'detail') {
            props.value._select = !props.value._select
            return
        }
        if (props.type && props.type === 'edge') {
            props.setEdge(props.value)
            return
        }
        if (props.type && props.type === 'value') {
            props.setValue(props.value)
            return
        }
        if (props && props.value) {
            if (isClicked) {
                props.setMaterial([]);
            } else {
                props.setMaterial(prevArray => [...prevArray, props.value]);
            }
        }
    }
    return (
        <>
            <div className={activeCheckbox} onClick={() => changeProp()}>
                <i className={activeIcon}></i>
            </div>
        </>
    );
};

export default Checkbox;
