import Helpers from "./3D/Helpers";
import Processing from "./3D/Processing";
import Languages from "../translation/Languages";

export default class CurvedLine extends Processing {
	_contour = [];
	_subType = 'curvedLines';
	_isActive = false;
	_isErrorText = "";
	_type = 'gEdge';
	_angle;
	_edge;
	constructor({
		            dataForConstructor = {},
		            x = 250,
								y = 250,
		            angle = dataForConstructor?.angle ?? "right_bottom",
								edge = null,
		            detail,
		            comment = "",
								r = 40,
	            }) {
		super({x, y, r, detail, comment});
		this._edge = edge
		this._angle = angle;
		this.gEdge()
		this.addParams()
	}

	addParams() {
		this.setFormField({
			name: 'type', value: this.type, label: Languages.getTranslation('type-of-proces', true), type: 'select', variables: [
				{key: "", value: Languages.getTranslation('Not-backwards', true)},
				{key: "gEdge", value: Languages.getTranslation('lShape-proc1', true)},
			]
		})
		this.setFormField({name: 'x', label: 'x', value: this.x, type: 'number', visible: this.type === "gEdge"})
		this.setFormField({name: 'y', value: this.y, type: 'number', label: 'y', visible: this.type === "gEdge"})
		this.setFormField({name: 'r', value: this.r, type: 'number', label: Languages.getTranslation('radius', true), visible: this.type === "gEdge"})
	}

	get paramsSorting() {
		const sortingArr = ['type', 'r', 'x', 'y', "comment"]

		return this.formFields.sort((a, b) => sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
	}

	get angle() {
		return this._angle;
	}

	set angle(angle) {
		this._angle = angle;
		this.updateDataForConstructor({name: 'angle', value: angle})
		this.buildDetail()
			.then(() => this.renderDetail());
	}

	get type() {
		return this._type;
	}

	set type(type) {
		this._type = type
	}

	get edge() {
		return this._edge;
	}

	set edge(edge) {
		this._edge = edge
	}

	get contour() {
		return this._contour
	}

	set contour(contourItem) {
		return this._contour = contourItem
	}

	gEdge() {
		let newContour = []
		switch (this.angle){
			case 'left_bottom' :
				newContour.push(Helpers.createLine(this.x, 0, this.x, this.y - this.r, this.edge))
				newContour.push(Helpers.createArc(this.x, this.y - this.r, this.x - this.r, this.y, this.r, this.x - this.r, this.y - this.r, false, this.edge))
				newContour.push(Helpers.createLine(this.x - this.r, this.y, 0, this.y, this.edge))
			break
			case 'left_top' :
				newContour.push(Helpers.createLine(0, this.detail.h - this.y, this.x - this.r, this.detail.h - this.y, this.edge))
				newContour.push(Helpers.createArc(this.x - this.r, this.detail.h - this.y, this.x, this.detail.h - this.y + this.r, this.r, this.x - this.r, this.detail.h - this.y + this.r, false, this.edge))
				newContour.push(Helpers.createLine(this.x, this.detail.h - this.y + this.r, this.x, this.detail.h, this.edge))
				break
			case 'right_top' :
				newContour.push(Helpers.createLine(this.detail.l - this.x - this.r, this.detail.h, this.detail.l - this.x - this.r, this.detail.h - this.y, this.edge))
				newContour.push(Helpers.createArc(this.detail.l - this.x - this.r, this.detail.h - this.y, this.detail.l - this.x, this.detail.h - this.y - this.r, this.r, this.detail.l - this.x, this.detail.h - this.y, false, this.edge ))
				newContour.push(Helpers.createLine(this.detail.l - this.x, this.detail.h - this.y - this.r, this.detail.l, this.detail.h - this.y - this.r, this.edge))
			break
			case 'right_bottom' :
	      newContour.push(Helpers.createLine(this.detail.l, this.y, this.detail.l - this.x, this.y, this.edge))
				newContour.push(Helpers.createArc(this.detail.l - this.x, this.y, this.detail.l - this.x - this.r, this.y - this.r, this.r,this.detail.l - this.x, this.y - this.r, false, this.edge ))
	      newContour.push(Helpers.createLine(this.detail.l - this.x - this.r, this.y - this.r, this.detail.l - this.x - this.r, 0, this.edge))
			break
		}
		this._contour = newContour
	}

}