import img from "../../../../../media/material-test.png";
import {edgeTitle} from "../../../../../helpers/helper";
import React from "react";

const EdgeHelper = ({edges, edgeHelperRef, leftPoint2})=>{
	return 	<div className="quickInputTable__edges-helper" ref={edgeHelperRef} style={{left: leftPoint2 + 10 }}>
		{edges.map((edge, edgeIdx) => <div className="quickInputTable__edges-helper-item" key={edgeIdx}>
			<div className="edgesHelper__number">{edgeIdx + 1}</div>
			<img src={img} alt="img" className='edgesHelper__img'/>
			<div className="edgesHelper__item-name">{edgeTitle(edge)}</div>
		</div>)}
	</div>
}

export default EdgeHelper