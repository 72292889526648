import React, {useEffect, useState} from "react";
import DeleteItem from "./DeleteItem";
import Languages from "../../../translation/Languages";
import { ReactComponent as Arrow } from '../../../media/angle-down-purpur.svg';
import './style.scss';
import {empty} from "../../../helpers/helper";

const DeleteProcessesContainer = ({param, idx, createComment, detail, handlerRemove }) => {
	const [currentParams, setCurrentParams] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [deleteAll, setDeleteAll] = useState(false)

	const updateParams = () => {
		if ( !empty(param.items)) {
			// if (param.items[0].type === 'mill') {
			// 	setCurrentParams([...param.items[0]].filter(el => ['gEdge', 'uShape'].includes(el.type)))
			// 	return
			// }
			setCurrentParams([...param.items.filter(item => item.type !== 'partial')])
		} else {
			setCurrentParams([])
		}
	}

	useEffect(() => {
		updateParams()
	}, [param])

	const deleteAllProc = () => {
		setDeleteAll(prevState => !prevState)
		handlerRemove(param, true, !deleteAll)
	}

	const delOneProc = (item) => {
		handlerRemove(item, false)
		let all = true
		param.items.forEach(i => {
			if(!i.hasOwnProperty('remove') && i.type !== "partial"){
				all = false
			}
		})
		setDeleteAll(all)
	}

	return currentParams && currentParams.length > 0 &&
		<div className="deleteProcesses__item-container">
			<div className="deleteProcesses__title" key={idx}>
				<div className="deleteProcesses__title_wrapper">
					<i className="deleteProcesses__title-icon"></i>
					<span className="deleteProcesses__title-text">{param.name}</span>
				</div>
				<div className='deleteProcesses__title_delAll' >
					{Languages.getTranslation( `${deleteAll ? 'deselect-treatments' : 'delete-all-treatments'}`, true)}
					<button
						onClick={deleteAllProc}
						title={Languages.getTranslation('delete-all-treatments', true)}
						className="deleteProcesses__btn-delete"></button>
					<Arrow
						onClick={() => setIsOpen(prevState => !prevState)}
						className={`${!isOpen ? 'deleteProcesses__title_delAll_arrow-rotate' : 'deleteProcesses__title_delAll_arrow-down'} deleteProcesses__title_delAll_svgSize`} />
				</div>
			</div>
			{isOpen && currentParams.map((item, id) => <DeleteItem
													deleteAll={deleteAll}
													type={param.type}
													handlerRemove={delOneProc}
													param={item}
													idx={id}
													updateParams={() => updateParams()}
													detail={detail}
													createComment={createComment}
													paramMain={param}/>
			)}
		</div>
}

export default DeleteProcessesContainer