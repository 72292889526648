import React from "react";
import Languages from "../../../../../translation/Languages";
import {materialTitle} from "../../../../../helpers/helper";

const ReplaceMaterialProjectItem2 = ({material, idx})=>{
	return <div
		className="replaceMaterialProject__material"
		key={idx}>
		<div className="replaceMaterialProject__content-container">
			<div className="replaceMaterialProject__number">{idx + 1}</div>
			<div
				className="replaceMaterialProject__name">{materialTitle(material)}</div>
		</div>
		<div className="replaceMaterialProject__data-container">
			<div className="replaceMaterialProject__data-item">
				<span className="replaceMaterialProject__data">{material.article || material.code}</span>
			</div>
			<div className="replaceMaterialProject__data-item">
				<span className="replaceMaterialProject__data">{material.height || material.l}</span>
			</div>
			<div className="replaceMaterialProject__data-item">
				<span className="replaceMaterialProject__data">{material.width || material.w}</span>
			</div>
			<div className="replaceMaterialProject__data-item">
				<span className="replaceMaterialProject__data">{material.thickness}</span>
			</div>
		</div>
		<div className='replaceMaterialProject__table-header_invincible'></div>
	</div>
}

export default ReplaceMaterialProjectItem2