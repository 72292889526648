import React, { useState, useEffect, useRef } from "react";
import CloseBtn from "../../Buttons/CloseBtn";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import { empty } from "../../../../helpers/helper";
import { useSelector } from "react-redux";
import { projectSelectors } from "redux/project";
import Requests from "api/API";
import Errors from "models/Errors";
import './style.scss';
import txtIcon from "../../../../media/txt-icon.svg";
import Loader from "../../Loader";
import Languages from "../../../../translation/Languages";

const MAX_TOTAL_SIZE = 20 * 1024 * 1024; // 20MB in bytes
const _errors = new Errors([])

const QARequestModal = ({ closeModal, openSuccessModal }) => {
  const construction = useSelector(projectSelectors.getConstruction);
  const projectId = construction?.projectData?.id === 0 ? '' : construction?._projectData?.id;
  const [formData, setFormData] = useState({
    subjectLetter: "",
    textLetter: "",
    files: [],
    projectId,
  });
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    const totalSize = formData.files.reduce((acc, file) => acc + file.size, 0);
    setIsBtnDisabled(empty(formData.subjectLetter) || empty(formData.textLetter) || totalSize > MAX_TOTAL_SIZE)
  }, [formData]);

  const submit = async (event) => {
    event.preventDefault();
    setIsLoaded(true)
    setIsBtnDisabled(true)
    const { subjectLetter, textLetter, files, projectId } = formData;
    const dataToSend = {
      title: subjectLetter,
      message: textLetter,
      project_file_id: projectId,
      filename: files,
    };

    Requests.Projects.sendSupportMessage(dataToSend).then((res) => {
      if (res && res.data) {
        setIsLoaded(false)
        closeModal()
        openSuccessModal()
      }
    }).catch(error => {
      _errors.setError(error?.response?.data?.message, 'error', true)
    })
  };

  const handleTextChange = (e) => {
    setFormData({
      ...formData,
      textLetter: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    setFormData({
      ...formData,
      files: [...formData.files, ...selectedFiles].slice(0, 5),

    });
  };

  const handleSubjectChange = (e) => {
    setFormData({
      ...formData,
      subjectLetter: e.target.value,
    });
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  }

  const handleRemoveFile = (index) => {
    const filteredFiles = formData.files.filter((_, i) => i !== index)

    setFormData({
      ...formData,
      files: filteredFiles
    });
    fileInputRef.current.value = null;
  }
  const handleClipboardPaste = async (e) => {
    try {
      const clipboardData = e.clipboardData || window.clipboardData;
      if (clipboardData && clipboardData.items) {
        for (let i = 0; i < clipboardData.items.length; i++) {
          const item = clipboardData.items[i];
          if (item.kind === "file" && item.type.startsWith("image/")) {
            const blob = item.getAsFile();
            if (blob) {
              const file = new File([blob], "pasted-image.png", { type: "image/png" });
              setFormData({
                ...formData,
                files: [...formData.files, file].slice(0, 5),
              });
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="form">
      <form className="form__content" onSubmit={submit} style={{
        width: '731px',
        minHeight: '657px',
      }}>
        <div className="form__header">
          <span className="form__header-title">{Languages.getTranslation('Kronas-Master-3D-support-service', true)}</span>
          <span className="form__close-btn"><CloseBtn handler={closeModal} /></span>
        </div>
        <div className="form__subject" >
          <span className="form__subject-title">{Languages.getTranslation('subject-email', true)}</span>
          <textarea
            className="form__subject-area form__subject-area-title"
            name="subjectLetter"
            placeholder={Languages.getTranslation("text-email", true)}
            value={formData.subjectLetter}
            onChange={handleSubjectChange}
          />
        </div>
        <br />
        <div className="form__subject">
          <span className="form__subject-title">{Languages.getTranslation('subject-email', true)}</span>
          <textarea
            className="form__subject-area form__subject-area-text"
            name="textLetter"
            cols="100"
            placeholder={Languages.getTranslation("subject-email", true)}
            value={formData.textLetter}
            onChange={handleTextChange}
            onPaste={handleClipboardPaste}
          />
        </div>
        <div className="form__additions">
          {!empty(formData.files) && (
              <>
              <span className="form__header-file">{Languages.getTranslation('added-files', true)}</span>
            <div className="form__list">
              {formData.files.map((file, index) => (
                <div key={index} className="form__list-item">
                  <span className="form__list-item-block">
                    <img
                      src={file.type.includes('image') ? URL.createObjectURL(file) : txtIcon}
                      className="form__list-item_img"
                    />
                    <span className="form__list-item_name">{file.name}</span>
                  </span>
                  <span onClick={() => handleRemoveFile(index)} className="form__list-item_trash"></span>
                </div>
              ))}
            </div>
              </>

          )}
        </div>
        <div className="form__footer">
          <div className="form__files" onClick={handleFileButtonClick}>
            <input
              className="form__files-input"
              type="file"
              name="files"
              ref={fileInputRef}
              accept=".jpg, .jpeg, .png, .bmp, .ppt, .pptx, .doc, .docx, .pdf, .xls, .xlsx, .json, .xml, .project, .kronas, .b3d, .mpr, .lc4, .gibcut, .ptx, .dwg, .dxf, .wes"
              onChange={handleFileChange}
              multiple
            />
            <span className="form__files-clip" />
            <span className="form__files-text">{Languages.getTranslation('add-new-file', true)}</span>
          </div>
          <DefaultBtn title={Languages.getTranslation("send-message", true)} disabled={isBtnDisabled} />
        </div>
      </form>
      {isLoaded && <Loader />}
    </div>
  )
}

export default QARequestModal
