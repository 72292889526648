import CloseBtn from "components/UI/Buttons/CloseBtn";
import "./style.scss";
import Languages from "translation/Languages";
import {useDispatch, useSelector} from "react-redux";
import {modalsOperations} from "redux/modals";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import {projectSelectors} from "redux/project";
import {useNavigate} from "react-router-dom";
import Project from '../../../../models/Project'
import {errorMessageOperations} from "redux/errors";

const newProjectOrLoadOrLoad = ({setIsEmpty}) => {
    const dispatch = useDispatch();
    const construction = useSelector(projectSelectors.getConstruction);

    const close = () => {
        Project.loadProject(null, dispatch)
            .then(() => Project.validateProjectDetails())
            .then(() => {
                window.location.href = window.location.href.replace('?action=create', '')
            })
            .catch(err => console.log(err))
    };

    const create = () => {
        construction.startNewProject()
            .then(() => {
                dispatch(errorMessageOperations.removeAllErrors({message: '', drop: true}))
                setIsEmpty([])
            })
            .catch(error => console.log(error))
    }

    return (
        <div className='modal'>
            <div className="newProjectOrLoad">
                <div className="newProjectOrLoad__header">
        <span className="newProjectOrLoad__header-title">
          {Languages.getTranslation("new-project", true)}
        </span>
                </div>
                <div className="newProjectOrLoad__body">
                    {Languages.getTranslation("new-project-or-load-text", true)}
                </div>
                <div className="newProjectOrLoad__actions">
                    <DefaultBtn
                        title={Languages.getTranslation("load-project", true)}
                        handler={close}
                    />
                    <DefaultBtn
                        title={Languages.getTranslation("create-project", true)}
                        handler={create}
                    />

                </div>
            </div>
        </div>
    );
};

export default newProjectOrLoadOrLoad;
