import ACTIONS from './actions';

const setFileProject = ({file}) => {
  return async dispatch => {
    try {
      dispatch(ACTIONS.FileStateProject({file: file}))
    } catch (e) {
      console.log(e)
    }
  };
}

export default {
  setFileProject
};
