import {checkForAnyHandles, checkForMilling, empty} from "../../../../../helpers/helper";
import img from "../../../../../media/material-test.png";
import drill from "../../../../../media/drill.svg";
import saw from "../../../../../media/saw.svg";
import cutter from "../../../../../media/cutter.svg";
import multiplicityImg from "../../../../../media/multiplicity.svg";
import {Tooltip} from "react-tooltip";
import Languages from "../../../../../translation/Languages";
import {memo} from "react";
import {TooltipPortal} from "../../../TooltipPortal";
import {multiplicityArray} from "../../../Modal/MultiplicityModel";

const Handlers = ({detail}) => {
    return (
        <>
            {!empty(detail.holes) &&
                <span title='Сверління' className="pointer">
                    <img src={drill} alt="Сверління"/>
                </span>
            }
            {(!empty(detail.grooves) || !empty(detail.rabbets)) &&
                <span title='Пази, Чверті' className="pointer">
                    <img src={saw} alt="Пази, Чверті"/>
                </span>
            }
            {checkForMilling(detail) &&
                <span title='Фрезерування' className="pointer">
                    <img src={cutter} alt="Фрезерування"/>
                </span>
            }
            {!empty(detail.multiplicity) &&
                <span title='Сшивка' className="pointer"
                      data-tooltip-id={`${detail.multiplicity !== null && detail.multiplicity?.type > 0 ? `multiplicity-tooltip-${detail.id}` : ''}`}>
                    <img src={multiplicityImg} alt="Сшивка"/>
                </span>
            }
            {!checkForAnyHandles(detail) &&
                <span title='Немає обробок' className="pointer">
                    —
                </span>
            }

            <TooltipPortal>
                <Tooltip
                    key={`m-${detail.id}`}
                    className='tooltip-multiplicity'
                    id={`multiplicity-tooltip-${detail.id}`}
                    place="right-center"
                    variant='light'
                >
                    <div className='tooltip-multiplicity_body'>
                        <div className='tooltip-multiplicity_header'>
                            {Languages.getTranslation("stitching-type", true)}
                        </div>
                        {multiplicityArray.sort((a, b) => a.number - b.number).map((item, index) =>
                            !index ? null :
                                <div key={index}
                                     className={`tooltip-multiplicity_body_wrapper ${detail.multiplicity?.type === item.number ? 'selectedM' : ''}`}>
                                    <div className="tooltip-multiplicity_body_wrapper_sub">
                                        <div
                                            className="tooltip-multiplicity_body_wrapper_item">{`${item.number}. `}</div>
                                        <div className="tooltip-multiplicity_body_wrapper_item">{item.nameMin}</div>
                                    </div>
                                    <img className="tooltip-multiplicity_body_wrapper_img" src={item.img}
                                         alt={item.name}/>
                                </div>
                        )}
                    </div>
                </Tooltip>
            </TooltipPortal>
        </>
    )
};

export default memo(Handlers);