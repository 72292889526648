import {forwardRef, memo} from "react";

const CustomInput = forwardRef(({onClick, onChange, onKeyDown, value, name, className, isDisabled}, ref) => {

    return <input
        onClick={onClick}
        disabled={isDisabled}
        ref={ref}
        name={name}
        className={`detailsTab__input ${className}`}
        type="text"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete='off'
    />
});

export default memo(CustomInput)