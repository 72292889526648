import {
	Mesh,
	Shape,
	ShapeGeometry,
	Group,
	EdgesGeometry,
	LineSegments
} from 'three'

import {empty} from "../../helpers/helper";
import Helpers from "./Helpers";
import CutOut from "./CutOut";

const ShapeGeometrySegments = 32

class Face3D {
	contourClass;
	_holes;
	_side;
	multiplicity;
	shapes = [];
	constructor({w, h, l,
		            shapes = [],
		            side,
		            corners = [],
		            contour = [],
		            mills = [],
		            cutOuts = [],
		            holes= [],
		            multiplicityClass,
		bevels = []
	}) {
		this._holes = holes;
		this._side = side;
		this.multiplicity = multiplicityClass || null;
		this.w = w
		this.h = h
		this.l = l

		this.contour = contour.contour;

		this.cutOut = new CutOut({
			h: this.h,
			l: this.l,
			w: this.w,
			cutOuts,
			bevels,
			contour: this.contour,
			shapes,
			side
		})




		this.mills = mills;
		this.meshes = [];



		const geometry = this.build()

		this.mesh = new Group
		const color = this._side === "back" ? Helpers.backSidemMaterialColor : null
		this.mesh.add(new Mesh(geometry, Helpers.getMaterial3D(color)))



		for (let mesh of this.meshes) {
			if(!empty(mesh)) {
				this.mesh.add(mesh)
			}
		}

		if (side === 'back') {
			this.mesh.scale.x = -1
		}

	}

	set w(w) {
		this._w = w
	}

	set h(h) {
		this._h = h
	}

	set l(l) {
		this._l = l
	}

	get w() {
		return this._w
	}

	get h() {
		return this._h
	}

	get l() {
		return this._l
	}

	getContourPositionX(x) {
		return x - this.l / 2
	}

	getContourPositionY(y) {
		return y - this.h / 2
	}

	build() {
		const w = this._w / 2

		let squareShape = new Shape();

		const contours = this.contour;

		squareShape.moveTo(0, 0);
		contours.forEach(contour => {
			if(empty(contour)) return;
			switch (contour.type) {
				case 'line':
					squareShape.moveTo(this.getContourPositionX(contour.x1), this.getContourPositionY(contour.y1))
					squareShape.lineTo(this.getContourPositionX(contour.x2), this.getContourPositionY(contour.y2));
					break
				case 'arc':
					squareShape.absarc(this.getContourPositionX(contour.xc), this.getContourPositionY(contour.yc), contour.r, contour.startAngleRad, contour.endAngleRad, contour.dir);
					break
			}
		})

		const _cutOuts = this.cutOut.build();
		_cutOuts.forEach(el => {
			if(!empty(el.mesh)) {
				this.meshes.push(el.mesh);
			}
		})
		if(this.multiplicity && this._side === this.multiplicity.side) {
			this.multiplicity.cutOuts = this.cutOut;
			this.meshes.push(...this.multiplicity.getMesh())
		}

		if(!empty(this.multiplicity.shapes)) {
			this.shapes.push(...this.multiplicity.shapes);
			const faceShapes = [];
			const holesShapes = [];
			if(this.multiplicity.parts > 1) {
				const fondShapes = [];
				const foundHoles = []
				this.multiplicity.parts.forEach((part, i) => {
					this.cutOut.createFaceShape(part, false).forEach(shape => {
						if(i === 0) {
							fondShapes.push(shape);
						}
						if(!faceShapes.find(el => el.uuid !== shape.uuid) && fondShapes.find(el => el.uuid !== shape.uuid)) {
							faceShapes.push(shape)
						}
					})
					this._holes.forEach((hole, i) => {
						if(!Helpers.rectanglesAreNested(
							Helpers.getHoleRectangle(hole.x - this.l / 2, hole.y - this.h / 2, hole.diam / 2),
							part
						)) {
							if(i === 0) {
								foundHoles.push(hole)
							}
							if(!foundHoles.find(h => h === hole) && !holesShapes.find(h => h === hole)) {
								holesShapes.push(hole)
							}
						}
					})
				})
			} else {
				this.cutOut.createFaceShape(this.multiplicity.parts[0], false).forEach(shape => {
						faceShapes.push(shape)
				})
				this._holes.forEach(hole => {
					if(!Helpers.rectanglesAreNestedLB(
						Helpers.getHoleRectangle(hole.x - this.l / 2, hole.y - this.h / 2, hole.diam / 2),
						this.multiplicity.parts[0]
					)) {
						holesShapes.push(hole);
					}

				})
			}
			if(!empty(holesShapes)) {
				holesShapes.forEach(hole => {
					const holeShape = new Shape();
					const r = hole.diam / 2;
					const cx = hole.calcX() - this.l / 2;
					const cy = hole.calcY()  - this.h / 2;
					holeShape.moveTo(cx, cy)
					holeShape.absellipse(cx, cy, r, r, 0, Math.PI * 2, false, 0);
					this.shapes.push(holeShape);
				})
			}
			if(!empty(faceShapes)) {
				this.shapes.push(...faceShapes)
			}
		} else {

			this.cutOut.createFaceShape({
				x1: - this.l / 2, y1: - this.h / 2,
				x2: - this.l / 2, y2: this.h / 2,
				x3: this.l / 2, y3: this.h / 2,
				x4: this.l / 2, y4: - this.h / 2
			}).forEach(shape => {
				this.shapes.push(shape)
			})

			//Need for cross for holes
			const __holes = [];
			this._holes.forEach(h1 => {
				const tmp_hole = this._holes.find(h2 => h1.x === h2.x && h1.y === h2.y && h1.id !== h2.id);

				if(__holes.findIndex(el => el.id === h1.id) === -1) {
					if (!empty(tmp_hole)) {
						if (h1.diam >= tmp_hole.diam) {
							__holes.push(h1)
						} else {
							__holes.push(tmp_hole)
						}
					} else {
						__holes.push(h1)
					}
				}
			})

			__holes.forEach(hole => {
				const holeShape = new Shape();
				const r = hole.diam / 2;
				const cx = hole.calcX() - this.l / 2;
				const cy = hole.calcY()  - this.h / 2;
				holeShape.moveTo(cx, cy)
				holeShape.absellipse(cx, cy, r, r, 0, Math.PI * 2, false, 0);
				this.shapes.push(holeShape);
			})
		}

		this.shapes.forEach(shape => {
			squareShape.holes.push(shape)
		})

		let squareShapeGeometry = new ShapeGeometry(squareShape, ShapeGeometrySegments)
		squareShapeGeometry.translate(0, 0, w)

		return squareShapeGeometry
	}


}

export {
	Face3D
}
