import Detail from "./Detail";
import {List} from "react-virtualized";
import {useCallback} from "react";
import cloneDeep from "lodash.clonedeep";
import {empty, validateDetail} from "../../../../../helpers/helper";
import {errorMessageOperations} from "redux/errors";
import Languages from "../../../../../translation/Languages";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import CONSTANTS from "../../../../../config/constants";
import {useProjectTableContext} from "../../../../../hooks/useContexts";

const Table = ({
                   selectDetail,
                   selectedDetails,
                   remove,
                   details,
                   height,
                   setDetails,
                   isScrolling,
                   onChildScroll,
                   scrollTop,
                   width
               }) => {
    const construction = useSelector(projectSelectors.getConstruction);
    const detailsTableConfig = useSelector(projectSelectors.getDetailsTableConfig);
    const dispatch = useDispatch();
    const {tableRef} = useProjectTableContext();

    const updateDetail = useCallback((detail, copiedDetail, detailProperty, newValue) => {
        detail.updateDetail(detailProperty, newValue)
            .then(() => {
                setDetails((prev) => prev.map((d) => d.id === copiedDetail.id ? copiedDetail : d))
            })
            .catch(error => console.log(error));
    }, [setDetails]);

    const changeInputType = useCallback((detail, event) => {
        let {name, value} = event.target, copiedDetail = cloneDeep(detail);
        if (name === CONSTANTS.detailFieldNames.NAME) {
            copiedDetail[name] = value;
        }

        if ([CONSTANTS.detailFieldNames.HEIGHT, CONSTANTS.detailFieldNames.WIDTH].includes(name)) {
            copiedDetail[`error_${name}`] = false

            const neededMaterial = construction.materials.find(el => el.index === copiedDetail.material);
            let number = 0;
            if (name === CONSTANTS.detailFieldNames.WIDTH) {
                number = 26
            }
            if (['Постформинг', 'Постформінг'].includes(neededMaterial.type)) {
                if (empty(neededMaterial.double_rounding) && name === CONSTANTS.detailFieldNames.WIDTH) {
                    number = 26
                }

                if (!empty(neededMaterial.double_rounding) && copiedDetail.soft.bottom) {
                    number = CONSTANTS.cutGaps.soft
                }

                if (!empty(neededMaterial.double_rounding) && copiedDetail.soft.bottom && copiedDetail.soft.top) {
                    number = CONSTANTS.cutGaps.doubleSoft();
                }

            } else if (!['Постформинг', 'Постформінг', 'OSB'].includes(neededMaterial.type)) {
                number = 26
            }

            if (neededMaterial.type === 'OSB' || (neededMaterial.type === 'Стеновая панель' && name === CONSTANTS.detailFieldNames.HEIGHT)) {
                number = 0
            }

            const tempMat = {...neededMaterial}

            if (copiedDetail.soft.top) tempMat[name] -= CONSTANTS.cutGaps.soft
            if (copiedDetail.soft.bottom) tempMat[name] -= CONSTANTS.cutGaps.soft

            if (validateDetail(value, neededMaterial, name)) {
                copiedDetail[`error_${name}`] = true
                copiedDetail[name] = value !== '' ? Number(value) : value;
                if (number) {
                    dispatch(errorMessageOperations.switchStateError(
                        {
                            message: `${Languages.getTranslation('same-sides-detail-material', true)} ${number} ${Languages.getTranslation('same-sides-detail-material-text-two')}`,
                            type: 'warning',
                            show: true
                        }))
                } else {
                    dispatch(errorMessageOperations.switchStateError(
                        {
                            message: `${Languages.getTranslation('error-detail-params', true)}`,
                            type: 'warning',
                            show: true
                        }))
                }
            } else {
                copiedDetail[name] = value !== '' ? Number(value) : value;
                copiedDetail[`error_${name}`] = Number(value) === 0;
            }
            value = copiedDetail[name];
        }

        if (name === CONSTANTS.detailFieldNames.COUNT) {
            copiedDetail[name] = Number(value) === 0 || value === '' ? 1 : Number(value);
            value = copiedDetail[name]
        }

        updateDetail(detail, copiedDetail, name, value);
    }, [dispatch, construction.materials, updateDetail]);

    const changeEdge = useCallback((detail, name, event) => {
        let {value} = event, copiedDetail = cloneDeep(detail);

        if (copiedDetail.edges[name] !== value) {
            if (copiedDetail.edges.hasOwnProperty(name)) {
                copiedDetail.edges[name] = value === "" ? null : value;
            } else {
                copiedDetail.edges = {...copiedDetail.edges, [name]: value === "" ? null : value};
            }

            updateDetail(detail, copiedDetail, 'edges', copiedDetail.edges);
        }
    }, [updateDetail]);

    const selectTexture = useCallback((detail, copiedDetail, value) => {
        const neededMaterial = construction.materials.find(el => el.index === value);

        if (['Постформінг', 'Постформинг'].includes(neededMaterial.type)) {
            copiedDetail.isRotateTexture = true;

            updateDetail(detail, copiedDetail, 'isRotateTexture', true);
        }
    }, [updateDetail, construction.materials]);

    const changeSelectType = useCallback((detail, name, event) => {
        let {value} = event, copiedDetail = cloneDeep(detail);

        if (copiedDetail[name] !== value) {
            if (name === CONSTANTS.detailFieldNames.MATERIAL) {
                selectTexture(detail, copiedDetail, value);
            }

            copiedDetail[name] = value;

            updateDetail(detail, copiedDetail, name, value);
        }
    }, [updateDetail, selectTexture]);

    const handlerSetFocused = useCallback((meta) => {
        dispatch(projectOperations.setDetailsTableFocusedRow(meta));
    }, [dispatch]);

    const rowEditRenderer = ({index, style}) => {
        const detail = details[index];

        return (
            <Detail
                style={style}
                editLimits={detailsTableConfig.limits}
                detail={detail}
                index={index}
                key={detail.id}
                selectDetailHandler={selectDetail}
                detailsCount={details.length}
                construction={construction}
                isSelected={selectedDetails.includes(detail)}
                focusedRow={detailsTableConfig.focusedRow}
                changeInputType={changeInputType}
                changeSelectType={changeSelectType}
                setFocusedDetail={handlerSetFocused}
                remove={remove}
                changeEdge={changeEdge}
            />)
    }

    return (
        <List
            className="edit-details-table"
            ref={tableRef}
            details={details}
            autoHeight
            height={height}
            isScrolling={isScrolling}
            onScroll={onChildScroll}
            overscanRowCount={3}
            rowCount={details.length}
            rowHeight={70}
            rowRenderer={rowEditRenderer}
            scrollTop={scrollTop}
            width={width}
        />)
}

export default Table;