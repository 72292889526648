import {projectOperations} from "../../redux/project";
import React, {Component} from "react";
import {connect} from "react-redux";
import SceneModel from "models/3D/SceneModel";
import  {panelSelectors} from "redux/panel";
import './style.scss';

class ThreeScene extends Component {
	sceneModel = null;
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.sceneModel = new SceneModel(this.mount);
		this.props.setsceneModel(this.sceneModel);
	}
	componentWillUnmount() {
		if (this.sceneModel) this.sceneModel.dispose();
	}
	render() {
		return (
			<div
				className="three-scene  three-scene-sizes"


				ref={(mount) => {
					this.mount = mount;
				}}
			/>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setsceneModel: (sceneModel) => {
			dispatch(
				projectOperations.setsceneModel({
					sceneModel,
				})
			);
		},
	};
};

const mapState = state => {
	return {
		panel: panelSelectors.getPanelShowState(state),
	};
};

export default connect(mapState, mapDispatchToProps)(ThreeScene);
