import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalsOperations } from "redux/modals";
import "./style.scss";
import Project from "models/Project";
import Requests from "api/API";
import { projectSelectors } from "redux/project";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import { errorMessageOperations } from '../../../../redux/errors'
import Errors from "../../../../models/Errors";
import CustomSelect from "../../Select";
import {toastError} from "../../../../helpers/toasts";
const _errors = new Errors([])

const SaveProject = () => {
  const dispatch = useDispatch();
  const [folders, setFolders] = useState([]);
  const [folderName, setFolderName] = useState(null);
  const [saveType, setSaveType] = useState('save-type1');
  const [disableBtn, setDisableBtn] = useState(false)
  const construction = useSelector(projectSelectors.getConstruction);
  const [name, setName] = useState(construction.projectData.name);

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const setFolderNameProp = (e) => {
    if (e.value === "") {
      setFolderName(null);
    } else {
      setFolderName(e.value);
    }
  };
  useEffect(() => {
    const getListFolders = async () => {
      const data = await Requests.Projects.getUserFolders().catch((error) => {
        dispatch(errorMessageOperations.switchStateError(
          { message: error.message, type: 'warn', show: true }))
      });
      if (data && data.data.length) {
        setFolders(data.data);
      }
    };
    getListFolders();
  }, []);

  const close = () => {
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };

  const submit = async () => {
    if (name === "") {
      _errors.setError(`${Languages.getTranslation("dont-enter-project-name", true)}`, 'error', true)
      return;
    }

    if (construction.details.filter((detail) => detail.material === null).length) {
      _errors.setError(`${Languages.getTranslation("chose-material-for-detail", true)}`,  'error', true )
      return;
    }
    setDisableBtn(true)
       Project.saveProject(name, folderName, dispatch)
           .then((data)=> {
             if (saveType === 'save-type1') {
               window.location.href = `${window.location.href}?id=${data.id}`
             } else {
               close()
               setDisableBtn(false)
             }
            })
           .catch(e => console.log(e))
  };

  const changeSaveType = (e) => {
    setSaveType(e.value)
  }

  return (
    <>
      <div className="modal-save-project">
        <h2 className="modal-save-project__title">{Languages.getTranslation('save-project', true)}</h2>
        <div className="modal-save-project__body">
          <div className="modal-save-project__form-control">
            <label
              htmlFor="name"
              className="modal-save-project__form-control-label"
            >
              {Languages.getTranslation('enter-project-name', true)}
            </label>
            <input
              id="name"
              onChange={handleChange}
              name="name"
              value={name}
              type="text"
              maxLength="21"
              className="modal-save-project__form-control-input"
            />
          </div>
          <div className="modal-save-project__form-control">
            <label
              htmlFor="name"
              className="modal-save-project__form-control-label"
            >
              {Languages.getTranslation('chose-folder-for-project', true)}
            </label>
            <CustomSelect
                options={[
                  { value: '', label: Languages.getTranslation('new-projects', true) },
                  ...folders.map(folder => ({ value: folder.id, label: folder.name }))
                ]}
                handleChange={setFolderNameProp}
                placeholder={Languages.getTranslation('new-projects', true)}
            />
          </div>
          <div className="modal-save-project__form-control">
            <label
              htmlFor="name"
              className="modal-save-project__form-control-label"
            >
              {Languages.getTranslation('choose-save-type', true)}
            </label>

            <CustomSelect
                options={[
                  { value: 'save-type1', label: Languages.getTranslation('save-type1', true) },
                  { value: 'save-type2', label: Languages.getTranslation('save-type2', true) }
                ]}
                placeholder={Languages.getTranslation('save-type1', true)}

                handleChange={selectedOption => changeSaveType(selectedOption ? selectedOption.value : "")}
            />
          </div>
        </div>
        <div className="modal-save-project__btns">
          <DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>
          <DefaultBtn title={Languages.getTranslation('save', true)} handler={submit} disabled={disableBtn}/>
        </div>
      </div>
    </>
  );
};

export default SaveProject;
