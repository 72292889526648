import API from "config/api";
import $store from 'redux/store'
import { userOperations } from "redux/user";
import {empty} from "../helpers/helper";
import Errors from "../models/Errors";
import Languages from "../translation/Languages";
import userPermissions from "../config/process_config.json"

export default class Auth {
  constructor(axios, token, headers) {
    this.axios = axios;
    this.token = token;
    this.headers = headers;
    this.errors = new Errors([])
  }

  // Получение данных о юзере

  async GetUserInfo() {
    if(this.token) {
      return this.axios
        .get(API.ROUTE.get_user_info(), this.headers)
        .then((response) => {
          const user = response.data
          $store.dispatch(
            userOperations.setUserInfo({user})
          )
          localStorage.setItem('uuid', response.data.id);
          return response.data
        })
        .catch((error) => {
          if(error.response.data && 
            error.response.data.message && 
            error.response.data.message === 'Unauthenticated.') 
          {
            localStorage.removeItem("token");
            this.setCookie('token', '')
            if(window.location.pathname !== '/') {
              window.location.replace('/')
            }

          }
          console.log(error)
        });
    }
  }

  async getUser(data = null) {
    return this.axios
      .post(API.ROUTE.get_user(), data, this.headers)
      .then((response) => {
        if (!empty(response) && !empty(response.data) && !empty(response.data.token)) {

          this.setCookie('token', response.data.token.replace('|', '%7C'));

          if(!empty(response.data.user) && !empty(response.data.user.filial) && !empty(response.data.user.filial.ttid)) {
            this.setCookie('department', response.data.user.filial.ttid)
          } else {
            this.setCookie('department', '54096') // set default value to Kiev Korolyova
          }
          window.location.replace('/')
          return Promise.resolve(response.data);
        } else {
          return Promise.reject({})
        }

      })
  }

  async logout() {
    return this.axios
      .post(API.ROUTE.logout(),{},this.headers)
      .then((response) => {
        if (response && response.data) {
          localStorage.removeItem("locate");
          localStorage.removeItem("uuid");
          this.setCookie('token', '');
          window.location.replace('/')
          return Promise.resolve()
        }
        else{
          return Promise.reject()
        }
      })
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  setCookie(name, val) {
    const dArray = document.location.host.split('.');
    const move = dArray.includes('dev') ? -2 : -3;
    const domain = dArray.length > 2 ? document.location.host.split('.').splice(move).join('.') : document.location.hostname;
    const exp = empty(val) ? ';expires=Thu, 01 Jan 1970 00:00:00 GMT' : ''
    document.cookie = `${name}=${val}; domain=.${domain}; path="/" ${exp}`
  }

  async searchUser(name) {
    return this.axios.get(`${API.ROUTE.searchUser()}/${name}`, this.headers)
      .then((response) => response)
      .catch((error) => console.log(error))
  }

  async searchUserById(id) {
    return this.axios.get(`${API.ROUTE.searchUserById()}/${id}`, this.headers)
      .then((response) => response)
      .catch((error) => console.log(error))
  }

  async getFilialAgency() {
    return this.axios.get(API.ROUTE.getFilialAgency(), this.headers)
        .then((response) => response)
        .catch((error) => console.log(error))
  }

  async setFilialAgency(filialId) {
    return this.axios.post(API.ROUTE.setFilialAgency(), {'filial_agency': filialId},  this.headers)
        .then((response) => {
          if(!empty(response.data.user.filial.ttid)) {
            this.setCookie('department', response.data.user.filial.ttid)
          }
         return response
        })
        .catch((error) => console.log(error))
  }
}
