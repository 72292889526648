import {useEffect, useState} from 'react'
import img from "../../../../media/material-add-img-test.svg";
import {createMaterialTitle, empty} from "../../../../helpers/helper";
import Languages from "../../../../translation/Languages";
import MaterialItemPart from "./MaterialItemPart";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {errorMessageSelectors} from "redux/errors";
import {toastError} from "../../../../helpers/toasts";
import DefaultCheckbox from "../../../UI/Inputs/Input/DefaultCheckbox/DefaltCheckbox";
import Project from "../../../../models/Project";
import {v4 as uuidv4} from "uuid";

const MaterialItem = ({material, index, openCuttingParams, deleteMaterial, mapListMaterial, permissions}) => {
	const [materialParts, setMaterialParts] = useState(false);
	const [materialPartsItems, setMaterialPartsItems] = useState(material.parts);
	const construction = useSelector(projectSelectors.getConstruction);
	const errorMessage = useSelector(errorMessageSelectors.getErrorMessageState)
	const [isShow, setIsShow] = useState(false)

	useEffect(() => {
		setIsShow(() => {
			const isExitDetailsWithMaterial = construction.details.some((detail) => detail.material === material.index);
			const isDetailsMultiplicityHasMaterial = construction.details.some((detail) => !empty(detail.multiplicity) && detail.multiplicity.material === material.index);

			return isExitDetailsWithMaterial || isDetailsMultiplicityHasMaterial;
		})
	}, [construction.details.length, errorMessage])

	useEffect(() => {
		setTimeout(() => {
		setMaterialPartsItems(material.parts)
		}, 1000)
	}, [material.parts]);

	const deleteMaterialPart = async (part) => {

		let newParts = []
		if (part.typeParts === 'kronasElement') {
			await construction.deleteReservingMaterials(construction.projectData.id, part.id)
			newParts = material.parts.filter((el) => el.id !== part.id)
			toastError(Languages.getTranslation('text-delete-part-kronasElement', true))
		} else {
			newParts = material.parts.filter((el) => el.article !== part.article)
		}
		if (empty(newParts)){
			setMaterialPartsItems([])
			setMaterialParts(false)
		} else {
		setMaterialPartsItems(newParts)
		}
		construction.updateMaterial(material.index, 'parts', newParts)
	}

	const materialsIconClass = materialParts
		? "btn__icon-angle-up"
		: "btn__icon-angle-down";

	const addMaterialPartsButton = () => {
		if(!permissions.includes('KM_editMaterial') || empty(materialPartsItems)) return null;
		return (
			<button
				className="btn"
				onClick={() => setMaterialParts(prevState => !prevState)}
			>
				<i className={materialsIconClass}></i>
			</button>
		)
	}

	const addDeleteMaterialButton = () => {
		if(!permissions.includes('KM_editMaterial')) return null;
		return (
			<button
				className="btn"
				title="Видалити матеріал"
				onClick={() => deleteMaterial(material)}
			>
				<i className="btn__icon-delete"></i>
			</button>
		)
	}
	const addCuttingParamsButton = () => {
		if(!permissions.includes('KM_CuttingParams')) return null;

		return (
			<button
				className="btn"
				title="Параметри розкрою"
				onClick={() => openCuttingParams(material)}
			>
				<i className="btn__icon-cuttingParams"></i>
			</button>
		)
	}
	const addSawdustItem = () => {
		if(!permissions.includes('KM_sawdust')) return null;
		return (
			<div className="table__data">
				<div className="table__data-item">
					<DefaultCheckbox disabled={material.type === "OSB"} checked={material.sawdust}
									 changeProp={() => setValueSawdust(material.sawdust)}/>
				</div>
			</div>
		)
	}
	const setValueSawdust = (sawdust) => {
		console.log('material', material)
		construction.updateMaterial(material.index, 'sawdust', !sawdust)
		validateDetails()
	}
	const validateDetails = () => {
		Project.validateProjectDetails().then(() => {}).catch(error => console.log(error))
	}
	return (
		<div className="table__item_container">
			<div className="table__item">
				<div className="table__number-and-name">
					<div className="table__number">{index + 1}</div>
					<img
						src={material?.images?.length > 0 &&  material.images[0].hasOwnProperty('small') ? material.images[0]?.small : img}
						alt="image"
						className="material__img"
					/>
					{/*<img src={img} alt="img" className="table__item-img"/>*/}
					<div className="table__name">
						{" "}
						{createMaterialTitle(material)}
					</div>
				</div>
				<div className="materialItem__data-and-btn">
					<div className="table__data-container">
					<div className="table__data">
						{material.article}
					</div>
					<div className="table__data">
						{material.height}
					</div>
					<div className="table__data">
						{material.width}
					</div>
					<div className="table__data">
						{material.thickness}
					</div>
					<div className="table__data">

						{addSawdustItem()}
					</div>
						<div className="table__data">
							{material.count || material.amount}
						</div>
					</div>
					<div className="materialItem__btn-container">

						{addMaterialPartsButton()}
						{addCuttingParamsButton()}

						{isShow && <button
							className="btn"
							title="Розкрій"
							onClick={() => mapListMaterial(material)}
						>
							<i className="btn__icon-map"></i>
						</button>}
						{addDeleteMaterialButton()}
					</div>
				</div>
			</div>
				{materialParts && <div className="materialItem__item-parts-container">
			<span className='materialItem__item-parts-title'>{Languages.getTranslation('related-materials', true)}</span>
			<div className="materialItem__item-parts">
				{material.hasOwnProperty('parts') && !empty(materialPartsItems) ? materialPartsItems.map((part, number) => {
					const uuid = uuidv4();
					return <MaterialItemPart key={uuid} number={number} material={part} index={index} deleteMaterialPart={deleteMaterialPart}/>
				}) : <></>}
			</div>
		</div>}
	</div>
	)
}
export default MaterialItem
