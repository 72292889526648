import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import Animations from "./Animations";
// import Stats from 'three/examples/jsm/libs/stats.module'
import gsap from 'gsap'
import {empty} from "../../helpers/helper";
export default class sceneModel {
  rendered = 0;
  shouldRender = false;
  constructor(dom) {
    this.dom = dom;
    this.init();
  }

  init() {
    const width = this.dom.clientWidth;
    const height = this.dom.clientHeight;
    this.scene = new THREE.Scene();
    //FPS gui
    // this.stats = new Stats()
    // document.body.appendChild(this.stats.dom)
    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      powerPreference: "high-performance", //"high-performance", "low-power" or "default"
      precision: "mediump", //highp", "mediump" or "lowp"
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);
    this.renderer.setClearColor(0x000000, 0); // the default
    this.dom.appendChild(this.renderer.domElement);
    //add Camera
    this.camera = new THREE.PerspectiveCamera(40, width / height, 0.1, 100000);
    this.camera.position.z = 800;
    this.camera.position.y = 0;
    //Camera Controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.addEventListener('change', this.renderScene)
    this.controls.mouseButtons.LEFT = THREE.MOUSE.ROTATE;
    this.scene.add(new THREE.AmbientLight("#f5f5f5", 1));
    window.addEventListener(
      "resize",
      () => {
        this.updateRenderSize();
      },
      false
  );
    this.controls.addEventListener('start', () => {
      this.disableVisibleControlsAndMesh(false)
    });

    this.controls.addEventListener('end', () => {
      this.disableVisibleControlsAndMesh(true)
    });
    this.renderScene();
    this.start();
  }
  dispose() {
    this.stop();

    window.removeEventListener('resize', this.updateRenderSize);

    this.renderer.dispose();

    this.controls.dispose();


    if(!empty(this.renderer?.domElement?.parentNode)) {
      this.renderer?.domElement.parentNode.removeChild(this.renderer.domElement);
    }

  }
  clear() {
    while(this.scene.children.length > 0){
      this.scene.remove(this.scene.children[0]);
    }
    this.renderer.dispose()
    this.stop()
  }

  updateRenderSize() {
    if (!this.renderer?.domElement?.parentElement) {
      console.error("cant calc size without parent dom element");
      return;
    }
    if (this.camera && this.renderer?.domElement?.parentElement) {
      const parentElement = this.renderer?.domElement?.parentElement;
      let width = parentElement.clientWidth;
      let height = parentElement.clientHeight;
      const aspect = width / height;

      this.camera.aspect = aspect;

      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
    }
  }

  getCenter(zPoint) {
    this.controls.reset()
    this.camera.position.set(0.1, 9.06, zPoint);
    this.controls.update();
  }

  getCenterWithAnimation(point, type) {
    switch (type) {
      case 'front':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: 0.1,
          y: 9.06,
          z: point,
          onUpdate: () => this.controls.update(),
        })
        break
      case 'top':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: 0.000001,
          y: point,
          z: 0.0015,
          onUpdate: () => this.controls.update(),
        })
        break
      case 'bottom':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: 0.000001,
          y: -point,
          z: 0.0015,
          onUpdate: () => this.controls.update(),
        })
        break
      case 'right':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: point,
          y: 9.06,
          z: 0.0015,
          onUpdate: () => this.controls.update(),
        })
        break
      case 'left':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: -point,
          y: 9.06,
          z: 0.0015,
          onUpdate: () => this.controls.update(),
        })
        break
      case 'back':
        gsap.to(this.controls.target, {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        })
        gsap.to(this.camera.position, {
          duration: 1,
          x: 0.1,
          y: 9.06,
          z: -point,
          onUpdate: () => this.controls.update(),
        })
        break

    }
  }

  start() {
    if (!this.frameId) {
      // this.animate();
      // this.renderScene();
      // this.frameId = requestAnimationFrame(this.animate);
      this.controls.update();
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId);
  }

  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
  };

  /**
   * Toggles the visibility of controls and meshes.
   *
   * @param {boolean} visibility - The visibility value to set for controls and meshes.
   * @returns {void}
   */
  toggleControlsAndMeshVisibility = (visibility) => {
    const nameOfControlsAndMeshes = ['stolTextDown', 'strangeArrowDown', 'stolTextUp', 'strangeArrowUp', 'lArrow', 'hArrow', 'axesHelper']
    if (this.scene.children.length > 1) {
      const arr3dControllersAndMeshes = this.scene.children[1].children.filter(el => nameOfControlsAndMeshes.includes(el.name))
      arr3dControllersAndMeshes.forEach(el => el.visible = visibility)
      this.renderScene();
    }
  }

  /**
   * Disable visible controls and mesh.
   *
   * @function
   */
  disableVisibleControlsAndMesh = (show) => {
    this.toggleControlsAndMeshVisibility(show);
  }

  /**
   * Features for controls and mesh when the camera rotates.
   *
   * @function featuresWhenCameraRotated
   * @memberof moduleName
   * @returns {void}
   */
  featuresWhenCameraRotated = () => {
    this.disableVisibleControlsAndMesh()
  }

  /**
   * Animates the models.
   *
   * @function animate
   * @memberof global
   * @instance
   * @memberOf global
   * @returns {void}
   */
  animate = () => {
    //Animate Models Here
    this.frameId = window.requestAnimationFrame(this.animate);

    //ReDraw Scene with Camera and Scene Object
    // this.controls.update();
    if(this.shouldRender) {
      this.renderScene();
    }

  };


}
