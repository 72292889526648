import React from "react";
import './style.scss'
import DefaultBtn from "components/UI/Buttons/DefaultBtn";

const ModalSuccess = ({ text, buttonText, close }) => {
  return (
    <div className="form">
      <div className="form__content">
        <span className="form__content-img" />
        <span className="form__content-text">{text}</span>
        <span className="form__content-btn">
          <DefaultBtn title={buttonText} handler={close} minWidth="100px" />
        </span>
      </div>
    </div>

  )
}

export default ModalSuccess;
