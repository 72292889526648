import {forwardRef, memo, useCallback} from "react";
import Languages from "../../../../../translation/Languages";
import {customStylesProduct} from "./select-styles";
import Select from "react-select";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {CustomOption} from "./CustomSelectOption";
import CONSTANTS from "../../../../../config/constants";

export const Product = forwardRef(({selectConfig, index, isDisabled, detail, handleKeyDown, changeSelectType}, ref) => {
	const construction = useSelector(projectSelectors.getConstruction);
	const optionsProduct = construction._products.map((item, key) => ({
		value: item.id,
		label: `${key + 1}: ${item.name}`
	}));

	const filterOptionsProduct = useCallback((option, input) => {
		if (input) {
			return option.value === Number(input)
		}
		return true;
	}, []);

	return (
		<Select
			{...selectConfig({index, field: CONSTANTS.detailFieldNames.PRODUCT_ID})}
			noOptionsMessage={() => Languages.getTranslation('not-find-variant', true)}
			filterOption={filterOptionsProduct}
			styles={customStylesProduct}
			isDisabled={isDisabled}
			components={{ Option: CustomOption }}
			name={CONSTANTS.detailFieldNames.PRODUCT_ID}
			ref={ref}
			value={optionsProduct.find((option) => option.value === detail.productId)}
			onKeyDown={(event) => handleKeyDown(event, CONSTANTS.detailFieldNames.PRODUCT_ID)}
			onChange={(event) => changeSelectType(detail, CONSTANTS.detailFieldNames.PRODUCT_ID, event, index)}
			options={optionsProduct}/>
	)
})

export const DetailProductSelect = memo(Product);