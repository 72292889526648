import Languages from "../../../../../translation/Languages";
import {customStylesMaterial} from "./select-styles";
import Select from "react-select";
import {materialTitle} from "../../../../../helpers/helper";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {forwardRef, memo} from "react";
import {CustomOption} from "./CustomSelectOption";
import CONSTANTS from "../../../../../config/constants";

const Material = forwardRef(({
                                 detail,
                                 filterOptions,
                                 isDisabled,
                                 index,
                                 handleKeyDown,
                                 formatOptionLabel,
                                 changeSelectType,
                                 selectConfig
                             }, ref) => {
    const construction = useSelector(projectSelectors.getConstruction);

    const optionsMaterial = construction._materials.map((item, key) => ({
        value: item.index, label: `${key + 1}: ${materialTitle(item)}`, shortLabel: `${key + 1}: ${item.article}`
    }));

    return (<Select
            {...selectConfig({index, field: CONSTANTS.detailFieldNames.MATERIAL})}
            noOptionsMessage={() => Languages.getTranslation('not-find-variant', true)}
            filterOption={filterOptions}
            isDisabled={isDisabled}
            styles={customStylesMaterial}
            ref={ref}
            components={{ Option: CustomOption }}
            name={CONSTANTS.detailFieldNames.MATERIAL}
            formatOptionLabel={formatOptionLabel}
            value={optionsMaterial.find((option) => option.value === detail.material)}
            onKeyDown={(event) => handleKeyDown(event, CONSTANTS.detailFieldNames.MATERIAL)}
            onChange={(event) => changeSelectType(detail, CONSTANTS.detailFieldNames.MATERIAL, event, index)}
            options={optionsMaterial}/>
    )
});

export default memo(Material);