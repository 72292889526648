import React, {useState} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import "./style.scss";
import ArrowLeft from "../../../media/ArrowLeft.svg";
import ArrowRight from "../../../media/ArrowRight.svg";
import kmDate from "../../../media/kmDate.svg";
import playBtn from "../../../media/PlayIcon.svg";
import VideoModal from '../Modal/VideoModal'

const Slider = ({videos}) => {
  const [current, setCurrent] = useState(0);
  const length = videos.length;
  const [modalShow, setModalShow] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);
  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  
  function openModal(video) {
    setActiveVideo(video);
    setModalShow(true);
  }
  
  return (
    <section className="slider">
      <button onClick={prevSlide} disabled={current === 0} className="slider__btn slider__btn-left">
        <img src={ArrowLeft} alt="<"/>
      </button>
      
      <TransitionGroup className="images">
        <div className="slider__cards">
          {videos.map((video, index) => (
            <CSSTransition key={index} classNames="fade" timeout={300}>
              <div>
                {(index >= current && index <= current + 2) && (
                  <div className="slider__cards-card">
                    <div className="slider__cards-card-link">
                      <picture>
                        <source srcSet={video.thumbnails.standard.url} width={video.thumbnails.standard.width}
                                height={video.thumbnails.standard.height} media="(max-width: 767px)"/>
                        <img src={video.thumbnails.maxres.url} width={video.thumbnails.maxres.width}
                             height={video.thumbnails.maxres.height} alt={video.title}
                             className="slider__cards-card-img1"/>
                      </picture>
                      
                      <img onClick={() => openModal(video)} src={playBtn} alt="play"
                           className="slider__cards-card-img2 cursor"/>
                    </div>
                    <p className="slider__cards-card-title">Kronas</p>
                    <p className="slider__cards-card-name">{video.title}</p>
                    <div className="slider__cards-card-date">
                      <img src={kmDate} alt="kr"/>
                      <span className="slider__cards-card-date-text">{video.date}</span>
                    </div>
                  
                  </div>
                )}
              </div>
            </CSSTransition>
          ))}
        </div>
      </TransitionGroup>
      
      <button onClick={nextSlide} disabled={current === length - 3} className="slider__btn slider__btn-right">
        <img src={ArrowRight} alt=">"/>
      </button>
      <VideoModal show={modalShow} onHide={() => setModalShow(false)} video={activeVideo}/>
    </section>
  );
}

export default Slider;