import Languages from "../../../../../translation/Languages";

const ImportErrorModalItem = ({error}) =>{
	return <div className='importErrorModal__item' key={error[0]}>
		<div className='importErrorModal__item-text'>
		<span className='importErrorModal__item-text_firstWord'>{+error[0] + 1}</span>
		<span className='importErrorModal__item-text_secondWord'>{Languages.getTranslation('dont-have-parameters', false)}</span>
		</div>
		<div className='importErrorModal__item-text2'>{error[1]}</div>
	</div>
}

export default ImportErrorModalItem