import React from 'react'
import img1 from "../../../../../media/addMaterial-test.png";
import {edgeTitle} from "../../../../../helpers/helper";
import RoundCheckBox from "../../../Inputs/Input/RoundCheckBox";

const ReplaceEdgesProjectItem1 = ({edge, idx, selectEdge, selectEdgeHandler }) => {
	const activeClass = `replaceMaterialProject__material ${edge === selectEdge[0] ? `replaceMaterialProject__material_active` : ''}`

	return <div
		className={activeClass} key={idx}>
		<div className="replaceMaterialProject__content-container">
			<div className="material__number">{`№${idx + 1}`}</div>
			<img src={img1} alt="image" className="replaceMaterialProject__img"/>
			<h3 className="replaceMaterialProject__name">
				{edgeTitle(edge)}
			</h3>
		</div>
		<div className="replaceMaterialProject__data-container">
			<div className="replaceMaterialProject__data-item replaceMaterialProject__data">
				{edge.article || edge.code}
			</div>
			<div className="replaceMaterialProject__data-item replaceMaterialProject__data">
				{edge.w || edge.width}
			</div>
			<div className="replaceMaterialProject__data-item replaceMaterialProject__data">
				{edge.thickness}
			</div>
		</div>
		<div className="deleteMaterial__chosen-material">
			<RoundCheckBox value={edge} setMaterial={selectEdgeHandler} selectedMaterial={selectEdge}/>
		</div>
	</div>
}
export default ReplaceEdgesProjectItem1
