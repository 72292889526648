import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from "./actions";
import Languages from "../../translation/Languages";

const initialState = {
  locale: Languages.lang
}

const lang = createReducer(initialState, {
  [ACTIONS.LangStateSwitch.type]: (state, action) => {
      const locale = action.payload;
      localStorage.setItem("locale", locale);
    return { ...state, locale };
  },
});

export default combineReducers({
    lang,
});
