import Helpers from "../Helpers";
import {Shape, Mesh, ShapeGeometry} from "three";
import {empty} from "../../../helpers/helper";
export default class MultiplicityPart {
	_height;
	_width;
	_w;
	cords = {};
	type;
	cutOuts = [];
	holes = [];
	ownShapes = [];
	mesh;
	side;
	mainW;
	edge;
	detailL;
	detailH;

	constructor({x1, y1, x2, y2, x3, y3, x4, y4, w, type, cutOuts, holes, side, mainW, edge, detailL, detailH}) {
		this.cords = {
			x1, y1,
			x2, y2,
			x3, y3,
			x4, y4
		}
		this.w = w;
		this.type = type;
		this.cutOuts = cutOuts || [];
		this.holes = holes || [];
		this.side = side;
		this.mainW = mainW;
		this.edge = edge;
		this.detailL = detailL;
		this.detailH = detailH;
		this.create();

	}

	create() {
		switch (this.type) {
			case 3:
			case 4:
			case 5:
				return this.createMesh(['top', 'left', 'right', 'bottom']);
			case 6:
				return this.createMesh(['top']);
			case 8:
				return this.createMesh(['bottom']);
			case 7:
				return this.createMesh(['top', 'left', 'right']);
		}
	}

	createHoleShape({x1, y1, x2, y2, x3, y3, x4, y4}) {
		const elements = [];
		elements.push(Helpers.createLine(x1, y1, x2, y2))
		elements.push(Helpers.createLine(x2, y2, x3, y3))
		elements.push(Helpers.createLine(x3, y3, x4, y4))
		elements.push(Helpers.createLine(x4, y4, x1, y1))
		return elements;
	}

	getEdgeHoles() {
		switch (this.type) {
			case 6:
				return {
					left: {
						"x": 0,
						"y": 0,
						"z": this.mainW,
						"side": "left",
						"height": this.cords.y2 - this.cords.y1,
						"width": this.w,
						"type": "cutout",
						"subType": "multiplicity",
						"depth": this.w,
						"ext": true,
						"color": null,
						"direction": "ver",
						"elements": this.createHoleShape({
							x1: 0, y1: 0,
							x2: 0, y2: this.cords.y2,
							x3: this.w, y3: this.cords.y2,
							x4: this.w, y4: 0,
						})
					},
					right: this.createHoleShape({
						x1: 0, y1: 0,
						x2: 0, y2: this.cords.y2,
						x3: this.w, y3: this.cords.y2,
						x4: this.w, y4: 0,
					}),
					bottom: this.createHoleShape({
						x1: 0, y1: 0,
						x2: this.cords.x3, y2: 0,
						x3: this.cords.x3, y3: this.w,
						x4: 0, y4: this.w,
					})
				}
			case 7:
				return {bottom: {
					"x": 0,
					"y": 0,
					"z": this.mainW,
					"side": "bottom",
					"height": this.cords.y2 - this.cords.y1,
					"width": this.w,
					"type": "cutout",
					"subType": "multiplicity",
					"depth": this.w,
					"ext": true,
					"color": null,
					"direction": "ver",
					"elements": this.createHoleShape({
						x1: 0, y1: 0,
						x2: this.cords.x3, y2: 0,
						x3: this.cords.x3, y3: this.w,
						x4: 0, y4: this.w,
					})
				}}
			default:
				return null;
		}
	}

	getShape() {
		const shape = new Shape();
		shape.moveTo(this.cords.x1, this.cords.y1);
		shape.lineTo(this.cords.x2, this.cords.y2);
		shape.lineTo(this.cords.x3, this.cords.y3);
		shape.lineTo(this.cords.x4, this.cords.y4);
		shape.lineTo(this.cords.x1, this.cords.y1);

		this.cutOuts.forEach((cutOut, i) => {
			shape.holes.push(cutOut)
		})
		this.holes.forEach(hole => {
			const holeShape = new Shape();
			const r = hole.diam / 2;
			const cx = hole.x - this.detailL / 2;
			const cy = hole.y - this.detailH / 2;
			holeShape.moveTo(cx, cy)
			holeShape.absellipse(cx, cy, r, r, 0, Math.PI * 2, false, 0);
			shape.holes.push(holeShape);
		})
		this.ownShapes = [shape];
		return shape;
	}

	createMesh(sides) {
		const squareShapeGeometry = new ShapeGeometry(this.getShape(), 4);

		const mesh = new Mesh(squareShapeGeometry, Helpers.getMaterial3D());

		sides.forEach(side => {
			let x1, y1, x2, y2, posX, posY, rotate;
			switch (side) {
				case 'top':
					x1 = this.cords.x2
					y1 = this.cords.y2
					x2 = this.cords.x3
					y2 = this.cords.y3
					posX = this.cords.x2
					posY = this.cords.y2
					rotate = -Math.PI / 2
					break;
				case 'left':
					x1 = this.cords.x1
					y1 = this.cords.y1
					x2 = this.cords.x2
					y2 = this.cords.y2
					posX = this.cords.x1
					posY = this.cords.y1
					rotate = 0
					break;
				case 'right':
					x1 = this.cords.x3
					y1 = this.cords.y3
					x2 = this.cords.x4
					y2 = this.cords.y4
					posX = this.cords.x4
					posY = this.cords.y4
					rotate = 0
					break;
				case 'bottom':
					x1 = this.cords.x4
					y1 = this.cords.y4
					x2 = this.cords.x1
					y2 = this.cords.y1
					posX = this.cords.x1
					posY = this.cords.y1
					rotate = -Math.PI / 2
					break;
			}
			const color = this.edge ? Helpers.getColorByThickEdge(this.edge.thickness) : null;
			const _mesh = Helpers.getLineMesh({
				x1,	y1,	x2,	y2, w: this.w
			}, color, null, true)
			_mesh.rotateZ(rotate);
			_mesh.position.x = posX;
			_mesh.position.y = posY;
			_mesh.position.z = this.w;
			mesh.add(_mesh);
		})
		return mesh;
	}

	set height(height) {
		this._height = Number(height)
	}

	get height() {
		return this._height;
	}

	set width(width) {
		this._width = Number(width);
	}

	get width() {
		return this._width;
	}

	set w(w) {
		this._w = Number(w);
	}

	get w() {
		return this._w;
	}
}