import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalsOperations } from "redux/modals";
import "./style.scss";
import Project from "models/Project";
import { projectSelectors } from "redux/project";
import { useSelector } from "react-redux";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import Loader from "../../Loader";
import {edgeTitle, materialTitle} from "../../../../helpers/helper";
import '../../../../components/CreateProject/Materials/style.scss'
import img from "../../../../media/material-add-img-test.svg";
import download from "../../../../media/download.svg";
import {tempDetailOperations} from "redux/tempDetail";


const MapProject = () => {
  const dispatch = useDispatch();
  const [mapList, setMapList] = useState([]);
  const [mapListName, setMapListName] = useState([]);
  const [load, setLoad] = useState(true)
  const [isError, setIsError] = useState(null)
  const construction = useSelector(projectSelectors.getConstruction);
  const { tempProject } = useSelector(state => state.tempDetail.property)
  const { materials } = construction

  const getListMaterials = async () => {
    Project.getMapMaterials(dispatch, tempProject)
        .then((response) => {
          if (response && response.data.length) {
            setMapList(response.data.map(item => {
              // return { ...materials.find(el => el.article === item.match('\/([^\/]+)_')[1]), link: item}
              return { ...materials.find(el => el.article === item.match('\\/([^\\/]+)__')[0].replace('__', '').replace('/', '')), link: item}
            }));
            setMapListName(response.data.map(item => item.split('__')[1].split('.')[0]))
            setLoad(false)
          }
        })
        .catch(e => {
          setIsError(
              Languages.getTranslation(e.response.data.errors.text, true) === 'Відсутній переклад'
                  ? e.response.data.errors.text
                  : Languages.getTranslation(e.response.data.errors.text, true)
          )
          setLoad(false)
        })
  };

  useEffect(() => {
    getListMaterials();
  }, []);

  const close = () => {
    dispatch(tempDetailOperations.setTempProject({}))
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };

  return (
    <>
      <div className="modal-map-project">
        <h2 className="modal-map-project__title">{Languages.getTranslation('res-map-material', true)}</h2>
        <div className="modal-map-project__body">
          <div className="modal-map-project__form-control">
            <div className="materials-class">
              {load ? <Loader /> :
                  isError ? <h1 className='align-center' >{`${isError}.`}</h1> :
              <div className="table">
                <div className="table__header">
                  <div className="table__number-and-name table__number-and-name_edges">
                    <div className="table__header-title table__number">
                      Номер
                    </div>
                    <div className="table__header-title table__name table__name_edges">
                      {Languages.getTranslation('name', true)}
                    </div>
                  </div>
                  <div className="table__data-container">
                    <div className="table__header-title">{Languages.getTranslation('article', true)}</div>
                    <div className="table__header-title">{Languages.getTranslation('height', true)}</div>
                    <div className="table__header-title">{Languages.getTranslation('width', true)}</div>
                    <div className="table__header-title">{Languages.getTranslation('thickness', true)}</div>
                    <div className="table__header-title">{Languages.getTranslation('see', true)}</div>
                  </div>
                </div>
                {mapList.length > 0 && mapList.map((item, index) => (
                        <div className="table__body" >
                        <div className="table__item" key={index}>
                          <div className="table__number-and-name table__number-and-name_edges">
                            <div className="table__number">{index + 1}</div>
                            <img src={img} alt="img" className="table__item-img"/>
                            <div className="table__name table__name_edges">{mapListName[index]}</div>
                        </div>
                        <div className="table__data-container">
                          <div className="table__data">
                            {item.article}
                          </div>
                          <div className="table__data">
                            {item.height}
                          </div>
                          <div className="table__data">
                            {item.width}
                          </div>
                          <div className="table__data">
                            {item.thickness}
                          </div>
                          <div className="table__data">
                            <a href={item.link} target='_blank'>
                              <img src={download} alt="download"/>
                            </a>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div> ))}
              </div> }
            </div>
          </div>
        </div>
        <div className="modal-map-project__btns">
          <DefaultBtn title={Languages.getTranslation('close-btn', true)} handler={close}/>
        </div>
      </div>
    </>
  );
};

export default MapProject;

