import {useContext} from "react";
import {TableRefContext} from "../contexts";

export const useProjectTableContext = () => {
    const context = useContext(TableRefContext);

    if (!context) {
        throw new Error("useProjectTableContext must be used within the ProjectTableProvider");
    }
    return context;
};