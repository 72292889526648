import Db from 'api/Db'
import { empty } from '../helpers/helper'
import Languages from "../translation/Languages";

export default class _Furnitures extends Db {
    constructor() {
        super();
    }

    addFurnitures(furnitures){
        if(!furnitures || (Array.isArray(furnitures) && !furnitures.length)){
            return Promise.reject('Incorrect furnitures')
        }

        const process = () => {
            const furniture = furnitures.shift()
            if(furniture){
                this.addFurniture(furniture)
                    .then(() => process())
                    .catch(e => Promise.reject(e))
            }else{
                return Promise.resolve()
            }
        }

        return process()
    }

    addFurniture (furniture) {
        return new Promise((resolve, reject) => {
            if (typeof furniture !== 'object') {
                return reject('Incorrect furniture');
            }

            const dbObject = {
                index: furniture.index,
                name: furniture.name || furniture.translations[Languages.langPr === 'ua' ? 'uk' : Languages.langPr]?.name,
                measure: "шт",
                article: furniture.article || furniture.vendor_code,
                count: furniture.count
            };

            this.addItem('furnitures', dbObject)
                .then(() => resolve(dbObject))
                .catch(error => reject(error))
        })
    }

    getFurnitures() {
        return this.getAllData('furnitures')
    }

    getFurniture(index) {
        return this.getItem('furnitures', index)
    }

    updateFurniture(index, field, value){
        return this.update('furnitures', index, field, value)
            .then(data => Promise.resolve(data))
    }

    deleteFurniture(index) {
        if(empty(index)){
            return Promise.reject({Error: 'Incorrect furniture.index'});
        }
        return this.removeItem('furnitures', index)
        // return this.getDataByKey('furnitures', 'index', index)
        //     .then(data => {
        //         if(data.length){
        //             return Promise.reject({ Warning: 'not find furniture' })
        //         }
        //         return this.removeItem('furnitures', index)
        //     })
    }
}