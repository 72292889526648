import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { modalsOperations } from "redux/modals";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import Requests from "api/API";
import { toast } from "react-toastify";
import Languages from "translation/Languages";
import { hideAllOperations } from "redux/hideAll";
import { errorMessageOperations } from '../../../../redux/errors'
import Errors from "../../../../models/Errors";

const _errors = new Errors([])

const AddFolder = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const close = () => {
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };

  const submit = async () => {
    const options = {
      progress: undefined,
      onOpen: (props) => {
        dispatch(hideAllOperations.switchStateForHideAll({ show: true }));
      },
    };

    if (name === "") {
      _errors.setError(`${Languages.getTranslation("folder-without-name", true)}`, 'error', true)
      return;
    }
    await Requests.Projects.addFolder(name).then(() => {
      dispatch(errorMessageOperations.switchStateError(
          { message: Languages.getTranslation("add-folder-success", true), type: 'success', show: true }))
    }).catch((error) => dispatch(errorMessageOperations.switchStateError(
        { message: error.message, type: 'warn', show: true })))

    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };

  return (
    <>
      <div className="modal-add-folder">
        <h2>{Languages.getTranslation("add-folder", true)}</h2>
        <div className="body">
          <div className="form-control">
            <label htmlFor="name">
              {Languages.getTranslation("type-folder-name", true)}
            </label>
            <input
              id="name"
              onChange={handleChange}
              name="name"
              value={name}
              type="text"
              maxLength="255"
            />
          </div>
        </div>
        <div className="actions">
          <button onClick={close} className="cancel">
            {Languages.getTranslation("cancel", true)}
          </button>
          <button onClick={submit} className="submit">
            {Languages.getTranslation("save", true)}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddFolder;
