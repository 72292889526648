import React from 'react'
import {edgeTitle} from "../../../../../helpers/helper";

const ReplaceEdgesProjectItem2 = ({edge, idx}) => {
	return (
		<div
			className="replaceMaterialProject__material"
			key={idx}>
			<div className="replaceMaterialProject__content-container">
				<div className="replaceMaterialProject__number">{`№${idx + 1}`}</div>
				<div
					className="replaceMaterialProject__name">{edgeTitle(edge)}</div>
			</div>
			<div className="replaceMaterialProject__data-container">
				<div className="replaceMaterialProject__data-item">
					<span className="replaceMaterialProject__data">{edge.article || edge.code}</span>
				</div>
				<div className="replaceMaterialProject__data-item">
					<span className="replaceMaterialProject__data">{edge.width || edge.w}</span>
				</div>
				<div className="replaceMaterialProject__data-item">
					<span className="replaceMaterialProject__data">{edge.thickness}</span>
				</div>
			</div>
			<div className='replaceMaterialProject__table-header_invincible'></div>
		</div>
	)
}
export default ReplaceEdgesProjectItem2
