import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { modalsOperations } from "redux/modals";
import "./style.scss";
import Languages from "translation/Languages";
import {userSelectors} from "redux/user";
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";

const ProcessType = ({ img, title, type, classes, imgClass }) => {
  const dispatch = useDispatch();
  const permissions = useSelector(userSelectors.permissions);
  useUpdateTranslations();

  const openCustomerWarehouse = () => {
    if (permissions.includes('KM_openCustomerWarehouse')) {
      dispatch(
        modalsOperations.switchStateForModal({
          show: true,
          type: "CHOOSE_OWNER",
        })
      );
      return
    }
    dispatch(
      modalsOperations.switchStateForModal({
        show: true,
        type: "CUSTOMER_WAREHOUSE",
      })
    );
  };

  const handleAction = () => {
    if (type === "CUSTOMER_WAREHOUSE") {
      openCustomerWarehouse()
      return
    }

    dispatch(
      modalsOperations.switchStateForModal({
        show: true,
        type: type,
      })
    );
  };

  return (
    <>
      <div
        onClick={handleAction}
        className={"type " + (classes ? classes : "")}
      >
        <img className="process-img" style={imgClass} src={img} alt={title} />
        <span className="title">{Languages.getTranslation(title, true)}</span>
      </div>
    </>
  );
};

export default ProcessType;
