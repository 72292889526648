export const holesParamsForFace = [
  {key: "2", value: 2},
  {key: "5", value: 5},
  {key: "7", value: 7},
  {key: "8", value: 8},
  {key: "10", value: 10},
  {key: "15", value: 15},
  {key: "20", value: 20},
  {key: "26", value: 26},
  {key: "35", value: 35},
]

export const holesParamsForEdgeSide = [
  {key: "4.5", value: 4.5},
  {key: "8", value: 8},
]