import {useState, useEffect, useRef} from "react";
import TableDetails from "components/UI/Tables/Details";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import "./style.scss";
import {modalsOperations, modalsSelectors} from "redux/modals";
import Languages from "translation/Languages";
import _Details from "../../db/_Details";
import {empty} from "../../helpers/helper";
import {toast} from "react-toastify";
import SubmitModal from "../UI/Modal/SubmitModal";
import {errorMessageOperations} from '../../redux/errors'
import Errors from "../../models/Errors";
import {tempDetailOperations} from "redux/tempDetail";
import AhtungModal from "../UI/Modal/AhtungModal";
import Loader from "../UI/Loader";
import AddDetailsModal from "../UI/Modal/AddDetailsModal";
import CloneDetailsModal from "../UI/Modal/CloneDetailsModal";
import cloneDeep from "lodash.clonedeep";
import FilterSidebar from "../UI/FilterSidebar";
import InputModal from "../UI/Modal/InputModal";
import Requests from "../../api/API";
import InputExelModal from "../UI/Modal/InputExelModal";
import EditCount from "../UI/Modal/EditCount";
import {toastError, toastSuccess} from "../../helpers/toasts";
import TableHardware from "../UI/Tables/Hardware";
import AddHardwareModal from "../UI/Modal/AddHardwareModal";
import AddCatalogModal from "../UI/Modal/AddCatalogModal";

import CustomSelect from "../UI/Select"

const detailsDb = new _Details();
const _errors = new Errors([])
import axios from "axios";
import {userSelectors} from "redux/user";
import materialImg from '../../media/selectAction/material-to-detail.png';
import edgeImg from '../../media/selectAction/edge-to-detail.png';
import deleteDetailsImg from '../../media/selectAction/delete-details.png';
import textureImg from '../../media/selectAction/texture.png';
import noTextureImg from '../../media/selectAction/noTexture.png';
import cloneDetailImg from '../../media/selectAction/clone-detail.png';
import countDetailsImg from '../../media/selectAction/count-details.png';
import groupChangeHoles from '../../media/selectAction/groupChangeHoles.png';
import Project from "../../models/Project";
import Contour from "../UI/SvgSketch";
import {ScrollTopBtn} from "../UI/Tables/Details/common";
import Loader2 from "../UI/Loader2";
import CONSTANTS from "../../config/constants";

const parentStyles = {
	control: (provided, {isDisabled}) => ({
		...provided,
		cursor: isDisabled ? 'not-allowed' : 'default',
		backgroundColor: "#ffffff",
		opacity: isDisabled ? 0.7 : 1,
		border: 'none',
	}),
	placeholder: (provided, {isDisabled}) => ({
	...provided,
	cursor: isDisabled ? 'not-allowed' : 'default',
	}),
	container: (provided) => ({
		...provided,
		pointerEvents: 'initial'
	})
};

const DETAILS_TABS = {
	DETAILS: "details",
	HARDWARE: "hardware"
};

const Details = ({materials}) => {
	const dispatch = useDispatch();
	const construction = useSelector(projectSelectors.getConstruction);
	const {errors} = useSelector(state => state.project.project);
	const modalState = useSelector(modalsSelectors.getModalShowState);
	const detailsList = useSelector(projectSelectors.getDetails);
	const detailsTableConfig = useSelector(projectSelectors.getDetailsTableConfig);
	const tooltipRef = useRef(null)
	const inputRef = useRef()
	const permissions = useSelector(userSelectors.permissions);

	const [selectedDetails, setSelectedDetails] = useState([]);
	const [details, setDetails] = useState([]);
	const [isDetailsLoading, setIsDetailsLoading] = useState(true)
	const [initialDetails, setInitialDetails] = useState([]);
	const [filteredMaterials, setFilteredMaterials] = useState([]);
	const [filteredEdges, setFilteredEdges] = useState([]);
	const [filteredThickness, setFilteredThickness] = useState([]);
	const [haveTexture, setHaveTexture] = useState(false);
	const [isNotTexture, setIsNotTexture] = useState(false);
	const [haveMultiplicity, setHaveMultiplicity] = useState(false);
	const [isNotMultiplicity, setIsNotMultiplicity] = useState(false);

	const [selectedValue, setSelectedValue] = useState('');
	const [showSubmitModal, setShowSubmitModal] = useState(false)
	const [selectType, setSelectType] = useState('')
	const [submitModalText, setSubmitModalText] = useState('')
	const [deleteDetail, setDeleteDetail] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [load, setLoad] = useState(false)
	const [showAhtung, setShowAhtung] = useState(false)
	const [showAddDetails, setShowAddDetails] = useState(false)
	const [showCloneDetail, setShowCloneDetail] = useState(false)
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)
	const [openInputModal, setOpenInputModal] = useState(false)
	const [openModalGitlab, setOpenModalGitlab] = useState(false)
	const [editCountModal, setEditCountModal] = useState(false)

	const [isMaterialsOpen, setIsMaterialsOpen] = useState(false);
	const [isEdgesOpen, setIsEdgesOpen] = useState(false);
	const [isThicknessOpen, setIsThicknessOpen] = useState(false);
	const [isTextureOpen, setIsTextureOpen] = useState(false);
	const [isMultiplicityOpen, setIsMultiplicityOpen] = useState(false);
	const [edgesForFilter, setEdgesForFilter] = useState([])
	const [materialsForFilter, setMaterialsForFilter] = useState([])
	const [thicknessForFilter, setThicknessForFilter] = useState([]);
	const [filteredActive, setFilteredActive] = useState(({
		isMaterialChecked: false,
		isEdgesChecked: false,
		isThicknessChecked: false,
		isMultiplicityChecked: false,
		isTextureChecked: false,
	}));
	const [tabToggle, setTabToggle] = useState(DETAILS_TABS.DETAILS)
	const [openHardwareModal, setOpenHardwareModal] = useState(false)
	const [openCatalog, setOpenCatalog] = useState(false);

	const [currentDetailsToShow, setCurrentDetailsToShow] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(false); // новое состояния для контроля рендеринга

	const lastDetailWithImgPdf = detailsList[detailsList.length - 1]?.imgPdf
	const detailsPerInterval = 10;
	const intervalTime = 700;

	const isDetailsModeEqualsList = detailsTableConfig.mode === CONSTANTS.detailsTableModes.list;

	useEffect(() => {
		let timer = null;

		if (empty(lastDetailWithImgPdf)) {
			timer = setTimeout(() => {
				if (currentIndex < construction.details.length) {
					setIsLoading(true); // начинаем загрузку
					const nextDetails = construction.details.slice(currentIndex, currentIndex + detailsPerInterval);
					setCurrentDetailsToShow(nextDetails);
					setCurrentIndex(prevIndex => prevIndex + detailsPerInterval);
				} else if (empty(lastDetailWithImgPdf)) {
					setIsLoading(true); // начинаем загрузку
					let objectsWithImgPdfNull = construction.details.filter(detail => detail.imgPdf === null);
					setCurrentDetailsToShow(objectsWithImgPdfNull);
				} else {
					setIsLoading(false); // если все детали отображены, останавливаем загрузку
				}
			}, intervalTime);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [currentIndex, lastDetailWithImgPdf, detailsList]);

	const FORMATS_BASIS =
		'.kronas, .project, .json, .xml';
	const FORMATS_PRO100 =
		'.txt';

	const updateOrderAfterRemoval = async () => {
		await Promise.all([...construction._details].map(async (item, index) => {
			const newOrder = index + 1;
			if (newOrder !== item.order) {
				item.order = newOrder;
				await detailsDb.updateDetail(item.id, "order", newOrder);
			}
		}));
	};

	// TODO removeDetails and removeDetail have the same logic. Can I combine them?
	const removeDetails = (id) => {
		detailsDb.deleteDetail(id)
			.then(() => {
				setDetails([]);
				setTimeout(() => {

					setDetails([...construction._details]);
				}, 100);
			}).then(() => {
			setTimeout(() => {
				updateOrderAfterRemoval()

			}, 100)

		})
			.catch(err => console.log(err))
	};
	const removeDetail = () => {
		setInitialDetails(initialDetails.filter((el) => el !== deleteDetail));
		construction._details = details.filter((el) => el !== deleteDetail);
		detailsDb.deleteDetail(deleteDetail.id)
			.then(() => {
				setDetails([]);
				setTimeout(() => {
					setDetails([...construction._details]);
				}, 100);
			})
			.catch(err => console.log(err))
	};
	const filterDetailsByEdge = (currentDetails) => {
		const filteredIndexEdges = filteredEdges.map(({index}) => index);

		return currentDetails.filter(({leftEdge, rightEdge, topEdge, bottomEdge}) => {
			const edgeValues = [leftEdge, rightEdge, topEdge, bottomEdge];
			return edgeValues.some(edge => filteredIndexEdges.includes(parseInt(edge, 10)));
		});
	}
	const filterDetailsByThickness = (currentDetails) => {
		const filteredIndexThickness = filteredThickness.map(({w}) => w);
		return currentDetails.filter((det) => filteredIndexThickness.includes(det.w));
	}
	const filterDetailsByTexture = (currentDetails) => {
		if (haveTexture && isNotTexture) {
			return currentDetails
		} else if (haveTexture) {
			return currentDetails.filter((det) => det.isRotateTexture);
		} else {
			return currentDetails.filter((det) => !det.isRotateTexture);
		}
	}
	const filterDetailsByMyltiplicity = (currentDetails) => {
		if (haveMultiplicity && isNotMultiplicity) {
			return currentDetails
		} else if (haveMultiplicity) {
			return currentDetails.filter((det) => !!det.multiplicity);
		} else {
			return currentDetails.filter((det) => !!det.multiplicity === false);
		}
	}
	const filterDetailsByMaterials = (currentDetails) => {
		const filteredIndexMaterials = filteredMaterials.map(({index}) => index);
		return currentDetails.filter((det) => filteredIndexMaterials.includes(det.material) || filteredIndexMaterials.includes(det.multiplicity?.material));
	}
	const checkFilteredProps = () => {
		const isMaterialChecked = materialsForFilter.some(({isChecked}) => isChecked)
		const isEdgesChecked = edgesForFilter.some(({isChecked}) => isChecked)
		const isThicknessChecked = thicknessForFilter.some(({isChecked}) => isChecked)
		const isMultiplicityChecked = haveMultiplicity || isNotMultiplicity
		const isTextureChecked = haveTexture || isNotTexture

		setFilteredActive({
			isMaterialChecked,
			isEdgesChecked,
			isThicknessChecked,
			isMultiplicityChecked,
			isTextureChecked,
		});

	}
	const filterDetails = () => {
		const isFilterExist = filteredEdges.length || filteredThickness.length || filteredMaterials.length || haveTexture || isNotTexture || haveMultiplicity || isNotMultiplicity;
		if (isFilterExist) {
			const materialItems = filteredMaterials.length ? filterDetailsByMaterials(initialDetails) : initialDetails
			const edgesItems = filteredEdges.length ? filterDetailsByEdge(materialItems) : materialItems
			const thicknessItems = filteredThickness.length ? filterDetailsByThickness(edgesItems) : edgesItems
			const textureItems = haveTexture || isNotTexture ? filterDetailsByTexture(thicknessItems) : thicknessItems
			const myltiplicityItems = haveMultiplicity || isNotMultiplicity ? filterDetailsByMyltiplicity(textureItems) : textureItems

			setDetails(myltiplicityItems)
		} else {
			setDetails(initialDetails)
		}
		checkFilteredProps()
	}
	useEffect(() => {
		if (!errors.isShow) {
			detailsList.every(item => {
				if (item.error.allErrorsMessage.length > 0) {
					const message = Languages.getTranslation('erroneous-parts-found-in-the-project', true)

					toast.error(`${message}`, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
						toastId: `${Languages.getTranslation('erroneous-parts-found-in-the-project', true)}`,
					})

					dispatch(projectOperations.errorProject(message, true))
					return false
				}
				return true
			})
		}
	}, [errors]);

	useEffect(() => {
		if (deleteDetail) {
			setSubmitModalText(Languages.getTranslation('delete-detail-accept', true) + deleteDetail.name + "?")
			setSelectType('deleteDetail')
			setShowSubmitModal(true)
		}
	}, [deleteDetail])

	useEffect(() => {
		setDetails([...detailsList]);
		setIsDetailsLoading(false);
	}, [detailsList]);

	useEffect(() => {
		const filteredEdges = construction.edges.map((item) => ({...item, isChecked: false}))
		setEdgesForFilter(filteredEdges)
	}, [modalState, materials, construction?.edges])

	useEffect(() => {
		const filteredMaterials = construction.materials.map((item) => ({...item, isChecked: false}))
		setMaterialsForFilter(filteredMaterials)
		const thicknessArrFilter = initialDetails.map(({w}) => ({w, isChecked: false}));
		const uniqueArrayThickness = thicknessArrFilter.filter((obj, index, arr) => {
			return arr.findIndex((item) => item.w === obj.w) === index;
		});
		setThicknessForFilter(uniqueArrayThickness)
		if (!modalState) {
			setDetails([...detailsList.sort((a, b) => {
				if (!empty(a.order) && !empty(b.order)) {
					return a.order - b.order;
				} else {
					return a.id - b.id;
				}
			})]);
			setInitialDetails([...detailsList])
		}
	}, [modalState, materials]);

	const updateOrderAfterRemovalOnce = (deletedItem) => {
		// slicing to create a new copy
		const newDetails = [...details].filter(detail => detail.id !== deletedItem.id);
		newDetails.forEach((item, index) => {
			item.order = index + 1;
		});
		setDetails(newDetails);

		// updating database
		Promise.all(newDetails.map((item, index) =>
			detailsDb.updateDetail(item.id, "order", index + 1)))
	}

	useEffect(() => {
		if (selectedValue === 'selectRemoveDetails') {
			selectedDetails.forEach((sd) => {
				removeDetails(sd._id)
			})
			construction.details = details.filter((detail) => !selectedDetails.includes(detail))
			setInitialDetails(initialDetails.filter((detail) => !selectedDetails.includes(detail)));

			setSelectedValue('')
			setSelectedDetails([])
		}
		if (selectedValue === "deleteDetail") {
			updateOrderAfterRemovalOnce(deleteDetail)
			removeDetail()
			setSelectType('')
			setSelectedValue('')
		}
		if (selectedValue === "import_project" && !empty(inputRef.current)) {
			inputRef.current.click()
			setSelectedValue('')
		}
		if (selectedValue === "import_project_id") {
			setOpenInputModal(true)
			setSelectedValue('')
		}
		if (selectedValue === "transferToReverseSide") {
			updateHolesOnSelectedDetails()
			setSelectedValue('')
			setSelectedDetails([])
		}
		if (selectedValue === "import_project_gitlab") {
			setOpenModalGitlab(true)
			setSelectedValue('')
		}
		if (selectedValue === "import_project_pro100") {
			inputRef.current.click()
		}
	}, [selectedValue, inputRef])

	useEffect(() => {
		const handleClickOut = (e) => {
			if (tooltipRef.current && !tooltipRef.current.contains(e.target) && isOpen) {
				setIsOpen(prevState => !prevState);
			}
		};

		if (isOpen) {
			document.addEventListener('click', handleClickOut)
		}
		return () => {
			document.removeEventListener('click', handleClickOut);
		};
	}, [isOpen])

	const updateHolesOnSelectedDetails = () => {
		let isSuccess = false
		selectedDetails.map(detail => Promise.all(detail.holes.map(hole => {
			if (hole) {
				isSuccess = true
				return hole.reverseSide()
			}
		})))
		if (isSuccess) toastSuccess(Languages.getTranslation('reverse-holes-to-side-success', true))
	}

	const closeFilterSidebar = () => {
		setIsSidebarOpen(false);
		document.body.classList.remove("sidebar-open");
	}

	const changeAction = (event) => {
		const value = event

		if (empty(selectedDetails)) {
			_errors.setError(`${Languages.getTranslation('choose-one-detail', true)}`, 'error', true)
			return
		}

		if (value === 'selectMaterial') {
			dispatch(modalsOperations.switchStateForModal({
				show: true,
				type: 'REPLACE_MATERIAL_PROJECT',
				isEdit: selectedDetails,
				isReplacedMaterialForProject: false,
			}))
			setSelectedValue('')
			setSelectedDetails([])
		}

		if (value === 'changeProductOnDetail') {
			dispatch(modalsOperations.switchStateForModal({
				show: true,
				type: 'CHANGE_PRODUCT_ON_DETAIL',
				isEdit: selectedDetails,
			}))
			setSelectedValue('')
			setSelectedDetails([])
		}

		if (value === 'selectEdge') {
			if (empty(construction.edges)) {
				dispatch(errorMessageOperations.switchStateError({
					message: Languages.getTranslation('edge-to-detail-error', true),
					type: 'error',
					show: true
				}))
				setSelectedValue('')
				setSelectedDetails([])
				return
			}
			dispatch(modalsOperations.switchStateForModal({
				show: true,
				type: 'REPLACE_EDGE',
				isEdit: selectedDetails
			}))
			setSelectedValue('')
			setSelectedDetails([])
		}

		if (value === 'groupChangeDiamHoles') {
			dispatch(modalsOperations.switchStateForModal({
				show: true,
				type: 'GROUP_CHANGE_DIAM_HOLES',
				isEdit: selectedDetails
			}))
			setSelectedValue('')
			setSelectedDetails([])
		}

		if (value === 'removeDetails') {
			setSubmitModalText(Languages.getTranslation('submit-removeDetails', true))
			setSelectType('selectRemoveDetails')
			setShowSubmitModal(true)
			setSelectedValue('')
		}

		if (value === 'selectTexture') {
			selectedDetails.forEach((sd) => {
				if (!['OSB'].includes(construction.materials.find(mat => mat.index === sd.material).type)) {
					detailsDb.updateDetail(sd.id, "isRotateTexture", true)
				}
			})
			construction.details.forEach((el) => {
				if (selectedDetails.includes(el) && !['OSB'].includes(construction.materials.find(mat => mat.index === el.material).type)) {
					el.isRotateTexture = true
				}
			})
			setSelectedValue('')
			setSelectedDetails([])
		}

		if (value === 'removeTexture') {
			selectedDetails.forEach((sd) => {
				if (!['Постформінг', 'Постформинг', 'OSB'].includes(construction.materials.find(mat => mat.index === sd.material).type)) {
					detailsDb.updateDetail(sd.id, "isRotateTexture", false);
				}
			})
			construction.details.forEach((el) => {
				if (selectedDetails.includes(el)) {
					if (!['Постформінг', 'Постформинг', 'OSB'].includes(construction.materials.find(mat => mat.index === el.material).type)) {
						el.isRotateTexture = false
					}
				}
			})
			setSelectedValue('')
			setSelectedDetails([])
		}

		if (value === 'updateCountDetails') {
			setEditCountModal(true)
			setSelectedValue('')
		}

		if (value === 'transferToReverseSide') {
			setSelectedValue('transferToReverseSide')
		}

		if (value === 'cloneDetail') {
			const tempDetails = cloneDeep(selectedDetails)
			tempDetails.forEach((item) => {
				item.name += Languages.getTranslation('copy', true)
			})
			dispatch(tempDetailOperations.setTempDetails(tempDetails))
			setShowCloneDetail(true)
		}
	};

	const createDetail = async () => {
		if (!construction._materials.length) {
			toastError(Languages.getTranslation('dont-have-materials-project', true))
			return;
		}
		if (empty(construction.products)) {
			await construction.createProduct({name: Languages.getTranslation('single-product', true)})
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "ADD_DETAIL",
			})
		);
	};

	const openHardware = () => {
		setOpenHardwareModal(prevState => !prevState)
	}

	const quickInputTableHandler = async () => {
		if (!construction._materials.length) {
			toastError(Languages.getTranslation('dont-have-materials-project', true))
			return;
		}
		if (empty(construction.products)) {
			await construction.createProduct({name: Languages.getTranslation('single-product', true)})
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "QUICK_INPUT_TABLE",
			})
		);
	};

	const productsHandler = () => {
		if (!construction._materials.length) {
			toastError(Languages.getTranslation('dont-have-materials-project', true))
			return;
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "PRODUCTS",
			})
		);
	};

	const uploadFileHandler = async (event) => {
		setIsOpen(prevState => !prevState)
		setLoad(true)

		const formData = new FormData()
		formData.append('file', event.target.files[0]);

		await axios.post(`${process.env.CVRT_KRONAS}/api/${selectedValue === 'import_project_pro100' ? 'convert-file-pro100' : 'convert-file'}`, formData, {
			headers: {'Content-Type': 'multipart/form-data',}
		})
			.then(data => {
				setIsOpen(prevState => !prevState)
				dispatch(tempDetailOperations.setTempProject(data.data.data))
				setLoad(false)
				setShowAddDetails(true)
			})
			.catch(err => console.log(err))
	}

	const handleGetId = (id) => {
		Requests.Projects.getProjectById(id)
			.then(response => {
				// TODO JSON.parse(response.file) RangeError: Maximum call stack size exceeded
				// dispatch(tempDetailOperations.setTempProject(JSON.parse(response.file)))
				dispatch(tempDetailOperations.setTempProject(JSON.parse(response.file)))
				setOpenInputModal(false)
				setShowAddDetails(true)
			})
			.catch(error => console.log(error))
	}

	const handleOpenCLone = () => {
		setOpenModalGitlab(false)
		setShowAddDetails(true)
	}

	const closeEditCount = () => {
		setSelectedDetails([])
		setEditCountModal(false)
	}

	useEffect(() => {
		setDetails(construction.details.sort((a, b) => {
				if (!empty(a.order) && !empty(b.order)) {
					return a.order - b.order;
				} else {
					return a.id - b.id;
				}
			})
		)
	}, [editCountModal]);

	const edgesModeClass = detailsTableConfig.limits.onlyEdge
		? "details__options-add_editMode__edges-mode details__options-add_editMode__edges-mode_active"
		: "quickInputTable__edges-mode";

	const edgesModeItemClass = detailsTableConfig.limits.onlyEdge
		? "details__options-add_editMode__edges-mode-item details__options-add_editMode__edges-mode-item_active"
		: "details__options-add_editMode__edges-mode-item";

	const garModeClass = detailsTableConfig.limits.onlyFirstGroup
		? "details__options-add_editMode__edges-mode details__options-add_editMode__edges-mode_active"
		: "quickInputTable__edges-mode";

	const garModeItemClass = detailsTableConfig.limits.onlyFirstGroup
		? "details__options-add_editMode__edges-mode-item details__options-add_editMode__edges-mode-item_active"
		: "details__options-add_editMode__edges-mode-item";


	const searchCatalog = () => {
		setOpenHardwareModal(prev => !prev)
		setOpenCatalog(prev => !prev)
	}

	const changeDetailsMode = () => {
		if (!isDetailsModeEqualsList) {
			Project.validateProjectDetails().then(() => {
				dispatch(
					projectOperations.setDetailsTableMode(CONSTANTS.detailsTableModes.list)
				);
				dispatch(
					projectOperations.setDetailsTableLimits({onlyFirstGroup: false, onlyEdge: false})
				);
			}).catch(error => console.log(error))
		} else {
			dispatch(
				projectOperations.setDetailsTableMode(CONSTANTS.detailsTableModes.edit)
			);
			dispatch(
				projectOperations.setDetailsTableLimits({onlyFirstGroup: false, onlyEdge: false})
			);
		}
	}

	const createDetailButton = () => {
		if (!permissions.includes('KM_createDetail')) return null;

		const modeTogglerTitle = isDetailsModeEqualsList ?
			Languages.getTranslation("part-editing-mode", true) :
			Languages.getTranslation("save", true)

		return (
			<div
				className={`${isDetailsModeEqualsList ? 'details__options-add' : 'details__options-add_editMode'}
                ${tabToggle === DETAILS_TABS.HARDWARE ? 'bgNoSelect' : 'bgSelect'}`}
				onClick={() => {
					toast.dismiss();
					setTabToggle('details')
				}}>
				<div className="details__options-add_sub">
					<button
						onClick={createDetail}
						className="btn"
						style={{marginLeft: "23px"}}
						title={Languages.getTranslation('add-detail', true)}
					>
						<i className="btn__icon-add"></i>
					</button>
					<span className="details__options-title">{Languages.getTranslation("details", true)}</span>
				</div>
				{!isDetailsModeEqualsList &&
					<div className='details__options-add_editMode_wraper'>
						<div className='details__options-add_editMode_block'>
                            <span
															className='details__options-add_editMode_block_title'>{Languages.getTranslation("gabarits", true)}</span>
							<div className={garModeClass} title={Languages.getTranslation('gabarits-only', true)}
									 onClick={() => {
										 if (detailsTableConfig.limits.onlyFirstGroup) {
											 dispatch(projectOperations.setDetailsTableLimits({onlyFirstGroup: false, onlyEdge: false}));
											 dispatch(projectOperations.setDefaultDetailsTableFocusedRow());
										 } else {
											 dispatch(projectOperations.setDetailsTableLimits({onlyFirstGroup: true, onlyEdge: false}));
											 dispatch(projectOperations.setDetailsTableFocusedRow({index: detailsTableConfig.focusedRow.index, field: CONSTANTS.detailFieldNames.WIDTH}));

										 }
									 }}>
								<div className={garModeItemClass}></div>
							</div>
						</div>
						<div className='details__options-add_editMode_block'>
                            <span
															className='details__options-add_editMode_block_title'>{Languages.getTranslation("edge", true)}</span>
							<div className={edgesModeClass} title={Languages.getTranslation('edges-only', true)}
									 onClick={() => {
										 if (detailsTableConfig.limits.onlyEdge) {
											 dispatch(projectOperations.setDetailsTableLimits({onlyFirstGroup: false, onlyEdge: false}));
											 dispatch(projectOperations.setDefaultDetailsTableFocusedRow());
										 } else {
											 dispatch(projectOperations.setDetailsTableLimits({onlyFirstGroup: false, onlyEdge: true}));
											 dispatch(projectOperations.setDetailsTableFocusedRow({index: detailsTableConfig.focusedRow.index, field: CONSTANTS.detailFieldNames.TOP}));
										 }
									 }}>
								<div className={edgesModeItemClass}></div>
							</div>
						</div>
					</div>}
				{permissions.includes('KM_editDetails') &&
					<button type="button" title={modeTogglerTitle} onClick={changeDetailsMode}
									className="details__options-add_edit">
						{isDetailsModeEqualsList
							?
							<svg width="26" height="25" viewBox="0 0 26 25" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
								<rect x="0.75" y="0.778076" width="25" height="24" rx="4" fill="#F3E0EE"/>
								<path
									d="M15.3753 20.3927H11.1253C7.27908 20.3927 5.63574 18.7494 5.63574 14.9032V10.6532C5.63574 6.80691 7.27908 5.16357 11.1253 5.16357H12.542C12.8324 5.16357 13.0732 5.40441 13.0732 5.69482C13.0732 5.98524 12.8324 6.22607 12.542 6.22607H11.1253C7.85991 6.22607 6.69824 7.38774 6.69824 10.6532V14.9032C6.69824 18.1686 7.85991 19.3302 11.1253 19.3302H15.3753C18.6407 19.3302 19.8024 18.1686 19.8024 14.9032V13.4865C19.8024 13.1961 20.0432 12.9552 20.3337 12.9552C20.6241 12.9552 20.8649 13.1961 20.8649 13.4865V14.9032C20.8649 18.7494 19.2216 20.3927 15.3753 20.3927Z"
									fill="#A14389"/>
								<path
									d="M10.7722 16.8086C10.3401 16.8086 9.94341 16.6528 9.65299 16.3694C9.30591 16.0223 9.15716 15.5194 9.23508 14.9882L9.53966 12.8561C9.59633 12.4453 9.86549 11.914 10.1559 11.6236L15.7376 6.04193C17.1472 4.63235 18.578 4.63235 19.9876 6.04193C20.7597 6.81401 21.1067 7.60026 21.0359 8.38651C20.9722 9.02401 20.6322 9.64734 19.9876 10.2848L14.4059 15.8665C14.1155 16.1569 13.5842 16.4261 13.1734 16.4828L11.0413 16.7873C10.9492 16.8086 10.8572 16.8086 10.7722 16.8086ZM16.4884 6.79276L10.9067 12.3744C10.7722 12.509 10.6163 12.8207 10.588 13.0048L10.2834 15.1369C10.2551 15.3423 10.2976 15.5123 10.4038 15.6186C10.5101 15.7248 10.6801 15.7673 10.8855 15.739L13.0176 15.4344C13.2017 15.4061 13.5205 15.2503 13.648 15.1157L19.2297 9.53401C19.6901 9.0736 19.9309 8.66276 19.9663 8.28026C20.0088 7.81985 19.768 7.3311 19.2297 6.78568C18.0963 5.65235 17.3172 5.9711 16.4884 6.79276Z"
									fill="#A14389"/>
								<path
									d="M18.8108 11.241C18.7613 11.241 18.7117 11.2339 18.6692 11.2198C16.8063 10.6956 15.3258 9.21519 14.8017 7.35227C14.7238 7.06894 14.8867 6.77852 15.17 6.69352C15.4533 6.61561 15.7438 6.77852 15.8217 7.06186C16.2467 8.57061 17.4438 9.76769 18.9525 10.1927C19.2358 10.2706 19.3988 10.5681 19.3208 10.8514C19.2571 11.0923 19.0446 11.241 18.8108 11.241Z"
									fill="#A14389"/>
							</svg>
							:
							<svg width="26" height="25" viewBox="0 0 26 25" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
								<rect x="0.882812" y="0.506592" width="25" height="24" rx="4" fill="#A14389"/>
								<path fillRule="evenodd" clipRule="evenodd"
											d="M17.1908 5.34327C17.2341 5.3056 17.2852 5.28134 17.3444 5.27051H19.1311C19.2767 5.27051 19.3954 5.32177 19.4869 5.42412L20.5138 6.45106C20.6108 6.54808 20.6592 6.66126 20.6592 6.79062V19.2736C20.6592 19.403 20.6134 19.5134 20.5216 19.6051C20.4354 19.6966 20.3303 19.7426 20.2064 19.7426H6.56744C6.44326 19.7426 6.33557 19.6968 6.24405 19.6051C6.15221 19.5134 6.10645 19.4028 6.10645 19.2736V5.73943C6.10645 5.61007 6.15221 5.49963 6.24405 5.40795C6.33557 5.31643 6.44617 5.27051 6.57521 5.27051H9.12193C9.21362 5.27051 9.28654 5.29751 9.34039 5.35136C9.37806 5.3837 9.4049 5.4322 9.42123 5.49688H9.42932V10.4691C9.43466 10.5931 9.48042 10.6982 9.56676 10.7844C9.64761 10.8706 9.75013 10.9163 9.87399 10.9218H16.6491C16.7731 10.9218 16.881 10.8761 16.9725 10.7844C17.064 10.6982 17.1127 10.5929 17.118 10.4691V5.49688C17.1342 5.4377 17.1586 5.38644 17.1908 5.34327ZM15.9863 6.01448V10.2427H13.9568V6.01448H15.9863ZM18.7025 18.9743V13.2504C18.6971 13.1264 18.6514 13.024 18.565 12.9432C18.4789 12.857 18.3736 12.8085 18.2499 12.7978H8.5319L8.47515 12.814H8.44297C8.34061 12.8302 8.25427 12.8814 8.18425 12.9676C8.10874 13.0538 8.0709 13.1481 8.0709 13.2506H8.06298V18.9743H18.7025Z"
											fill="white"/>
							</svg>
						}
					</button>}
			</div>
		)
	}

	return (
		<>
			{load ? <div className='mT-10'><Loader/></div> :
				<div className="details">
					<div className="details__options">
						{createDetailButton()}

						{isDetailsModeEqualsList && permissions.includes('KM_furniture') &&
							<div
								className={`details__options-add ${tabToggle === DETAILS_TABS.DETAILS ? 'bgNoSelect' : 'bgSelect'}`}
								style={{justifyContent: "flex-start"}}
								onClick={() => {
									toast.dismiss();
									setTabToggle('hardware')
								}}>
								<button
									onClick={openHardware}
									className="btn"
									style={{marginLeft: "23px"}}
									title={Languages.getTranslation('add-hardware', true)}
								>
									<i className="btn__icon-add"></i>
								</button>
								<span style={{marginLeft: "15px"}}
											className="details__options-title">{Languages.getTranslation('furniture-title', true)}</span>
							</div>}
						{permissions.includes('KM_TSW') && (
							<button
								className="details__quick-input-table"
								onClick={quickInputTableHandler}
								title={Languages.getTranslation('open-qit', true)}
							>
								{Languages.getTranslation("quick-input-table", true)}
							</button>
						)}
						{permissions.includes('KM_importDetail') && (
							<button
								ref={tooltipRef}
								className="details__quick-input-table"
								title={Languages.getTranslation("import-parts", true)}
								onClick={() => setIsOpen(prevState => !prevState)}
							>
								<i className="icon-link"></i>
								{isOpen &&
									<div className='details__quick-input-table_tooltip'>
										<div className='details__quick-input-table_tooltip-item-wrapper'>
											<div
												onClick={() => {
													setShowAhtung(prevState => !prevState)
													setSelectType('import_project')
												}}
												className='details__quick-input-table_tooltip-item-wrapper_type'>
												{Languages.getTranslation("from-Basis-Meblyar", true)}</div>
											<div
												onClick={() => {
													setShowAhtung(prevState => !prevState)
													setSelectType('import_project_id')
												}}
												className='details__quick-input-table_tooltip-item-wrapper_type'>
                                             <span className="project-actions__input-title">
                                                {`${Languages.getTranslation("import-saved-project", true)}`}
                                              </span>
											</div>
											<div
												onClick={() => {
													setShowAhtung(prevState => !prevState)
													setSelectType('import_project')
												}}
												className='details__quick-input-table_tooltip-item-wrapper_type'>
												{Languages.getTranslation("from-GibLab", true)}</div>
											<div
												onClick={() => {
													setShowAhtung(prevState => !prevState)
													setSelectType('import_project_gitlab')
												}}
												className='details__quick-input-table_tooltip-item-wrapper_type'>
												{Languages.getTranslation("from-Xsl", true)}</div>
											<div
												onClick={() => {
													setShowAhtung(prevState => !prevState)
													setSelectType('import_project_pro100')
												}}
												className='details__quick-input-table_tooltip-item-wrapper_type'>
												{Languages.getTranslation("importPro100", true)}</div>
										</div>
									</div>
								}
							</button>
						)}
						{permissions.includes('KM_TSW') && (
							<button
								className="details__products"
								onClick={productsHandler}
								title={Languages.getTranslation('open-productsModal', true)}
							>
								<i className="details__products-icon"></i>
								{Languages.getTranslation("products", true)}
							</button>
						)}
						{permissions.includes("KM_detailsSelect") && (
							<div className="details__select">
								<CustomSelect
									value={selectedValue}
									customStyles={parentStyles}
									placeholder={Languages.getTranslation("select-action", true)}
									options={[
										{
											value: 'selectMaterial',
											label: Languages.getTranslation("material-to-detail", true),
											img: materialImg
										},
										{
											value: 'changeProductOnDetail',
											label: Languages.getTranslation("change-product-on-detail", true),
											img: materialImg
										},
										{
											value: 'selectEdge',
											label: Languages.getTranslation("edge-to-detail", true),
											img: edgeImg
										},
										{
											value: 'removeDetails',
											label: Languages.getTranslation("delete-selected-details", true),
											img: deleteDetailsImg
										},
										{
											value: 'selectTexture',
											label: Languages.getTranslation("select-texture-on-detail", true),
											img: textureImg
										},
										{
											value: 'removeTexture',
											label: Languages.getTranslation("remove-texture-on-detail", true),
											img: noTextureImg
										},
										{
											value: 'cloneDetail',
											label: Languages.getTranslation(`clone-detail${selectedDetails.length > 1 ? 's' : ''}`, true),
											img: cloneDetailImg
										},
										{
											value: 'updateCountDetails',
											label: Languages.getTranslation(`update-count-details`, true),
											img: countDetailsImg
										},
										{
											value: 'transferToReverseSide',
											label: Languages.getTranslation(`reverse-holes-to-side`, true),
											img: countDetailsImg
										},
										{
											value: 'groupChangeDiamHoles',
											label: Languages.getTranslation(`group-change-diamHoles`, true),
											img: groupChangeHoles
										},
									]}
									disabled={selectedDetails.length === 0}
									withImages={true}
									handleChange={(selectedOption) => {
										setSelectedValue(selectedOption);
										changeAction(selectedOption && selectedOption.value ? selectedOption.value : "");
									}}
								/>
							</div>
						)}
						<div className="details__filter" onClick={() => {
							setIsSidebarOpen(true);
							document.body.classList.add("sidebar-open");
						}}>
                            <span className="details__filter-text">
                                {Languages.getTranslation('filter', true)}
                            </span>
							<div className="details__icon"/>
						</div>
					</div>
					<input type="file" id="project-calc__import"
								 className='import-project'
								 accept={selectedValue === 'import_project_pro100' ? FORMATS_PRO100 : FORMATS_BASIS}
								 onChange={(e) => uploadFileHandler((e))}
								 ref={inputRef}
					/>
					{tabToggle === DETAILS_TABS.DETAILS && details.length > 0 && !isDetailsLoading &&
						<>
                            <TableDetails
                                isDetailsModeEqualsList={isDetailsModeEqualsList}
                                details={details}
                                removeDetail={removeDetail}
                                setSelectedDetails={setSelectedDetails}
                                selectedDetails={selectedDetails}
                                filteredActive={filteredActive}
                                setDetails={setDetails}
                                setDeleteDetail={setDeleteDetail}
						    />
							<ScrollTopBtn/>
						</>
					}
					{tabToggle === DETAILS_TABS.DETAILS && details.length === 0 && !isDetailsLoading &&
						<div className="details__empty-project">{Languages.getTranslation("empty-project", true)}</div>
					}
					{tabToggle === DETAILS_TABS.DETAILS && isDetailsLoading &&
						<Loader2/>
					}
					{tabToggle === DETAILS_TABS.HARDWARE && <TableHardware/>}
					{openHardwareModal &&
						<AddHardwareModal
							searchCatalog={searchCatalog}
							close={() => setOpenHardwareModal(prev => !prev)}
						/>}
					{openCatalog &&
						<AddCatalogModal
							close={() => setOpenCatalog(prev => !prev)}
							backClick={() => searchCatalog()}
						/>}
					{showSubmitModal && <SubmitModal
						setShowSubmitModal={setShowSubmitModal}
						setSelectedValue={setSelectedValue}
						selectType={selectType}
						submitModalText={submitModalText}
						setDeleteDetail={setDeleteDetail}/>}
					{showAhtung && <AhtungModal
						setShowSubmitModal={setShowAhtung}
						submitModalText={Languages.getTranslation('import-project-accept', true)}
						setSelectedValue={setSelectedValue} selectType={selectType}/>}
					{showAddDetails && <AddDetailsModal close={() => {
						dispatch(tempDetailOperations.setTempProject({}))
						setShowAddDetails(false)
					}}/>}
					{showCloneDetail && <CloneDetailsModal close={() => {
						dispatch(tempDetailOperations.setTempDetails([]))
						setShowCloneDetail(false)
					}}/>}
					{openInputModal && <InputModal
						close={() => setOpenInputModal(false)}
						getId={handleGetId}/>}
					{openModalGitlab && <InputExelModal
						close={() => setOpenModalGitlab(false)}
						openCLone={handleOpenCLone}/>}
				</div>}
			{editCountModal && <EditCount close={closeEditCount} selectedDetails={selectedDetails}/>}
			{isSidebarOpen &&
				<FilterSidebar
					materials={materialsForFilter}
					edges={edgesForFilter}
					haveTexture={haveTexture}
					isNotTexture={isNotTexture}
					isNotMultiplicity={isNotMultiplicity}
					haveMultiplicity={haveMultiplicity}
					setFilteredEdges={setFilteredEdges}
					setHaveTexture={setHaveTexture}
					setIsNotTexture={setIsNotTexture}
					setIsNotMultiplicity={setIsNotMultiplicity}
					setHaveMultiplicity={setHaveMultiplicity}
					setFilteredThickness={setFilteredThickness}
					setFilteredMaterials={setFilteredMaterials}
					setIsMaterialsOpen={setIsMaterialsOpen}
					isMaterialsOpen={isMaterialsOpen}
					setIsEdgesOpen={setIsEdgesOpen}
					isEdgesOpen={isEdgesOpen}
					setIsThicknessOpen={setIsThicknessOpen}
					isThicknessOpen={isThicknessOpen}
					setIsMultiplicityOpen={setIsMultiplicityOpen}
					isMultiplicityOpen={isMultiplicityOpen}
					setIsTextureOpen={setIsTextureOpen}
					setMaterialsForFilter={setMaterialsForFilter}
					setEdgesForFilter={setEdgesForFilter}
					setThickness={setThicknessForFilter}
					thickness={thicknessForFilter}
					isTextureOpen={isTextureOpen}
					filterDetails={filterDetails}
					close={closeFilterSidebar}/>
			}

			<div>
				{!empty(currentDetailsToShow) && empty(lastDetailWithImgPdf) && isLoading && currentDetailsToShow.map(detail =>
					<Contour
						key={detail.id}
						detail={detail}
						rects={detail.getCutoutsData()}
						holes={detail.holes}
					/>
				)}
			</div>
		</>
	);
};

export default Details;
