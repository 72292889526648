import React from "react";
import "./style.scss";

import Processing from "../Processing";

import { connect } from "react-redux";

const DetailProps = ({ show, type }) => {

  return show && type == "PROCESSING" ? <Processing /> : null;
};

const mapStateToProps = (state) => ({
  show: state.modals.modals.show,
  type: state.modals.modals.type,
});

export default connect(mapStateToProps)(DetailProps);
