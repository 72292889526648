import ACTIONS from "./actions";

const switchStateForPanel = ({ show, isEditPanel}) => {
  return async (dispatch) => {
    try {
      dispatch(ACTIONS.PanelStateSwitch({ show: show, isEditPanel: isEditPanel }));
    } catch (e) {
      console.log(e);
    }
  };
};

export default {
  switchStateForPanel,
};
