import {combineReducers} from 'redux';
import {createReducer} from '@reduxjs/toolkit';
import ACTIONS from './actions';

const initialFileState = {
  file: {},
};

const inputs = createReducer(initialFileState, {
  [ACTIONS.FileStateProject.type]: (state, file) => {
    return {
      ...state,
      file: file,
    }
  },
});

export default combineReducers({
  inputs
});
