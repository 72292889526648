import {memo, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {userSelectors} from "redux/user";
import Checkbox from "./Checkbox";

const Texture = ({isRotateTexture, id, isDisabled}) => {
    const construction = useSelector(projectSelectors.getConstruction);
    const permissions = useSelector(userSelectors.permissions);

    const [item, setItem] = useState(null);
    const isCheckboxDisabled = item === null || isDisabled || permissions.includes("KM_disabledParamsDetail");

    const changeItem = () => {
        if (!isCheckboxDisabled) {
        setItem(prevItem => {
            const newItem = !prevItem;
            construction.updateDetail(id, 'isRotateTexture', newItem)
            return newItem;
        });
        }
    };

    useEffect(() => {
        setItem(isRotateTexture)
    }, [isRotateTexture]);

    return (<Checkbox isSelected={!!item} onChange={changeItem} isDisabled={isCheckboxDisabled}/>
    )
};
export default memo(Texture)