import API from "config/api";
import {empty} from "../helpers/helper";
import Languages from "../translation/Languages";

export default class Materials {
  constructor(axios, token, headers) {
    this.axios = axios;
    this.token = token;
    this.headers = headers;
  }

  async getMaterialsCurrent2(code, filial=72) {
    return await this.axios
      .get(`${API.ROUTE.get_current_material()}/?code=${code}&filial=${filial}`, this.headers)
      .then((response) => {
        if(!empty(response.data)) {
          if(empty(response.data[0].languages) && empty(response.data[0].name)) {
            return Promise.reject({Error: 'incorrect Material'})
          } else {
            if(empty(response.data[0].languages)) {
              response.data.languages = {
                ru: response.data[0].name,
                ua: response.data[0].name
              }
            } else if(empty(response.data[0].languages.ua)) {
              response.data[0].languages.ua = response.data[0].languages.ru
            }
          }
          if(!empty(response.data[0].edges)) {
            response.data[0].edges.forEach((edge, i) => {
              if(empty(edge.languages) && empty(edge.name)) {
                return Promise.reject({Error: `incorrect Material Edge ${i}`})
              }
              if(empty(edge.languages) && !empty(edge.name)) {
                edge.languages = {
                  ru: edge.name,
                  ua: edge.name
                }
              } else if(empty(edge.languages.ua)) {
                edge.languages.ua = edge.languages.ru
              }
            })
          }
        }

        return Promise.resolve(response.data);
      })
      .catch(err => console.log(err));
  }
  async getMaterialsCurrent(code, filial= 72) {
    const body = {
      vendoreCodeProduct: code,
      filial,
      perPage: 1,
      responseType: 'Sheet'
    };
    return await this.axios
        .post(`${API.ROUTE.get_search_materials()}`,body, this.headers)
        .then((response) => {
          if(!empty(response.data.data)) {
            if(empty(response.data.data[0].languages) && empty(response.data.data[0].name)) {
              return Promise.reject({Error: 'incorrect Material'})
            } else {
              if(empty(response.data.data[0].languages)) {
                response.data.data.languages = {
                  ru: response.data.data[0].name,
                  ua: response.data.data[0].name
                }
              } else if(empty(response.data.data[0].languages.uk)) {
                response.data.data[0].languages.ua = response.data.data[0].languages.ru
              }
            }
            if(!empty(response.data.data[0].edges)) {
              response.data.data[0].edges.forEach((edge, i) => {
                // debugger
                // if(empty(edge.languages)) {
                //   return Promise.reject({Error: `incorrect Material Edge ${i}`})
                // }
                if(empty(edge.languages) && !empty(edge.name)) {
                  edge.languages = {
                    ru: edge.name,
                    ua: edge.name
                  }
                } else if(empty(edge.languages.ua)) {
                  edge.languages.ua = edge.languages.ru
                }
              })
            }
          }

          return Promise.resolve(response.data.data);
        })
        .catch(err => console.log(err));
  }




  async getSearchMaterials(code) {
    const body = {
      vendoreCodeProduct: code,
      filial: 72,
      perPage: 10,
      responseType: 'Sheet'
    };
    return await this.axios
        .post(`${API.ROUTE.get_search_materials()}`,body, this.headers)
        .then((response) => {
          if(!empty(response.data.data)) {
            if(empty(response.data.data[0].languages) && empty(response.data.data[0].name)) {
              return Promise.reject({Error: 'incorrect Material'})
            } else {
              if(empty(response.data.data[0].languages)) {
                response.data.languages = {
                  ru: response.data.data[0].name,
                  ua: response.data.data[0].name
                }
              } else if(empty(response.data.data[0].languages.uk)) {
                response.data.data[0].languages.ua = response.data.data[0].languages.ru
              }
            }
          }
          return Promise.resolve(response.data.data);
        })
  }
  async getEdgesCurrent(code, filial = 72) {
    const body = {
      vendoreCodeProduct: code,
      filial,
      perPage: 15,
      responseType: 'Band'
    };
    return await this.axios
        .post(`${API.ROUTE.get_search_materials()}`, body, this.headers)
        .then((response) => {
          if(!empty(response.data?.data)) {
            if(empty(response.data?.data[0]?.languages)) {
              return Promise.reject({Error: 'incorrect Edjes'})
            } else {
              if(empty(response.data?.data[0]?.languages)) {
                response.data.data.languages = {
                  ru: response.data.data[0].name,
                  ua: response.data.data[0].name
                }
              } else if(empty(response.data.data[0].languages.ua)) {
                response.data.data[0].languages.ua = response.data.data[0].languages.ru
              }
            }
          }
          return Promise.resolve(response.data);
        })
  }

  async getMaterials() {
    return await this.axios
      .get(API.ROUTE.get_materials(), this.headers)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // NotificationManager.warning(error.message, "Error");
      });
  }

  async getEdges() {
    return await this.axios
      .get(API.ROUTE.get_edges(), this.headers)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // NotificationManager.warning(error.message, "Error");
      });
  }

  async getWarehouseMaterials(phone){
    return await this.axios
      .get(`${API.ROUTE.get_warehouse_materials()}?phone=${phone}`, this.headers)
      .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
    //   .catch((error)=>{
    //   NotificationManager.warning(error.message, "Error");
    // })
  }

  async getMaterialsKronas(body){
    this.headers = {
      headers: {
        ...this.headers.headers,
        app: "KM"
      }
    }
    return await this.axios
    .post(`${API.ROUTE.get_kronas_materials()}`, body, this.headers)
    .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
      .catch((error)=>{
        console.log(error.message)
    })
  }

  async reservingMaterials (body)  {
    this.headers = {
      headers: {
        ...this.headers.headers,
        app: "KM"
      }
    }
    return await this.axios
      .post(`${API.ROUTE.reserving_materials()}`, body, this.headers)
      .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
      .catch((error)=>{
        console.log(error.message)
      })
  }

  async deleteReservingMaterials (body)  {
    this.headers = {
      headers: {
        ...this.headers.headers,
        app: "KM"
      }
    }
    return await this.axios
      .post(`${API.ROUTE.delete_reserved_materials()}`, body, this.headers)
      .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
      .catch((error)=>{
        console.log(error.message)
      })
  }

  async checkKronasElement (body) {
    this.headers = {
      headers: {
        ...this.headers.headers,
        app: "KM"
      }
    }
    return await this.axios
      .post(`${API.ROUTE.check_kronas_element()}`, body ,this.headers)
      .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
      .catch((error)=>{
        console.log(error.message)
      })
  }

  async checkKronasOneElement (id) {
    this.headers = {
      headers: {
        ...this.headers.headers,
        app: "KM"
      }
    }

    return await this.axios
      .get(`${API.ROUTE.check_kronas_one_element()}/${id}/show`, this.headers)
      .then((response)=> {
        if(response && response.data)
          return Promise.resolve(response.data)
        else
          return Promise.reject()
      })
      .catch((error)=>{
        console.log(error.message)
      })
  }



  async getHardware(search, article = 270){
    return await this.axios
      .get(`${API.ROUTE.search_hardware()}/?search=${search}&categoryIds=${article}&price=true`, this.headers)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error)
      });
  }

  async getFurnitureAll(search, article = 270){
    return await this.axios
      .get(`${API.ROUTE.get_list_furniture()}?${search}&price=true&&categoryIds=${article}&categoryTypeAlias=site`, this.headers)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error)
      });
  }

  async getFurnitureCatalogList(categoryId){
    return await this.axios
      .get(`${API.ROUTE.search_catalog_id_furniture()}/${categoryId}?perPage=15&price=true`, this.headers)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error)
      });
  }

  async getCategoryID(id){
    return await this.axios
      .get(`${API.ROUTE.search_catalog_id_furniture()}/${id}/?price=true`, this.headers)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error)
      });
  }

  async getFurnitureCatalog(code){
    return await this.axios
      .get(`${API.ROUTE.search_catalog_furniture()}/?parentId=${code}&categoryTypeAlias=site`, this.headers)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error)
      });
  }
  async getFurnitureAllLinks(artikulesArray){
    const tokenSite = 'cMFGF8t/u3k0it9d+F1aFY4Blty6G8td0qHYx1QxuVk65j6SIJXfj1J3XgbwbRpbPHKUlKSQtUSdY7/QiXxKVbZf3aZF8CJZtPeTdUrWhORbr3fs0RatZFhBGwzDM+9U3kv+0uhB2NToHbpDTnDWPg=='
    return await this.axios
      .post(`${API.ROUTE.get_list_furniture_links()}`,
          { artikuls: artikulesArray,
            lang: Languages.langPr === 'ua' ? 'uk' : Languages.langPr },
          {headers: {Accept: "application/json", ContentType: 'application/json', Authorization: "Bearer " + tokenSite}})
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error)
      });
  }
}
