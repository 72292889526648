import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modalsOperations} from "redux/modals";
import {projectOperations, projectSelectors} from "redux/project";
import _Details from "../../../../db/_Details";

import {addDetail} from "hooks/useDetails";
import Languages from "../../../../translation/Languages";
import CloseBtn from "components/UI/Buttons/CloseBtn";
import "./style.scss";
import {empty, validateDetail} from "../../../../helpers/helper";
import DefaultBtn from "../../Buttons/DefaultBtn";
import {hideAllOperations} from "redux/hideAll";
import { errorMessageOperations } from '../../../../redux/errors'
import DetailBody from "../DetailBody";
import cloneDeep from "lodash.clonedeep";
import {tempDetailOperations} from "redux/tempDetail";
import {toast} from "react-toastify";
import Project from "../../../../models/Project";
import {handlePaste} from '../../../../helpers/helper'
const detailsDb = new _Details();

const AddDetail = () => {
	const dispatch = useDispatch();
	const construction = useSelector(projectSelectors.getConstruction);
	const detailR = useSelector(projectSelectors.getDetail);
	const currentMaterials = useSelector(projectSelectors.getMaterials);
	const { tempDetail } = useSelector(state => state.tempDetail.property)
	const [selectedMaterial, setSelectedMaterial] = useState(currentMaterials[0]);
	const [materials, setMaterials] = useState(currentMaterials);
	const [formData, setFormData] = useState({
		name: tempDetail.name,
		material: empty(tempDetail.material.index) ? selectedMaterial : tempDetail.material,
		height: tempDetail.height,
		heightError: tempDetail.heightError,
		width: tempDetail.width,
		widthError: tempDetail.widthError,
		multiplicity: tempDetail.multiplicity,
		amount: tempDetail.amount,
		countError: tempDetail.countError,
		productId: tempDetail.productId,
		isRotateTexture: selectedMaterial.type === 'OSB' ? false : tempDetail.isRotateTexture,
		isCutting: tempDetail.isCutting,
		edges: tempDetail.edges === 'empty' ? { left: null, top: null, right: null, bottom: null } : tempDetail.edges,
		soft: {top: false, bottom: false}, // TODO свойство для столешниц
		isPostForming: false,
		error_width_show: false,
		error_height_show: false,
	});
	const [isDisable, setIsDisable] = useState(false)
	const [isClose, setIsClose] = useState(tempDetail.afterCloseCheckbox)
	const [show, setShow] = useState(false)
	const [sawdustCurrent, setSawdustCurrent] = useState(null)

	useEffect(() => {
	  const neededMaterial = materials.find(
	    (material) => material.article === formData.material
	  );
	  if (neededMaterial) {
	    setSelectedMaterial([neededMaterial]);
	  }
	}, [formData.material]);

	const options = {
		progress: undefined,
		onOpen: (props) => {
			dispatch(hideAllOperations.switchStateForHideAll({show: true}));
		},
	};
	useEffect(() => {
		construction.getMaterialCur(selectedMaterial.article).then((mat) => {
			setSawdustCurrent(mat?.sawdust)
		})

	}, [selectedMaterial])

	const changeMultiplicityType = () => {
		dispatch(tempDetailOperations.addProperty('material', formData.material))
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "MULTIPLICITY",
			})
		);
	}

	useEffect(() => {
		if(show){
			toast.error(`${Languages.getTranslation('min-100x30', true)}`, {
				onClose: () => {},
				position: "bottom-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				toastId: `min-100x30`})
			setShow(false)
		}
	}, [show]);

	const handleChange = (event) => {
		let {name, value} = event.target;
		if(name === 'name'){
			setFormData(prevState => {
				const temp = cloneDeep(prevState)
				temp[name] = value
				return temp
			});
			dispatch(tempDetailOperations.addProperty(name, value))
			return
		}
		if(name === 'productId'){
			setFormData(prevState => {
				const temp = cloneDeep(prevState)
				temp[name] = Number(value)
				return temp
			});
			dispatch(tempDetailOperations.addProperty(name, Number(value)))
			return
		}
		if (name === 'material') {
			const index = construction.materials.find(item => item.index === Number(value))

			setFormData(prevState => {
				const temp = cloneDeep(prevState)
				temp[name] = index
				temp.soft = {top: false, bottom: false}
				temp.edges = ['Постформінг', 'Постформинг'].includes(index.type) ? { ...temp.edges, top: null, bottom: null } : temp.edges
				temp.isRotateTexture = !['OSB'].includes(index.type)
				return temp
			});
			setSelectedMaterial(index)
			dispatch(tempDetailOperations.addProperty(name, index))
			// dispatch(tempDetailOperations.addProperty('width', ''))
			// dispatch(tempDetailOperations.addProperty('height', ''))
			return
		}
		if (name === 'height' || name === 'width') {
			const neededMaterial = construction._materials.find((material) => material.index === selectedMaterial.index);

			if(['Постформінг', 'Постформинг'].includes(neededMaterial.type) && Number(value) < 100 && Number(value) > 99 && !empty(value)){
				setIsDisable(true)
				isDisable && toast.error(`${Languages.getTranslation('size-be-100x100', true)}`, {
					   onClose: () => {},
					   position: "bottom-right",
					   autoClose: 5000,
					   hideProgressBar: false,
					   closeOnClick: true,
					   pauseOnHover: true,
					   draggable: true,
					   progress: undefined,
					   theme: "light",
					   toastId: `${Languages.getTranslation('size-be-100x100', true)}`})
			}
			if(['Постформінг', 'Постформинг'].includes(neededMaterial.type) && Number(value) >= 100){
				setIsDisable(false)
			}
			const newName  = name === 'height' ? 'width' : 'height'
			let number = 0;
			if (validateDetail(value, neededMaterial, name, formData.soft.bottom, formData.soft.top)) {
				if(['Постформінг', 'Постформинг'].includes(neededMaterial.type)){
					if (name === 'width') {
						if(sawdustCurrent){
							number = 26
						}else {
							number = 0
						}
					}

					if(empty(neededMaterial.double_rounding)
						&& formData.soft.top  && name === 'height'){ number = 20}

					if(!empty(neededMaterial.double_rounding)
						&& formData.soft.bottom) { number = 20 }

					if(!empty(neededMaterial.double_rounding)
						&& formData.soft.bottom && formData.soft.top) { number = 40 }
				}else if(!['Постформінг', 'Постформинг', 'OSB'].includes(neededMaterial.type)){

					if(sawdustCurrent){
						number = 26
					}else {
						number = 0
					}
				}
				if(["Стеновая панель", 'Постформінг', 'Постформинг'].includes(neededMaterial.type) && name === 'height') { number = 0}
				setFormData(prevState => {
					const temp = cloneDeep(prevState)
					temp[name] = Number(value) > neededMaterial[newName] - number ? neededMaterial[newName] - number : Number(value);
					temp.multiplicity = null
					// temp[`error_${name}_show`] = number > 0;
					return temp
				});
				dispatch(tempDetailOperations.addProperty(name, neededMaterial[newName] - number))
				// dispatch(tempDetailOperations.addProperty('multiplicity', null))

				number !== 0 && dispatch(errorMessageOperations.switchStateError(
					{
						message: `${Languages.getTranslation('same-sides-detail-material', true)} 
						${number} ${Languages.getTranslation('same-sides-detail-material-text-two')}`,
						type: 'warning',
						show: true
					}))

				return;
			}else {
				if(!['OSB'].includes(neededMaterial.type)){
					if(sawdustCurrent){
						number = 26
					}else {
						number = 0
					}				}
				if(["Стеновая панель", 'Постформінг', 'Постформинг'].includes(neededMaterial.type) && name === 'height') { number = 0}
				setFormData(prevState => {
					const temp = cloneDeep(prevState)
					temp[name] = Number(value) > neededMaterial[newName] - number ? neededMaterial[newName] - number : value;
					temp.multiplicity = null
					return temp
				});
				dispatch(tempDetailOperations.addProperty(name, Number(value)))

				Number(value) > neededMaterial[newName] - number && dispatch(errorMessageOperations.switchStateError(
					{
						message: `${Languages.getTranslation('same-sides-detail-material', true)}
						${number} ${Languages.getTranslation('same-sides-detail-material-text-two')}`,
						type: 'warning',
						show: true
					}))

			}
			return;
		}
		if(name === 'top' || name === 'bottom' || name === 'left' || name === 'right'){
			const val = value ? Number(value) : null;
			const edges = cloneDeep(tempDetail.edges)
			if (!empty(val)) {
				const edge = construction.edges.find((edge, index) => index === val);
				if (!empty(edge)) {
					if((name === 'top' || name === 'bottom')
						&& ['Постформінг', 'Постформинг'].includes(selectedMaterial.type)){
						let show = false;
						if(edge.thickness < 1 && (formData.soft.top || formData.soft.bottom)) {
							if ((name === 'top' || name === 'bottom')
								&& (formData.edges.top === null || formData.edges.bottom === null)
								&& formData.height
								&& formData.height - edge.thickness + 1 > formData.height) {
								show = true
							} else if (name === 'bottom' && formData.height && formData.edges.top !== null) {
								if (formData.height - edge.thickness + 1 -
									construction.edges.find(edge => edge.index === formData.edges.top).thickness + 1 > formData.height) {
									show = true
								}
							}
						}
						show && showWarningSoft()
					}
					setFormData({
						...formData,
						edges: { ...formData.edges, [name]: edge.index}
					})
					edges[name] = edge.index
					dispatch(tempDetailOperations.addProperty('edges', edges))
				} else {
					console.log("Error: Edge not found in scope");
				}
			} else {
				edges[name] = val
				dispatch(tempDetailOperations.addProperty('edges', edges))
			}
			return;
		}
		setFormData(prevState => {
			const temp = cloneDeep(prevState)
			temp[name] = value
			return temp
		});
		dispatch(tempDetailOperations.addProperty(name, value))
	};

	const changeTextureHandler = () => {
		if(['Постформінг', 'Постформинг', 'OSB'].includes(formData.material.type )){ return }
		setFormData({
			...formData,
			isRotateTexture: !formData.isRotateTexture
		})
		dispatch(tempDetailOperations.addProperty('isRotateTexture', !formData.isRotateTexture))
	}


	const close = () => {
		// const detail = {multiplicity: null, _l: "", _h: ""}
		// dispatch(projectOperations.setDetail({ detail }))
		dispatch(tempDetailOperations.addProperty(null))
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};

	const createDetail = () => {
		if (
			formData.width === "0" ||
			formData.width === "" ||
			formData.height === "0" ||
			formData.height === "" ||
			formData.amount === "0" ||
			formData.amount === ""
		) {
			if(formData.height === "0" || formData.height === "") {
				setFormData(prevState => {
					const temp = cloneDeep(prevState)
					temp.heightError = true
					return temp
				});
			}
			if(formData.width === "0" || formData.width === "") {
				setFormData(prevState => {
					const temp = cloneDeep(prevState)
					temp.widthError = true
					return temp
				});
			}
			if(formData.amount === "0" || formData.amount === "") {
				setFormData(prevState => {
					const temp = cloneDeep(prevState)
					temp.countError = true
					return temp
				});
			}
			return null;
		}

		if(Number(formData.width) < 30 && Number(formData.height) < 100) {
			setFormData(prevState => {
				const temp = cloneDeep(prevState)
				temp.widthError = true
				temp.heightError = true
				return temp
			});
			setShow(true)
			return;
		}

		if(Number(formData.width) < 30 && Number(formData.height) >= 100) {
			setFormData(prevState => {
				const temp = cloneDeep(prevState)
				temp.widthError = true
				return temp
			});
			setShow(true)
			return;
		}

		if(Number(formData.height) < 30 && Number(formData.width) < 100) {
			setFormData(prevState => {
				const temp = cloneDeep(prevState)
				temp.heightError = true
				temp.widthError = true
				return temp
			});
			setShow(true)
			return;
		}

		if(Number(formData.height) < 30 && Number(formData.width) >= 100) {
			setFormData(prevState => {
				const temp = cloneDeep(prevState)
				temp.heightError = true
				return temp
			});
			setShow(true)
			return;
		}

		const neededMaterial = construction._materials.find(
			(material) => material.index === selectedMaterial
		);

		setFormData(prevState => {
			const temp = cloneDeep(prevState)
			temp.heightError = false
			temp.widthError = false
			temp.countError = false
			return temp
		});
		const detail_l = Number(formData.width);
		const detail_h = Number(formData.height);
		const detail = {
			name: formData.name,
			l: detail_l,
			h: detail_h,
			cl: detail_l,
			ch: detail_h,
			w: formData.material.thickness,
			material: formData.material.index,
			count: Number(formData.amount),
			multiplicity: formData.multiplicity,
			isRotateTexture: formData.isRotateTexture,
			isCutting: formData.isCutting,
			edges: tempDetail.edges,
			soft: formData.soft,
			productId: formData.productId,
			isPostForming: ['Постформінг', 'Постформинг'].includes(selectedMaterial.type)
		};
		detailsDb.getIndex('details')
			.then(id => {
				detail.id = id;
				const item = addDetail(construction, detail);
				const dbData = construction.getDataDetail(item.id);
				return detailsDb.addDetail(dbData)
			})
			.then(() => {
				const detail = { ...detailR, multiplicity: null}
				dispatch(projectOperations.setDetail({ detail }))
				dispatch(errorMessageOperations.switchStateError(
					{ message: Languages.getTranslation('part-created-successfully', true), type: 'success', show: true }))
				Project.validateProjectDetails()
					.then(() => { isClose && close() })
					.catch(err => console.log(err))
			}).catch(error => console.log(error))
	};

	const validSide = (side) => {
		const neededMaterial = construction._materials.find((material) => material.index === selectedMaterial.index);

		let number = 0;
		if(!formData.soft[side]) {
			let newHeight = 0;
			if(side === "top" && neededMaterial?.double_rounding !== 1){
				newHeight = 20
			}
			if(side === "bottom" && neededMaterial?.double_rounding){
				if(formData.soft.top){
					newHeight = 40
				}else {
					newHeight = 20
				}
			}
			if(side === "top" && neededMaterial?.double_rounding){
				if(formData.soft.bottom){
					newHeight = 40
				}else {
					newHeight = 20
				}
			}
			if (validateDetail((formData.height + newHeight).toString(), neededMaterial, 'height')
				/*&& Number(formData.height + newHeight) >= neededMaterial.width*/) {
				if (!formData.soft.top) { number = 20 }
				if (formData.soft.bottom && neededMaterial?.double_rounding === 1) {
					if(!formData.soft.top){
						number = 40
					}else {
						number = 20
					}
				}

				if (formData.soft.top && neededMaterial?.double_rounding === 1) {
					if(!formData.soft.bottom){
						number = 40
					}else {
						number = 20
					}
				}

				return {name: 'height', number: Number(neededMaterial['width'] - number)}
			}else if(neededMaterial?.double_rounding === 1 && formData.soft.bottom && formData.soft.top){
				return {name: 'height', number: Number(neededMaterial['width'] - 40)}
			}
			else return {name: false, number}
		} else return {name: false, number}
	}

	const changeSoft = (side) => {
		let {name, number} = validSide(side)

		if(name === 'height'){
			setFormData({
				...formData,
				soft: {...formData.soft, [side]: !formData.soft[side]},
				edges: {...formData.edges, [side]: formData.soft[side] ? null : formData.edges[side]},
				[name]: number
			})
		}else{
			if(side === 'bottom' && formData.soft.top && formData.soft.bottom && formData.material.double_rounding === 1){
				setFormData({
					...formData,
					soft: { top: formData.soft.top, bottom: false},
					// soft: { top: false, bottom: false},
					// edges: {...formData.edges, [side]: formData.soft[side] ? null : formData.edges[side], top: null},
					edges: {...formData.edges, [side]: formData.soft[side] ? null : formData.edges[side]},
					[name]: number
				})
			}else {
				setFormData({
					...formData,
					soft: {...formData.soft, [side]: !formData.soft[side]},
					edges: {...formData.edges, [side]: formData.soft[side] ? null : formData.edges[side]},
					[name]: number
				})
			}
		}
		dispatch(tempDetailOperations.addProperty(name, number))
	}

	const showWarningSoft = () => {
		const message = Languages.getTranslation('detail-will-be-changed', true)
		toast.warn(message, {
					onClose: () => {},
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					toastId: message,
				})
	}

	const closeAfterSave = () => {
		setIsClose(prevState => !prevState)
		dispatch(tempDetailOperations.addProperty('afterCloseCheckbox', !isClose))
	}

	const deleteErrors = (property) => {
		setFormData(prevState => {
			const temp = cloneDeep(prevState)
			temp[property] = false
			return temp
		});
	}

	const detailTextureClass = isClose ? "detailsTab__texture detailsTab__texture_active addDetail__actions-check" : "detailsTab__texture addDetail__actions-check"
	const detailTextureIcon = isClose ? "checkbox__icon checkbox__icon_active" : "checkbox__icon"


	return (
		<div className="addDetail">
			<div className="addDetail__header">
				<span className="addDetail__header-title">{Languages.getTranslation("new-detail", true)}</span>
				<CloseBtn handler={close}/>
			</div>
				<DetailBody
					formData={formData}
					construction={construction}
					detail={detailR}
					disabled={false}
					changeTextureHandler={changeTextureHandler}
					changeMultiplicityType={changeMultiplicityType}
					handleChange={handleChange}
					handlePaste={(event) => handlePaste(event, setFormData)}
					materialSel={selectedMaterial}
					createDetail={createDetail}
					changeSoft={changeSoft}
					deleteErrors={deleteErrors}
				/>
			<div className="addDetail__actions">
					<div className={detailTextureClass} onClick={closeAfterSave}>
						<i className={detailTextureIcon} style={{ cursor: 'pointer'}} ></i>
					</div>
					<span className="detailInfo__texture-title">
						{Languages.getTranslation('close-after-saving', true)}</span>
				<DefaultBtn
					title={Languages.getTranslation("cancel2", true)}
					handler={close}
				/>
				<DefaultBtn
					disabled={isDisable}
					title={Languages.getTranslation('save', true)}
					handler={() => createDetail()}/>
			</div>
		</div>
	);
};

export default AddDetail;
