import React, {useEffect, useState} from "react";
import "../../style.scss";
import {empty} from "../../../../../helpers/helper";
import {toast} from "react-toastify";
import Languages from "../../../../../translation/Languages";
import {toastError} from "../../../../../helpers/toasts";
import Helpers from "../../../../../models/3D/Helpers";
// const reg = /^\d{1,4}.?[0-9]?/g;
const Input = ({treatment, setError, param, updateParams, value, handlerAdditionalParams, classCustom, detail, treatmentType}) => {

  const [inputValue, setInputValue] = useState(value || '')

  useEffect(() => {
    setInputValue(value)
  }, [handlerAdditionalParams])

  const saveData = () => {
    param.onChange(param.type === "number" ? Number(inputValue) : inputValue);
    updateParams()
  }

  const preventMinusPlus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }

    if (e.code === "Equal") {
      e.preventDefault();
    }
  };

  let timeOutHandler;

  const changeHandler = (e) => {
    clearTimeout(timeOutHandler);
    setInputValue(e.target.value)
    timeOutHandler = setTimeout(() => {
      const error = param.type === 'number' ? treatment.checkRequiredField(param.name, Number(e.target.value)) : false;
      if(!error) {
        setInputValue(e.target.value)
      } else {
        setInputValue(error)
        saveData()
      }
    }, 2000)


    // validate()
    // }
    //   setTimeout(() => {
    //    setError(false)
    //   }, 2000)
  }

  const  validationOnInput = (value) => {
    const realValue = Math.round(Number(value))
    if (param.type === 'number') {
      // if (realValue.toString().length > 4) {
      //   toastError(Languages.getTranslation('enter-normal-value', true))
      //   setError(true)
      //   return false
      // }
      if (param.name === 'x' || param.name === 'width') {
        // if (treatment.type === 'uShape' || treatmentType === 'rabbet') {
        //   if (['left', 'right'].includes(treatment.edgeSide) && (realValue + treatment.offset) >=  detail.h) {
        //     toastError(Languages.getTranslation('error-value-plus-offset', true))
        //     setError(true)
        //     return false
        //   }
        //   if (['top', 'bottom'].includes(treatment.edgeSide) && (realValue + treatment.offset) >=  detail.l) {
        //     toastError(Languages.getTranslation('error-value-plus-offset', true))
        //     setError(true)
        //     return false
        //   }
        // }
        // if (realValue > detail.l && treatmentType === 'corner') {
        //   toastError(Languages.getTranslation('validate-sizes', true))
        //   setError(true)
        //   return false
        // }
        // if (realValue >= detail.l && treatmentType !== 'corner') {
        //   toastError(Languages.getTranslation('validate-sizes', true))
        //   setError(true)
        //   return false
        // }
      }
      if (param.name === 'y' || param.name === 'height') {
        if (treatment.type === 'uShape' || treatmentType === 'rabbet') {
          // if (['top', 'bottom'].includes(treatment.edgeSide) && (realValue) >=  detail.h) {
          //   toastError(Languages.getTranslation('error-value-plus-offset', true))
          //   setError(true)
          //   return false
          // } else if (['left', 'right'].includes(treatment.edgeSide) && (realValue) <  detail.l) {
          //   return true
          // }
        }
        // if (realValue > detail.h && treatmentType === 'corner') {
        //   toastError(Languages.getTranslation('validate-sizes', true))
        //   setError(true)
        //   return false
        // }
        // if (realValue >= detail.h && treatmentType !== 'corner') {
        //   toastError(Languages.getTranslation('validate-sizes', true))
        //   setError(true)
        //   return false
        // }
      }
      if (param.name === 'r' ) {
        // if (treatment.type === 'gEdge') {
        //   if (Number(value).toFixed(10) > Math.min(Number(treatment.width), Number(treatment.height))) {
        //     toastError(Languages.getTranslation('validate-sizes-proc', true))
        //     setError(true)
        //     return false
        //   }
        // }
        // if (treatment.type === 'radiusEdge') {
        //   if (Number(value).toFixed(10) > Math.max(Number(detail.l), Number(detail.h))*3) {
        //     toastError(Languages.getTranslation('validate-sizes-proc', true))
        //     setError(true)
        //     return false
        //   }
        // }
        // if (Number(value).toFixed(10) > Math.min(Number(detail.l), Number(detail.h)) && treatment.type !== 'radiusEdge') {
        //   toastError(Languages.getTranslation('validate-sizes', true))
        //   setError(true)
        //   return false
        // }
      }
      if (param.name === 'offset') {
      //   if (realValue > detail.l && ['top', 'bottom'].includes(treatment.edgeSide)) {
      //     toastError(Languages.getTranslation('validate-sizes', true))
      //     setError(true)
      //     return false
      //   }
      //   if (realValue > detail.h && ['right', 'left'].includes(treatment.edgeSide)) {
      //     toastError(Languages.getTranslation('validate-sizes', true))
      //     setError(true)
      //     return false
      //   }
      // }
      // if (param.name === 'depth' ) {
      //   if (realValue.toString().length > 2) {
      //     toastError(Languages.getTranslation('enter-normal-value', true))
      //     setError(true)
      //     return false
      //   }
      }
      setError(false)
      return true
    }
    return true
  }

  const onBlurHandler = () => {
    clearTimeout(timeOutHandler);
    saveData()
    setError(false)
  }

  const showCaption = () => {
    if(empty(param.isError)) return null;
    return (<span className="param__error-caption">{`${Languages.getTranslation(param.isError, true)} `}</span>)
  }

  return (
    <>
      <input
        onKeyDown={preventMinusPlus}
        className={`${classCustom} param__input ${!empty(param.isError) ? 'bg-error' : ''}`}
        disabled={param?.disabled || false}
        value={inputValue}
        onBlur={onBlurHandler}
        onChange={(e) => changeHandler(e)}
        type={param.type}
        name={param.id}
        autoComplete='off'
      />
      {showCaption()}
    </>
  );
};

export default Input;
