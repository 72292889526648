import React, {useEffect, useRef, useState} from "react";
import Languages from "../../../../translation/Languages";
import "./style.scss";
import {empty, edgeTitle, materialTitle, isset, createMaterialTitle} from "helpers/helper";
import { multiplicityArray } from "../MultiplicityModel/index"
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {toast} from "react-toastify";
import {userSelectors} from "redux/user";

const DetailBody = ({formData,
						changeSoft,
						construction,
						detail,
						disabled,
						createDetail,
						changeTextureHandler,
						changeMultiplicityType,
						materialSel,
						deleteErrors,
						handlePaste,
						handleChange}) => {
	// const audio = new Audio('error.mp3')
	const location = useLocation()
	const inputRef1 = useRef(null);
	const inputRef2 = useRef(null);
	const inputRef3 = useRef(null);
    const permissions = useSelector(userSelectors.permissions);

	const currentMaterials = useSelector(projectSelectors.getMaterials);
	const { tempDetail } = useSelector(state => state.tempDetail.property)
	const detailR = useSelector(projectSelectors.getDetail);

	const [selectedMaterial, setSelectedMaterial] = useState(currentMaterials[0].index);
	const [materials, setMaterials] = useState(currentMaterials);
	const [errorSoft, setErrorSoft] = useState(false)

	useEffect(() => {
		inputRef1.current?.focus();
	}, []);

	// useEffect(() => {
	// 	if(formData.error_width_show){
	// 		audio.play().then(() => {
	//
	// 		})
	// 			.catch((error) => console.log("Error playing audio:", error));
	// 		setTimeout(() => {
	// 			deleteErrors('error_width_show')
	// 		}, 2000)
	// 	}
	// 	if(formData.error_height_show){
	// 		audio.play().then(() => {
	//
	// 		})
	// 			.catch((error) => console.log("Error playing audio:", error));
	// 		setTimeout(() => {
	// 			deleteErrors('error_height_show')
	// 		}, 2000)
	// 	}
	// }, [formData.error_width_show, formData.error_height_show])


	const validationInput = (el) => {
		el.target.value = el.target.value.replace(/[^0-9]+/g, "");
		if (el.target.min && el.target.max) {
			el.target.value = Number(el.target.value) < Number(el.target.min) ? null : Number(el.target.value) > Number(el.target.max) ? el.target.max : el.target.value
		}
	};

	const nextDetailByEnter = (e, type) => {
		if (e.key === "Enter") {
			if (e.target.value === 0 || e.target.value === "") {
				return null;
			} else {
				if(type === 'one'){
					inputRef2.current.focus();
				}
				if(type === 'two'){
					inputRef3.current.focus();
				}
			}
		}
		if( ['+','-','e'].includes( e.key ) ) e.preventDefault()
	};

	const createDetailByEnter = (e) => {
		if (e.key === "Enter" && !disabled) {
			createDetail();
		}
		if(['+','-','e'].includes( e.key )) e.preventDefault()
	};
	const disabledForManufacture =() => {
	return  permissions.includes('KM_disabledParamsDetail')
	}
	const disableSoft = disabledForManufacture() ? {
		pointerEvents: 'none',
	    opacity: '0.5',
}: {cursor: 'pointer'}

	const materialSelect = () => {
		const currentMaterial = materials.find(el => el.index === selectedMaterial);
		if (!materials.length || !currentMaterial) {
			return null;
		}
		if (materials.length > 1) {
			return (
				<select
					className="addDetail__select"
					// defaultValue={selectedMaterial}
					name="material"
					onChange={(event) => handleChange(event)}
				>
					{materials.map((material, indx) => (
						<option
							selected={indx === construction.materials.findIndex(m => m.index === tempDetail?.material.index)}
							key={indx}
							value={material.index}
							className={`val-${material.index}`}>
							{`${indx + 1}: ${createMaterialTitle(material)}`}
						</option>
					))}
				</select>
			)
		} else {
			return (
				<p style={{fontSize: 16}}>
					{currentMaterial.name ? currentMaterial.name : Languages.getAvaliableTranslation(currentMaterial?.languages)}
				</p>
			)
		}
	}

	const productSelect = () => {
		const currentProduct = construction.products.find(el => el.id === formData.productId);
		if (!construction.products.length || !currentProduct) {
			return null;
		}
		if (construction.products.length > 1) {
			return (
				<select
					className="addDetail__select"
					// defaultValue={selectedMaterial}
					name="productId"
					style={{padding: 0}}
					disabled={disabledForManufacture()}
					onChange={(event) => handleChange(event)}
				>
					{construction.products.map((product, indx) => (
						<option
							selected={indx === construction.products.findIndex(p => p.id === formData?.productId)}
							key={indx}
							value={Number(product.id)}
							className={`val-${product.index}`}>
							{`${product.name}`}
						</option>
					))}
				</select>
			)
		} else {
			return (
				<p style={{fontSize: 16}}>
					{`${currentProduct.name}`}
				</p>
			)
		}
	}

	const typePostForming = () => {
		return ['Постформинг', 'Постформінг'].includes(materialSel?.type) && empty(materialSel.double_rounding);
	}

	const detailTextureClass = formData.isRotateTexture ? "detailsTab__texture detailsTab__texture_active" : "detailsTab__texture"
	const detailTextureIcon = formData.isRotateTexture ? "checkbox__icon checkbox__icon_active" : "checkbox__icon"

	let oneSideClass, oneSideIcon, bothSidesClass, bothSidesIcon

	if(typePostForming()){
		oneSideClass = formData.soft?.top ? "detailsTab__texture detailsTab__texture_active" : "detailsTab__texture"
		oneSideIcon = formData.soft?.top ? "checkbox__icon checkbox__icon_active" : "checkbox__icon"
	}else {
		oneSideClass = formData.soft?.bottom ? "detailsTab__texture detailsTab__texture_active" : "detailsTab__texture"
		oneSideIcon = formData.soft?.bottom ? "checkbox__icon checkbox__icon_active" : "checkbox__icon"
	}

	if(!typePostForming()){
		bothSidesClass = formData.soft?.top ? "detailsTab__texture detailsTab__texture_active" : "detailsTab__texture"
		bothSidesIcon = formData.soft?.top ? "checkbox__icon checkbox__icon_active" : "checkbox__icon"
	}else {
		bothSidesClass = formData.soft?.bottom ? "detailsTab__texture detailsTab__texture_active" : "detailsTab__texture"
		bothSidesIcon = formData.soft?.bottom ? "checkbox__icon checkbox__icon_active" : "checkbox__icon"
	}

	const topEdgeSoft = () => {
		if(empty(materialSel?.double_rounding)){
			return typePostForming() && !formData.soft?.top
		}else{
			if(formData.soft.bottom){
				return !formData.soft?.top
			}
		}
	}

	const showErrorSoft = (num) => {
		const isPostformingType = ['Постформинг', 'Постформінг'].includes(materialSel?.type);
		const hasError = (angle1, angle2) => detailR.corners.some(el => (el.angle === angle1 || el.angle === angle2) && el.type === 'radius');

		if (num === 'one') {
			if (isPostformingType && !materialSel?.double_rounding && detail?.soft?.top && hasError('left_top', 'right_top')) {
				setErrorSoft(true);
			} else if (isPostformingType && materialSel?.double_rounding && detail?.soft?.bottom && hasError('left_bottom', 'right_bottom')) {
				setErrorSoft(true);
			} else {
				setErrorSoft(false);
			}
		} else {
			if (isPostformingType && materialSel?.double_rounding && detail?.soft?.top && hasError('left_top', 'right_top')) {
				setErrorSoft(true);
			} else {
				setErrorSoft(false);
			}
		}
	};
	const lockEdgeIfZIsEmpty = (edgeSide) => {
		if(!empty(detail?.bevels)){
			return detail.bevels.some(bevel => bevel.edgeSide === edgeSide && bevel.z === 0);
		}
		return false
	};

// Example usage for the different sides
	const isBottomLocked = lockEdgeIfZIsEmpty('bottom');
	const isTopLocked = lockEdgeIfZIsEmpty('top');
	const isRightLocked = lockEdgeIfZIsEmpty('right');
	const isLeftLocked = lockEdgeIfZIsEmpty('left');

	const handleKeyPress = (event) => {
		if (event.key === '*') {
			event.preventDefault();
		}
	};

	return (
		<div className="detailInfo__body">
			<div className="detailInfo__param param" style={{width: "100%"}}>
				<label className="param__label">
					{Languages.getTranslation("name", true)}
				</label>
				<div className="param__default-input" style={{width: "100%"}}>
					<input
						className="param__input"
						type="text"
						name="name"
						value={formData.name}
						onChange={(event) => handleChange(event)}
						onKeyPress={handleKeyPress}
						onPaste={handlePaste}
            placeholder={Languages.getTranslation("detail", true)}
						maxLength={40}
						disabled={disabledForManufacture()}
					/>
				</div>
			</div>
			<div className="detailInfo__param param" style={{width: "100%"}}>
				<label className="param__label">
					{Languages.getTranslation("material", true)}
				</label>
				<div className="param__default-input" style={{width: "100%"}}>
					{location.pathname.indexOf('/details') === -1 ?
						<input
							className="param__input"
							type="text"
							value={`${construction.materials.findIndex(ind => ind.index === formData.material?.index) + 1}: ${!empty(formData) ? createMaterialTitle(formData.material) : ''}`}
							disabled={disabled && disabledForManufacture()}
						/> : materialSelect()
					}
				</div>
			</div>

			{/* Кромки */}
			<div className="detailInfo__param param">
				<label className="param__label">
					{`${Languages.getTranslation(
						"edge",
						true
					)} (${Languages.getTranslation("top", false)})`}
				</label>
				{!empty(construction.edges) && !['OSB'].includes(materialSel?.type) && (
					<select
						disabled={topEdgeSoft() || isTopLocked ||  disabledForManufacture() || (materialSel?.double_rounding === 1 && !formData.soft.top) || ['Compact-плита'].includes(materialSel?.type) || detail?.mills?.some(el => ['partial', 'closed'].includes(el.type))}
						className="param__default-select"
						onChange={(e) => handleChange(e)}
						name="top"
					>
						<option value={null} selected={formData.edges?.top === null}>
							{topEdgeSoft() || (materialSel?.double_rounding === 1 && ['Постформинг', 'Постформінг'].includes(materialSel?.type) && !formData.soft.top) ? Languages.getTranslation('soft', true) : ""}</option>
						{!empty(construction.edges)
							? construction.edges.map((item, key) => {
								return (
									<option key={key} value={key} selected={item.index === formData?.edges?.top}>
										{`${item.index + 1}: ${edgeTitle(item)}`}
									</option>
								);
							})
							: ""}
					</select>
				)}
			</div>
			<div className="detailInfo__param param">
				<label className="param__label">
					{" "}
					{`${Languages.getTranslation(
						"edge",
						true
					)} (${Languages.getTranslation("bottom", false)})`}
				</label>
				{!empty(construction.edges) && !['OSB'].includes(materialSel?.type) && (
					<select
						disabled={disabledForManufacture() || isBottomLocked || formData?.multiplicity?.type === 7 || (materialSel?.double_rounding === 1 && !formData.soft.bottom)
							|| (!typePostForming() && !empty(materialSel?.double_rounding) && !formData.soft.bottom) || ['Compact-плита'].includes(materialSel?.type) || detail?.mills?.some(el => ['partial', 'closed'].includes(el.type))}
						className="param__default-select"
						onChange={(e) => handleChange(e)}
						name="bottom"
					>
						<option selected={formData?.multiplicity?.type === 7 || formData.edges?.bottom === null}
										value={null}>{
							formData?.multiplicity?.type === 7
								? Languages.getTranslation("not-available", false)
								: !typePostForming() && !empty(materialSel?.double_rounding) && formData.edges?.bottom === null && !formData.soft.bottom
									? Languages.getTranslation('soft', true) : ''}</option>
						{!empty(construction.edges)
							? construction.edges.map((item, key) => {
								return (
									<option key={key} value={key} selected={item.index === formData?.edges?.bottom}>
										{`${item.index + 1}: ${edgeTitle(item)}`}
									</option>
								);
							})
							: ""}
					</select>
				)}
			</div>
			<div className="detailInfo__param param">
				<label className="param__label">
					{" "}
					{`${Languages.getTranslation(
						"edge",
						true
					)} (${Languages.getTranslation("left", false)})`}
				</label>

				{!empty(construction.edges) && !['OSB'].includes(materialSel?.type) && (
					<select
						disabled={disabledForManufacture() || isLeftLocked ||  formData?.multiplicity?.type === 6 || formData?.multiplicity?.type === 8 || ['Compact-плита'].includes(materialSel?.type) || detail?.mills?.some(el => ['partial', 'closed'].includes(el.type))}
						className="param__default-select"
						onChange={(e) => {
							handleChange(e);
						}}
						name="left"
					>
						<option
							value={null}>{formData?.multiplicity?.type === 6 || formData?.multiplicity?.type === 8
							? Languages.getTranslation("not-available", false)
							: ''}</option>
						{!empty(construction.edges)
							? construction.edges.map((item, key) => {
								return (
									<option key={key} value={key}
													selected={item.index === formData?.edges?.left}>
										{`${item.index + 1}: ${edgeTitle(item)}`}
									</option>
								);
							})
							: ""}
					</select>
				)}
			</div>
			<div className="detailInfo__param param">
				<label className="param__label">
					{" "}
					{`${Languages.getTranslation(
						"edge",
						true
					)} (${Languages.getTranslation("right", false)})`}
				</label>

				{!empty(construction.edges) && !['OSB'].includes(materialSel?.type) && (
					<select
						disabled={disabledForManufacture() || isRightLocked || formData?.multiplicity?.type === 6 || formData?.multiplicity?.type === 8 || ['Compact-плита'].includes(materialSel?.type) || detail?.mills?.some(el => ['partial', 'closed'].includes(el.type))}
						className="param__default-select"
						onChange={(e) => {
							handleChange(e)
						}}
						name="right"
					>
						<option
							value={null}>{formData?.multiplicity?.type === 6 || formData?.multiplicity?.type === 8
							? Languages.getTranslation("not-available", false)
							: ''}</option>
						{!empty(construction.edges)
							? construction.edges.map((item, key) => {
								return (
									<option key={key} value={key}
													selected={item.index === formData?.edges?.right}>
										{`${item.index + 1}: ${edgeTitle(item)}`}
									</option>
								);
							})
							: ""}
					</select>
				)}
			</div>

			<div className="detailInfo__param param">
				<label className="param__label">
					{`${Languages.getTranslation("height", true)} ${Languages.getTranslation("overall-size", true)}`}
				</label>
				<div className={`param__default-input 
								${formData.widthError ? 'bg-error' : ''}
								${formData.error_width_show ? 'border_error' : ''}`}>
					<input
						placeholder={formData.widthError ? Languages.getTranslation("enter-value", true) : ''}
						type="number"
						className={`param__input ${formData.widthError ? 'bg-error' : ''}`}
						name="width"
						value={formData.width}
						disabled={isset(detail?.contour) && !empty(detail.contour) ||  disabledForManufacture()}
						ref={inputRef1}
						onKeyDown={(e) => nextDetailByEnter(e, 'one')}
						// onInput={(e) => validationInput(e)}
						onFocus={() => toast.dismiss()}
						onChange={(e) => handleChange(e)}
						// onBlur={(e) => handleChange(e)}
					/>
					<span className="detailInfo__input-plhrd">мм</span>
				</div>
			</div>

			<div className="detailInfo__param param">
				<label className="param__label">
					{`${Languages.getTranslation("width", true)} ${Languages.getTranslation("overall-size", true)}`}
				</label>
				<div className={`param__default-input 
								${formData.heightError ? 'bg-error' : ''}
								${formData.error_height_show ? 'border_error' : ''}`}>
					<input
						placeholder={formData.heightError ? Languages.getTranslation("enter-value", true) : ''}
						type="number"
						className={`param__input ${formData.heightError ? 'bg-error' : ''}`}
						name="height"
						value={formData.height}
						ref={inputRef2}
						disabled={isset(detail?.contour) && !empty(detail.contour) ||  disabledForManufacture()}
						// onInput={(e) => validationInput(e)}
						onChange={(e) => handleChange(e)}
						onFocus={() => toast.dismiss()}
						onKeyDown={(e) => nextDetailByEnter(e, 'two')}
						// onBlur={(e) => handleChange(e)}
						// onKeyDown={(e) => createDetailByEnter(e)}
					/>
					<span className="detailInfo__input-plhrd">мм</span>
				</div>
			</div>
			{!disabledForManufacture() && (
				<div className="detailInfo__param param">
					<div className="detailInfo__texture__center">
						<div className={detailTextureClass} onClick={changeTextureHandler}
							 style={['Постформінг', 'Постформинг', 'OSB'].includes(formData?.material?.type) ? {
								 cursor: 'not-allowed',
								 opacity: '.5'
							 } : {cursor: "pointer"}}>
							<i className={detailTextureIcon}></i>
						</div>
						<span
							className="detailInfo__texture-title">{Languages.getTranslation('texture-along-the-length', true)}</span>
					</div>
				</div>
			)}


			<div  className="detailInfo__param param">
				<label className="param__label">
					{Languages.getTranslation("amount", true)}
				</label>
				<div className={`param__default-input ${formData.countError ? 'bg-error' : ''}`}>
					<input
						ref={inputRef3}
						placeholder={formData.countError ? Languages.getTranslation("enter-value", true) : ''}
						type="text"
						className={`param__input ${formData.countError ? 'bg-error' : ''}`}
						name="amount"
						value={formData.amount}
						disabled={disabledForManufacture()}
						onInput={(e) => validationInput(e)}
						onChange={(event) => handleChange(event)}
						onKeyDown={(e) => createDetailByEnter(e)}
					/>
					<span className="addDetail__input-plhrd">шт</span>
				</div>
			</div>

			{['Постформинг', 'Постформінг'].includes(materialSel?.type) && <>
				<div className="detailInfo__param param">
					<div className="detailInfo__texture_soft">
						<div className={oneSideClass} style={disableSoft} onClick={() => {
							showErrorSoft('one');
							changeSoft(typePostForming() ? 'top' : 'bottom');
						}} >
							<i className={oneSideIcon}></i>
						</div>
						<span
							className="detailInfo__texture-title"
							style={{fontSize: '14px'}}
						>
							{!empty(materialSel?.double_rounding) ?
								Languages.getTranslation('cutting-on-one-side-u2', true) :
								Languages.getTranslation('cutting-on-one-side', true)}</span>
					</div>
				</div>

				<div className="detailInfo__param param">
					{/*{!empty(materialSel?.double_rounding) && formData?.soft?.bottom &&*/}
					{!empty(materialSel?.double_rounding) &&
						<div className="detailInfo__texture_soft">
							<div className={bothSidesClass}
									 onClick={() => {
										 showErrorSoft('two');
										 changeSoft(!typePostForming() ? 'top' : 'bottom')
									 }}
								 style={disableSoft}
							>
								<i className={bothSidesIcon}></i>
							</div>
							<span
								className="detailInfo__texture-title"
								style={{fontSize: '14px'}}
							>
								{Languages.getTranslation('cutting-on-both-sides', true)}</span>
						</div>
					}
				</div>

				{errorSoft &&
					<div className='detailInfo__param_tooltip-error_body param__error errorValidationOnInput'>
						{Languages.getTranslation('set-soft', true)}
					</div>
				}
			</>}


			<div className="detailInfo__param param" style={{width: "100%"}}>
				<label className="param__label">
					{Languages.getTranslation("products", true)}
				</label>
				<div className="param__default-input" style={{width: "100%"}}>
					{productSelect()}
				</div>
			</div>

			{/*multiplicity show*/}
			{!['Постформинг', 'Постформінг', 'Compact-плита', 'OSB'].includes(materialSel?.type) &&
				<div className="detailInfo__param param" style={{width: "100%"}}>
					<label className="param__label">
						{Languages.getTranslation("stitching-type", true)}
					</label>
					<div
						className={`param__default-input ${formData.width === '' || formData.height === '' ? 'bg-error' : ''}`}
						style={{width: "100%"}}>
						<button
							disabled={formData.width === '' || formData.height === '' ||  disabledForManufacture()}
							className="param__default-select"
							onClick={changeMultiplicityType}
						>
							{formData?.multiplicity === null
									? multiplicityArray[0]?.name
									: multiplicityArray[formData?.multiplicity?.type]?.name
							}
						</button>
					</div>
				</div>}
		</div>
	);
};


export default DetailBody;
