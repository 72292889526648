import {empty} from "../../../../../helpers/helper";
import Languages from "../../../../../translation/Languages";
import {useEffect, useRef, useState, useCallback, useMemo} from "react";
import CONSTANTS from "../../../../../config/constants";
import {DeleteBtn, Checkbox, DetailWrapper, Texture} from "../common";
import {Edges} from "./Edges";
import {toastWarning} from "../../../../../helpers/toasts";
import Material from "./Material";
import {DetailProductSelect} from "./Product";
import CustomInput from "./CustomInput";
import {generateEdgeOptions, disableByMaterialType} from "../helper";

const EMPTY_LINE = "";

const Detail = ({
													style,
													detail,
										 			detailsCount,
													index,
													editLimits,
													remove,
													setFocusedDetail,
													changeInputType,
													changeSelectType,
													isSelected,
													focusedRow,
													construction,
													selectDetailHandler,
													changeEdge
												}) => {
	const refDetail = useRef(null);
	const refName = useRef(null);
	const refWidth = useRef(null);
	const refHeight = useRef(null);
	const refCount = useRef(null);
	const refMaterial = useRef(null);
	const refTop = useRef(null);
	const refBottom = useRef(null);
	const refLeft = useRef(null);
	const refRight = useRef(null);
	const refProduct = useRef(null);
	const [warningState, setWarningState] = useState({width: false, height: false});

	const refs = useMemo(() => (
		{refTop, refBottom, refLeft, refRight}
	), [refTop, refBottom, refLeft, refRight])

	const detailMaterial = useMemo(() => construction.materials.find(el => detail.material === el.index), [detail, construction]);

	const showError = useCallback(() => {
		const message = Languages.getTranslation('can\'t-be-empty', true);
		toastWarning(message, {
			onClose: () => {
			}
		})
	}, []);

	const updateWidthWarning = useCallback(() => {
		setWarningState(prevState => ({height: prevState.height, width: true}));
	},[setWarningState]);

	const updateHeightWarning = useCallback(() => {
		setWarningState(prevState => ({width: prevState.width, height: true}));
	}, [setWarningState]);

	const handlerUpDownArrow = useCallback((keyCode, index, ref) => {
        const nextDetailIndex = [CONSTANTS.keyCodes.top].includes(keyCode) ? index - 1 : index + 1;

        if ([CONSTANTS.keyCodes.enter, CONSTANTS.keyCodes.right].includes(keyCode) && index < detailsCount - 1) {
			let fieldName = CONSTANTS.detailFieldNames.NAME;

			if (editLimits.onlyFirstGroup) {
				fieldName = CONSTANTS.detailFieldNames.WIDTH;
			}

			if (editLimits.onlyEdge) {
				fieldName = CONSTANTS.detailFieldNames.TOP;
			}

            setFocusedDetail({index: nextDetailIndex, field: fieldName});
        }
        if (keyCode === CONSTANTS.keyCodes.bottom && index < detailsCount - 1) {
            setFocusedDetail({index: nextDetailIndex, field: ref});
        }
        if (keyCode === CONSTANTS.keyCodes.top && index > 0) {
            setFocusedDetail({index: nextDetailIndex, field: ref});
        }
	}, [setFocusedDetail, editLimits]);

	/**
	 * Handles keydown events and performs specific actions based on the key pressed and the provided reference.
	 * @param {Event} event - The keydown event object.
	 * @param {Number|String} ref - The reference value indicating which element to handle.
	 */
	const handleKeyDown = useCallback((event, fieldName) => {
		if ([CONSTANTS.keyCodes.enter, CONSTANTS.keyCodes.right].includes(event.keyCode)) {
			switch (fieldName) {
				case CONSTANTS.detailFieldNames.NAME:
					event.preventDefault();
					if (refName.current.value === EMPTY_LINE) {
						showError();
					} else {
						refWidth.current.select();
					}
					break;
				case CONSTANTS.detailFieldNames.WIDTH:
					event.preventDefault();
					if (refWidth.current.value === EMPTY_LINE) {
						showError();
						updateWidthWarning();
					} else {
						refHeight.current.select();
					}
					break;
				case CONSTANTS.detailFieldNames.HEIGHT:
					event.preventDefault();
					if (refHeight.current.value === EMPTY_LINE) {
						showError();
						updateHeightWarning();
					} else {
						refCount.current.select();
					}
					break;
				case CONSTANTS.detailFieldNames.COUNT:
					if (editLimits.onlyFirstGroup) {
						handlerUpDownArrow(event.keyCode, index, fieldName);
					} else {
						refMaterial.current.focus();
					}
					break;
				case CONSTANTS.detailFieldNames.MATERIAL:
					refTop.current.focus();
					break;
				case CONSTANTS.detailFieldNames.TOP:
					refBottom.current.focus();
					break;
				case CONSTANTS.detailFieldNames.BOTTOM:
					refLeft.current.focus();
					break;
				case CONSTANTS.detailFieldNames.LEFT:
					refRight.current.focus();
					break;
				case CONSTANTS.detailFieldNames.RIGHT:
					if (editLimits.onlyEdge) {
						handlerUpDownArrow(event.keyCode, index, fieldName);
					} else {
						refProduct.current.focus();
					}
					break;
				case CONSTANTS.detailFieldNames.PRODUCT_ID:
					handlerUpDownArrow(event.keyCode, index, fieldName);
					break;
			}
		}
		if ([CONSTANTS.keyCodes.top, CONSTANTS.keyCodes.bottom].includes(event.keyCode)) {
			switch (fieldName) {
				case CONSTANTS.detailFieldNames.WIDTH:
					event.preventDefault();
					if (refWidth.current.value === EMPTY_LINE) {
						showError();
						updateWidthWarning();
					} else {
						handlerUpDownArrow(event.keyCode, index, fieldName);
					}
					break;
				case CONSTANTS.detailFieldNames.HEIGHT:
					event.preventDefault();
					if (refHeight.current.value === EMPTY_LINE) {
						showError();
						updateHeightWarning();
					} else {
						handlerUpDownArrow(event.keyCode, index, fieldName);
					}
					break;
				case CONSTANTS.detailFieldNames.NAME:
				case CONSTANTS.detailFieldNames.COUNT:
					event.preventDefault();
					handlerUpDownArrow(event.keyCode, index, fieldName);
					break;
			}
		}
		if (event.keyCode === CONSTANTS.keyCodes.left) {
			switch (fieldName) {
				case CONSTANTS.detailFieldNames.WIDTH:
					event.preventDefault();
					if (refWidth.current.value === EMPTY_LINE) {
						showError();
						updateWidthWarning();
					} else {
						refName.current.select();
					}
					break;
				case CONSTANTS.detailFieldNames.HEIGHT:
					event.preventDefault();
					if (refHeight.current.value === EMPTY_LINE) {
						showError();
						updateHeightWarning();
					} else {
						refWidth.current.select();
					}
					break;
				case CONSTANTS.detailFieldNames.COUNT:
					event.preventDefault();
					if (refCount.current.value === EMPTY_LINE) {
						showError();
					} else {
						refHeight.current.select();
					}
					break;
				case CONSTANTS.detailFieldNames.MATERIAL:
					event.preventDefault();
					refCount.current.select();
					break;
				case CONSTANTS.detailFieldNames.TOP:
					refMaterial.current.focus();
					break;
				case CONSTANTS.detailFieldNames.BOTTOM:
					refTop.current.focus();
					break;
				case CONSTANTS.detailFieldNames.LEFT:
					refBottom.current.focus();
					break;
				case CONSTANTS.detailFieldNames.RIGHT:
					refLeft.current.focus();
					break;
				case CONSTANTS.detailFieldNames.PRODUCT_ID:
					refRight.current.focus();
					break;
			}
		}
	}, [showError, updateHeightWarning, updateWidthWarning, editLimits, handlerUpDownArrow, refName, index,
		refWidth,
		refHeight,
		refCount,
		refMaterial,
		refTop,
		refBottom,
		refLeft,
		refRight,
		refProduct]);

	const formatOptionLabel = useCallback(({label, shortLabel}, {context}) => {
		switch (context) {
			case 'menu':
				return label;
			case 'value':
				return shortLabel;
			default:
				return label;
		}
	}, []);

	const filterOptions = useCallback((option, input) => {
		if (input) {
			return option.value === Number(input - 1)
		}
		return true;
	}, [])

    const selectConfig = useCallback((meta) => ({
        menuPlacement: "auto",
        openMenuOnFocus: true,
		menuPortalTarget: document.querySelector("body"),
        onMenuOpen: () => setFocusedDetail(meta)
    }), [setFocusedDetail]);

	const selectDetail = useCallback(() => {
		selectDetailHandler(detail);
	}, [selectDetailHandler, detail])

	const selectInput = useCallback((event) => {
		const {name} = event.target;
		setFocusedDetail({index, field: name});
	}, [setFocusedDetail, index]);

	const selectField = ({field}) => {
		switch (field) {
			case CONSTANTS.detailFieldNames.NAME:
				refName.current.select();
				break;
			case CONSTANTS.detailFieldNames.WIDTH:
				refWidth.current.select();
				break;
			case CONSTANTS.detailFieldNames.HEIGHT:
                refHeight.current.select();
				break;
			case CONSTANTS.detailFieldNames.COUNT:
				refCount.current.select();
				break;
            case CONSTANTS.detailFieldNames.MATERIAL:
				refMaterial.current.focus();
				break;
			case CONSTANTS.detailFieldNames.TOP:
				refTop.current.focus();
				break;
			case CONSTANTS.detailFieldNames.BOTTOM:
				refBottom.current.focus();
				break;
			case CONSTANTS.detailFieldNames.LEFT:
				refLeft.current.focus();
				break;
			case CONSTANTS.detailFieldNames.RIGHT:
				refRight.current.focus();
				break;
			case CONSTANTS.detailFieldNames.PRODUCT_ID:
				refProduct.current.focus();
				break;
		}
	};
	
	const changeName = useCallback((event) => {
		changeInputType(detail, event, index);
	}, [changeInputType]);
	
	const changeWidth = useCallback((event) => {
		if (event.target.value) {
			setWarningState(prevState => {
				return {height: prevState.height, width: false};
			})
		}
		
		changeInputType(detail, event, index);
	}, [changeInputType]);
	
	const changeHeight = useCallback((event) => {
		if (event.target.value) {
			setWarningState(prevState => {
				return {width: prevState.width, height: false};
			})
		}
		changeInputType(detail, event, index)
	}, [changeInputType]);
	
	const pressKeyOnField = useCallback((event) => {
		const {name} = event.target;
		
		handleKeyDown(event, name)
	}, [handleKeyDown]);

	const edges = useMemo(() => {
		const isU1Disabled = disableByMaterialType(detailMaterial) || ['Постформінг', 'Постформинг'].includes(detailMaterial);
		const isU2Disabled = disableByMaterialType(detailMaterial) || ['Постформінг', 'Постформинг'].includes(detailMaterial) && detailMaterial.double_rounding;

		return [
			{
				ref: refs.refTop,
				name: CONSTANTS.detailFieldNames.TOP,
				options: generateEdgeOptions(construction._edges, isU1Disabled)
			},
			{
				ref: refs.refBottom,
				name: CONSTANTS.detailFieldNames.BOTTOM,
				options: generateEdgeOptions(construction._edges, isU2Disabled)
			},
			{
				ref: refs.refLeft,
				name: CONSTANTS.detailFieldNames.LEFT,
				options: generateEdgeOptions(construction._edges, disableByMaterialType(detailMaterial))
			},
			{
				ref: refs.refRight,
				name: CONSTANTS.detailFieldNames.RIGHT,
				options: generateEdgeOptions(construction._edges, disableByMaterialType(detailMaterial))
			}
		];
	}, [refs, detailMaterial, construction._edges]);

	useEffect(() => {
        if (focusedRow.index === index && !empty(refDetail.current)) {
            selectField(focusedRow);
            refDetail.current.scrollIntoView({block: "center", behavior: "smooth", inline: "center"})
        }
    }, [focusedRow]);

	return (
		<DetailWrapper
			style={style}
			ref={refDetail}
			className={`${focusedRow.index === index ? "detailsTab__body-item--selected" : ""}`}
			detailId={detail.id}>
            <Checkbox onChange={selectDetail} isSelected={isSelected} />
                <span className="detailsTab__number">{index + 1}</span>
			<CustomInput
				isDisabled={editLimits.onlyEdge || editLimits.onlyFirstGroup}
				ref={refName}
				onClick={selectInput}
				name={CONSTANTS.detailFieldNames.NAME}
				className="detailsTab__data-input_name"
				value={detail.name}
				onChange={changeName}
				onKeyDown={pressKeyOnField}
			/>
			<CustomInput
				isDisabled={editLimits.onlyEdge}
				ref={refWidth}
				onClick={selectInput}
				name={CONSTANTS.detailFieldNames.WIDTH}
				className={`text-center detailsTab__data-input ${warningState.width ? ' border_warning' : ''} ${!!detail?.[`error_${CONSTANTS.detailFieldNames.WIDTH}`] ? ' border_error' : ''} `}
				value={detail.l}
				onChange={changeWidth}
				onKeyDown={pressKeyOnField}
			/>
			<CustomInput
				isDisabled={editLimits.onlyEdge}
				ref={refHeight}
				onClick={selectInput}
				name={CONSTANTS.detailFieldNames.HEIGHT}
				className={`text-center detailsTab__data-input ${warningState.height ? 'border_warning' : ''} ${!!detail?.[`error_${CONSTANTS.detailFieldNames.HEIGHT}`] ? 'border_error' : ''}`}
				value={detail.h}
				onChange={changeHeight}
				onKeyDown={pressKeyOnField}
			/>
			<CustomInput
				isDisabled={editLimits.onlyEdge}
				ref={refCount}
				onClick={selectInput}
				name={CONSTANTS.detailFieldNames.COUNT}
				className="text-center detailsTab__data-input"
				value={detail.count}
				onChange={changeName}
				onKeyDown={pressKeyOnField}
			/>
            <Material
                detail={detail}
                index={index}
                selectConfig={selectConfig}
                isDisabled={editLimits.onlyEdge || editLimits.onlyFirstGroup} ref={refMaterial}
                handleKeyDown={handleKeyDown}
                formatOptionLabel={formatOptionLabel}
                changeSelectType={changeSelectType}
                filterOptions={filterOptions}
            />
            <div className="detailsTab__data-item">{detail.w}</div>
            <Edges
                isDisabled={editLimits.onlyFirstGroup}
                handleKeyDown={handleKeyDown}
                filterOptions={filterOptions}
				selectConfig={selectConfig}
				edges={edges}
				index={index}
				changeEdge={changeEdge}
				formatOptionLabel={formatOptionLabel}
				detail={detail}/>
            <div className="detailsTab__texture-edit">
                <Texture
                    isDisabled={['Постформінг', 'Постформинг', 'OSB'].includes(detailMaterial?.type)}
                    isRotateTexture={detail.isRotateTexture} id={detail.id}/>
            </div>
            <DetailProductSelect
                isDisabled={editLimits.onlyEdge || editLimits.onlyFirstGroup}
                ref={refProduct}
                selectConfig={selectConfig}
                detail={detail}
                changeSelectType={changeSelectType}
                handleKeyDown={handleKeyDown}
                index={index}/>
        <div className="detailsTab__data-item detailsTab__number">
            <DeleteBtn detail={detail} remove={remove}/>
        </div>
		</DetailWrapper>
	)
};

export default Detail