import Db from "api/Db";
import {empty} from "../helpers/helper";
export default class _Materials extends Db {
    constructor() {
        super()
    }

    addMaterials(materials) {
        if(!materials || (Array.isArray(materials) && !materials.length)) {
            return Promise.reject('Incorrect materials');
        }
        const process = () => {
            const material = materials.shift();
            if(material) {

                this.addMaterial(material)
                    .then(() => process())
                    .catch(e => Promise.reject(e))
            } else {
                return Promise.resolve();
            }
        }
        return process();
    }

    addMaterial(material) {
        return new Promise((resolve, reject) => {
            if(typeof material !== 'object' || !material.hasOwnProperty('article')) {
                return Promise.reject('incorrect material')
            }
            if (material.hasOwnProperty('code')){
                material.article = material.code.toString()
                delete material.code
            }
            if (material.hasOwnProperty('l')){
                material.height = material.l
                delete material.l
            }
            if (material.hasOwnProperty('w')){
                material.width = material.w
                delete material.w
            }
            if (material.hasOwnProperty('t')){
                material.thickness = material.t
                delete material.t
            }
            if (material.hasOwnProperty('typename')){
                material.type = material.typename
                delete material.typename
            }

            this.addItem('materials', material)
                .then(() => resolve(material))
                .catch(err => reject(err))

        })
    }

    getMaterials() {
        return this.getAllData('materials');
    }

    getMaterial(index) {
        return this.getItem('materials', index)
    }

    updateMaterial(index, field, value) {
       return this.update('materials', index, field, value)
    }

    deleteMaterial(index) {
        if(empty(index)) {
            return Promise.reject({Error : 'Incorrect material.index'})
        }
        return this.getDataByKey('details', 'material', index)
            .then(data => {
                if(data.length) {
                    return Promise.reject({Warning: 'material_used'})
                }
                return this.removeItem('materials', index);
            })

    }
}