import Processing from "./3D/Processing";
import Helpers from "./3D/Helpers";
import SizeArrow from "./3D/components/SizeArrow";
import Languages from "../translation/Languages";
import {empty} from "../helpers/helper";

export default class Bevel extends Processing {
	_subType = 'bevel';
	_isActive = false;
	_edgeSide = 'left';
	_angle = 45;
	_angleRad = 0;
	sideB = 0;
	sideA = 0;
	_z = 0;
	_x = 0;
	_y = 0;
	sideC = 0;
	alfa = 0;
	ownHoles =  [];

	constructor({
		            detail,
		            side = "left",
		            start = 0,
		            alpha = 45,
								id,
		            comment = "",
		            additionalInfo = '',
		            isInit = false
	            }) {
		super({z: start, side: alpha > 0 ? 'front' : 'back', detail, id, comment, x: 0, y: 0, additionalInfo, isInit});

		this._angle = Math.abs(alpha);
		this._angleRad = (this._angle * Math.PI) / 180;
		this.alfa = alpha;
		this._z = start;
		this._edgeSide = side;
		this.calcSides();
		this._x = this.calcX();
		this._y = this.calcY();
		this.addParams()
		this.initRequiredFields();

	}

	initRequiredFields () {
		this.requiredFields = {
			angle: {
				max: 45,
				min: 1
			},
		}
	}

	calcSides() {
		this.sideB = this.detail.w - this.z;
		this.sideA = this.sideB * Math.tan(this._angleRad);
		this.sideC = this.sideB / Math.cos(this._angleRad);
		this.calcY();
		this.calcX();
	}

	calcAlfa() {
		this.alfa = this.side === 'front' ? this.angle : -this.angle;
	}

	addParams() {
		this.setFormField({name:'side', label: Languages.getTranslation('side', true), type: 'select', value: this.side, variables: [
				{ key: "front", value: Languages.getTranslation('face', true) },
				{ key: "back", value: Languages.getTranslation('back', true) }
			], visible: true})


		this.setFormField({name:'edgeSide', type: 'select', label: Languages.getTranslation('only-end', true), value: this.edgeSide, variables: [
				...this.getSidesValues()
			]})

		this.setFormField({name: 'z', value: this.z, type: 'number', label: 'z', visible: true, additionalParam : [
				{name: 'center_z', label: `${Languages.getTranslation('center-on-axis', true)} Z`, callback: () => {
						this.setCenterZ();
					}}
			]})
		this.setFormField({name: 'x', visible: false})
		this.setFormField({name: 'y', visible: false})
		this.setFormField({name: 'angle', value: this.angle, type: 'number', label: `${Languages.getTranslation('Cutting-angle', true)} (${Languages.getTranslation('from')} 1 ${Languages.getTranslation('to')} 45)`, visible: true})
	}

	setCenterZ() {
		this.z = this.detail.w / 2;
	}

  calcX() {
		switch (this.edgeSide) {
			case 'left':
			case 'top':
			case 'bottom':
				return 0;
			case 'right':
				return this.detail.l - this.sideA
		}
	}

	calcY() {
		switch (this.edgeSide) {
			case 'left':
			case 'bottom':
			case 'right':
				return 0;
			case 'top':
				return this.detail.h - this.sideA
		}
	}

	get cutOut() {
		const h = ['top', 'bottom'].includes(this.edgeSide) ? this.sideC : this.detail.h;
		const l = ['left', 'right'].includes(this.edgeSide) ? this.sideC :  this.detail.l;
		const holeh = ['top', 'bottom'].includes(this.edgeSide) ? this.sideA : this.detail.h;
		const holel = ['left', 'right'].includes(this.edgeSide) ? this.sideA :  this.detail.l;
		const depth = this.detail.w - this.z;
		const meshY = this.edgeSide === 'top' ? this.detail.h - this.sideC : 0;
		const meshX = this.edgeSide === 'right' ? this.detail.l - this.sideC : 0;

		const cutout =  {
			x: this.x,
			y: this.y,
			z: this.z,
			h,
			l,
			side: this.side,
			type: 'cutout',
			subType: 'bevel',
			edgeSide: this.edgeSide,
			angle: this.angleRad,
			depth,
			multiplicity: this.multiplicity,
			edge: null,
			width: ['left', 'right'].includes(this.edgeSide) ? this.sideA : this.detail.l,
			height: ['top', 'bottom'].includes(this.edgeSide) ? this.sideA :  this.detail.h,
			color: this.isActive ? Helpers.colorForActive : null,
			elements: [
				Helpers.createLine(meshX, meshY, meshX, meshY + h),
				Helpers.createLine(meshX, meshY + h, meshX + l, meshY + h),
				Helpers.createLine(meshX + l, meshY + h, meshX + l, meshY),
				Helpers.createLine(meshX + l, meshY, meshX, meshY)
			],
			topShape: [
				Helpers.createLine(this.x, this.y, this.x, this.y + holeh),
				Helpers.createLine(this.x, this.y + holeh, this.x + holel, this.y + holeh),
				Helpers.createLine(this.x + holel, this.y + holeh, this.x + holel, this.y),
				Helpers.createLine(this.x + holel, this.y, this.x, this.y)
			]
		}
		return cutout;
	}

	getEdgeHoles(side) {
		const startX = this.side === 'front' ? 0 : this.detail.w;
		const startZ = this.side === 'front' ? this.detail.w - this.z : this.z;
		const fixB = this.side === 'front' ? this.sideB : this.detail.w - this.sideB;
		switch (this.edgeSide) {
			case 'left':
				switch (side) {
					case 'top':
						return { elements: [
								Helpers.createLine(startX, 0, startX, this.sideA),
								Helpers.createLine(startX, this.sideA, fixB, 0),
								Helpers.createLine(fixB - this.z, 0,  startX, 0)
							]};
					case 'bottom':
						return { elements: [
								Helpers.createLine(startX, this.detail.l, startX, this.detail.l - this.sideA),
								Helpers.createLine(startX, this.detail.l - this.sideA, fixB, this.detail.l),
								Helpers.createLine(fixB, this.detail.l,  startX, this.detail.l)
							]};
					case 'left':
						if(this.z === 0) {
							return null
						} else {
							return { elements: [
								Helpers.createLine(startX, 0, startX, this.detail.h),
								Helpers.createLine(startX, this.detail.h, startZ, this.detail.h),
								Helpers.createLine(startZ, this.detail.h, startZ, 0),
								Helpers.createLine(startZ, startX, startX, startX)
							]}
						}
					default: return null;
				}
			case 'top':
				switch (side) {
					case 'left':
						return { elements: [
								Helpers.createLine(startX, this.detail.h - this.sideA, startX, this.detail.h),
								Helpers.createLine(startX, this.detail.h, fixB, this.detail.h),
								Helpers.createLine(fixB, this.detail.h,  startX,this.detail.h - this.sideA)
							]};
					case 'right':
						return { elements: [
								Helpers.createLine(startX, 0, startX, this.sideA),
								Helpers.createLine(startX, this.sideA, fixB, 0),
								Helpers.createLine(fixB - this.z, 0,  startX, 0)
							]};
					case 'top':
						if(this.z === 0) {
							return null
						} else {
							return { elements: [
									Helpers.createLine(startX, 0, startX, this.detail.l),
									Helpers.createLine(startX, this.detail.l, startZ, this.detail.l),
									Helpers.createLine(startZ, this.detail.l, startZ, 0),
									Helpers.createLine(startZ, 0, startX, 0)
								]
							}
						}
					default: return null;
				}
			case 'right':
				switch (side) {
					case 'top':
						return { elements: [
								Helpers.createLine(startX, this.detail.l - this.sideA, startX, this.detail.l),
								Helpers.createLine(startX, this.detail.l, fixB, this.detail.l),
								Helpers.createLine(fixB, this.detail.l,  startX,this.detail.l - this.sideA)
							]};
					case 'bottom':
						return { elements: [
								Helpers.createLine(startX, 0, startX, this.sideA),
								Helpers.createLine(startX, this.sideA, fixB, 0),
								Helpers.createLine(fixB, 0,  startX, 0)
							]};
					case 'right':
						if(this.z === 0) {
							return null
						} else {
							return { elements: [
									Helpers.createLine(startX, 0, startX, this.detail.h),
									Helpers.createLine(startX, this.detail.h, startZ, this.detail.h),
									Helpers.createLine(startZ, this.detail.h, startZ, 0),
									Helpers.createLine(startZ, 0, startX, 0)
								]
							}
						}
					default: return null;
				}
			case 'bottom':
				switch (side) {
					case 'left':
						return { elements: [
								Helpers.createLine(startX, 0, startX, this.sideA),
								Helpers.createLine(startX, this.sideA, fixB, 0),
								Helpers.createLine(fixB - this.z, 0,  startX, 0)
							]};
					case 'right':
						return { elements: [
								Helpers.createLine(startX, this.detail.h - this.sideA, startX, this.detail.h),
								Helpers.createLine(startX, this.detail.h, fixB, this.detail.h),
								Helpers.createLine(fixB, this.detail.h,  startX,this.detail.h - this.sideA)
							]};

					case 'bottom':
						if(this.z === 0) {
							return null
						} else {
							return { elements: [
									Helpers.createLine(startX, 0, startX, this.detail.l),
									Helpers.createLine(startX, this.detail.l, startZ, this.detail.l),
									Helpers.createLine(startZ, this.detail.l, startZ, 0),
									Helpers.createLine(startZ, 0, startX, 0)
								]
							}
						}
					default: return null;
				}
		}
	}

	addSizesArrows() {
		if(!this.isActive) return false;

		let y = this.side === 'top' ? this.detail.h : this.side === 'bottom' ? 0 : this.y;
		let x = this.side === 'left' ? 0 : this.side === 'right' ? this.detail.l : this.x;
		const pos = this.x_axis + '_' + this.y_axis;
		switch (this.side) {
			case 'top':
				y = this.depth;
				break
			case 'bottom':
				y = this.depth;
				break
			case 'left':
				x = this.depth
				break
			case 'right':
				x = this.depth
				break
		}
		return SizeArrow.getElementSizeArrow(
			x,
			y,
			null,
			pos,
			this.detail,
			false,
			this.side === 'back'
		)
	}

	get paramsSorting() {
		const sortingArr = ['side', 'edgeSide', 'z', 'angle', 'comment']

		return this.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
	}

	get angleRad() {
		return this._angleRad;
	}

	set angleRad(angle) {
		this._angleRad = (Math.abs(angle) * Math.PI) / 180;
	}

	get angle() {
		return this._angle;
	}

	set angle(angle) {
		this._angle = Number(angle)
		this.angleRad = this.angle;
		this.calcAlfa();
		this.calcSides();
		this.x = this.calcX();
		this.y = this.calcY();
		this.setFormField({name: 'angle', value: this.angle})
		this.buildDetail()
			.then(() => {
				this.renderDetail()
			});
	}

	get side() {
		return this._side;
	}

	set side(side) {
		this._side = side;
		this.calcAlfa();
		this.calcSides();
		this.x = this.calcX();
		this.y = this.calcY();
		this.setFormField({name: 'side', value: this.side})
		this.buildDetail()
			.then(() => this.renderDetail());
	}

	get z() {
		return this._z;
	}

	set z(z) {
		this._z = Number(z);
		this.calcSides()
		this.calcY();
		this.calcX()
		this.setFormField({name: 'z', value: this.z})
		this.buildDetail()
			.then(() => {
				this.renderDetail()
			})
	}

	get edgeSide() {
		return this._edgeSide;
	}

	set edgeSide(edgeSide) {
		this._edgeSide = edgeSide;
		this.calcSides()
		this.x = this.calcX();
		this.y = this.calcY()
		this.setFormField({name: 'edgeSide', value: this.edgeSide});
		this.buildDetail()
			.then(() => this.renderDetail())
	}

	get dataForValidate() {
		return this.realData
	}

	fixMultiplicity() {
		return null
	}

	get realData() {
		return {
			side: this.edgeSide,
			start: this.z,
			alpha: this.alfa,
			id: this.id
		}
	}

	validate() {
		if(this.z < 0){
			this.detail.error.setError(`${Languages.getTranslation('Z-cannot-be-negative', true)}`, 'error', true);
			return Promise.reject(`Z-cannot-be-negative`)
		}
		if(this.angle === 0){
			this.detail.error.setError(`${Languages.getTranslation('angle-cannot-be', true)} ${this.angle}`, 'error', true);
			return Promise.reject(`angle-cannot-be`)
		}
		if(this.angle < 0){
			this.detail.error.setError(`${Languages.getTranslation('angle-cannot-be-negative', true)}`, 'error', true);
			return Promise.reject(`angle-cannot-be-negative`)
		}
		return Promise.resolve()
	}

	rotateDetail(direction) {
		this.edgeSide = this.getNextEdgeSideForRotate(direction)
		return this.updateDb()
	}
}