import {toast} from "react-toastify";

const toastConfig = {
	autoClose: 3500,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	theme: "light",
}

export const toastError = (text, options = {position: "bottom-right"}) => {
	toast.error(text, {
		...toastConfig,
		...options,
		toastId: text,
	})
}

export const toastWarning = (text, options = {position: "top-right"}) => {
	toast.warn(text, {
		...toastConfig,
		...options,
		toastId: text,
	})
}

export const toastSuccess = (text, options = {position: "bottom-right"}) => {
	toast.success(text, {
		...toastConfig,
		...options,
		toastId: text,
	})
}