import store from "../redux/store";
import * as THREE from "three";
import {AxesHelper} from "three";
import detailSidesConfig from "./3D/Detail-sides-config";
import {Face3D} from "./3D/Face3D";
import Groove from "./Groove";
import RectangleAndCircle from "./RectangleAndCircle";
import UShape from "./Mills/UShape";
import Rabbet from "./Rabbet";
import Hole from "./Hole";
import Corner from "./Corner";
import Requests from "api/API";
import Languages from "translation/Languages";
import Mill from "./Mill";
import {empty, findEmptySpace, toAddSide} from "../helpers/helper";
import Contour from "./3D/Contour";
import ContourEdges from "./3D/ContourEdges";
import SizeArrow from "./3D/components/SizeArrow";
import Helpers from "./3D/Helpers";
import _Details from "../db/_Details";
import Errors from "./Errors";
import Multiplicity from "./3D/Multiplicity";
import CurvedLine from "./CurvedLine";
import Bevel from "./Bevel";
import TableProc from "./TableProc";
import Animations from "./3D/Animations";
import Templates from "./Templates";
import _Detail from "../db/_Detail";
import processConfig from "../config/process_config.json"
import processing from "../components/UI/Modal/Processing";
import {log} from "three/examples/jsm/nodes/math/MathNode";
import CutOut from "./CutOut";
import {v4 as uuidv4} from "uuid";

const detailsDb = new _Details();
class Detail {
  /*
    Список параметров Детали

    "name":           Имя
    "texture":        Текстура
    "multiplicity":   multiplicity
    "leftCut":        Подрезание слева
    "rightCut":       Подрезание справа
    "topCut":         Подрезание сверху
    "bottomCut":      Подрезание снизу
    "count":          Количетсво
    "edges":          Кромки
    "holes":          Отверстия
    "material"        Материал детали (номер из общего списка материалов)
    "h":              Высота
    "l":              Ширина
    "w":              Глубина
    "corners":        Углы
    "arcs":           Дуга
    "smiles":         Улыбки
    "grooves"         Пазы,
    "rectangles"      Премоугольные вырезы,
    "circles"         Круглые вырезы,
    "rabbets"         Четвертя,
    "mills"           Криволінійні обробки,
    "preCutting"      Підрізка в розмір
  */
  _showMainXArrow = 'top';
  _showMainYArrow = 'right';
  multiplicityClass;
  detailRenderTimeout;
  _dataForAnimations;
  bevels = [];
  currentContour = [];
  parent;
  preCutting = {
    left: null,
    right: null,
    top: null,
    bottom: null
  };
  editableSides = {
    left: {
      edge: true,
      hole: true,
      mill: true,
      rect: true,
      bevel: true,
      corner: true
    },
    right: {
      edge: true,
      hole: true,
      mill: true,
      rect: true,
      bevel: true,
      corner: true
    },
    top: {
      edge: true,
      hole: true,
      mill: true,
      rect: true,
      bevel: true,
      corner: true
    },
    bottom: {
      edge: true,
      hole: true,
      mill: true,
      rect: true,
      bevel: true,
      corner: true
    }
  };
  templates = [];
  contour3d = [];
  detailDb;
  processingUpdate = {};
  order= null;
  imgBase = null;
  imgPdf = null;
  isImported = false;
  processConfig = processConfig;
  _detailId;
  _productId;

  constructor(data) {

    this.init(data);
  }

  init({
    id = 0,
    multiplicity = null,
    leftCut = 0,
    rightCut = 0,
    topCut = 0,
    order = null,
    imgPdf = null,
    imgBase = null,
    bottomCut = 0,
    count = 1,
    type = "",
    name = "",
    detailDescription = "",
    edges = {
      left: null,
      right: null,
      top: null,
      bottom: null,
    },
    holes = [],
    uShapes = [],
    rabbets = [],
    material = null,
    isRotateTexture = false,
    isCutting = false,
    h = 600,
      ch = h,
    l = 1000,
      cl = l,
      w = 10,
    corners = [],
    arcs = [],
    smiles = [],
    grooves = [],
    rectangles = [],
    circles = [],
    mills = [],
    contour = [],
    contour3d = [],
    cutouts = [],
    bevels = [],
    select = false,
    soft = {top: false, bottom: false},
    isPostForming = false,
    templates = [],
    preCutting = {
      left: null,
      right: null,
      top: null,
      bottom: null
    },
    parent,
    detailId = null,
    productId = 1
  }) {
    this.parent = parent;
    this.id = id;
    this.order = order || this.parent.details.length + 1;
    this.imgBase = imgBase;
    this.imgPdf = imgPdf;
    this._select = select;
    this.mesh = null;
    this.multiplicity = multiplicity;
    this.leftCut = leftCut;
    this.type = type;
    this.rightCut = rightCut;
    this.topCut = topCut;
    this.bottomCut = bottomCut;
    this.count = count;
    this.material = material;
    this.edges = edges;
    this.h = h;
    this.ch = ch;
    this.isRotateTexture = isRotateTexture;
    this.isCutting = isCutting;
    this.name = name;
    this.detailDescription = detailDescription;
    this.l = l;
    this.cl = cl;
    this.w = !empty(multiplicity) && !empty(multiplicity.type) ? this.getMaterialThickness(material) + this.getMaterialThickness(multiplicity.material) : this.getMaterialThickness(material);
    this.holes = holes;
    this.corners = corners;
    this.arcs = arcs;
    this.smiles = smiles;
    this.templates = templates;
    this.grooves = grooves;
    this.rabbets = rabbets;
    this.rectangles = rectangles;
    this.circles = circles;
    this.uShapes = uShapes;
    this.mills = mills;
    this.contour = contour;
    this.contour3d = contour3d;
    this.cutouts = cutouts;
    this.bevels = bevels;
    this.soft = soft;
    this.isPostForming = isPostForming;
    this.error = new Errors([], this.id);
    this.preCutting = preCutting;
    this._dataForAnimations = null
    this.templates = templates;
    this.detailDb = new _Detail(this.id)
    if(!empty(this.contour)) {
      this.isImported = true;
    }

    this._detailId = detailId || uuidv4();
    this._productId = Number(productId)

    setTimeout(() => {
      if(empty(this.contour3d)){
       this.createContour()
      }
      this.checkIfPreCuttingNeeded()
      // this.validate()
      //     .then(() => {})
    }, 200)
  }

  addMill(dataMill = {elements: []}) {
    const self = this;
    const mill = new Mill({...dataMill, detail: self});
    this.mills.push(mill)
    return mill;
  }

  addTableProc(dataTableProc = {elements: []}) {
    const self = this;
    const tableProc = new TableProc({...dataTableProc, detail: self});
    this.mills.push(tableProc)
    return tableProc;
  }

  addTemplates(dataTemplate = {}) {
    const self = this;
    const template = new Templates({...dataTemplate, detail: self});
    this.templates.push(template)
    return template;
  }

  addCorner(dataCorner = {}) {
    const self = this;
    const corner = new Corner({...dataCorner, detail: self});
    this.corners.push(corner);
    // window.corner = corner;
    return corner;
  }


  addGroove(dataGroove = {}) {
    dataGroove.detail = this;
    const groove = new Groove(dataGroove);
    this.grooves.push(groove);
    // window.groove = groove;
    return groove;
  }

  addRabbet(dataRabbet = {}) {
    dataRabbet.detail = this;
    const rabbet = new Rabbet(dataRabbet);
    this.rabbets.push(rabbet);
    // window.rabbet = rabbet;
    return rabbet;
  }

  addRectangle(dataRectangle = {}) {
    dataRectangle.detail = this;
    const rectangle = new RectangleAndCircle({...dataRectangle, subType: "rectangle"});
    this.rectangles.push(rectangle);
    return rectangle;
  }

  addCircle(dataCircle = {}) {
    dataCircle.detail = this;

    const circle = new RectangleAndCircle({...dataCircle, subType: "circle"});
    this.rectangles.push(circle);
    return circle;
  }

  addUShape(dataUshape = {}) {
    dataUshape.detail = this;
    const uShape = new UShape(dataUshape);
    this.uShapes.push(uShape);
    // window.uShape = uShape;
    return uShape;
  }

  addHole(dataHole = {}) {
    dataHole.detail = this;
    const hole = new Hole(dataHole);
    this.holes.push(hole);
    // window.hole = hole;
    // this.build(false)
    return hole;
  }

  addBevel(dataBevel = {}) {
    dataBevel.detail = this;
    const bevel = new Bevel(dataBevel);
    this.bevels.push(bevel);
    return bevel
  }

  addCutOut(dataCutOut = {}) {
    dataCutOut.detail = this;
    const cutOut = new CutOut(dataCutOut);
    this.cutouts.push(cutOut);
    return cutOut;
  }

  addCutOutSampling(dataCutOut = {}) {
    dataCutOut.detail = this;
    dataCutOut.type = 'Sampling';
    const cutOut = new CutOut(dataCutOut);
    this.cutouts.push(cutOut);
    return cutOut;
  }

  rmMesh() {
    for (let detail of this.parent.details)
      if (detail.mesh && detail.mesh.parent)
        detail.mesh.parent.remove(detail.mesh);
  }

  showMainArrowXBottom(bottom = false) {
    if(bottom) {
      this._showMainXArrow = 'bottom';
    } else {
      this._showMainXArrow = 'top';
    }
  }

  showMainArrowYLeft(left = false) {
    if(left) {
      this._showMainYArrow = 'left';
    } else {
      this._showMainYArrow = 'right';
    }
  }

  /**
   * Validates the given process by performing various operations and updating objects accordingly.
   *
   * @param {Object} process - The process object to be validated.
   *
   * @return {Promise} - A Promise that resolves with the validation result.
   */
  async validate(process) {
    if (empty(process)) return
    if (
      this.grooves &&
      this.rectangles &&
      this.circles &&
      this.uShapes &&
      this.rabbets &&
      this.mills &&
      this.corners
    ) {
      for (let item of [
        ...this.grooves,
        ...this.rectangles,
        ...this.circles,
        ...this.mills,
        // ...this.uShapes,
        ...this.rabbets,
        ...this.corners,
      ]) {
        item.isErrorText = "";
      }

      for (let hole of this.holes) {
        hole.isErrorText = "";
      }

      const { details } = store.getState().project.project.construction.payload.construction
      const tempDetails = details.find(detail => detail.id === this.id)

      tempDetails.error.dropErrors()
      this.error.dropErrors()

      /**
       * The switching function handles different cases based on the value of the `item.name` parameter.
       * It performs various operations and updates the `tempDetail` and `this` objects accordingly.
       *
       * @param {Object} item - The item object.
       * @param {Object} tempDetail - The tempDetail object.
       */
      const switching = (item, tempDetail) => {
        let tempProcce, tempIndex;
        switch(item.name){
          case 'Sampling':
              tempProcce = tempDetail.cutouts.find(f => f.id === item.uidOperation)
              if(!empty(tempProcce)){
                this.cutouts.find(f => f.id === item.uidOperation).isErrorText = item.message
                tempProcce.isErrorText = item.message
              }
            tempDetail.error.addError(`${item.message} ${Languages.getTranslation(`add_cutoutSampling_title`, true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            this.error.addError(`${item.message} ${Languages.getTranslation(`add_cutoutSampling_title`, true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
              break;
          case 'Circle':
            tempProcce = tempDetail[`rectangles`].find(f => f.id === item.uidOperation)
            if(!empty(tempProcce)){
              this[`rectangles`].find(f => f.id === item.uidOperation).isErrorText = item.message
              tempProcce.isErrorText = item.message
            }
            tempDetail.error.addError(`${item.message} ${Languages.getTranslation(`circle-proc`, true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            this.error.addError(`${item.message} ${Languages.getTranslation(`circle-proc`, true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            break;
          case 'Rectangle':
            // tempIndex = tempDetail[`rectangles`].findIndex(f => f.id === item.uidOperation)
            tempProcce = tempDetail[`rectangles`].find(f => f.id === item.uidOperation)
            if(!empty(tempProcce)) {
              this[`rectangles`].find(f => f.id === item.uidOperation).isErrorText = item.message
              tempProcce.isErrorText = item.message
            }
            tempDetail.error.addError(`${item.message} ${Languages.getTranslation('rectangle', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            this.error.addError(`${item.message} ${Languages.getTranslation('rectangle', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            break;
          case 'DetailDimensions':
            tempDetail.error.addError(item.message, item.type, item.index, item.uidOperation, process.id)
            this.error.addError(item.message, item.type, item.index, item.uidOperation, process.id)
            break;
          case 'holes':
            tempIndex = tempDetail[`holes`].findIndex(f => f.id === item.uidOperation)
            tempProcce = tempDetail[`holes`][tempIndex]
            if(!empty(tempProcce)) {
              tempProcce.isErrorText = item.message
              this[`holes`][tempIndex].isErrorText = item.message
            }
            tempDetail.error.addError(`${item.message} ${Languages.getTranslation('hole', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            this.error.addError(`${item.message} ${Languages.getTranslation('hole', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            break;
          case 'corners':
            tempIndex = tempDetail[`corners`].findIndex(f => f.id === item.uidOperation)
            tempProcce = tempDetail[`corners`][tempIndex]
            if(!empty(tempProcce)) {
              tempProcce.isErrorText = item.message
              this[`corners`][tempIndex].isErrorText = item.message
            }
            tempDetail.error.addError(`${item.message} ${Languages.getTranslation('corner', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            this.error.addError(`${item.message} ${Languages.getTranslation('corner', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            break;
          case 'groove':
            tempIndex = tempDetail[`grooves`].findIndex(f => f.id === item.uidOperation)
            tempProcce = tempDetail[`grooves`][tempIndex]
            if(!empty(tempProcce)) {
              tempProcce.isErrorText = item.message
              this[`grooves`][tempIndex].isErrorText = item.message
            }
            tempDetail.error.addError(`${item.message} ${Languages.getTranslation('groove', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            this.error.addError(`${item.message} ${Languages.getTranslation('groove', true)}: ${item.index + 1}`, item.type, item.index, item.uidOperation, process.id)
            break;
          case 'mills':
            tempIndex = tempDetail[`mills`].findIndex(f => f.id === item.uidOperation)
            tempProcce = tempDetail[`mills`][tempIndex]
            if(!empty(tempProcce)) {
              tempProcce.isErrorText = item.message
              this[`mills`][tempIndex].isErrorText = item.message
            }
            tempDetail.error.addError(item.message, item.type, item.index, item.uidOperation, process.id)
            this.error.addError(item.message, item.type, item.index, item.uidOperation, process.id)
            break;
          case 'bevels':
            tempIndex = tempDetail[`bevels`].findIndex(f => f.id === item.uidOperation)
            tempProcce = tempDetail[`bevels`][tempIndex]
            if(!empty(tempProcce)) {
              tempProcce.isErrorText = item.message
              this[`bevels`][tempIndex].isErrorText = item.message
            }
            tempDetail.error.addError(item.message, item.type, item.index, item.uidOperation, process.id)
            this.error.addError(item.message, item.type, item.index, item.uidOperation, process.id)
            break;
          case 'rabbet':
            tempIndex = tempDetail[`rabbets`].findIndex(f => f.id === item.uidOperation)
            tempProcce = tempDetail[`rabbets`][tempIndex]
            if(!empty(tempProcce)) {
              tempProcce.isErrorText = item.message
              this[`rabbets`][tempIndex].isErrorText = item.message
            }
            tempDetail.error.addError(item.message, item.type, item.index, item.uidOperation, process.id)
            this.error.addError(item.message, item.type, item.index, item.uidOperation, process.id)
            break;
          case 'edges':
            tempDetail.error.addError(item.message, item.type, item.index)
            break;
          case "Common":
            tempDetail.error.addError(item.message, item.type, item.index)
            break;
          case 'multiplicity':
            tempDetail.error.addError(item.message, item.type, item.index)
            break;
          default:
            break;
        }
      }
      if(!empty(Object.values(this.preCutting).find(el => !empty(el)))) {

      }
      const res = await Requests.Detail.validate(this, tempDetails);
      try {
        if (!empty(res.errors)) {
          const errArr = res.errors
          if (!empty(errArr)) {
            for (const operation in errArr) {
              const tempDetail = details.find(item => item.id === this.id)
              const tempArr = errArr[operation]
              for (const i in tempArr) {
                if (Array.isArray(tempArr[i])) {
                  tempArr[i].forEach(dep => {
                    if (Array.isArray(dep)) {
                      dep.forEach(gg => {
                        if (Array.isArray(gg)) {
                          gg.forEach(wtf => {
                            switching(wtf, tempDetail, operation)
                          })
                        } else {
                          switching(gg, tempDetail)
                        }
                      })
                    }
                  })
                } else {
                  const depArr = tempArr[i]
                  for (const dep in depArr) {
                    depArr[dep].forEach(dd => {
                      try {
                        switching(dd, tempDetail)
                      } catch (error) {
                        console.log(error)
                      }
                    })
                  }
                }
              }
            }
          }
          return res
        } else return res && res.success;
      } catch (err) { console.log(err) }
    }
  }

  /**
   * Retrieves the details for a project validation.
   *
   * @param {Array} edges - The edges data.
   * @param {Array} materials - The materials data.
   * @returns {Object} detail - The details for the project validation.
   */
  getDetailForProjectValidate(edges, materials){
    const getEdgesData = () => {
      const tmp_edges = {};
      for(let edge in this.edges) {
        if(!empty(this.edges[edge])) {
          tmp_edges[edge] = edges.findIndex(e => e.index === this.edges[edge])
        } else {
          tmp_edges[edge] = null;
        }
      }
      return tmp_edges;
    }

    const getMaterial = () => {
      return materials.findIndex(m => m.index === this.material)
    }

    const getDataForValidateMultiplicity = () => {
      const multiplicity = {material: null, edge: null, type: 0};
      if(!empty(this.multiplicity)) {
        multiplicity.type = this.multiplicity.type
        multiplicity.material = this.parent.materials.findIndex(el => el.index === this.multiplicity.material)
        if(!empty(this.multiplicity.edge)) {
          multiplicity.edge = !empty(this.multiplicity.edge) ? this.parent.edges.findIndex(el => el.index === this.multiplicity.edge): null;
        }
      }else return null
      return multiplicity
    }

    const detail = {};
    detail.material = getMaterial();
    detail.h = this.h
    if(!empty(this.preCutting.top)){
      detail.h += this.preCutting.top
    }
    if(!empty(this.preCutting.bottom)){
      detail.h += this.preCutting.bottom
    }
    detail.l = this.l;
    if(!empty(this.preCutting.left)){
      detail.l += this.preCutting.left
    }
    if(!empty(this.preCutting.right)){
      detail.l += this.preCutting.right
    }
    detail.multiplicity = getDataForValidateMultiplicity();
    detail.holes = this.holes.map((hole) => hole.realData);
    detail.bevels = this.bevels.map(bevel => bevel.realData);
    detail.rects = [ ...this.rectangles, ...this.rabbets, ...this.grooves ].map(item => item.realData);
    detail.corners = this.corners.map(corner => corner.realData);
    detail.mills = this.mills.map(mill => mill.realData);
    detail.cutouts = this.cutouts.map(cutout => cutout?.realData)
    detail.arcs = [];
    detail.smiles = [];
    detail.templates = [];
    detail.edges = getEdgesData();
    detail.isRotateTexture = this.isRotateTexture;
    detail.isCutting = this.isCutting;
    detail.preCutting = this.preCutting;
    detail.soft = this.soft;
    detail.increase = this.increase;
    return detail
  }

  getRectsData() {
    const rects = [
      ...this.grooves.filter((el) => !empty(el.id)).map((groove) => groove), // Пазы
      ...this.rabbets.filter((el) => !empty(el.id)).map((rabbet) => rabbet), // Четвертя
      ...this.rectangles.filter((el) => !empty(el.id)).map((rectangle) => rectangle), // Прямоугольные вырезы
      ...this.circles.filter((el) => !empty(el.id)).map((circle) => circle), // Круглый вырез
      // ...this.uShapes.map((shape) => shape), // П-образный вырез
    ].map((rect) => {
      const shape = rect.realData;
      // const dataForConstructor = rect.getDataForConstructor();
      return {
        ...{
          side: shape.side,
          id: shape.id,
          x: shape.x, // Смещение по X (от левого нижнего угла)
          y: shape.y, // Смещение по Y (от левого нижнего угла)
          z: shape.z || 0, //для торцевіх пазов
          width: shape.width,
          height: shape.height,
          depth: shape.depth,
          type: shape.type,
          fullDepth: shape.fullDepth ?? false,
          comment: shape.comment,
          r: shape.r,
          edge: shape.edge,
          ext: shape.ext,
          direction: shape.direction ?? null,
          dataForConstructor: rect.getDataForConstructor()

        }
      };
    });
    return rects;
  }

  /**
   * Sets an error message for the current object and its associated details.
   *
   * @param {Object} error - The error object containing the error message and other details.
   */
  setError(error) {
    const { details } = store.getState().project.project.construction.payload.construction
    const tempDetails = details.find(detail => detail.id === this.id)

    const cutouts = [...this.rectangles, ...this.circles, ...this.uShapes, ...this.mills];
    let message = null;

    if (error.response?.data?.error?.handler === "detail"
        || error.response?.data?.error?.handler === "edges") {
      message = `${error.response.data.error.message} ${Languages.getTranslation('detail', true)} [${error.response.data.error.indexDetail + 1}]`;
    } else {
      if (error.response?.data.error.indexOperation && cutouts.length > 0) {
        const index = 0
          switch (error.response?.data.error.handler) {
            case "grooves": //Паз
              message = `${ error.response?.data.error.message } ${Languages.getTranslation("groove",true)} 
              [${error.response?.data.error.indexOperation.map((item) => Number(item) + 1).join(",")}]`;

              this["grooves"][index].isErrorText = message;
              break;
            case "holes": //Отвір
              message = `${ error.response.data.error.message } ${Languages.getTranslation("hole",true)} 
              [${error.response.data.error.indexOperation.map((item) => Number(item) + 1).join(",")}]`;

              this["holes"][index].isErrorText = message;
              break;
            case "quarters": //Четверть
              message = `${error.response.data.error.message} ${Languages.getTranslation("rabbet",true)} 
              [${error.response.data.error.indexOperation.map((item) => Number(item) + 1).join(",")}]`;

              this["rabbets"][index].isErrorText = message;
              break;
            case "corners": //Кут
              message = `${ error.response.data.error.message } ${Languages.getTranslation("corner",true)} 
              [${error.response.data.error.indexOperation.map((item) => Number(item) + 1).join(",")}]`;

              this["corners"][index].isErrorText = message;
              break;
            case "cutouts": //Виріз
              switch (error.response.data.error.nameOperation) {
                case "rect": //Прямокутний виріз
                  message = `${ error.response.data.error.message } ${Languages.getTranslation("rectangle-proc",true)} 
                  [${error.response.data.error.indexOperation.map((item) => Number(item) + 1).join(",")}]`;

                  cutouts[index].isErrorText = message;
                  break;
                case "ushape": //П-подібний виріз
                  message = `${ error.response.data.error.message } ${Languages.getTranslation("uShape-proc",true)}
                   [${error.response.data.error.indexOperation.map((item) => Number(item) + 1).join(",")}]`;

                  cutouts[index].isErrorText = message;
                  break;
                case "lshape": //Г-подібний виріз
                  message = `${ error.response.data.error.message } ${Languages.getTranslation("lShape-proc",true)} 
                  [${error.response.data.error.indexOperation.map((item) => Number(item) + 1).join(",")}]`;

                  cutouts[index].isErrorText = message;
                  break;
                case "circle": //круглий виріз
                  message = `${ error.response.data.error.message } ${Languages.getTranslation("circle-proc",true)} 
                  [${error.response.data.error.indexOperation.map((item) => Number(item) + 1).join(",")}]`;

                  cutouts[index].isErrorText = message;
                  break;
              }
              break;
          }
      }
    }

    if (message) {
      let flagThis = true, flagTemp = true

      const thisErrors = this.error.allErrorsMessage
      for(let i = 0; i < thisErrors.length; i++){
        if(thisErrors[i].indexOf(message) >= 0){
          flagThis = false
          break
        }
      }
      flagThis && this.error.addError(message, 'error')

      if(window.location.pathname === '/processes'){
        const tempErrors = tempDetails.error.allErrorsMessage
        for(let i = 0; i < tempErrors.length; i++){
          if(tempErrors[i].indexOf(message) >= 0){
            flagTemp = false
            break
          }
        }
        flagTemp && tempDetails.error.addError(message, 'error')
      }
    }
  }

  /**
   * Returns a detail object containing validated data for the current instance.
   *
   * @return {object} - The detail object containing validated data.
   */
  get dataForValidate() {
    const detail = {};
    detail.material = this.parent.materials.findIndex(el => el.index === this.material)
    detail.width = this.h;
    detail.height = this.l;
    if(!empty(this.multiplicity)) {
      detail.multiplicity.material = this.parent.materials.find(el => el.index === this.multiplicity.material)
      if(!empty(this.multiplicity.edge)) {
        detail.multiplicity.edge = !empty(this.multiplicity.edge) ? this.parent.edges.findIndex(el => el.index === this.multiplicity.edge): null;
      }
    }

    detail.handlers = {};
    detail.handlers.holes = this.holes.map((hole) => hole.dataForValidate);
    detail.handlers.grooves = this.grooves.map(
      (groove) => groove.dataForValidate
    );
    detail.handlers.quarters = this.rabbets.map(
      (rabbet) => rabbet.dataForValidate
    );
    detail.handlers.cutouts = [
      ...this.rectangles,
      ...this.circles,
      ...this.uShapes,
      ...this.mills.filter(el => ['gEdge', 'uShape'].includes(el.type)),
    ].map((item) => item.dataForValidate);
    detail.handlers.edges = {};
    detail.handlers.corners = this.corners
      .filter((corner) => ((corner.x && corner.y) || corner.r) && corner.type)
      .reduce((cornersAccumulator, current) => {
        if (!cornersAccumulator.find((item) => item.angle === current.angle)) {
          cornersAccumulator.push(current);
        }
        return cornersAccumulator;
      }, [])
      .map((corner) => corner.dataForValidate);
    detail.handlers.arcs = [];
    detail.handlers.smiles = [];
    for (let side of ["left", "right", "top", "bottom"]) {
      const edge = this[`${side}Edge`];
      if (edge != null) {
        const edgeData = this.parent.edges.find(e => e.index === Number(edge));
        if (edgeData) {
          detail.handlers.edges[side] = {
            thickness: edgeData.t || edgeData.thickness,
            width: edgeData.w || edgeData.width,
          };
        }
      }
    }
    return detail;
  }

  async validateDetail() {
    return await this.validate();
  }

  clearHandlers() {
    for (let item of [
      ...this.grooves,
      ...this.rectangles,
      ...this.circles,
      ...this.rabbets,
      ...this.holes,
      ...this.bevels,
      ...this.mills
    ]) {
      const shape = item.shape;
      shape ? shape.remove() : item.remove();
    }
  }

  updateDbContour(contour) {
    contour.forEach((cont) => {
      if(empty(cont)) return;
      cont.length = cont.type === 'line' ? Helpers.calculateDistance(cont.x1, cont.y1, cont.x2, cont.y2) : Helpers.calculateArcLength(cont.r, cont.startAngleRad, cont.endAngleRad)
    })
    detailsDb.updateDetail(this.id, 'contour3d', contour)
  }

  remove3dObj(scene, obj) {
    if(!empty(obj.material)) {
      obj.material.dispose()
    }
    if(!empty(obj.geometry)) {
      obj.geometry.dispose()
    }
    if(!empty(obj.children)) {
      obj.children.forEach(el => this.remove3dObj(scene, el))
    } else {
      scene.remove(obj)
    }
  }

  ifIsTableTop() {
    return this.parent.materials.find(el=> el.index === this.material)?.type === "Постформинг"
  }

  createContour() {

    const corners = this.corners
      .filter((corner) => ((corner.x && corner.y) || corner.r) && corner.type)
      .reduce((cornersAccumulator, current) => {
        if (!cornersAccumulator.find((item) => item.angle === current.angle)) {
          cornersAccumulator.push(current);
        }
        return cornersAccumulator;
      }, []);
    const shapesForContour = [];
    for (let item of [
      ...this.mills
    ]) {
      shapesForContour.push(item);
    }
    const contour = new Contour({
      h: this.h,
      l: this.l,
      corners,
      contour: [],
      shapes: shapesForContour,
      cutouts: this.cutouts ?? [],
      bevels: this.bevels ?? [],
      edges: this.edges,
      detail: this,
      updateContour: (cont) => this.updateDbContour(cont)
    })
   // this.currentContour = [...contour]
    contour.contour = Helpers.addContourSides(contour.contour, this.l)
    return {contour, corners};
  }

  getCutoutsData() {
     return this.rectangles.map(rect => rect.cutOut)
  }

  enableEditSide(side = null, type = null) {
    if(!empty(side)) {
      if(!empty(type)) {
        this.editableSides[side][type] = true;
      } else {
        for (let t in this.editableSides[side]) {
          this.editableSides[side][t] = true;
        }
      }
    } else {
      if(empty(type)) {
        for(let s in this.editableSides) {
          for (let t in this.editableSides[s]) {
            this.editableSides[s][t] = true;
          }
        }
      } else {
        for(let s in this.editableSides) {
          this.editableSides[s][type] = true;
        }
      }
    }
  }

  checkProcessingSingle(side, type) {
    if(empty(this[type])) return ;
    return !empty(this[type].find(el => el.side === side))
  }

  checkProcessing(side, type=null, id=null) {
      const holes = this.holes.find(el => el.side === side && (type === 'hole' ? id !== el.id : true));
      const corners = [
        ...this.corners.filter(el => el.angle.split('_').includes(side) && (type === 'corner' ? id !== el.id : true)),
        ...this.mills.filter(el => ['gEdge', 'radiusEdge'].includes(el.type) && el.angle.split('_').includes(side) && (type === 'mill' ? id !== el.id : true))
      ];
      const mills = this.mills.filter(el => !['gEdge', 'radiusEdge'].includes(el.type) && el.edgeSide === side && (type === 'mill' ? id !== el.id : true));
      const rects = [
        ...this.grooves.filter(el => el.side === side && (type === 'rect' ? id !== el.id : true)),
        ...this.rabbets.filter(el => el.edgeSide === side && (type === 'rect' ? id !== el.id : true))
      ];
      const bevels = this.bevels.find(el => el.edgeSide === side && (type === 'mill' ? id !== el.id : true));
      return !empty(mills) || !empty(corners) || !empty(holes) || !empty(rects) || !empty(bevels);
  }

  disableProcessing(side, type = 'processing') {
    switch (type) {
      case 'edge':
        this.editableSides[side].edge = false;
        break;
      case "processing":
        const edgeProcessing = ['mill', 'rect', 'hole', 'corner', 'bevel'];
        edgeProcessing.forEach(el => this.editableSides[side][el] = false);
        break;
      default:
        this.editableSides[side][type] = false;
    }

  }

  /**
   * Checks if pre-cutting is needed for the current detail.
   * Updates the pre-cutting values and saves them to the detail database.
   * @return {void}
   */
  checkIfPreCuttingNeeded() {
    const preCutting = {
      left: null,
      right: null,
      top: null,
      bottom: null
    };
    this.parent.updateDetail(this.id, 'increase', true)

    this.templates.forEach((template) => {
      if (template.templateId === 'tableTop' && template.templateData.edgeSide === 'left') {
        preCutting.left = 6.1;
      } else  if (template.templateId === 'tableTop' && template.templateData.edgeSide === 'right'){
        preCutting.right = 6.1;
      }
    })

    this.templates.forEach((template) => {
      if (['compactLock2', "compactLock3", "compactLock1"].includes(template.templateId) && template.templateData.edgeSide === 'left') {
        preCutting.left = 1;
      } else  if (['compactLock2', "compactLock3", "compactLock1"].includes(template.templateId) && template.templateData.edgeSide === 'right'){
        preCutting.right = 1;
      }
    })

    const shapes = [
      ...this.grooves.map(el => {
        if('additionalCutter' in el && el.additionalCutter) { return el }
      }),
      ...this.mills,
      ...this.circles,
      ...this.rectangles,
      ...this.rabbets,
      ...this.cutouts,
      ...this.corners
    ]

    this.enableEditSide();
    let minSize = this.h > this.l ?  [70, 30] : [30, 70], sawSize = 10;
    let minBaseVer = [220, 140], minBaseGor = [100, 350], minStep = 2;
    let min = true, newSizeY, newSizeX;
    const errors = [];

    if(!empty(this.holes)) {
      if(this.h > this.l) {
        /**
         * Calculates the minimum size of an array.
         *
         * @param {Array} array - The input array.
         * @returns {number} The minimum size of the array.
         * @throws {TypeError} If the input is not an array.
         */
        minSize = [200, 70];
        if(this.checkProcessingSingle('top', 'holes')
          && this.checkProcessingSingle('bottom', 'holes')
          && this.h < minSize[0]
        ) {
          /**
           * Calculates the minimum size from the given array of sizes.
           *
           * @param {number[]} sizes - The array of sizes.
           * @returns {number} - The minimum size.
           */
          minSize = [70, 200];
        }
      } else {
        /**
         * Calculates the minimum size from an array of numbers.
         *
         * @param {number[]} array - The array of numbers to calculate the minimum size from.
         * @returns {number} The minimum size from the array.
         */
        minSize = [70, 200];
        if(this.checkProcessingSingle('left', 'holes')
          && this.checkProcessingSingle('right', 'holes')
          && this.l < minSize[1]
        ){
          /**
           * Calculates the minimum size of an input array.
           *
           * @param {Array} array - The input array to calculate the minimum size of.
           * @returns {number} The minimum size of the input array.
           */
          minSize = [200, 70];
        }
      }
      if((this.l < minSize[1] || this.h < minSize[0])
        && this.checkProcessingSingle('left', 'holes')
        && this.checkProcessingSingle('right', 'holes')
        && this.checkProcessingSingle('top', 'holes')
        && this.checkProcessingSingle('bottom', 'holes')
      ) {
        errors.push('Мінімальні розміри деталі для сверління 210*80, приберіть обробки з однієї із сторін для застосування послуги "підрізка в розмір"');
      }
      /**
       * Calculates the minimum value from an array of numbers.
       *
       * @param {number[]} numbers - The array of numbers to find the minimum value from.
       * @returns {number} - The minimum value from the array of numbers.
       */
      min = false;
    }

    if(!empty(this.bevels)) {
      if(this.h > this.l) {
        /**
         * Calculates the minimum value among a given set of numbers.
         *
         * @param {...number} numbers - The numbers to find the minimum from.
         * @returns {number} The minimum value among the given numbers.
         */
        minSize = [200, 70];
        if(this.checkProcessingSingle('top', 'bevel')
          && this.checkProcessingSingle('bottom', 'bevel')
          && this.h < minSize[0]
        ) {
          /**
           * Returns the minimum value from an array or a string.
           *
           * @param {Array|string} data - The array or string to find the minimum value from.
           * @returns {*} - The minimum value from the given array or string.
           */
          minSize = [70, 200];
        }
      } else {
        /**
         * Returns the minimum size among the provided sizes.
         *
         * @param {number[]} sizes - An array of sizes.
         * @returns {number} - The minimum size.
         * @throws {Error} - If the sizes array is empty.
         */
        minSize = [70, 200];
        if(this.checkProcessingSingle('left', 'bevel')
          && this.checkProcessingSingle('right', 'bevel')
          && this.l < minSize[1]
        ){
          /**
           * Finds the minimum value within an array or string.
           *
           * @param {Array|string} input - The array or string to search for the minimum value.
           * @returns {*} - The minimum value found within the input.
           * @throws {TypeError} - If the input is not an array or string.
           * @throws {Error} - If the input is empty.
           *
           * @example
           * // Returns 2
           * minSize([5, 2, 7, 10]);
           *
           * // Returns 1
           * minSize('Hello');
           *
           * // Throws TypeError: Input must be an array or string
           * minSize({ a: 1, b: 2 });
           *
           * // Throws Error: Input must not be empty
           * minSize([]);
           */
          minSize = [200, 70];
        }
      }
      if((this.l < minSize[1] || this.h < minSize[0])
        && this.checkProcessingSingle('left', 'bevel')
        && this.checkProcessingSingle('right', 'bevel')
        && this.checkProcessingSingle('top', 'bevel')
        && this.checkProcessingSingle('bottom', 'bevel')
      ) {
        errors.push('Мінімальні розміри деталі для сверління 210*80, приберіть обробки з однієї із сторін для застосування послуги "підрізка в розмір"');
      }
      /**
       * Calculates the minimum value from an array of numbers.
       *
       * @param {number[]} numbers - The array of numbers to find the minimum from.
       * @returns {number} - The minimum value found in the array.
       */
      min = false;
    }

    // if(empty(shapes)){
    //   if(this.l > this.h && (!empty(this.edges.top) || !empty(this.edges.bottom))){
    //     minSize[0] = 70
    //   }
    //   if(this.h > this.l && (!empty(this.edges.left) || !empty(this.edges.right))){
    //     minSize[1] = 70
    //   }
    // }

    if(!empty(errors)) {
      errors.forEach(err => this.error.addError(err, 'error'));
    } else {
      if (this.l < minSize[1]) {
        const findOffset = minSize[1] - this.l + sawSize
        const offsetLess70 = 70 - findOffset
        if (this.checkProcessing('left') && !empty(this.edges.left)) {
          if (!this.checkProcessing('right')) {
            preCutting.right = this.l < 70 ? findOffset + offsetLess70 : findOffset;
            this.disableProcessing('right');
          }
        } else {
          if (this.checkProcessing('right') && !empty(this.edges.right)) {
            this.disableProcessing('left');
            preCutting.left = this.l < 70 ? findOffset + offsetLess70 : findOffset;
          } else if (!this.checkProcessing('right') && this.checkProcessing('left') /*&& empty(this.edges.right)*/) {
            this.disableProcessing('right');
            preCutting.right = this.l < 70 ? findOffset + offsetLess70 : findOffset;
          } else {
            preCutting.left = this.l < 70 ? findOffset + offsetLess70 : findOffset;
          }
        }
      }
      if (this.h < minSize[0]) {
        if (this.checkProcessing('bottom')) {
          if (!this.checkProcessing('top')) {
            preCutting.top = minSize[0] - this.h + sawSize;
            this.disableProcessing('top');
          }
        } else {
          if (this.checkProcessing('top')) {
            this.disableProcessing('bottom');
          }
          const findOffset = minSize[0] - this.h + sawSize
          const offsetLess70 = 70 - findOffset
          if (!empty(this.edges.bottom) && !this.checkProcessing('top')) {

            preCutting.top = this.h < 70 ? findOffset + offsetLess70 : findOffset;
          } else {
            if (!this.checkProcessing('bottom')) {
              preCutting.bottom = this.h < 70 ? findOffset + offsetLess70 : findOffset;
            }
          }
        }
      }
    }

    if(shapes.length !== 0 && (this.h <= 500 || this.l <= 500)){
      findEmptySpace(this, this.h, this.l);
      const tempPreCutting = {...toAddSide(this)};
      const {left, right, top, bottom} = tempPreCutting;

      if([left, right, top, bottom].every(space => empty(space))){
        this.preCutting = preCutting;
      }else{
        this.preCutting = tempPreCutting;
      }

      this.detailDb.updateDetail('preCutting', this.preCutting);
    }else{
      this.preCutting = preCutting;
      this.detailDb.updateDetail('preCutting', this.preCutting);
    }
  }

  deleteDetailFromScene(sceneModel) {
    if(!empty(sceneModel.scene.children)) {
      sceneModel.scene.children.forEach(el => {
        if(el.isGroup || el.isMesh) {
          el.children.forEach(obj => {
            this.remove3dObj(sceneModel.scene, obj)
          })
          sceneModel.scene.remove(el)
        }
      })
    }
  }

  renderDetailWithCenterCamera() {
    const sceneModel = store.getState().project?.project?.sceneModel?.payload?.sceneModel;

    clearTimeout(this.detailRenderTimeout);
    this.detailRenderTimeout = setTimeout(() => {
      this.render();
      if (sceneModel) {
        const maxSize = Math.max(this.l, this.h);
        const Z = maxSize / (2 * Math.tan((Math.PI * 40) / 360)) + 160;
        sceneModel.getCenter(Z)
      }
    },10)
  }

  fullRender() {
    clearTimeout(this.detailRenderTimeout);
    this.detailRenderTimeout = setTimeout(() => {
      this.render();
    },50)
  }

  render() {
    const sceneModel = store?.getState().project?.project?.sceneModel?.payload?.sceneModel;

    if (!sceneModel) {
      return null
    }
    sceneModel.shouldRender = false;
    const shapes = [];
    const showSizesShapes = [];
    this.deleteDetailFromScene(sceneModel)

    for (let item of [
      ...this.grooves,
      ...this.rectangles,
      ...this.circles,
      // ...this.uShapes,
      ...this.rabbets,
      // ...this.bevels
    ]) {
      item.meshes = []
      shapes.push(item);
    }

    for (let item of [
      ...this.grooves,
      ...this.rectangles,
      ...this.circles,
      ...this.rabbets,
      ...this.mills
    ]) {
      showSizesShapes.push(item);
    }



    const detail = new THREE.Group();

    this.multiplicityClass = new Multiplicity( {
      l: this.l,
      h: this.h,
      multiplicity: this.multiplicity,
      getMaterialThickness: this.getMaterialThickness,
      material: this.material,
      w: this.w,
      holes: this.holes,
      parent: this.parent
    });

    const { contour, corners } = this.createContour()

    for (let SIDE of detailSidesConfig) {
      const rotation = [...SIDE.rotation];
      const scale = SIDE.scale({ w: this.w, h: this.h, l: this.l });

      let mesh;
      if (SIDE.userData.side === "front" || SIDE.userData.side === "back") {

        mesh = new Face3D({
          w: this.w,
          h: scale[1],
          l: scale[0],
          multiplicityClass: this.multiplicityClass,
          shapes,
          side: SIDE.userData.side,
          corners,
          contour,
          bevels: this.bevels.filter(bevel => bevel.side === SIDE.userData.side),
          cutOuts: this.cutouts,
          holes: [...this.holes.filter(hole => hole.side === SIDE.userData.side || ['front', 'back'].includes(hole.side) && hole.depth + hole.multiplicity >= this.w)]
        }).mesh;
        mesh.rotation.set(rotation[0], rotation[1], rotation[2]);
      } else {
        mesh = new ContourEdges({
          h: this.h,
          l: this.l,
          w: this.w,
          shapes: shapes.filter(shape => shape.side === SIDE.userData.side),
          corners,
          bevels: this.bevels,
          side: SIDE.userData.side,
          contour,
          holes: [
            ...this.holes.filter(hole => hole.side === SIDE.userData.side)
          ],
          edges: this.parent.edges,
          multiplicityClass: this.multiplicityClass
        }).mesh
        if(mesh) {
          mesh.position.set(-this.l / 2, -this.h / 2, 0);
        }

      }
      // if(mesh) {
      // mesh.userData.isDetail = true;
      if(mesh) {
        detail.add(mesh);
      }

      // }

    }

    this.showMainArrowXBottom(false);
    this.showMainArrowYLeft(false);

    for (let corner of corners) {
      if(corner.showArrow) {
        detail.add(corner.addSizesArrows())
      }
    }

    for (let sizes of showSizesShapes) {
      if(sizes.showArrow) {
        detail.add(sizes.addSizesArrows())
      }
    }
    for (let mill of this.mills) {
      if(mill.showArrow && mill.type !== "tableTop") {
        detail.add(mill.addSizesArrows())
      }
    }
    for (let cutout of this.cutouts) {
      if(cutout.showArrow && cutout.type !== "cutout") {
        detail.add(cutout.addSizesArrows())
      }
    }

    //TODO - для отображение милсов с базиса

    // for (let mill of this.mills) {
    //   mill.elements.forEach(el => {
    //     let mesh;
    //     switch (el.type) {
    //       case 'line': mesh = Helpers.getLineMesh({...el, w: this.w}, 'red');
    //         mesh.position.x = - (this.l / 2 - el.x1);
    //         mesh.position.y = - (this.h / 2 - el.y1);
    //         mesh.rotation.z = Math.atan2(el.y2-el.y1, el.x2-el.x1) - Math.PI / 2
    //         mesh.position.z = this.w / 2;
    //         break;
    //       case 'arc': mesh = Helpers.getArcMesh({...el, w: this.w}, 'red');
    //         mesh.position.x = -this.l / 2;
    //         mesh.position.y = -this.h / 2;
    //         mesh.position.z = -this.w / 2;
    //         break;
    //     }
    //
    //     detail.add(mesh)
    //   })
    // }

    //віддзеркалювання
    if (this.dataForAnimations) {
      sceneModel.shouldRender = true;
      const line = this.createMirrorDirectionLine()
      if (this.dataForAnimations.xMirrorLine) {
        const textMesh = SizeArrow.getTextMesh(`*${Languages.getTranslation('clone-hor')}*`.toUpperCase(), Helpers.getHelpersFontSize({l: this.l, h: this.h}) + 3)
        textMesh.position.set(-this.l/2,-this.h / 2 - 25, 1 )
        Animations.smoothPositionForMesh(line,{duration: 0.5, x: line.position.x, y: this.h / 2, z: line.position.z})
        detail.add(textMesh)
      } else {
        const textMesh = SizeArrow.getTextMesh(`*${Languages.getTranslation('clone-ver')}*`.toUpperCase(), Helpers.getHelpersFontSize({l: this.l, h: this.h}) + 3)
        textMesh.position.set(-this.l/2,-this.h / 2 - 25, 1 )
        Animations.smoothPositionForMesh(line,{duration: 0.5, x: this.l / 2, y: line.position.y, z: line.position.z})
        detail.add(textMesh)
      }
      line.children.forEach((child) => {
          Animations.gsapAnimationForMesh(child.material, null, {opacity: 0.1, repeat: -1, yoyo: true, duration: 0.3})
      })
      Animations.gsapAnimationForMesh(line.material, null,{opacity: 0.1, repeat: -1, yoyo: true, duration: 0.3,})
      detail.add(line)


    }

    //create dashed line
    for (let hole of this.holes.filter(el => el.isSelected)) {
      if (hole.pointsAfterMove) {
        sceneModel.shouldRender = true;

        const line = this.createDashedMovement(hole)
        const shadowProc = hole.mesh
        const {x, y, z} = line.userData
        shadowProc.material = Helpers.getMaterial3D()
        shadowProc.children[0].material.transparent = true
        Animations.smoothPositionForMesh(shadowProc,{duration: 0.5, x, y, z})
        Animations.gsapAnimationForMesh(line.material, null,{opacity: 0.1, repeat: -1, yoyo: true, duration: 0.3,})
        Animations.gsapAnimationForMesh(shadowProc.children[0].material, null, {opacity: 0.1, repeat: -1, yoyo: true, duration: 0.3})

        detail.add(shadowProc)
        detail.add(line)
      }
    }

    this.validateEdges()

    for (let hole of this.holes) {
      if(hole.showArrow) {
        detail.add(hole.addSizesArrows())
      }
    }

    if(this.isRotateTexture) {
      detail.add(Helpers.getTextureMesh(this.l, this.h, this.w, 'front'))
      detail.add(Helpers.getTextureMesh(this.l, this.h, this.w, 'back'))
    } else {
      // detail.add(Helpers.getTextureMesh(this.l, this.h, this.w, 'front', false))
      // detail.add(Helpers.getTextureMesh(this.l, this.h, this.w, 'back', false))
    }

    this.checkIfPreCuttingNeeded();

    for (let preCutting in this.preCutting) {
      if(!empty(this.preCutting[preCutting])) {
        let l = 0, h = 0, cutMesh, deltaL = 0, deltaX = 0;

        switch (preCutting) {
          case 'left':
            l = this.preCutting[preCutting];
            h = this.h;

            cutMesh = Helpers.getCubeMesh(h, l, this.w);
            cutMesh.position.set( -this.l / 2 - l / 2, 0, 0);
            break;
          case 'right':
            l = this.preCutting[preCutting];
            h = this.h;
            cutMesh = Helpers.getCubeMesh(h, l, this.w);
            cutMesh.position.set(this.l / 2 + l / 2, 0, 0);
            break;
          case 'top':
            if(!empty(this.preCutting.left)) {
              deltaL = this.preCutting.left;
            }
            if(!empty(this.preCutting.right)) {
              deltaL = this.preCutting.right;
              deltaX = deltaL;
            }
            l = this.l + deltaL;
            h = this.preCutting[preCutting];

            cutMesh = Helpers.getCubeMesh(h, l, this.w);
            cutMesh.position.set(this.l / 2 - l / 2 + deltaX, this.h / 2 + h / 2, 0);
            break;
          case 'bottom':
            if(!empty(this.preCutting.left)) {
              deltaL = this.preCutting.left;
            }
            if(!empty(this.preCutting.right)) {
              deltaL = this.preCutting.right;
              deltaX = deltaL;
            }
            l = this.l + deltaL;
            h = this.preCutting[preCutting];

            cutMesh = Helpers.getCubeMesh(h, l, this.w);
            cutMesh.position.set(this.l / 2 - l / 2 + deltaX, -this.h / 2 - h / 2, 0);
            break;
        }

        detail.add(cutMesh);
      }
    }

    //стрелки для отображения закругления
    if(this.isPostForming && !this.soft.top) {
      const strangeArrowSize = Math.max(this.l / 32, this.h / 16);
      const stolText = SizeArrow.getTextMesh(Languages.getTranslation('rounding', true), Helpers.getHelpersFontSize({l: this.l, h: this.h}))
      const strangeArrow = Helpers.getStrangeArrow(strangeArrowSize)
      stolText.position.y = this.h / 2 + strangeArrowSize + 50 + strangeArrowSize / 8;
      strangeArrow.position.y = this.h / 2 + strangeArrowSize + 50;
      stolText.position.x = -this.l / 2 + 50;
      strangeArrow.position.x = -this.l / 2 + 50;
      strangeArrow.rotation.x = Math.PI
      stolText.position.z = this.w / 2 + 3;
      strangeArrow.position.z = this.w / 2 + 5;
      stolText.name = 'stolTextUp'
      strangeArrow.name = 'strangeArrowUp'
      detail.add(stolText)
      detail.add(strangeArrow)
    }
    if(this.isPostForming && !empty(this.parent.materials.find(el => el.index === this.material).double_rounding) && !this.soft.bottom) {
      const strangeArrowSize = Math.max(this.l / 32, this.h / 16);
      const stolText = SizeArrow.getTextMesh(Languages.getTranslation('rounding', true), Helpers.getHelpersFontSize({l: this.l, h: this.h}))
      const strangeArrow = Helpers.getStrangeArrow(strangeArrowSize)
      stolText.position.y = -this.h / 2 - strangeArrowSize - 50 - strangeArrowSize / 2;
      strangeArrow.position.y = -this.h / 2 - strangeArrowSize - 50;
      stolText.position.x = -this.l / 2 + 50;
      strangeArrow.position.x = -this.l / 2 + 50;
      strangeArrow.rotation.x = Math.PI * 2
      stolText.position.z = this.w / 2 + 3;
      strangeArrow.position.z = this.w / 2 + 5;
      stolText.name = 'stolTextDown'
      strangeArrow.name = 'strangeArrowDown'
      detail.add(stolText)
      detail.add(strangeArrow)
    }

    this.mesh = detail;
    // this.mesh.userData.detail = this;
    // this.mesh.userData.detailItem = this.item;

    for (let hole of this.holes) {
      this.mesh.add(hole.mesh);
    }

    const axesHelper = new AxesHelper(100);
    axesHelper.name = 'axesHelper';

    axesHelper.position.set(-this.l / 2, -this.h / 2, -this.w / 2);
    this.mesh.add(axesHelper);

    const lArrow = SizeArrow.addSizeArrow({_size: this.l, fontSize:  Helpers.getHelpersFontSize({l: this.l, h: this.h})});
    const posArrowY = this._showMainXArrow === 'bottom' ? -this.h / 2 - 50 : this.h / 2 + 50;
    lArrow.position.set(-this.l / 2, posArrowY, this.w / 2 + 1);
    lArrow.name = 'lArrow'
    this.mesh.add(lArrow)
    const posArrowX = this._showMainYArrow === 'right' ? this.l / 2 + 50 : -this.l / 2 - 50
    const hArrow = SizeArrow.addSizeArrow({_size: this.h, direction: 'ver', fontSize:  Helpers.getHelpersFontSize({l: this.l, h: this.h})})
    hArrow.position.set(posArrowX, -this.h / 2, this.w / 2 + 1);
    hArrow.name = 'hArrow'
    this.mesh.add(hArrow);

    sceneModel.scene.add(this.mesh);
    sceneModel.renderScene();
    // sceneModel.updateRenderSize();
  }

  async build(isValidate = true) {
    this.calcCSize();
    if (isValidate) return await this.validate();
    return Promise.resolve()
  }


  /**
   * Creates a dashed movement line based on the provided procedure.
   *
   * @param {Procedure} proc - The procedure object.
   * @returns {Line} - The created dashed movement line.
   */
  createDashedMovement(proc) {
    const arr = []
    const {startX, startY, endX, endY} = Helpers.getStartAndEndPointsForProc(proc, this.l, this.h)
    let z
    const zPointBySide = {
      back:  (-this.w / 2 + Number(proc.depth) / 2 + Number(proc.z) / 2) - 2,
      front:  this.w / 2 + 2,
      left: -this.w / 2 + proc.z,
      right: -this.w / 2 + proc.z,
      top: -this.w / 2 + proc.z,
      bottom: -this.w / 2 + proc.z
    }
    z = zPointBySide[proc.side]

    const startPoints = new THREE.Vector3(startX, startY, z)
    const endPoints = new THREE.Vector3(endX, endY, z)
    arr.push(startPoints)
    arr.push(endPoints)
    const geometry = new THREE.BufferGeometry().setFromPoints(arr);
    const material = new THREE.LineDashedMaterial({
      color: 0x0000,
      linewidth: 1,
      scale: 1,
      dashSize: 10,
      gapSize: 10,
    });
    material.transparent = true

    const line = new THREE.Line(geometry, material)
    line.computeLineDistances();
    line.userData = {
      x: endX,
      y: endY,
      z: z,
    }
    return line
  }

  /*
   * Метод используется для прощетов пильных размеров детали при нанесении кромки
   * */
  calcCSize() {
    let cl = this.l;
    let ch = this.h;
    const cConst = 0.5; //константа префуговки при прямолинейной кромковке
    if (this.corners.length && this.corners.length !== 0) {
      this.corners.forEach((el) => {
        const sides = el._angle.split("_");
        const edgeWidth =
          el._edge && el._edge.thickness ? el._edge.thickness : 0;
        if(!empty(el.type) && el.type === 1 && el.r > 0) {
          sides.forEach((side) => {
            //this.edges[side] = null; //Удалим кромку со сторон детали если применена криволинейная обработка
            if (["top", "bottom"].indexOf(side) !== -1) {
              ch -= edgeWidth;
            } else {
              cl -= edgeWidth;
            }
          });
        }

      });
    }
    for (const edge in this.edges) {
      if (this.edges[edge] !== null) {
        const usedEdge = this.getEdgeData(this.edges[edge]);
        if (usedEdge) {
          if (["top", "bottom"].indexOf(edge) !== -1) {
            ch -= usedEdge.thickness - cConst;
          } else {
            cl -= usedEdge.thickness - cConst;
          }
        }
      }
    }

    // this.cl = cl.toFixed(2);
    //this.ch = ch.toFixed(2);
    // console.log(`Пильные размеры Ширина: ${this.cl}, Длинна: ${this.ch}`); //TODO: Инфа для теста потом удалить!
  }

  /**
   * Creates a mirror direction line.
   * The line represents a mirror direction in a 3D scene.
   * The line is positioned at a specific location and has a specific color.
   *
   * @returns {THREE.Line | null} The mirror direction line if either xMirrorLine or yMirrorLine is true, otherwise null.
   */
  createMirrorDirectionLine() {
    const material = new THREE.LineBasicMaterial({
      color: 0x0000ff
    });
    material.transparent = true

    const points = [];
    if (this.dataForAnimations.xMirrorLine) {
      points.push(new THREE.Vector3(-this.l / 2 - 100, -this.h / 2, (this.w / 2) + 2));
      points.push(new THREE.Vector3(0, -this.h / 2, (this.w / 2) + 2));
      points.push(new THREE.Vector3(this.l / 2 + 100, -this.h / 2, (this.w / 2) + 2));

      const geometry = new THREE.BufferGeometry().setFromPoints( points );

      const line =  new THREE.Line(geometry, material)

      const textMesh = SizeArrow.getTextMesh('X', Helpers.getHelpersFontSize({l: this.l, h: this.h}) + 3)
      const textMesh2 = SizeArrow.getTextMesh('X', Helpers.getHelpersFontSize({l: this.l, h: this.h}) + 3)
      line.add(textMesh)
      line.add(textMesh2)
      textMesh.position.set(this.l / 2 + 80, -this.h / 2 + 10, (this.w / 2) + 2)
      textMesh2.position.set(-this.l / 2 - 90, -this.h / 2 + 10, (this.w / 2) + 2)

      return line
    } else if (this.dataForAnimations.yMirrorLine){
      points.push(new THREE.Vector3(-this.l / 2, -this.h / 2 - 100, (this.w / 2) + 2));
      points.push(new THREE.Vector3(-this.l / 2, 0, (this.w / 2) + 2));
      points.push(new THREE.Vector3(-this.l / 2, this.h / 2 + 100, (this.w / 2) + 2));

      const geometry = new THREE.BufferGeometry().setFromPoints( points );

      const line =  new THREE.Line(geometry, material)

      const textMesh = SizeArrow.getTextMesh('Y', Helpers.getHelpersFontSize({l: this.l, h: this.h}) + 3)
      const textMesh2 = SizeArrow.getTextMesh('Y', Helpers.getHelpersFontSize({l: this.l, h: this.h}) + 3)
      line.add(textMesh)
      line.add(textMesh2)
      textMesh.position.set(-this.l / 2 + 5, -this.h / 2 - 90, (this.w / 2) + 2)
      textMesh2.position.set(-this.l / 2 + 5, this.h / 2 + 90, (this.w / 2) + 2)
      return line
    } else {
      return null
    }
  }

  validateEdges() {
    if (this.mills.some(mill => ['smile', 'tableCorner', 'tableTop'].includes(mill.type))) {
      this.mills.filter(mill =>  ['smile', 'tableCorner', 'tableTop'].includes(mill.type)).forEach((mill) => {
        if (mill.type === 'smile') {
          this.edges = {...this.edges, [mill.edgeSide] : mill.edge}
        }
        if (mill.type === 'tableCorner') {
          if (mill.angle==='left_top') {
            this.edges = {...this.edges, left: mill.edge}
          } else {
            this.edges = {...this.edges, right: mill.edge}
          }
        }
        if (mill.type === 'tableTop') {
          if (mill.edgeSide === 'left') {
            this.edges = {...this.edges, left: null}
          } else if (mill.edgeSide === 'right') {
            this.edges = {...this.edges, right: null}
          }
        }
      })
    }
    if (!empty(this.bevels)) {
      this.bevels.map((bevel => {
        const removeEdgeSide = bevel.z === 0 ? bevel.edgeSide : null
        if(!empty(removeEdgeSide)){
          this.edges = {
            ...this.edges,
            [removeEdgeSide]: null
          };
         }
      }))

    }
    this.detailDb.updateDetail('edges', this.edges)
  }

  updateDetail(name, value){
    return new Promise((resolve, reject) => {
      this.detailDb.updateDetail(name, value)
          .then(() => resolve())
          .catch(error => reject(error));
    })
  }

  get dataForAnimations() {
    return this._dataForAnimations;
  }

  set dataForAnimations(dataForAnimations) {
      this._dataForAnimations = dataForAnimations;
  }

  get materialType() {
    return this.parent.materials.find(el => el.index === this.material).type
  }

  getEdgeData(edgeIndex) {
    const i = Number(edgeIndex);
    const currentEdge = this.parent.edges.find((ed, index) => {
      return ed.index === i;
    });

    return currentEdge || null;
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
  }

  get l() {
    return this._l;
  }

  set l(l) {
    this._l = l;
    // this.validate();
  }

  get cl() {
    return this._cl;
  }

  set cl(cl) {
    this._cl = cl;
  }

  get h() {
    return this._h;
  }

  set h(h) {
    this._h = h;
    // this.validate();
  }

  get detailId() {
    return this._detailId;
  }

  set detailId(detailId) {
    this._detailId = detailId;
    // this.validate();
  }

  get productId() {
    return this._productId;
  }

  set productId(productId) {
    this._productId = productId;
    // this.validate();
  }

  get ch() {
    return this._ch;
  }

  set ch(ch) {
    this._ch = ch;
  }

  // get w() {
  //   return this._w;
  // }

  set w(w) {
    this._w = w;
    // this.validate();
  }

  get id() {
    return this._id;
  }

  set id(id) {
    this._id = id;
  }

  get w() {
    // if(this._w) {
    //   return this._w;
    // } else {
      const material = this.parent.materials.find(el => el.index === this.material);
      let w = this.getMaterialThickness(material);
      if(!empty(this.multiplicity) && !empty(this.multiplicity.type)) {
        w += this.getMaterialThickness(this.multiplicity.material)
      }
      this.w = w;
      return w;
    // }

  }
  updateEdges(edges) {
    return this.detailDb.updateDetail('edges', edges)
  }
  updateSvgBase(imgBase) {
    this.imgBase = imgBase
    return this.detailDb.updateDetail('imgBase', imgBase)
  }
  updateSvgPdf(imgPdf) {
    this.imgPdf = imgPdf
    return this.detailDb.updateDetail('imgPdf', imgPdf)
  }

  get holes() {
    return this._holes;
  }

  set holes(holes) {
    this._holes = holes;
  }

  get arcs() {
    return this._arcs;
  }

  set arcs(arcs) {
    this._arcs = arcs;
  }

  get smiles() {
    return this._smiles;
  }

  set smiles(smiles) {
    this._smiles = smiles;
  }

  get realW() {
    return this.getMaterialThickness(this.material)
  }

  get name() {
    if (this._name.toString().trim() === "") {
      const index = this.parent._details.findIndex((detail) => detail === this);
      return `${Languages.getTranslation('detail', true)} ` + (index + 1);
    }
    return this._name;
  }

  set name(name) {
    this._name = name;
  }

  get detailDescription() {
    return this._detailDescription;
  }

  set detailDescription(detailDescription) {
    this._detailDescription = detailDescription;
  }

  get rightCut() {
    return this._rightCut;
  }

  set rightCut(rightCut) {
    this._rightCut = rightCut;
  }

  get topCut() {
    return this._topCut;
  }

  set topCut(topCut) {
    this._topCut = topCut;
  }

  get bottomCut() {
    return this._bottomCut;
  }

  set bottomCut(bottomCut) {
    this._bottomCut = bottomCut;
  }

  get leftCut() {
    return this._leftCut;
  }

  set leftCut(leftCut) {
    this._leftCut = leftCut;
  }

  get multiplicity() {
    return this._multiplicity;
  }

  set multiplicity(multiplicity) {
    this._multiplicity = multiplicity;
  }

  get count() {
    return this._count;
  }

  set count(count) {
    this._count = count;
  }

  get material() {
    return this._material;
  }

  set material(material) {
    this._material = material;
  }

  get edges() {
    return this._edges;
  }

  set edges(edges) {
    this._edges = edges;
  }

  get isRotateTexture() {
    return this._isRotateTexture;
  }


  set isRotateTexture(isRotateTexture) {
    this._isRotateTexture = isRotateTexture;
  }

  checkGabarites() {
    return this.l != 0 && this.w != 0 && this.h != 0;
  }

  getMaterialThickness(index=null) {
    if(empty(index) || empty(this.parent)) return 0;
    let _ind = index
    if(!empty(index.index)) {
      _ind = index.index;
    }
    const material =  this.parent.materials.find(el => el.index === _ind);
    if(!empty(material)) {
      return Number(material.thickness) || Number(material.t)
    }

  }

  addMultiplicity(multiplicity) {
    if(typeof multiplicity === "object") {
      if(!empty(multiplicity?.material)) {
        multiplicity.material = this.parent.materials[multiplicity.material].index;
        this.w = this.w + this.getMaterialThickness(multiplicity.material)
      }
      this.multiplicity = multiplicity;
    } else if(Number(multiplicity) > 1) {
      this.multiplicity = {
        type: 3,
        edge: null,
        material: this.parent.materials[this.material].index
      };
      this.w = this.w + this.getMaterialThickness(this.multiplicity.material)
    } else {
      this.multiplicity = null;
    }
  }

  updateProcessing(dbIndex, processing) {
    if(empty(this.processingUpdate[dbIndex])) {
      this.processingUpdate[dbIndex] = {};
    }
    if(!empty(this.processingUpdate?.[dbIndex]?.timer)) {
      clearTimeout(this.processingUpdate[dbIndex].timer);
    }
    if(empty(this.processingUpdate[dbIndex].processing)) {
      this.processingUpdate[dbIndex].processing = [];
    }
    this.processingUpdate[dbIndex].processing = [...this.processingUpdate[dbIndex].processing, processing];
    return new Promise((resolve, reject) => {
      if(empty(this.processingUpdate[dbIndex]?.promises)) {
        this.processingUpdate[dbIndex].promises = [];
      }
      this.processingUpdate[dbIndex].promises.push(resolve)
      this.processingUpdate[dbIndex].timer = setTimeout(() => {
        const newData = [];
        this.detailDb.getProcessing(dbIndex)
          .then(dbData => {
            dbData.forEach(el => {
              const upIndex = this.processingUpdate[dbIndex].processing.findIndex(pr => pr.id === el.id);
              if(upIndex !== -1) {
                if(!this.processingUpdate[dbIndex].processing[upIndex]?.isDelete) {
                  newData.push({...this.processingUpdate[dbIndex].processing[upIndex]});
                }
                this.processingUpdate[dbIndex].processing.splice(upIndex, 1)
              } else {
                newData.push({...el});
              }
            })
            this.processingUpdate[dbIndex].processing.forEach(el => {
              if(!el?.isDelete) {
                newData.push(el)
              }
            })
            this.processingUpdate[dbIndex].processing = [];
            return this.detailDb.updateDetail(dbIndex, newData)
              .then(() => {
                this.processingUpdate[dbIndex].promises.forEach(pr => pr())
                this.processingUpdate[dbIndex] = {};
                return Promise.resolve()
              })
          })
          .then(() => resolve(processing.id))
          .catch(e => reject(e))
      }, 200)
    })
  }

  rotateUpdateEdges(direction) {
    const oldEdges = {...this.edges}
    const newEdges = {};
    const sides = ['left', 'top', 'right', 'bottom'];
    let newSide;

    for (const [s, v] of Object.entries(oldEdges)) {
      const newIndex = sides.indexOf(s);
      if(direction) {
        newSide = newIndex !== 3 ? sides[newIndex + 1] : sides[0]
      } else {
        newSide = newIndex !== 0 ? sides[newIndex - 1] : sides[3]
      }
      newEdges[newSide] = v;
    }
    this.edges = {...newEdges};
  }

  rotate(direction = true) {
    if(this.isImported) {
      this.contour = Helpers.rotateElementsArr(this.contour, this.l, this.h, direction);
    }
    const [l, h] = [this.l, this.h];
    [this.l, this.h] = [this.h, this.l];
    this.rotateUpdateEdges(direction)
    return Promise.all([
      ...this.holes,
      ...this.mills,
      ...this.rabbets,
      ...this.grooves,
      ...this.rectangles,
      ...this.bevels,
      ...this.corners,
      ...this.cutouts
    ].map(el => el.rotateDetail(direction, l, h)))
      .then(() => this.detailDb.updateDetail('h', this.h))
      .then(() => this.detailDb.updateDetail('l', this.l))
      .then(() => {
        if(this.isImported) {
          return this.detailDb.updateDetail('contour', this.contour)
        } else {
          return Promise.resolve()
        }
      })
      .then(() => {
        this.checkIfPreCuttingNeeded()
        return this.validate()
      })
      .then(() => {
        this.fullRender()
        return Promise.resolve()
      })
      .catch(e => console.log(e))
  }

  getAvailableProcessing() {
    const checkMaterial = processConfig?.[this.materialType] ? this.materialType : 'default';
    const aProcessing = processConfig?.[checkMaterial]?.processing;
    return aProcessing || {};
  }

  getAvailableFeatures() {
    const checkMaterial = processConfig?.[this.materialType] ? this.materialType : 'default';
    const aFeatures = processConfig?.[checkMaterial]?.features;
    return aFeatures || [];
  }
}

export default Detail;
