import {memo, useCallback} from "react";
import {toast} from "react-toastify";
import Languages from "../../../../../translation/Languages";
import {projectOperations, projectSelectors} from "redux/project";
import {errorMessageOperations} from "redux/errors";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Errors from "../../../../../models/Errors";
import ContourSVGComponent from "../../../Svg";

const _errors = new Errors([]);

const Draw = ({detail}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const construction = useSelector(projectSelectors.getConstruction);

    const setDetailToStore = useCallback((detail) => {
        toast.dismiss()
        if (!detail.checkGabarites()) {
            _errors.setError(`${Languages.getTranslation("Enter-part", true)}`, 'error', true)
            return;
        }

        if (detail.material === null) {
            _errors.setError(`${Languages.getTranslation("material-to-the-part", true)}`, 'error', true)

            return;
        }
        // sceneModel.clear();

        dispatch(projectOperations.setDetail({detail}));
        dispatch(errorMessageOperations.removeAllErrors({message: '', drop: true}));

        detail.build()
            .then(() => navigate(`/processes/${construction.projectData.id}/${detail.order}`));
    }, [dispatch, navigate]);

    return (
        <div className="detailsTab__img cursor"
             onClick={() => setDetailToStore(detail)}>
            <ContourSVGComponent detail={detail} rects={detail.getCutoutsData()} holes={detail.holes}/>
        </div>
    )
};

export default memo(Draw);