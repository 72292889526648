import React, {useEffect} from 'react';
import Checkbox from "../../../Inputs/Input/Checkbox";
import img1 from "../../../../../media/addMaterial-test.png";
import Languages from "../../../../../translation/Languages";
import "./style.scss";

const AddCurrentMaterial = ({currentMaterial, isMaterialFromLink, isShowCheckbox, setMaterial, selectedMaterials, getCurrentMaterial }) => {
    useEffect(() => {
        if(isMaterialFromLink){
            getCurrentMaterial(currentMaterial.article)
        }
    }, []);
    return (
        <div className="material" onClick={() => getCurrentMaterial(currentMaterial.article)}>
            <div className="material__checkbox">
                {isShowCheckbox && (
                    <Checkbox value={currentMaterial} isChecked={isMaterialFromLink} setMaterial={setMaterial}
                          materials={selectedMaterials}/>
                    )}
            </div>
            <div className="material__container">
                <img
                    src={currentMaterial.images[0]?.small}
                    alt="image" className="material__img" />
                <h3 className="material__name">
                    {Languages.getAvaliableTranslation(currentMaterial.languages) || currentMaterial.typename}
                </h3>
            </div>
            <div className="material__data-container">
                <div className="material__data">
                    <span className="material__data-item">
                      {Languages.getTranslation("article", true)}
                    </span>
                    <span className="material__data-item-second">
                      {" "}
                        {currentMaterial.article || currentMaterial.code}
                    </span>
                </div>
                <div className="material__data">
                    <span className="material__data-item">
                      {Languages.getTranslation("height", true)}
                    </span>
                    <span className="material__data-item-second">
                      {" "}
                        {currentMaterial.l}
                    </span>
                </div>
                <div className="material__data">
                    <span className="material__data-item">
                      {Languages.getTranslation("width", true)}
                    </span>
                    <span className="material__data-item-second">
                      {" "}
                        {currentMaterial.w}
                    </span>
                </div>
                <div className="material__data">
                    <span className="material__data-item">
                      {Languages.getTranslation("thickness", true)}
                    </span>
                    <span className="material__data-item-second">
                      {" "}
                        {currentMaterial.thickness}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default AddCurrentMaterial;