import React from "react";
import './style.scss'
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import Languages from "../../../../../translation/Languages";

const WarningModalReplace = ({ text, save, cancel }) => {
  return (
    <div className="modal">
        <div className="modal__content">
            <span className="modal__content-img"/>
            <b className="ahtungModal__body-decription-title">
                {Languages.getTranslation('warning', true)}
            </b>
            <span className="modal__content-text">{text}</span>
            <div className="modal__content-btns">
             <DefaultBtn title={Languages.getTranslation("cancel", true)} handler={cancel} minWidth="100px"/>
             <DefaultBtn title={Languages.getTranslation("submit", true)} handler={save} minWidth="100px"/>
        </div>
        </div>
    </div>

  )
}

export default WarningModalReplace;
