const CONSTANTS = {
    // Цвета для кромок
    colors: [
        {color: "green", name: " 0.6 мм", sign: "<="},
        {color: "orange", name: " 0.8 мм", sign: "<="},
        {color: "lightblue", name: " 1.0 мм", sign: "<="},
        {color: "blue", name: " 2.0 мм", sign: "<"},
        {color: "purple", name: "2.0 мм", sign: "="},
    ],
    keyCodes: {
        enter: 13,
        space: 32,
        top: 38,
        bottom: 40,
        left: 37,
        right: 39
    },
    cutGaps: {
        soft: 20,
        doubleSoft: function () {
            return this.soft * 2
        }
    },
    nodeQuerySelector: {
        tooltipContainer: "#tooltip-container"
    },
    detailsTableModes: {
        list: "list",
        edit: "edit"
    },
    detailFieldNames: {
        NAME: "name",
        WIDTH: "l",
        HEIGHT: "h",
        COUNT: "count",
        MATERIAL: "material",
        TOP: "top",
        BOTTOM: "bottom",
        LEFT: "left",
        RIGHT: "right",
        PRODUCT_ID: "productId"
    },
    firstDetailIndex: 0,
    defaultFieldForFocusInDetail: function () {
        return this.detailFieldNames.NAME;
    },
    defaultFocusedRow: function () {
        return {index: this.firstDetailIndex, field: this.defaultFieldForFocusInDetail()}
    }
};

export default CONSTANTS;
