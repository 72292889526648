import React, {useEffect, useState} from 'react'
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import './style.scss';
import {useDispatch, useSelector} from "react-redux";
import {userSelectors} from "redux/user";
import {projectSelectors} from "redux/project";
import {modalsOperations} from "redux/modals";
import Project from "../../../../models/Project";
import API from "../../../../api/API";
import {empty} from "../../../../helpers/helper";
import Loader from "../../Loader";

const SaveToOwnerProfile = () => {
	const construction = useSelector(projectSelectors.getConstruction);

	const [ownerId, setOwnerId] = useState(null);
	const [value, setValue] = useState('');
	const [nameProj, setNameProj] = useState(construction.projectData.name ?? Languages.getTranslation('new-project', true));
	const [users, setUsers] = useState([]);
	const dispatch = useDispatch();

	const close = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};

	const handleChange = (e) => {
		setValue(e.target.value)
		searchClient()
	}
	const handleChangeProject = (e) => {
		setNameProj(e.target.value)
	}

	const searchClient = async () => {
		const response = await API.Auth.searchUser(value)
		if (response) {
		setUsers(response.data)
		}
	}

	const submit = () => {

		Project.editClientProject(nameProj, ownerId, dispatch)
			.then(()=>close())
			.catch(e => console.log(e))
	}

	const setUserId = (user) => {
		setOwnerId(user.id)
		setValue(user.name)
		setUsers([])
	}

	return (
		<>
			{<div className="saveToOwnerProfile">
				<h2 className="modal-save-project__title">{Languages.getTranslation('save-project-in-userProfile', true)}</h2>
				<div className="modal-save-project__body saveToOwnerProfile__body">
					<div className="modal-save-project__form-control saveToOwnerProfile__name">
						<label
							htmlFor="name"
							className="modal-save-project__form-control-label"
						>
							{Languages.getTranslation('enter-project-name', true)}
						</label>
						<input
							id="name"
							onChange={handleChangeProject}
							name="name"
							value={nameProj}
							type="text"
							maxLength="21"
							className="modal-save-project__form-control-input"
						/>
					</div>
					<div className="modal-save-project__form-control">
						<label
							htmlFor="name"
							className="modal-save-project__form-control-label"
						>
							{Languages.getTranslation('enter-name-or-phone', true)}
						</label>
						<input
							id="name"
							name="name"
							type="text"
							maxLength="21"
							className="modal-save-project__form-control-input"
							onChange={(e) => handleChange(e)}
							value={value}
						/>
					</div>
					<div className="saveToOwnerProfile__users">
						{!empty(users) ? users.map((user) => <div className="saveToOwnerProfile__user" onClick={() => setUserId(user)}>{user.name}</div>) : ''}
					</div>
				</div>
				<div className="modal-save-project__btns saveToOwnerProfile__btns">
					<DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>
					<DefaultBtn title={Languages.getTranslation('save', true)} handler={submit}/>
				</div>
			</div>}
		</>
	)
}
export default SaveToOwnerProfile
