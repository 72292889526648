import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from "./actions";

const initialState = {
  show: true,
};

const loader = createReducer(initialState, {
  [ACTIONS.LoaderStateSwitch.type]: (state, val) => {
    return { ...state, show: val.payload.show };
  },
});

export default combineReducers({
  loader,
});
