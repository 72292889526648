import Languages from "../../../../../translation/Languages";
import {memo, useState} from "react";
import {Checkbox} from "../common";

const SORT_NAMES = {
    COUNT: "count",
    HEIGHT: "height",
    WIDTH: "width",
    MULTIPLICITY: "multiplicity",
    MATERIAL: "material",
    THICKNESS: "thickness",
    TEXTURE: "texture",
    PRODUCTS: "products",
    DEFAULT: "default"
}

const Header = ({filteredActive, isAllDetailsSelected, setAllDetails, sortDetails, hasErrors}) => {
    const [sortType, setSortType] = useState('');

    const styleHeader = (type) => {
        let classes = "detailsTab__header-item detailsTab__data-item pointer"
        if (type === sortType) {
            classes += " detailsTab__header-item_active";
        }
        return classes
    };

    const sortingDetailsList = (type) => {
        switch (type) {
            case SORT_NAMES.COUNT:
                sortDetails("count");
                break;
            case SORT_NAMES.HEIGHT:
                sortDetails("_l");
                break;
            case SORT_NAMES.WIDTH:
                sortDetails("_h");
                break;
            case SORT_NAMES.MULTIPLICITY:
                sortDetails("multiplicity");
                break;
            case SORT_NAMES.MATERIAL:
                sortDetails("material");
                break;
            case SORT_NAMES.THICKNESS:
                sortDetails("w");
                break;
            case SORT_NAMES.TEXTURE:
                sortDetails("isRotateTexture");
                break;
            case SORT_NAMES.PRODUCTS:
                sortDetails("productId");
                break;
            case SORT_NAMES.DEFAULT:
                sortDetails(null);
                break;
            default:
                console.warn("Unknown sort type:", type);
        }

        setSortType(type);
    };

    return (
        <div className="detailsTab__header">
            <Checkbox isSelected={isAllDetailsSelected} onChange={setAllDetails}/>
            <div className="detailsTab__header-item pointer detailsTab__number"
                 onClick={() => sortingDetailsList(SORT_NAMES.DEFAULT)}>№
            </div>
            <div className="detailsTab__header-item detailsTab__img">Ескіз</div>
            <div className="detailsTab__header-item detailsTab__name">
                {Languages.getTranslation("name", true)}
            </div>
            <div className={styleHeader(SORT_NAMES.HEIGHT)} onClick={() => sortingDetailsList(SORT_NAMES.HEIGHT)}>
                {Languages.getTranslation("height", true)}
            </div>
            <div className={styleHeader(SORT_NAMES.WIDTH)} onClick={() => sortingDetailsList(SORT_NAMES.WIDTH)}>
                {Languages.getTranslation("width", true)}
            </div>
            <div className={styleHeader(SORT_NAMES.COUNT)} onClick={() => sortingDetailsList(SORT_NAMES.COUNT)}>
                {Languages.getTranslation("amount", true)}
            </div>
            <div className="detailsTab__header-item detailsTab__data-item">
                {Languages.getTranslation("processes", true)}
            </div>
            <div className={styleHeader(SORT_NAMES.MATERIAL)} onClick={() => sortingDetailsList(SORT_NAMES.MATERIAL)}>
                {Languages.getTranslation("material", true)}
                {filteredActive.isMaterialChecked && <span className="filter-icon"/>}
            </div>
            <div className={styleHeader(SORT_NAMES.THICKNESS)}
                 onClick={() => sortingDetailsList(SORT_NAMES.THICKNESS)}>
                {Languages.getTranslation("thickness", true)}
                {filteredActive.isThicknessChecked && <span className="filter-icon"/>}
            </div>
            <div className={styleHeader(SORT_NAMES.PRODUCTS)}
                 onClick={() => sortingDetailsList(SORT_NAMES.PRODUCTS)}>
                {Languages.getTranslation("products", true)}
                {filteredActive.isThicknessChecked && <span className="filter-icon"/>}
            </div>
            <div className={styleHeader(SORT_NAMES.TEXTURE)} onClick={() => sortingDetailsList(SORT_NAMES.TEXTURE)}>
                {Languages.getTranslation("texture", true)}
                {filteredActive.isTextureChecked && <span className="filter-icon"/>}
            </div>
            <div className="detailsTab__header-item detailsTab__data-item">
                {Languages.getTranslation("edge", true)}
                {filteredActive.isEdgesChecked && <span className="filter-icon"/>}
            </div>
            {hasErrors && <div className="detailsTab__header-item detailsTab__data-item detailsTab__number"></div>}
            <div className="detailsTab__header-item detailsTab__data-item detailsTab__number"></div>
        </div>
    )
}

export default memo(Header);