import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from "./actions";

const initialUserState = {
  token: null,
};

const user = createReducer(initialUserState, {
  [ACTIONS.UserInfo.type]: (state, user) => {
    return {
      ...state,
      user: user,
    };
  },
  [ACTIONS.UserToken.type]: (state, token) => {
    return {
      ...state,
      token: token,
    };
  },
});

export default combineReducers({
  user,
});
