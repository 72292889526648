import Helpers from "./3D/Helpers";
import {empty, isset, prepareObject} from "../helpers/helper";
import SizeArrow from "./3D/components/SizeArrow";
import Processing from "./3D/Processing";
import {Mesh} from "three";
import GEdge from "./Mills/GEdge";
import UShape from "./Mills/UShape";
import Smile from "./Mills/Smile";
import Languages from "../translation/Languages";
import RadiusEdge from "./Mills/RadiusEdge";
import {toastError} from "../helpers/toasts";
import Partial from "./Mills/Partial";
import store from "../redux/store";

const ShapeGeometrySegments = 32;
/*
* Received data
* @parent = link to the Class Detail
* @elements = array of
* */
export default class Mill extends Processing {
	_edge; //Edge
	_edgeSide = 'left';
	_elements = []; //Array
	_mesh = new Mesh();
	_side;
	_corner = false;
	_angle = null;
	_isActive = false;
	_isErrorText = "";
	_type;
	_width;
	_height;
	_mill;
	_offset;
	_depth;
	length;
	quant;
	_doubleSmiles;
	additionalInfo;
	_typeR;
	shouldRemoved = false;

	constructor({
		            elements,
		            detail,
		            x = 100,
		            y = 100,
		            r = 10,
		            height = 100,
		            width = 100,
		            edge = null,
		            angle = "left_bottom",
		            comment = "",
		            type = 'gEdge',
		            subType = 'mill',
		            edgeSide = 'left',
		            offset = 100,
		            dataForConstructor = {},
		            quant = 0,
		            length = 0,
								id,
		            doubleSmiles = false,
		            isErrorText = '',
								additionalInfo = '',
								isInit = false,
								typeR = 'internal'
	            }) {
		super({x, y, detail, r, id, comment, isErrorText, additionalInfo, isInit});
		this._elements = elements;
		this._subType = subType;
		this._angle = angle;
		this._edge = edge;
		this._width = width;
		this._height = height;
		this._type = type;
		this._edgeSide = edgeSide;
		this._typeR = typeR;
		this._offset = offset;
		this._depth = detail.w
		this._dataForConstructor = dataForConstructor;
		this._side = 'front';
		this.quant = quant;
		this.length = length;
		this._doubleSmiles = doubleSmiles;
		this._isErrorText = isErrorText;
		if(empty(this.getDataForConstructor().edgeSide)) {
			this.updateDataForConstructor({name: 'edgeSide', value: this.edgeSide})
		}

		this.switchMill()
		this.addParams()
		if(this?._mill) {
			this._mill?.initRequiredFields()
		}
		// this.detailDb.updateDetailProcessing(this.detail.id, this.id, 'elements', this.mill.elements)
	}



	detectEdgeSide() {
		const x0 = this.elements[0].x1;
		const y0 = this.elements[0].y1;
		const xLast = this.elements[this.elements.length - 1].x2;
		const yLast = this.elements[this.elements.length - 1].y2;
		let xSide = 'middle';
		let ySide = 'middle';
		if (x0 <= this.detail.l && x0 >= this.detail.l - 2.2) {
			xSide = 'right'
		} else if (x0 >= 0 && x0 <= 2.2) {
			xSide = 'left'
			if (xLast >= 0 && xLast <= 2.2) {
				this.edgeSide = 'left';
			}
		}
		if (y0 >= 0 && y0 <= 2.2) {
			ySide = 'bottom';
			if (yLast >= 0 && yLast <= 2.2) {
				this.edgeSide = 'bottom';
			}
		} else if (y0 <= this.detail.h && y0 >= this.detail.h - 2.2) {
			ySide = 'top'
		}
	}

	// fixRadius() {
	// 	if (['gEdge', 'uShape'].includes(this.type)) {
	// 		if (this.r<3) {
	// 			this.r = 3;
	// 			this.updateDb()
	// 		}
	// 	}
	// }


	fixEdgeSide() {
		if (this.x === 0) {
			this._edgeSide = 'left'
		} else if (this.x + this.width === this.detail.l) {
			this._edgeSide = 'right'
		} else if (this.y === 0) {
			this._edgeSide = 'bottom'
		} else if (this.y + this.height === this.detail.h) {
			this._edgeSide = 'top'
		}
		this.initRequiredFields()
	}

	fixType() {
		if (empty(this.type)) {
			this.fixEdgeSide()
			switch (this.edgeSide) {
				case "top" :
				case 'bottom' :
					if (this.x + this.width === this.detail.l) {
						this.type = 'gEdge'
						this.angle = `right_${this.edgeSide}`
					} else if (this.x === 0){
						this.type = 'gEdge'
						this.angle = `left_${this.edgeSide}`
					} else {
						this.type = 'uShape'
					}
				break
				case 'right' :
				case 'left' :
					if (this.y + this.height === this.detail.h) {
						this.type = 'gEdge'
						this.angle = `${this.edgeSide}_top`;
					} else if (this.y === 0){
						this.type = 'gEdge'
						this.angle = `${this.edgeSide}_bottom`;
					} else {
						this.type = 'uShape'
					}
			}
		}
	}

	addParams() {
		const commonVariables = [
			{key: "gEdge", value: Languages.getTranslation('lShape-proc1', true)},
			{key: "uShape", value: Languages.getTranslation('uShape-proc', true)},
			{key: "smile", value: Languages.getTranslation('smile', true)}
		];

		const permissions = store?.getState()?.user?.user?.user?.payload?.user?.permissions;

		const variables = permissions?.includes('KM_radiusAngle')
			? [...commonVariables, {key: "radiusEdge", value: Languages.getTranslation('Radius-angle-with-offset', true)}]
			: commonVariables;

		this.setFormField({
			name: 'type',
			value: this.type,
			label: Languages.getTranslation('type-of-proces', true),
			type: 'select',
			variables
		});
	}

	get paramsSorting() {
		return this.mill.paramsSorting
	}

	get type() {
		return this._type
	}

	remove() {
		const index = this.detail.mills.findIndex(el => el.id === this.id);
		if(index !== -1) {
			this.detail.error.deleteErrorProcessing(this.detail.mills[index].isErrorText)
			this.detail.mills.splice(index, 1);
			if (this.type === 'smile') {
				this.detail.edges[this.edgeSide] = null
			}
		}
		return this.updateDb(true)
			.then(() => this.detailDb.updateDetail('edges', this.detail.edges))
			.then(() => this.buildDetail())
			.then(() => this.renderDetail())
	}

	switchMill() {
		switch (this.type) {
			case 'gEdge' :
				this.setFormField({name: 'x', visible: false});
				this.setFormField({name: 'y', visible: false});
				this.setFormField({name: 'edgeSide', visible: false})
				this.setFormField({name: 'offset', visible: false})
				this.setFormField({name: 'width', value: this.width, type: 'number',
					label: Languages.getTranslation('height', true), additionalParam: []})
				// if (!Object.keys(this._dataForConstructor).length) {
				// 	this.width = this._dataForConstructor.width ?? 100;
				// 	this.height = this._dataForConstructor.height ?? 100;
				// 	this.r = 10;
				// }
				this.mill = new GEdge({
					parent: this,
					detailL: this.detail.l,
					detailH: this.detail.h,
					// elements: this.elements,
					edge: this.edge,
					angle: this.angle,
				})
				this.mill.paramsSorting
				// this.initRequiredFields()
				break
			case 'uShape' :
				this.setFormField({name: 'r', value: this.r, visible: true, type: 'number', additionalParam: []})
				if (!Object.keys(this._dataForConstructor).length) {
					this.x = 160;
					this.y = 0;
					this.r = 10;
					this.width = 100
					this.height = 100
				}
				this.mill = new UShape({
					parent: this,
					detailL: this.detail.l,
					detailH: this.detail.h,
					// elements: this.elements,
					edge: this.edge,
					edgeSide: this.edgeSide
				})
				this.mill.paramsSorting
				// this.initRequiredFields()
				break
			case 'smile' :
				this.setFormField({name: 'width', value: this.width, type: 'number', label: Languages.getTranslation('height', true), additionalParam: []})
				if (!Object.keys(this._dataForConstructor).length) {
					this.x = 160;
					this.y = 0;
					this.width = 222
					this.height = 30

					// this.setCenterForSmile()
				}
				this.mill = new Smile({
					parent: this,
					detailL: this.detail.l,
					detailH: this.detail.h,
					// elements: this.elements,
					edge: this.edge,
					edgeSide: this.edgeSide,
					doubleSmiles: this.doubleSmiles
				})
				this.mill.paramsSorting
				// this.initRequiredFields()
				break
			case 'radiusEdge' :
				// if (!Object.keys(this._dataForConstructor).length) {
				// 	this.width = this._dataForConstructor.width ?? 100;
				// 	this.height = this._dataForConstructor.height ?? 100;
				// 	this.r = 10;
				// }
				this.mill = new RadiusEdge({
					parent: this,
					// elements: this.elements,
					edge: this.edge,
					angle: this.angle,
				})
				this.mill.paramsSorting
				// this.initRequiredFields()
				break
			default:
				this.mill = new Partial({
					parent: this,
					elements: this.elements
				})
		}

	}

	setCenterForSmile() {
		if (this.doubleSmiles) {
			this.offset = 50;
			return
		}
		switch (this.edgeSide) {
			case "left" :
			case 'right' :
				this.offset = this.detail.h / 2 - this.width / 2
				break
			case 'top' :
			case 'bottom' :
				this.offset = this.detail.l / 2 - this.width / 2
				break
		}
	}

	get edgeSide() {
		return this._edgeSide;
	}

	set edgeSide(edgeSide) {
		this._edgeSide = edgeSide;
		this.updateDataForConstructor({name: "edgeSide", value: this.edgeSide})
		switch (edgeSide) {
			case 'top':
				this.x = this.offset
				this.y = this.detail.h - this.heightCalc;
				if (this.type === 'uShape') {
					if (this.width > this.detail.l - this.offset) {
						this.width = this.detail.l - this.offset;
					}
				}
				if (this.type === 'smile') {
					this.setCenterForSmile()
				}
				break;
			case 'bottom':
				this.x = this.offset
				this.y = 0;
				if (this.type === 'uShape') {
					if (this.width > this.detail.l - this.offset) {
						this.width = this.detail.l - this.offset;
					}
				}
				if (this.type === 'smile') {
					this.setCenterForSmile()
				}
				break;
			case 'left':
				this.y = this.offset;
				if (this.type === 'uShape') {
					if (this.height > this.detail.h - this.offset) {
						this.height = this.detail.h - this.offset
					}
				}
				if (this.type === 'smile') {
					this.setCenterForSmile()
				}
				this.x = 0;
				break;
			case 'right':
				this.x = this.detail.l - this.widthCalc
				this.y = this.offset;
				if (this.type === 'uShape') {
					if (this.height > this.detail.h - this.offset) {
						this.height = this.detail.h - this.offset
					}
				}
				if (this.type === 'smile') {
					this.setCenterForSmile()
				}
				break;
		}
		if (this.doubleSmiles) {
			this.offset = 50
		}
		this.updateDataForConstructor({name: 'edgeSide', value: this.edgeSide});
		this.setFormField({name: 'edgeSide', value: this.edgeSide})
		this.initRequiredFields()
		this.renderDetail()
	}

	initRequiredFields() {
		this?.mill?.initRequiredFields()
	}

	get mill() {
		return this._mill
	}

	set mill(mill) {
		this._mill = mill
	}


	set type(type) {
		this._type = type
		if (type === 'radiusEdge') {
			this.x = 50
			this.y = 50
			this.r = 50
		}
		if (type === 'gEdge') {
			this.r = Math.min(this.width, this.height)
		}
		this.setFormField({name: 'type', value: this.type})
		this._dataForConstructor = {}
		this.initRequiredFields()
		this.switchMill()
		if (type === 'smile') {
			if (['top', 'bottom'].includes(this.edgeSide) && this.detail.l < 223) {
				this.edgeSide = "left"
			} else if (['right', 'left'].includes(this.edgeSide) && this.detail.h < 223) {
				this.edgeSide = "bottom"
			}
			this.width = 222
			this.setCenterForSmile()
		}
	}

	get x() {
		return this._x
	}

	set x(x) {
		this._x = Number(x);
		this.fixMultiplicity();
		this.setFormField({name: 'x', value: this.x, label: 'x'})
		this.updateDataForConstructor({name: 'x', value: this.x})
		if (this.type === 'radiusEdge' && Helpers.arcCheckOnRadiusEdge(Helpers.getPointsForRadiusEdgeAngle(this.detail.l, this.detail.h, this.angle, this.x, this.y, this.r))) {
			this.r = Math.max(this.x, this.y)
		}
		this.initRequiredFields()
		// this.buildDetail(this)
		// 	.then(() => this.renderDetail())
		this.renderDetail()
	}

	get y() {
		return this._y
	}

	set y(y) {
		this._y = Number(y);
		this.fixMultiplicity();
		this.setFormField({name: 'y', value: this.y, label: 'y'})
		this.updateDataForConstructor({name: 'y', value: this.y})
		if (this.type === 'radiusEdge' && Helpers.arcCheckOnRadiusEdge(Helpers.getPointsForRadiusEdgeAngle(this.detail.l, this.detail.h, this.angle, this.x, this.y, this.r))) {
			this.r = Math.max(this.x, this.y)
		}
		this.initRequiredFields()
		// this.buildDetail(this)
		// 	.then(() => this.renderDetail())
		this.renderDetail()
	}

	get edge() {
		return this._edge;
	}

	set edge(edge) {
		let newEdge
		typeof edge === 'object' && edge !== null ? newEdge = edge.index : newEdge = edge
		this._edge = newEdge;
		if (this.type !== 'partial' && this.type !== 'closed') {
			if(this.type === 'smile') {
				this.detail.edges = {...this.detail.edges, [this.edgeSide] : this.edge}
			}
		}
		this.renderDetail()
	}

	get r(){
		return this._r
	}

	set r(r){
		if(typeof r === 'number') {
			if(!this.checkRequiredField('r', Number(r))) {
				this._r = Number(r);
				this.updateDataForConstructor({name: 'f_r', value: ''})
				this.setFormField({name: 'r', value: this.r, type: 'number', label: Languages.getTranslation('radius', true)})
			} else {
				this.showErrors()
			}
		} else {
			if(!empty(r)) {
				if (!empty(this._dataForConstructor['f_offset'])
					&& this._dataForConstructor['f_offset'].includes('pr')
					&& r.includes('po')) {
					this.showToastError('refer-each-other')
				} else if (!empty(this._dataForConstructor['f_width'])
					&& this._dataForConstructor['f_width'].includes('pr')
					&& r.includes('pw')) {
					this.showToastError('refer-each-other')
				} else if (!empty(this.formulaCalculation(r))) {
					const calculation = Number(Number(this.formulaCalculation(r)).toFixed(1));
					if(!this.checkRequiredField('r', Number(calculation))) {
						this._r = calculation;
						this.setFormField({
							name: 'r', value: this.r, type: 'number', label: `${Languages.getTranslation('radius', true)} (pr)`, additionalParam: [
								{name: 'formula', label: Languages.getTranslation('formula', true), value: r}]
						})
						this.updateDataForConstructor({name: 'f_r', value: r})

					}else{
						this.showErrors()
					}
				}

			} else {
				this.setFormField({name: 'r', value: this.r, type: 'number', label: Languages.getTranslation('radius', true)})
			}
		}
		this.updateDataForConstructor({name: 'r', value: this.r})
		this.fixMultiplicity();
		this.initRequiredFields()
		this.renderDetail()
	}

	get width() {
		return this._width;
	}

	set width(width) {
		if(typeof width === 'number'){
			if(!this.checkRequiredField('width', width)) {
				this._width = Number(width);
				this.updateDataForConstructor({name: 'width', value: this.width});
				this.updateDataForConstructor({name: 'height', value: this.height});
				if(this.type === 'uShape'){
					this.setFormField({name: 'width', value: this.width});
					this.updateDataForConstructor({name: 'f_width', value: ''})
				}else {
					this.setFormField({name: 'width', value: this.width});
				}
			} else {
				this.showErrors()
			}
		} else {
			if(!empty(width)) {
				if (!empty(this._dataForConstructor['f_offset'])
					&& this._dataForConstructor['f_offset'].includes('pw')
					&& width.includes('po')) {
					this.showToastError('refer-each-other')
				} else if (!empty(this._dataForConstructor['f_r'])
					&& this._dataForConstructor['f_r'].includes('pw')
					&& width.includes('pr')) {
					this.showToastError('refer-each-other')
				} else if (!empty(this.formulaCalculation(width))) {
					const calculation = Number(Number(this.formulaCalculation(width)).toFixed(1));
					if(!this.checkRequiredField('width', calculation)) {
						this._width = calculation;
						if (this.type === 'uShape') {
							this.setFormField({
								name: 'width',
								label: `${Languages.getTranslation('height', true)} (pw)`,
								value: this.width,
								type: 'number',
								visible: true,
								additionalParam: [
									{name: 'formula', label: Languages.getTranslation('formula', true), value: width}]
							});
							this.updateDataForConstructor({name: 'f_width', value: width})
						}
					}else{
						this.showErrors()
					}
				}
			}else{
				this.updateDataForConstructor({name: 'f_width', value: ''})
			}
		}
		if(!empty(this._dataForConstructor['f_offset']) && this._dataForConstructor['f_offset'].includes('pw')){
			this.offset = this._dataForConstructor['f_offset']
		}
		if(this.type === 'gEdge') {
			if (this.r >  Math.min(this.width, this.height)) {
				this.r = Math.min(this.width, this.height)
			}
			this.updateDataForConstructor({name: 'r', value: this.r});
			this.x = this.angle.split('_').includes('right') ? this.detail.l - this.width : 0
		}
		this.initRequiredFields()
		this.renderDetail()
	}

	get height() {
		return this._height;
	}

	set height(height) {
		if(!this.checkRequiredField('height', Number(height))) {
			this._height = Number(height);
			this.setFormField({name: 'height', value: this.height});
			this.updateDataForConstructor({name: 'height', value: this.height});
			this.updateDataForConstructor({name: 'width', value: this.width});
			if (this.type === 'gEdge') {
				if (this.r > Math.min(this.width, this.height)) {
					this.r = Math.min(this.width, this.height)
				}
				this.updateDataForConstructor({name: 'r', value: this.r});
				this.y = this.angle.split('_').includes('top') ? this.detail.h - this.height : 0
			}
			this.initRequiredFields()
			this.renderDetail()
		} else {
			this.showErrors()
		}
	}

	get angle() {
		return this._angle
	}

	set angle(angle) {
		this._angle = angle;
		this.setFormField({name: 'angle', value: this.angle});
		if (this.type === 'gEdge') {
			const sides = angle.split('_');
			this.x = sides[0] === 'left' ? 0 : this.detail.l - this.width;
			this.y = sides[1] === 'bottom' ? 0 : this.detail.h - this.height;
		}
		this.initRequiredFields()
		this.renderDetail()
	}

	get elements() {
		return this._elements
	}

	set elements(element) {
		this._elements = element
	}

	get doubleSmiles() {
		return this._doubleSmiles
	}

	set doubleSmiles(doubleSmiles) {
		this._doubleSmiles = doubleSmiles
		this.offset = 50
	}

	get cutOut() {
		// if (['gEdge', 'uShape', 'smile', 'radiusEdge'].includes(this.type)) {
			return this.mill.cutOut
		// }
		// return {
		// 	x: 0,
		// 	y: 0,
		// 	side: 'front',
		// 	type: this.type,
		// 	subType: 'mill',
		// 	depth: this.detail.w,
		// 	edge: this.edge,
		// 	place: 'left',
		// 	width: 100,
		// 	color: this.getMeshColor(),
		// 	height: 100,
		// 	elements: this.elements,
		// }
	}

	get dataForValidate() {
		const data = prepareObject(this.detail.mills)
		data.forEach(item => {
				item.edge = this.getEdgeArrayIndex(item.edge) });
		return data
	}

	get mesh() {
		return this.mill?.mesh
	}

	addSizesArrows() {
		if (['gEdge', 'uShape', "radiusEdge"].includes(this.type)) {
			return this.mill.addSizesArrows()
		}
	}

	get side() {
		return this._side;
	}

	get isErrorText() {
		return this._isErrorText;
	}

	set isErrorText(isErrorText) {
		this._isErrorText = isErrorText;
	}

	get depth() {
		return this._depth
	}

	set depth(depth) {
		this._depth = depth
	}

	get typeR() {
		return this._typeR
	}

	set typeR(typeR) {
		this._typeR = typeR
		this.setFormField({name: 'typeR', value: this.typeR})
		this.initRequiredFields()
		// this.mill.generateElements()
		this.renderDetail()
	}

	get offset() {
		return this._offset;
	}

	set offset(offset) {
		if(typeof offset === "number"){
			this._offset = offset;
			if(this.type === 'uShape' || this.type === 'smile'){
				this.updateDataForConstructor({name: 'f_offset', value: ''})
			} else {
				this.setFormField({name: 'offset', value: this.offset})
			}
			if(this.type === 'smile'){
				this.setFormField({
					name: 'offset', value: this.offset, additionalParam: [
						{name: 'formula', label: Languages.getTranslation('formula', true), value: ''},
						{name: 'center', label: `${Languages.getTranslation('center-on-axis', true)} X`, callback: () => {this.setCenterForSmile();}}]})
			}else {
				this.setFormField({
					name: 'offset', value: this.offset, additionalParam: [
						{name: 'formula', label: Languages.getTranslation('formula', true), value: ''},
						{name: 'center', label: Languages.getTranslation('center-detail', true), callback: () => {this.mill.setCenter();}}]})
			}
		}else{
			if(!empty(offset)) {
				if (!empty(this._dataForConstructor['f_width'])
					&& this._dataForConstructor['f_width'].includes('po')
					&& offset.includes('pw')) {
					this.showToastError('refer-each-other')
				}else if (!empty(this._dataForConstructor['f_r'])
					&& this._dataForConstructor['f_r'].includes('po')
					&& offset.includes('pr')) {
					this.showToastError('refer-each-other')
				} else if (!empty(this.formulaCalculation(offset))) {
					const calculation = Number(Number(this.formulaCalculation(offset)).toFixed(1));
					if (calculation >= 0) {
						this._offset = calculation;
						if(this.type === 'smile'){
							this.setFormField({
								name: 'offset', value: this.offset, additionalParam: [
									{name: 'formula', label: Languages.getTranslation('formula', true), value: offset},
									{name: 'center', label: `${Languages.getTranslation('center-on-axis', true)} X`, callback: () => {this.setCenterForSmile();}}]})
						}else {
							this.setFormField({
								name: 'offset', value: this.offset, additionalParam: [
									{name: 'formula', label: Languages.getTranslation('formula', true), value: offset},
									{name: 'center', label: Languages.getTranslation('center-detail', true), callback: () => {this.mill.setCenter();}}]})
						}
						this.updateDataForConstructor({name: 'f_offset', value: offset})
					}else{
						this.showToastError('cannot-be-negative')
					}
				}
			}else{
				this.updateDataForConstructor({name: 'f_offset', value: ''})
			}
		}

		if(!empty(this._dataForConstructor['f_width']) && this._dataForConstructor['f_width'].includes('po')){
			this.width = this._dataForConstructor['f_width']
		}

		if (this.type === 'uShape') {
			switch (this.edgeSide) {
				case 'top':
					this.x = this.offset;
					this.y = this.detail.h - this.heightCalc;
					if (this.widthCalc > this.detail.l - this.offset) {
						this.width = this.detail.l - this.offset
					}
					break;
				case 'bottom':
					this.x = this.offset;
					this.y = 0;
					if (this.widthCalc > this.detail.l - this.offset) {
						this.width = this.detail.l - this.offset
					}
					break;
				case 'left':
					this.y = this.offset;
					this.x = 0;
					if (this.heightCalc > this.detail.h - this.offset) {
						this.width = this.detail.h - this.offset
					}
					break;
				case 'right':
					this.y = this.offset;
					this.x = this.detail.l - this.widthCalc;
					if (this.heightCalc > this.detail.h - this.offset) {
						this.width = this.detail.h - this.offset
					}
					break;
			}
		}
		this.setFormField({name: 'offset', value: this.offset})
		this.updateDataForConstructor({name: 'offset', value: this.offset})
		this.initRequiredFields()
		this.renderDetail()
	}

	get widthCalc() {
		if(isset(this?.mill?.widthCalc)){
			return this.mill.widthCalc;
		}else{
			if (["top", "bottom"].includes(this.edgeSide)) {
				return this.width;
			} else {
				return this.height;
			}
		}
	}

	get heightCalc() {
		if(isset(this?.mill?.heightCalc)){
			return this.mill.heightCalc;
		}else{
			if (["top", "bottom"].includes(this.edgeSide)) {
				return this.height;
			} else {
				return this.width;
			}
		}
	}

	validate() {
		return this.mill.validate()
	}

	fixMultiplicity() {
		return null
	}

	get realData() {
		if(["gEdge","radiusEdge" ,"uShape","smile", "partial", "closed"].includes(this.type)) {
			this.elements = this?.mill?.elements || [];
		}

		const data = {
			side: this.side,
			x: this.x,
			y: this.y,
			width: this.widthCalc,
			height: this.heightCalc,
			depth: this.depth,
			offset: this.offset,
			r: this.r,
			z: this.z,
			type: this.type,
			edgeSide: this.edgeSide,
			edge: this.edge,
			id: this.id,
			isErrorText: this.isErrorText,
			elements: this.elements,
			...this?.mill?.realData || {},
			quant: this.elements.length,
			length: this.length,
		}
		if (!data.length) {
			let result = 0
			for (const argumentsKey in this.elements) {
				result += this.elements[argumentsKey].length
			}
			data.length = result
		}
		return data
	}

	calculateMillLength() {
		if (!this.length) {
			let result = 0
			for (const argumentsKey in this.mill.elements) {
				result += this.mill.elements[argumentsKey].length
			}
			this.length = result
		}
	}

	mirrorWithReverse(axis) {
		if (this.type === 'uShape') {
			if (axis.ver) {
				switch (this.edgeSide) {
					case "left":
						this.edgeSide = 'right'
						// this.x = this.detail.l - this.width
						// this.offset = this.x
						break
					case 'right':
						this.edgeSide = 'left'
						// this.x = 0
						break
					case 'bottom':
						this.edgeSide = 'bottom'
						// this.x = this.detail.l - this.width - this.offset
						// this.offset = this.x
						break
					case 'top':
						this.edgeSide = 'top'
						// this.x = this.detail.l - this.width - this.offset
						// this.offset = this.x
						break
				}
			}
			if (axis.hor) {
				switch (this.edgeSide) {
					case "top":
						this.edgeSide = 'bottom'
						this.y = 0
						break
					case 'bottom':
						this.edgeSide = 'top'
						this.y = this.detail.h - this.height
						break
					case 'right':
							this.offset = this.detail.h - (this.offset + this.heightCalc)
						// this.y = this.detail.h - this.height - this.offset
						// this.offset = this.y
						break
					case 'left':
						this.offset = this.detail.h - (this.offset + this.heightCalc)
				}
			}
		}
	}

	mirror(axis, data, detailH, detailL) {
		if (data.type === 'uShape') {
			if (axis === 'ver') {
				switch (this.edgeSide) {
					case "left":
						data.edgeSide = 'right'
						data.dataForConstructor = {
							edgeSide: 'right',
						}
						break
					case 'right':
						data.edgeSide = 'left'
						data.dataForConstructor = {
							edgeSide: 'left',
						}
						break
					case 'bottom':
					case 'top':
						data.x = detailL - data.width - this.offset
						break
				}
			}
			if (axis === 'hor') {
				switch (this.edgeSide) {
					case "top":
						data.y = 0
						data.edgeSide = 'bottom'
						data.dataForConstructor = {
							edgeSide: 'bottom',
						}
						break
					case 'bottom':
						data.y = detailH - data.height
						data.edgeSide = 'top'
						data.dataForConstructor = {
							edgeSide: 'top',
						}
						break
					case 'right':
					case 'left':
						data.y = detailH - data.height - this.offset
						break
				}
			}
		}
	}

	rotateDetail(direction, l, h) {
		return this.mill.rotateDetail(direction, l, h)
			.then(() => this.updateDb())
	}
}
