import Db from "../api/Db";
import {empty, isset} from "../helpers/helper";

export default class _Detail extends Db {
    _d_id = null;

    constructor(id) {
        super();
        this.id = id;
    }

    set id(id) {
        this._d_id = Number(id);
    }
    get id() {
        return this._d_id;
    }
    getDetail() {
        if(empty(this.id)) {
            return Promise.reject({'Error': 'Incorrect detail ID'})
        }

        return this.getItem('details', this.id);
    }

    updateDetail(field = null, value) {
        if(empty(field) || !isset(value)) {
            return Promise.reject({Error: 'incorrect data', data: {field, value}})

        }
        if(empty(this.id)) {
            return Promise.reject({'Error': 'Incorrect detail ID'})
        }
        return this.update('details', this.id, field, value)
          .then(() => {
              return Promise.resolve();
          })
    }

    getEdges() {
        return this.getAllData('edges');
    }

    getEdge({ type, value }) {
        return this.getDetail()
            .then(detail => {
                switch (type) {
                    case 'side':
                        if(detail[value] !== null) {
                            return this.getItem('edges', detail[value]);
                        } else {
                            return Promise.resolve(null);
                        }
                    case 'corner':
                        const corner = detail.corners.find(el => el.id === value)
                        if(corner && corner.edge !== null) {
                            return this.getItem('edges', corner.edge);
                        } else {
                            return Promise.resolve(null);
                        }
                    default: return Promise.resolve(null);
                }
            })
    }

    getProcessing(name = null) {
        if (empty(name)) {
            return Promise.resolve(null);
        }
        return this.getDetail()
          .then(detail => Promise.resolve(detail?.[name] || []))
    }

    deleteMultipleProcessing(type, ids = []) {
        // console.log('deleteAll: ', ids)
        return this.getProcessing(type)
          .then(data => {
              const newData = data.filter(item => !ids.includes(item.id));
              return this.updateDetail(type, newData);
          })
    }

}