import Helpers from "./3D/Helpers";
import SizeArrow from "./3D/components/SizeArrow";
import Processing from "./3D/Processing";
import {empty} from "../helpers/helper";
import Languages from "../translation/Languages";

export default class Rabbet extends Processing {
  _subType = 'rabbet';
  _isActive = false;
  _ext = false;
  _edgeSide = 'left';
  _offset = 50;
  _width;
  _height;

  constructor({
                detail,
                side = 'back',
                dataForConstructor = {},
                x = 50,
                y = 50,
                r = 2,
                z = 0,
                ext = false,
                depth = 5,
                comment = "",
                height = 100,
                width = 100,
                id,
                isErrorText='',
                additionalInfo = ''
              }) {
    super({x, y, r, depth, detail, side, comment, id, isErrorText, additionalInfo});

    this._width = width;
    this._height = height;
    this._dataForConstructor = dataForConstructor;
    this._ext = ext;

    const isLeftBottom = this.x <= 0 && this.y <= 0;
    const isRightBottom = this.x + this.width >= this.detail.l && this.y <= 0;
    const isRightTop = this.x + this.width >= this.detail.l && this.y + this.height >= this.detail.h;
    const isLeftTop = this.x <= 0 && this.y + this.height >= this.detail.h;
    if (isLeftBottom || isRightBottom || isRightTop || isLeftTop) {
      if (isLeftBottom) {
        if (this.width >= this.height) {
          this._edgeSide = "bottom";
          this._offset = this.x;
        } else {
          this._edgeSide = "left";
          this._offset = this.y;
        }
      }
      if (isRightBottom) {
        if (this.width >= this.height) {
          this._edgeSide = "bottom";
          this._offset = this.x;
        } else {
          this._edgeSide = "right";
          this._offset = this.y;
        }
      }
      if (isRightTop) {
        if (this.width >= this.height) {
          this._edgeSide = "top";
          this._offset = this.x;
        } else {
          this._edgeSide = "right";
          this._offset = this.y;
        }
      }
      if (isLeftTop) {
        if (this.width >= this.height) {
          this._edgeSide = "top";
          this._offset = this.x;
        } else {
          this._edgeSide = "left";
          this._offset = this.y;
        }
      }
    } else {
      if (this.y <= 0) {
        this._edgeSide = "bottom";
        this._offset = this.x;
      }

      if (this.x <= 0) {
        this._edgeSide = "left";
        this._offset = this.y;
      }

      if (this.y + this.height >= this.detail.h) {
        this._edgeSide = "top";
        this._offset = this.x;
      }

      if (this.x + this.width >= this.detail.l) {
        this._edgeSide = "right";
        this._offset = this.y;
      }
    }

    if (["left", "right"].includes(this.edgeSide)) {
      [this._width, this._height] = [this._height, this._width];
    }
    if(!empty(dataForConstructor.width)) {
      this._width = dataForConstructor.width
    }
    if(!empty(dataForConstructor.edgeSide)) {
      this._edgeSide = dataForConstructor.edgeSide
    }
    if(!empty(dataForConstructor.height)) {
      this._height = dataForConstructor.height
    }
    if(!empty(dataForConstructor.offset)) {
      this._offset = dataForConstructor.offset
    }
    this.addParams()
    this.initRequiredFields();
  }

  initRequiredFields () {
    this.requiredFields = {
      r: {
        max: Math.min(this.width, this.height) / 2,
        min: this.minValueForValidate('r',this.depth <= 10 ? 2 : 3)
      },
      width: {
        min: this.r * 2,
        max: ['left', 'right'].includes(this.edgeSide) ? this.detail.h - this.offset : this.detail.l - this.offset
      },
      height: {
        min: this.r,
        max: ['left', 'right'].includes(this.edgeSide) ? this.detail.l : this.detail.h
      },
      offset: {
        min: 0,
        max: ['left', 'right'].includes(this.edgeSide) ? this.detail.h - this.width : this.detail.l - this.width
      },
      depth: {
        min: 1,
        max: this.detail.w
      }
    }
  }

  addParams() {
    let f_value = ''
    this.setFormField({name:'side', label: Languages.getTranslation('side', true), type: 'select', value: this.side, variables: [
        { key: "front", value: Languages.getTranslation('face', true)},
        { key: "back", value: Languages.getTranslation('back', true)}
      ], visible: true})
    f_value = this._dataForConstructor.hasOwnProperty('f_width') ? this._dataForConstructor['f_width'] : '';
    this.setFormField({name: 'width', label: `${Languages.getTranslation('height', true)} (pw)`, value: this.width, type: 'number', additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'allWidth', label: Languages.getTranslation('full-length', true), callback: () => {
            this.setAllWidth();
          }}
      ]});
    this.setFormField({name: 'height', value: this.height, type: 'number', label: Languages.getTranslation('width', true)});
    this.setFormField({name:'edgeSide', type: 'select', label: Languages.getTranslation('tip', true), value: this.edgeSide, variables: [
        { key: "left", value: Languages.getTranslation('left1', true) },
        { key: "top", value: Languages.getTranslation('top1', true) },
        { key: "right", value: Languages.getTranslation('right1', true) },
        { key: "bottom", value: Languages.getTranslation('bottom1', true) }
      ]})
    f_value = this._dataForConstructor.hasOwnProperty('f_offset') ? this._dataForConstructor['f_offset'] : '';
    this.setFormField({name: 'offset', label: `${Languages.getTranslation('displacement', true)} (po)`, value: this.offset, type: 'number', additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'center', label: Languages.getTranslation('center-detail', true), callback: () => {
            this.setCenter();
          }}
      ]})
    this.setFormField({name: 'depth', label: Languages.getTranslation('depth', true), value: this.depth, type: 'number', visible: true})
    this.setFormField({name: 'x', visible: false, value: this.x, type: 'number'})
    this.setFormField({name: 'y', visible: false, value: this.y, type: 'number',})
    this.setFormField({name: 'ext', type: 'checkbox-orig', label: Languages.getTranslation('closed-rabbet', true), value: this.ext, visible: true})
    this.setFormField({name: 'r', visible: !this.isAllWidth, value: this.r, type: 'number', label: Languages.getTranslation('radius', true), additionalProcessing: {
      name: 'removeRadius', label: '', callback: () => {
        this.removeRadius()
        }
      }})
  }

  minValueForValidate(flag, defaultValue) {

    if (flag === 'r') {
      if (this.isAllWidth) {
        return 0
      }
    }
    return defaultValue;
  }

  removeRadius() {
    this.r = 0;
    this.setFormField({name: 'r', value: 0})
    this.buildDetail()
      .then(() => this.renderDetail())
  }

  get dataForValidate() {
    return this.realData
  }

  get realData() {
    // this.updateDataForConstructor({name: 'x', value: this.x})
    // this.updateDataForConstructor({name: 'y', value: this.y})
    this.updateDataForConstructor({name: 'width', value: this.width})
    this.updateDataForConstructor({name: 'height', value: this.height})
    this.updateDataForConstructor({name: 'offset', value: this.offset})
    return {
      side: this.side,
      x: this.xCalc,
      y: this.yCalc,
      width: this.widthCalc,
      height: this.heightCalc,
      depth: this.depth,
      r: this.r,
      z: this.z,
      type: "Rabbet",
      offset: this.offset,
      edgeSide: this.edgeSide,
      ext: this.ext,
      edge: null,
      id: this.id,
      isErrorText: this.isErrorText,
    };
  }

  get cutOut() {
    // return [];
    const width = this.widthCalc;
    const height = this.heightCalc;
    this.fixMultiplicity();
    const x = this.xCalc;
    const y = this.yCalc;
    const cutout = {
      x: x,
      y: y,
      z: this.z,
      side: this.side,
      type: 'cutout',
      subType: 'rabbet',
      edgeSide: this.edgeSide,
      ext: this.ext,
      depth: this.depth,
      multiplicity: this.multiplicity,
      edge: null,
      width: width,
      height: height,
      color: this.getMeshColor(),
      elements: []
    }
    if(!this.allWidth) {
      switch (this.edgeSide) {
        case 'left':
          cutout.elements.push(Helpers.createLine(0, 0, 0, height))
          cutout.elements.push(Helpers.createLine(0, height, width - this.r, height))
          cutout.elements.push(Helpers.createArc(width - this.r, height, width, height - this.r, this.r, width - this.r, height - this.r, true));
          cutout.elements.push(Helpers.createLine(width, height - this.r, width, this.r))
          cutout.elements.push(Helpers.createArc(width, this.r, width - this.r, 0, this.r, width - this.r, this.r, true));
          cutout.elements.push(Helpers.createLine(width - this.r, 0, 0, 0))
          break
        case 'top':
          cutout.elements.push(Helpers.createLine(0, height, 0, this.r))
          cutout.elements.push(Helpers.createArc(0, this.r, this.r, 0, this.r, this.r, this.r, false));
          cutout.elements.push(Helpers.createLine(this.r, 0, width - this.r, 0))
          cutout.elements.push(Helpers.createArc(width - this.r, 0, width, this.r, this.r, width - this.r, this.r, false));
          cutout.elements.push(Helpers.createLine(width, this.r, width, height))
          break
        case 'right':
          cutout.elements.push(Helpers.createLine(width, height, this.r, height))
          cutout.elements.push(Helpers.createArc(this.r, height, 0, height - this.r, this.r, this.r, height - this.r, false));
          cutout.elements.push(Helpers.createLine(0, height - this.r, 0, this.r))
          cutout.elements.push(Helpers.createArc(0, this.r, this.r, 0, this.r, this.r, this.r, false));
          cutout.elements.push(Helpers.createLine(this.r, 0, width, 0))
          break
        case 'bottom':
          cutout.elements.push(Helpers.createLine(width, 0, width, height - this.r))
          cutout.elements.push(Helpers.createArc(width, height - this.r, width - this.r, height, this.r, width - this.r, height - this.r, false));
          cutout.elements.push(Helpers.createLine(width - this.r, height, this.r, height))
          cutout.elements.push(Helpers.createArc(this.r, height, 0, height - this.r, this.r, this.r, height - this.r, false));
          cutout.elements.push(Helpers.createLine(0, height - this.r, 0, 0))
          break
      }
    } else {
      cutout.elements.push(Helpers.createLine(0, 0, 0, height));
      cutout.elements.push(Helpers.createLine(0, height, width, height));
      cutout.elements.push(Helpers.createLine(width, height, width, 0));
      cutout.elements.push(Helpers.createLine(width, 0, 0, 0));
    }
    return cutout;
  }

  addSizesArrows() {
    if(!this.isActive) return false;
    const pos = this.x_axis + '_' + this.y_axis;
    return SizeArrow.getElementSizeArrow(
      this.xCalc,
      this.yCalc,
      null,
      pos,
      this.detail
    )
  }

  get allWidth() {
    if (["top", "bottom"].includes(this.edgeSide)) {
      return this.width === this.detail.l
    } else {
      return this.width === this.detail.h
    }
  }

  get width() {
    return this._width;
  }

  // set side(side) {
  //   this._side = side;
  //   this.setFormField({name: 'side', value: this.side, label: Languages.getTranslation('side')})
  //   this.buildDetail(this)
  //       .then(() => this.renderDetail())
  // }

  set width(width) {
    if(typeof width === 'number'){
      if(!this.checkRequiredField('width', Number(width))) {
        this._width = width
        this.setFormField({name: 'width', value: this.width, type: 'number', additionalParam : [
            {name: 'formula', label: Languages.getTranslation('formula', true), value: ''},
            {name: 'allWidth', label: Languages.getTranslation('full-length', true), callback: () => {
                this.setAllWidth();
              }}
          ]});
        this.updateDataForConstructor({name: 'f_width', value: ''})
      } else {
        this.showErrors()
      }
    }else {
      if(!empty(width)) {
        if (!empty(this._dataForConstructor['f_offset'])
            && this._dataForConstructor['f_offset'].includes('pw')
            && width.includes('po')) {
          this.showToastError('refer-each-other')
        }
        else if (!empty(this.formulaCalculation(width))) {
          const calculation = Number(Number(this.formulaCalculation(width)).toFixed(1));

          if(!this.checkRequiredField('width', Number(calculation))) {
            this._width = calculation;
            this.setFormField({
              name: 'width', value: this.width});
            this.updateDataForConstructor({name: 'f_width', value: width})
          }else{
            this.showErrors()
          }
        }
      }else {
        this.updateDataForConstructor({name: 'f_width', value: ''})
      }
    }

    if(!empty(this._dataForConstructor['f_offset']) && this._dataForConstructor['f_offset'].includes('pw')){
      this.offset = this._dataForConstructor['f_offset']
    }
    if (this.isAllWidth) {
      this.r = 0
      this.setFormField({name: 'r', visible: false})
    } else {
      this.r = 2
      this.setFormField({name: 'r', visible: true})
      this.additionalCutter = false
    }
    this.setFormField({name: 'width', value: this.width})
    this.initRequiredFields()
    this.renderDetail()
  }

  // get side() {
  //   return this._side
  // }
  //
  // set side(side) {
  //   this._side = side;
  //   this.setFormField({name: 'side', value: this.side, label: Languages.getTranslation('side')})
  //   this.buildDetail(this)
  //       .then(() => this.renderDetail())
  // }

  get height() {
    return this._height;
  }

  set height(height) {
    if(!this.checkRequiredField('height', Number(height))) {
      this._height = Number(height);
      this.setFormField({name: 'height', value: this.height});
      this.initRequiredFields()
      if (this.isAllWidth) {
        this.r = 0
        this.setFormField({name: 'r', value: this.r, visible: false})
      } else {
        this.r = 2
        this.setFormField({name: 'r', value: this.r, visible: true})
      }
      this.renderDetail()
    } else {
      this.showErrors()
    }
  }

  setAllWidth() {
    this.offset = 0
    if (["top", "bottom"].includes(this.edgeSide)) {
      this.width = this.detail.l;
    } else {
      this.width = this.detail.h;
    }
    if (this.isAllWidth) {
      this.r = 0
      this.setFormField({name: 'r', value: this.r, visible: false})
    }
    this.updateDataForConstructor({name: 'offset', value: this.offset});
  }

  get isAllWidth() {
    return ((['left', "right"].includes(this.edgeSide) ? this.width === this.detail.h : this.width === this.detail.l) || this.height <= 20)
  }

  get ext() {
    return this._ext;
  }

  set ext(ext) {
    this._ext = ext;
    this.setFormField({name: 'ext', value: ext})
    // this.buildDetail()
    //   .then(() => this.renderDetail())

    this.renderDetail()
  }

  get edgeSide() {
    return this._edgeSide;
  }

  set edgeSide(edgeSide) {
    this._edgeSide = edgeSide;
    switch (edgeSide) {
      case 'top':
      case 'bottom':
        this.x = this.offset
        if(this.width > this.detail.l - this.offset) {
          this.width = this.detail.l - this.offset;
        }
        break;
      case 'left':
      case 'right':
        this.y = this.offset;
        if(this.height > this.detail.h - this.offset) {
          this.height = this.detail.h - this.offset
        }
        break;
    }
    this.updateDataForConstructor({name: 'edgeSide', value: this.edgeSide});
    this.setFormField({name: 'edgeSide', value: this.edgeSide});
    this.setFormField({name: 'width', label: `${Languages.getTranslation('height', true)} (pw)`, value: this.width, type: 'number', additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: ''},
        {name: 'allWidth', label: Languages.getTranslation('full-length', true), callback: () => {
            this.setAllWidth();
          }}
      ]});
    this.setFormField({name: 'height', value: this.height, type: 'number', label: Languages.getTranslation('width', true)});
    this.setFormField({name: 'offset', label: `${Languages.getTranslation('displacement', true)} (po)`, value: this.offset, type: 'number', additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: ''},
        {name: 'center', label: Languages.getTranslation('center-detail', true), callback: () => {
            this.setCenter();
          }}
      ]})
    if (this.isAllWidth) {
      this.r = 0
      this.setFormField({name: 'r', visible: false})
    } else {
      this.r = 2
      this.setFormField({name: 'r', visible: true})
      this.additionalCutter = false
    }
    this.initRequiredFields()
    this.renderDetail()
  }

  get offset() {
    return this._offset;
  }

  set offset(offset) {
    if(typeof offset === 'number'){
      if(!this.checkRequiredField('offset', Number(offset))) {
        this._offset = Number(offset)
        this.setFormField({name: 'offset', value: this.offset, additionalParam : [
            {name: 'formula', label: Languages.getTranslation('formula', true), value: ''},
            {name: 'center', label: Languages.getTranslation('center-detail', true), callback: () => {
                this.setCenter();
              }}
          ]})
        this.updateDataForConstructor({name: 'f_offset', value: ''})
      } else {
        this.showErrors()
      }
    }else{
      if(!empty(offset)) {
        if (!empty(this._dataForConstructor['f_width'])
            && this._dataForConstructor['f_width'].includes('po')
            && offset.includes('pw')) {
          this.showToastError('refer-each-other')
        }
        else if (!empty(this.formulaCalculation(offset))) {
          const calculation = Number(Number(this.formulaCalculation(offset)).toFixed(1));
          if(!this.checkRequiredField('offset', Number(calculation))) {
            this._offset = calculation;
            this.updateDataForConstructor({name: 'f_offset', value: offset})
            this.setFormField({name: 'offset', value: this.offset})
          }else{
            this.showErrors()
          }
        }
      }else {
        this.updateDataForConstructor({name: 'f_offset', value: ''})
      }
    }

    if(!empty(this._dataForConstructor['f_width']) && this._dataForConstructor['f_offset'].includes('po')){
      this.width = this._dataForConstructor['f_width']
    }

    switch (this.edgeSide) {
      case 'top':
        this.x = this.offset;
        this.y = this.detail.h - this.heightCalc;
        if(this.widthCalc > this.detail.l - this.offset) {
          this.width = this.detail.l - this.offset
        }
        break;
      case 'bottom':
        this.x = this.detail.l - this.widthCalc - this.offset;
        this.y = 0;
        if(this.widthCalc > this.detail.l - this.offset) {
          this.width = this.detail.l - this.offset
        }
        break;
      case 'left':
        this.y = this.offset;
        this.x = 0;
        if(this.heightCalc > this.detail.h - this.offset) {
          this.width = this.detail.h - this.offset
        }
        break;
      case 'right':
        this.y = this.offset;
        this.x = this.detail.l - this.widthCalc;
        if(this.heightCalc > this.detail.h - this.offset) {
          this.width = this.detail.h - this.offset
        }
        break;
    }
    this.updateDataForConstructor({name: 'offset', value: this.offset})
    this.initRequiredFields()
    this.renderDetail()
  }

  get depth() {
    return this._depth
  }

  set depth(depth) {
    this._depth = Number(depth);
    this.fixMultiplicity()
    this.setFormField({name: 'depth',value: this.depth, label: 'глибина'})
    if (this.depth > 10 && !this.isAllWidth) {
      this.r = 3
      this.setFormField({name: 'r', value: this.r, visible: true})
    }
    this.initRequiredFields()
    this.renderDetail()
  }

  get widthCalc() {
    if (["top", "bottom"].includes(this.edgeSide)) {
      return this.width;
    } else {
      return this.height;
    }
  }

  get heightCalc() {
    if (["top", "bottom"].includes(this.edgeSide)) {
      return this.height;
    } else {
      return this.width;
    }
  }

  get xCalc() {
    if (["top", "bottom"].includes(this.edgeSide)) {
      return this.offset;
    } else {
      if (["left"].includes(this.edgeSide)) return 0;
      if (["right"].includes(this.edgeSide)) return this.detail.l - this.widthCalc;
    }
  }

  get yCalc() {
    if (["left", "right"].includes(this.edgeSide)) {
      // if (this.allWidth) return 0;
      // if (this.center) return this.detail.h / 2 - this.width / 2;
      return this.offset;
    } else {
      if (["bottom"].includes(this.edgeSide)) return 0;
      if (["top"].includes(this.edgeSide)) return this.detail.h - this.heightCalc;
    }
  }

  setCenter() {
    if (["left", "right"].includes(this.edgeSide)) {
      this.offset = this.detail.h / 2 - this.heightCalc / 2
    } else {
      this.offset = this.detail.l / 2 - this.widthCalc / 2
    }
  }

  get paramsSorting() {
    const sortingArr = ['side', 'edgeSide', 'offset', 'width', 'height', 'depth', 'ext', 'r', 'comment']

    return this.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
  }

  validate() {
    if(this.height === 0) {
      this.detail.error.setError(`${Languages.getTranslation('width-cannot-be-0', true)}`, 'error', true);
      return Promise.reject('width-cannot-be-0')
    }
    if(this.depth === 0) {
      this.detail.error.setError(`${Languages.getTranslation('depth-cannot-be-0', true)}`, 'error', true);
      return Promise.reject('depth-cannot-be-0')
    }
    return Promise.resolve()
  }

  mirror(axis, data, detailH, detailL) {
    if (axis === 'ver') {
      switch (this.edgeSide) {
        case "left":
          data.x = detailL - data.width
          data.dataForConstructor = {
            edgeSide: 'right'
          }
          break
        case 'right':
          data.x = 0
          data.dataForConstructor = {
            edgeSide: 'left'
          }
          break
        case 'bottom':
          data.x = detailL - data.width - this.offset
          data.dataForConstructor = {
            edgeSide: 'bottom'
          }
          break
        case 'top':
          data.x = detailL - data.width - this.offset
          data.dataForConstructor = {
            edgeSide: 'top'
          }
          break
      }
    }
    if (axis === 'hor') {
      switch (this.edgeSide) {
        case "top":
          data.y = 0
          data.dataForConstructor = {
            edgeSide: 'bottom'
          }
          break
        case 'bottom':
          data.y = detailH - data.height
          data.dataForConstructor = {
            edgeSide: 'top'
          }
          break
        case 'right':
          data.y = detailH - data.height - this.offset
          data.dataForConstructor = {
            edgeSide: 'right'
          }
          break
        case 'left':
          data.y = detailH - data.height - this.offset
          data.dataForConstructor = {
            edgeSide: 'left'
          }
      }
    }

  }

  mirrorWithReverse(axis) {
    if (axis.ver) {
      switch (this.edgeSide) {
        case "left":
          this.x = this.detail.l - this.width
          this.edgeSide = 'right'
          break
        case 'right':
          this.x = 0
          this.edgeSide = 'left'
          break
        case 'bottom':
          this.x = this.detail.l - this.width - this.offset
          this.offset = this.x
          break
        case 'top':
          this.x = this.detail.l - this.width - this.offset
          this.offset = this.x
          break
      }
    }
    if (axis.hor) {
      switch (this.edgeSide) {
        case "top":
          this.y = 0
          this.edgeSide = "bottom"
          break
        case 'bottom':
          this.y = this.detail.h - this.height
          this.edgeSide = "top"
          break
        case 'right':
          this.y = this.detail.h - this.height - this.offset
          this.offset = this.y
          break
        case 'left':
          this.y = this.detail.h - this.height - this.offset
          this.offset = this.y
      }
    }

  }

  rotateDetail(direction) {
    let offset;
    const oldEdgeSide = this.edgeSide;
    this.edgeSide = this.getNextEdgeSideForRotate(direction);
    switch (oldEdgeSide) {
      case 'top':
        offset = direction ? this.detail.h - this.offset - this.width : this.offset;
        break;
      case 'right':
        offset = direction ? this.offset : this.detail.l - this.offset - this.width;
        break;
      case 'left':
        offset = direction ? this.offset : this.detail.l - this.offset - this.width;
        break;
      case 'bottom':
        offset = direction ? this.detail.h - this.offset - this.width : this.offset;
        break;
    }
    this.initRequiredFields()
    this.offset = offset;
    this.updateDataForConstructor({name: 'offset', value: this.offset})
    // this.x = x;
    // this.y = y;
    return this.updateDb();
  }

}
