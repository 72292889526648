import React, { useEffect,useLayoutEffect, useRef } from "react";
import { SVG } from "@svgdotjs/svg.js";
import Helpers from "../../../models/3D/Helpers";
import {empty} from "../../../helpers/helper";
import _Details from "../../../db/_Details";
const detailsDb = new _Details();
import {calculateDirection, calculateGrooveCoordinates, calculateSquareCoordinates,  calculateDirectionForArc,calculateInnerCoordinate,  roundPolygon} from '../../../helpers/svg'
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import * as pako from 'pako';

const ContourSVGComponent = ({ rects = [], holes, detail }) => {
    const svgRef = useRef();
    const svgRefPdf = useRef();
    const construction = useSelector(projectSelectors.getConstruction);

    useEffect(() => {
        const contour = detail.createContour()?.contour?.contour


        const padding = 40;
        const additionalSpace = 100; // Define how much additional space you need



        const draw = SVG().addTo(svgRef.current)
            .size(0, 0)
            .viewbox(0 - padding,
                0 - padding,
                detail.l + 2 * padding,
                detail.h + 2 * padding)

        const drawPdf = SVG().addTo(svgRefPdf.current)
            .size(0, 0) // You might need to increase these values
            .viewbox(0 - padding,
            0 - padding,
            detail.l + 2 * padding,
            detail.h + 2 * padding)

        const defaultStrokeWidth = Math.sqrt(detail.l * detail.h) * 0.005;
        const defaultStrokeWidth2 = Math.sqrt(detail.l * detail.h) * 0.01 +5;
        const defaultStrokeWidthPdf = Math.sqrt(detail.l * detail.h) * 0.01

        let maxY = Math.max(...contour.map(line => Math.max(line.y1 ?? line.y, line.y2 ?? line.y)));
        let maxX = Math.max(...contour.map(line => Math.max(line.x1 ?? line.x, line.x2 ?? line.x)));
        let minY = Math.min(...contour.map(line => Math.min(line.y1 ?? line.y, line.y2 ?? line.y)));
        let minX = Math.min(...contour.map(line => Math.min(line.x1 ?? line.x, line.x2 ?? line.x)));

            holes?.forEach((hole) => {
                if(['left', 'top', 'right', 'bottom'].includes(hole.side)){
                    const pointsHoles = calculateInnerCoordinate(hole, maxX, maxY, 0, false);
                    let outerPolygon = draw.polygon(pointsHoles).fill("#ffffff").stroke({ color: "#000000", width: 10 });
                    let outerPolygonPdf = drawPdf.polygon(pointsHoles).fill("#ffffff").stroke({ color: "#000000", width: 10 });

                    const pointsInnerHoles = calculateInnerCoordinate(hole, maxX, maxY, hole.depth, true);
                    let innerPolygon = draw.polygon(pointsInnerHoles).fill("#ffffff").stroke({ color: "#000000", width: 10 });
                    let innerPolygonPdf = drawPdf.polygon(pointsInnerHoles).fill("#ffffff").stroke({ color: "#000000", width: 10 });

                    if(['left', 'right'].includes(hole.side)){
                        // Поворачиваем на 90 градусов вокруг центра многоугольника
                        let box = outerPolygon.bbox();
                        outerPolygon.rotate(90, box.cx, box.cy);

                        box = innerPolygon.bbox();
                        innerPolygon.rotate(90, box.cx, box.cy);
                        let boxPdf = outerPolygonPdf.bbox();
                        outerPolygonPdf.rotate(90, box.cx, box.cy);

                        box = innerPolygonPdf.bbox();
                        innerPolygonPdf.rotate(90, box.cx, box.cy);
                    }

                    // Сдвигаем многоугольники на длину hole.diam в соответствии с ориентацией
                    let shiftDistance = hole._depth/2 -2.5;
                    if(hole.side === 'left'){
                        outerPolygon.dmove(0, -shiftDistance);
                        innerPolygon.dmove(0, -shiftDistance);

                        outerPolygonPdf.dmove(0, -shiftDistance);
                        innerPolygonPdf.dmove(0, -shiftDistance);
                    } else if (hole.side === 'top'){
                        outerPolygon.dmove(0, -shiftDistance);
                        innerPolygon.dmove(0, -shiftDistance);

                        outerPolygonPdf.dmove(0,-shiftDistance);
                        innerPolygonPdf.dmove(0, -shiftDistance);
                    } else if (hole.side === 'right'){
                        outerPolygon.dmove(0, shiftDistance);
                        innerPolygon.dmove(0, shiftDistance);

                        outerPolygonPdf.dmove(0, shiftDistance);
                        innerPolygonPdf.dmove(0, shiftDistance);
                    } else if (hole.side === 'bottom'){
                        outerPolygon.dmove(0, shiftDistance);
                        innerPolygon.dmove(0, shiftDistance);

                        outerPolygonPdf.dmove(0, shiftDistance);
                        innerPolygonPdf.dmove(0, shiftDistance);
                    }
                }
        else {
                let pointsHolesString = calculateInnerCoordinate(hole, maxX, maxY,0,  false);

                let pointsHoles = pointsHolesString.split(' ').map(coord => {
                    let [x, y] = coord.split(',').map(Number);  // Преобразование координат в числа
                    return [x, y];
                });

                roundPolygon(draw, pointsHoles, hole.diam/2);
                roundPolygon(drawPdf, pointsHoles, hole.diam/2);
            }

    });

        detail?.rabbets.forEach((rabbet) => {
            const points = calculateSquareCoordinates(rabbet, maxX, maxY);
            draw
                .polygon(points)
                .fill("#cecaca")
                .stroke({ color: "#000000", width: defaultStrokeWidth })
            drawPdf
                .polygon(points)
                .fill("#cecaca")
                .stroke({ color: "#000000", width: defaultStrokeWidth })
        });

        detail?.grooves.forEach((rabbet) => {

            const points = calculateGrooveCoordinates(rabbet, maxX, maxY, minY, minX);
            draw
                .polygon(points)
                .fill("#cecaca")
                .stroke({ color: "#000000", width: defaultStrokeWidth })
            drawPdf
                .polygon(points)
                .fill("#cecaca")
                .stroke({ color: "#000000", width: defaultStrokeWidth })
        });



        contour?.forEach(object => {
            const strokeWidth = defaultStrokeWidth +5;
            const strokeWidthPdf = defaultStrokeWidth ;
            let colorEdge = '';

            if(!empty(object.edge)){
                const edgeThi = construction.edges.find(el => el.index === object?.edge)?.thickness
                if (edgeThi <= 0.6) {
                    colorEdge = '#008000';
                } else if (edgeThi <= 0.8) {
                    colorEdge = '#FFA500';
                } else if (edgeThi <= 1) {
                    colorEdge = '#ADD8E6';
                } else if (edgeThi < 2) {
                    colorEdge = '#0101FF';
                } else if (edgeThi === 2) {
                    colorEdge = '#800180';
                } else {
                    colorEdge = '#000000';
                }
            }


            if (object?.type === "line"){


                draw.line(object.x1, object.y1, object.x2, object.y2).stroke({ color: '#000000', width: strokeWidth });
                drawPdf.line(object.x1, object.y1, object.x2, object.y2).stroke({ color: '#000000', width: strokeWidthPdf });
                if (!empty(object.edge)) {
                    let offset;
                    if(object.isCorner === "left_bottom"){
                        offset = 20
                    }else {
                        offset = -20
                    }
                    const direction = calculateDirection(object);

                    let centerX = (object.x1 + object.x2) / 2;
                    let centerY = (object.y1 + object.y2) / 2;

                    let newX1 = centerX - (centerX - object.x1) / 1.7;
                    let newY1 = centerY - (centerY - object.y1) / 1.7;
                    let newX2 = centerX + (object.x2 - centerX) / 1.7;
                    let newY2 = centerY + (object.y2 - centerY) / 1.7;

                    draw.line(newX1 + offset * direction.x, newY1 + offset * direction.y, newX2 + offset * direction.x, newY2 + offset * direction.y).stroke({ color: colorEdge, width: defaultStrokeWidth2 });
                    drawPdf.line(newX1 + offset * direction.x, newY1 + offset * direction.y, newX2 + offset * direction.x, newY2 + offset * direction.y).stroke({ color: colorEdge, width: defaultStrokeWidthPdf });
                }
            }
            else {
                if(object.length < 60){
                    object.edge = null
                }
                const sweepFlag = object?.dir ? "0" : "1";
                let pathString = `M ${object?.x1}, ${object?.y1} A ${object?.r},${object?.r} 0 0,${sweepFlag} ${object?.x2}, ${object?.y2}`;
                draw.path(pathString).fill('none').stroke({ color: '#000000', width: strokeWidth });
                drawPdf.path(pathString).fill('none').stroke({ color: '#000000', width: strokeWidthPdf });



                    if(!empty(object.edge)){

                        let offset;

                            if(!object.dir){
                                offset = -25;

                            }else {
                                offset = 25;
                            }

                const direction = calculateDirectionForArc(object); // You'd need to implement this
                        const newX1 = object.x1 + offset * direction.x;
                        const newY1 = object.y1 + offset * direction.y;
                        const newX2 = object.x2 + offset * direction.x;
                        const newY2 = object.y2 + offset * direction.y;

                    const newX1Pdf = object.x1 + offset * direction.x;
                    const newY1Pdf = object.y1 + offset * direction.y;
                    const newX2Pdf = object.x2 + offset * direction.x;
                    const newY2Pdf = object.y2 + offset * direction.y;

                let newPathString = `M ${newX1}, ${newY1} A ${object.r},${object.r} 0 0,${sweepFlag} ${newX2}, ${newY2}`;
                let newPathStringPdf = `M ${newX1Pdf}, ${newY1Pdf} A ${object.r},${object.r} 0 0,${sweepFlag} ${newX2Pdf}, ${newY2Pdf}`;
                draw.path(newPathString).fill('none').stroke({ color: colorEdge, width: defaultStrokeWidth2 });
                drawPdf.path(newPathStringPdf).fill('none').stroke({ color: colorEdge, width: defaultStrokeWidthPdf });
                }

            }
        });

        rects?.forEach((rect, i) => {
            let pointsRect = []

            rect.elements.forEach((element, index) => {
                let rectCoordinates = {}
                if(!empty(rect)){
                    rectCoordinates = Helpers.fixUShapePoints(rect)
                }

                if (element?.type === "arc") {
                    const coordinate = rectCoordinates[index];
                    element.xc += coordinate.xc;
                    element.yc += coordinate.yc;


                    const endAngleRad = Math.abs(element.endAngleRad);
                    const startAngleRad = Math.abs(element.startAngleRad);
                    const {x1, y1, x2, y2} = coordinate;
                    const largeArcFlag = (endAngleRad - startAngleRad <= Math.PI) ? "0" : "1";
                    const path = `M ${x1} ${y1} A ${element.r} ${element.r} 0 ${largeArcFlag} 0 ${x2} ${y2}`;

                    draw.path(path).stroke({color: '#000000', width: 7}).fill('#ffffff');
                    drawPdf.path(path).stroke({color: '#000000', width: 7}).fill('#ffffff');
                }
                else{
                    let shift = rectCoordinates[index];
                    element.x1 = shift.x1;
                    element.y1 = shift.y1;
                    element.x2 = shift.x2;
                    element.y2 = shift.y2;

                    // For lines, use the updated element's (x1, y1) and (x2, y2) as the line's start and end points.
                    let x1 = element.x1;
                    let y1 = element.y1;
                    let x2 = element.x2;
                    let y2 = element.y2;

                    pointsRect.push(`${x1},${y1} ${x2},${y2}`);
                    draw.polygon(pointsRect.join(" ")).fill('#ffffff');
                    draw.line(x1, y1, x2, y2)
                        .stroke({ color: '#000000', width: 10 }).fill('#ffffff');

                    drawPdf.polygon(pointsRect.join(" ")).fill('#ffffff');
                    drawPdf.line(x1, y1, x2, y2)
                        .stroke({ color: '#000000', width: 10 }).fill('#ffffff');
                }
            });
        });

        detail?.cutouts.forEach((cutout, i) => {
            let pathString = '';

            // Begin the path with the first element's start point
            const firstElement = cutout?.cutOut.elements[0];
            pathString += `M ${firstElement.x1 + cutout._x}, ${firstElement.y1 + cutout._y} `;
            const color = cutout?.cutOut?.side === "front" ? "#c1c1c1" : "#8c8787"
            cutout?.cutOut.elements.forEach((element) => {
                if (element?.type === "arc") {
                    const sweepFlag = element?.dir ? "0" : "1";
                    pathString += `A ${element?.r},${element?.r} 0 0,${sweepFlag} ${element?.x2 + cutout._x}, ${element?.y2 + cutout._y} `;
                } else {
                    pathString += `L ${element.x2 + cutout._x}, ${element.y2 + cutout._y} `;
                }
            });

            // Close the path to fill the inside area
            pathString += 'Z';

            // Draw the path and fill the inside area with green color
            draw.path(pathString).fill(color).stroke({ color: '#000000', width: 3 });
            drawPdf.path(pathString).fill(color).stroke({ color: '#000000', width: 3 });
        });

    }, []);


    async function flipImage(imageBase64) {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => {
                // Создайте новый холст и поместите изображение на него
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;

                // Переверните контекст холста по оси X
                ctx.translate(img.width, 0);
                ctx.scale(-1, 1);

                // Нарисуйте изображение на перевернутом холсте
                ctx.drawImage(img, 0, 0, img.width, img.height);

                // Преобразуйте перевернутое изображение обратно в Base64
                let flippedBase64Image = canvas.toDataURL('image/png');

                // Сжать строку Base64
                let compressed = pako.deflate(flippedBase64Image);
                let compressedAsString = btoa(String.fromCharCode.apply(null, new Uint8Array(compressed)));

                resolve(compressedAsString);
            };
            img.onerror = reject;
            img.src = `data:image/svg+xml;base64,${imageBase64}`;
        });
    }


    useEffect(() => {
        if(svgRef.current){
            svgRef.current.style.transform = 'rotateX(180deg)';


            let parser = new DOMParser();
            let SVGText = new XMLSerializer().serializeToString(svgRef.current.firstChild);
            let doc = parser.parseFromString(SVGText, 'image/svg+xml');
            let svg = doc.firstChild;

            let height = parseFloat(svg.getAttribute('height'));

            let transformMatrix = [1, 0, 0, -1, 0, height];

            svg.setAttribute('transform', 'matrix(' + transformMatrix.join(' ') + ')');

            let rotatedSVGText = new XMLSerializer().serializeToString(svg);
            let compressed = pako.deflate(rotatedSVGText);
            let compressedAsString = btoa(String.fromCharCode.apply(null, new Uint8Array(compressed)));

            detail.updateSvgBase(compressedAsString)
        }

        if(svgRefPdf.current){

            svgRefPdf.current.style.transform = 'rotateX(180deg)';


            let parser = new DOMParser();
            let SVGTextPdf = new XMLSerializer().serializeToString(svgRefPdf.current.firstChild);
            let doc = parser.parseFromString(SVGTextPdf, 'image/svg+xml');
            let svg = doc.firstChild;

            let height = parseFloat(svg.getAttribute('height'));

            let transformMatrix = [1, 0, 0, -1, 0, height];

            svg.setAttribute('transform', 'matrix(' + transformMatrix.join(' ') + ')');

            let rotatedSVGText = new XMLSerializer().serializeToString(svg);
            let compressed = pako.deflate(rotatedSVGText);
            let compressedAsString = btoa(String.fromCharCode.apply(null, new Uint8Array(compressed)));

            detail.updateSvgPdf(compressedAsString)
        }
    }, [svgRef.current, svgRefPdf.current]);

    return (
        <>
            <span ref={svgRefPdf} style={{width: '100%', height: '100%', overflow: 'hidden'}}></span>
            <span ref={svgRef} style={{width: '100%', height: '100%', overflow: 'hidden'}}></span>
        </>
    );
};

export default ContourSVGComponent
