import Languages from "../../../../../translation/Languages";
import {memo} from "react";
import {Checkbox} from "../common";

const Header = ({isAllDetailsSelected, setAllDetails}) => {
    return (<div className="detailsTab__header">
        <Checkbox isSelected={isAllDetailsSelected} onChange={setAllDetails}/>
        <div className="detailsTab__header-item detailsTab__number">№</div>
        <div className="detailsTab__header-item detailsTab__name-edit">
            {Languages.getTranslation("name", true)}
        </div>
        <div className="detailsTab__header-item detailsTab__data-input">
            {Languages.getTranslation("height", true)}
        </div>
        <div className="detailsTab__header-item detailsTab__data-input">
            {Languages.getTranslation("width", true)}
        </div>
        <div className="detailsTab__header-item detailsTab__data-input">
            {Languages.getTranslation("amount", true)}
        </div>
        <div className="detailsTab__header-item detailsTab__material-edit">
            {Languages.getTranslation("material", true)}
        </div>
        <div className="detailsTab__header-item detailsTab__data-item">
            {Languages.getTranslation("thickness", true)}
        </div>
        <div className='detailsTab__header-item detailsTab__edges-edit'>
            <i className="icon__triangle-up"></i>
        </div>
        <div className='detailsTab__header-item detailsTab__edges-edit'>
            <i className=" icon__triangle-down"></i>
        </div>
        <div className='detailsTab__header-item detailsTab__edges-edit'>
            <i className=" icon__triangle-left"></i>
        </div>
        <div className='detailsTab__header-item detailsTab__edges-edit'>
            <i className="icon__triangle-right"></i>
        </div>
        <div className="detailsTab__header-item detailsTab__texture-edit">
            {Languages.getTranslation("texture", true)}
        </div>
        <div className="detailsTab__header-item detailsTab__products-edit">
            {Languages.getTranslation("products", true)}
        </div>
        <div className="detailsTab__header-item detailsTab__data-item detailsTab__number"></div>
    </div>)
}

export default memo(Header);