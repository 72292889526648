import ACTIONS from './actions'

const switchStateError = (message) => {
	return async (dispatch) => {
		try {
			dispatch(ACTIONS.ErrorMessageSwitch({ message }))
		}catch (error){
			console.warn(error)
		}
	}
}

const removeAllErrors = (message) => {
	return async (dispatch) => {
		try{
			dispatch(ACTIONS.ErrorMessageSwitch({ message }))
		}catch (error){
			console.warn(error)
		}
	}
}

export default {
	switchStateError,
	removeAllErrors
}