const option = (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f1e5ef" : null,
    color: '#451D54',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    opacity: state.isDisabled ? '0.7' : '1',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
})

const menuList = (provided) => ({
    ...provided,
    padding: 0
});

const dropdownIndicator = (provided) => ({
    ...provided,
    borderTop: 'none',
    borderBottom: 'none',
    padding: 0,
});

const container = (provided) => ({
    ...provided,
    pointerEvents: 'initial'
});

const indicatorSeparator = (provided) => ({
    ...provided,
    display: 'none',
    width: 0
});

const input = (provided) => ({
    ...provided,
    width: 110,
    marginTop: 0,
    marginBottom: 0,
});

const control = (provided, state) => ({
    ...provided,
    border: 'none',
    backgroundColor: '#F0F0F0',
    outline: state.isFocused ? 'none' : provided.outline,
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    height: 26,
    minHeight: 26,
});

export const customStylesMaterial = {
    option,
    control: (provided, state) => ({
        ...provided,
        ...control(provided, state),
        width: 126,
    }),
    container,
    menu: provided => ({
        ...provided,
        width: 450,
        marginTop: 0,
    }),
    menuList,
    indicatorSeparator,
    input,
    dropdownIndicator
};

export const customStylesEdges = {
    option: (provided, state) => ({
        ...provided,
        ...option(provided, state),
        minHeight: 33
    }),
    control: (provided, state) => ({
        ...provided,
        ...control(provided, state),
        width: 35,
    }),
    container,
    menu: provided => ({
        ...provided,
        width: 450,
        marginTop: 0,
    }),
    menuList,
    indicatorSeparator,
    input,
    dropdownIndicator: (provided) => ({
        ...provided,
        ...dropdownIndicator(provided),
        display: 'none',
        width: 0
    })
};

export const customStylesProduct = {
    option,
    control: (provided, state) => ({
        ...provided,
        ...control(provided, state),
        width: 273,
    }),
    container,
    menu: provided => ({
        ...provided,
        width: 273,
        marginTop: 0,
    }),
    menuList,
    indicatorSeparator,
    input,
    dropdownIndicator
};