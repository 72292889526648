import {edgeTitle, empty} from "../../../../helpers/helper";

export const atLeastOneHasErrors = (details) => details.some((detail) => detail?.error?.allErrorsMessage);

export function getArrErrors(arrErr) {
	let type = '';
	
	if (!empty(arrErr)) {
		const priority = {'error': 3, 'warning': 2, 'success': 1};
		let maxPriority = 0;
		
		arrErr.forEach(({type: errType}) => {
			let currentPriority = priority[errType] || 0;
			
			if (currentPriority > maxPriority) {
				type = errType;
				maxPriority = currentPriority;
			}
		});
		
		arrErr.sort((a, b) => {
			return priority[b.type] - priority[a.type];
		});
	}
	
	return {errorsByPriority: arrErr, type};
}

export const scrollToTop = () => {
	window.scrollTo(0, 0);
};

export const disableByMaterialType = (materialType) => ['Compact-плита', 'OSB', 'HDF'].includes(materialType);

export const generateEdgeOptions = (edges, isDisabled = false) => {
	if (edges.length === 0) {
		return [];
	}

	return [
		{value: "", label: ""},
		...edges.map((item, key) => ({
			value: item.index,
			label: `${key + 1}: ${edgeTitle(item)}`,
			shortLabel: `${key + 1}`,
			disabled: isDisabled
		}))
	];
}

export const sortByPropertyDesc = (arr, property) => arr.sort((a, b) => b[property] - a[property]);