import React, { useState, useEffect } from "react";
import "./style.scss";
import DefaultBtn from "../Buttons/DefaultBtn";
import Languages from "../../../translation/Languages";
import Checkbox from "../Inputs/Input/Checkbox";
import CloseBtn from "components/UI/Buttons/CloseBtn";

const FilterSidebar = (
    {   materials,
        edges,
        filterDetails,
        haveTexture,
        isNotTexture,
        isNotMultiplicity,
        haveMultiplicity,
        setFilteredEdges,
        setFilteredThickness,
        setFilteredMaterials,
        setIsNotMultiplicity,
        setIsNotTexture,
        setHaveTexture,
        setHaveMultiplicity,
        isMaterialsOpen,
        setIsMaterialsOpen,
        isEdgesOpen,
        setIsEdgesOpen,
        isThicknessOpen,
        setIsThicknessOpen,
        isTextureOpen,
        setIsTextureOpen,
        isMultiplicityOpen,
        setIsMultiplicityOpen,
        setEdgesForFilter,
        setMaterialsForFilter,
        setThickness,
        thickness,
        close }) => {

    const generateHandleToggle = (type, setFilteredData) => (item) => {
        setFilteredData((prevData) => {
            const newData = [...prevData];
            const itemIndex = newData.indexOf(item);
            item.isChecked = !item?.isChecked;
            if (itemIndex === -1) {
                newData.push(item);
            } else {
                newData.splice(itemIndex, 1);
            }
            return newData;
        });
    };

    const handleToggleMaterial = generateHandleToggle("material", setFilteredMaterials);
    const handleToggleEdge = generateHandleToggle("edge", setFilteredEdges);
    const handleToggleThickness = generateHandleToggle("thickness", setFilteredThickness);
    const resetFilters =() => {
        // Clear materials checkboxes
        const clearedMaterials = materials.map((material) => ({
            ...material,
            isChecked: false
        }));
        setMaterialsForFilter(clearedMaterials);
        setFilteredMaterials([]);

        // Clear edges checkboxes
        const clearedEdges = edges.map((edge) => ({
            ...edge,
            isChecked: false
        }));
        setEdgesForFilter(clearedEdges)
        setFilteredEdges([]);

        // Clear thickness checkboxes
        const clearedThickness = thickness.map((item) => ({
            ...item,
            isChecked: false
        }));

        setThickness(clearedThickness);
        setFilteredThickness([])
        // Clear texture checkboxes
        setIsNotTexture(false);
        setHaveTexture(false);

        // Clear multiplicity checkboxes
        setIsNotMultiplicity(false);
        setHaveMultiplicity(false);

            setIsMaterialsOpen(false);
            setIsEdgesOpen(false);
            setIsThicknessOpen(false);
            setIsTextureOpen(false);
            setIsMultiplicityOpen(false);
    }
    return (
        <div className="filter-sidebar">

                <div className="filter-sidebar_header">
                    <div className="filter-sidebar_header-text">
                        {Languages.getTranslation('filter', true)}
                        <div className="filter-sidebar_header-icon" />
                    </div>
                    <div className="filter-sidebar_header-btn">
                        <CloseBtn handler={close} />
                    </div>
                </div>

          <div className="filter-sidebar_content">
              <div className="filter-sidebar_content-title" onClick={()=> setIsMaterialsOpen(!isMaterialsOpen)}>
                  <div>
                   <span className="filter-sidebar_content-title-text">
                      {Languages.getTranslation('materials', true)}
                   </span>
                  </div>
                  <div className={`filter-sidebar_content-title-angle${isMaterialsOpen? '-open': ''}`} ></div>

              </div>
              {isMaterialsOpen  && (
                  <div className="filter-sidebar_content-materials">
                      {materials.map((material, index) => {
                          return(
                              <div className="filter-sidebar_content-materials-item" key={material.article}>
                                  <Checkbox type="material" isChecked={material.isChecked} value={material} setMaterial={() => handleToggleMaterial(material)}/>
                                  <div className="filter-sidebar_content-materials-item-index">{index + 1}</div>
                                  <div className="filter-sidebar_content-materials-item-text">{Languages.getAvaliableTranslation(material.languages) || material?.name}</div>
                              </div>
                          )
                      })}
              </div>
              )}

              {edges.length !==0 && (
              <div>
              <div className="filter-sidebar_content-title" onClick={()=> setIsEdgesOpen(!isEdgesOpen)}>
                  <div className="filter-sidebar_content-title-text">{Languages.getTranslation('edges', true)}</div>
                  <div className={`filter-sidebar_content-title-angle${isEdgesOpen? '-open': ''}`} ></div>

              </div>
              {isEdgesOpen  && (
                  <div className="filter-sidebar_content-materials">
                      {edges.map((edge) => {
                          return(
                              <div className="filter-sidebar_content-materials-item" key={edge.article}>
                                  <Checkbox type="edge" isChecked={edge.isChecked} value={edge} setEdge={() => handleToggleEdge(edge)}/>
                                  <div className="filter-sidebar_content-materials-item-index">{edge.index + 1}</div>
                                  <div className="filter-sidebar_content-materials-item-text">{Languages.getAvaliableTranslation(edge.languages) || edge?.name}</div>
                              </div>
                          )
                      })}
                  </div>
              )}
              </div>
              )}
              {thickness.length !==0 && (
                  <div>
                  <div className="filter-sidebar_content-title" onClick={()=> setIsThicknessOpen(!isThicknessOpen)}>
                  <div className="filter-sidebar_content-title-text">{Languages.getTranslation('thickness', true)}</div>
                  <div className={`filter-sidebar_content-title-angle${isThicknessOpen? '-open': ''}`} ></div>

              </div>
              {isThicknessOpen  && (
                  <div className="filter-sidebar_content-materials">
                      {thickness.map((item, index) => {
                          return(
                              <div className="filter-sidebar_content-materials-item" key={index}>
                                  <Checkbox type="item" isChecked={item.isChecked} value={item} setMaterial={() => handleToggleThickness(item)}/>
                                  <div className="filter-sidebar_content-materials-item-index">{item.w}</div>
                              </div>
                          )
                      })}
                  </div>
               )}
                  </div>
              )}

              <div className="filter-sidebar_content-title" onClick={()=> setIsTextureOpen(!isTextureOpen)}>
                  <div className="filter-sidebar_content-title-text">{Languages.getTranslation('texture', true)}</div>
                  <div className={`filter-sidebar_content-title-angle${isTextureOpen? '-open': ''}`} ></div>

              </div>
              {isTextureOpen  && (
                  <div className="filter-sidebar_content-materials">
                      <div className="filter-sidebar_content-materials-item">
                          <Checkbox type="value" isChecked={haveTexture} value={haveTexture}
                                    setValue={() => setHaveTexture(!haveTexture)}/>
                          <span className="filter-sidebar_content-materials-item-index">{Languages.getTranslation('with-texture', true)}</span>
                              </div>
                      <div className="filter-sidebar_content-materials-item">
                          <Checkbox type="value" isChecked={isNotTexture} value={isNotTexture}
                                    setValue={() => setIsNotTexture(!isNotTexture)}/>
                          <span className="filter-sidebar_content-materials-item-index">{Languages.getTranslation('without-texture', true)}</span>
                      </div>
                  </div>
              )}
              <div className="filter-sidebar_content-title" onClick={()=> setIsMultiplicityOpen(!isMultiplicityOpen)}>
                  <div className="filter-sidebar_content-title-text">{Languages.getTranslation('multiplicity', true)}</div>
                  <div className={`filter-sidebar_content-title-angle${isMultiplicityOpen? '-open': ''}`} ></div>

              </div>
              {isMultiplicityOpen  && (
                  <div className="filter-sidebar_content-materials">
                      <div className="filter-sidebar_content-materials-item">
                          <Checkbox type="value" isChecked={haveMultiplicity} value={haveMultiplicity}
                                    setValue={() => setHaveMultiplicity(!haveMultiplicity)}/>
                          <span className="filter-sidebar_content-materials-item-index">{Languages.getTranslation('with-multiplicity', true)}</span>
                      </div>
                      <div className="filter-sidebar_content-materials-item">
                      <Checkbox type="value" isChecked={isNotMultiplicity} value={isNotMultiplicity}
                                setValue={() => setIsNotMultiplicity(!isNotMultiplicity)}/>
                      <span className="filter-sidebar_content-materials-item-index">{Languages.getTranslation('without-multiplicity', true)}</span>
                  </div>
                  </div>
              )}
          </div>
            <div className="filter-sidebar-actions">
                <DefaultBtn
                    title={Languages.getTranslation("cancel-filters", true)}
                    handler={resetFilters}
                />
                <DefaultBtn
                    title={Languages.getTranslation("to-apply", true)}
                    handler={filterDetails}
                />
            </div>
        </div>
    );
};

export default FilterSidebar;
