import React, { useState } from 'react';
import "./style.scss";
import Languages from "../../../../../translation/Languages";
import DefaultBtn from "../../../Buttons/DefaultBtn";
import closeBtn from '../../../../../media/close-btn.png'
import {empty} from "../../../../../helpers/helper";
const ModalXYCoordinate = ({ id, saveImage, handleClose,resetAllValuesExceptCurrent, valueY, valueX, setValueX, setValueY }) => {
    const [errorX, setErrorX] = useState(null);
    const [errorY, setErrorY] = useState(null);

    const handleChange =(type, event) => {
        let newValue = event.target.value;

        if (type === 'x' && (newValue < 5 || newValue > 4000)) {
            setErrorX('Значеняя має бути від 5 до 4000');
            setErrorY(null);
        } else if(type === 'y' && (newValue < 5 || newValue > 4000)) {
            setErrorY('Значеняя має бути від 5 до 4000');
            setErrorX(null);
        } else {
            setErrorX(null);
            setErrorY(null);
        }

        resetAllValuesExceptCurrent(id);

        if (type === 'x') {
            setValueX(newValue);
        } else if (type === 'y') {
            setValueY(newValue);
        }
    }

    const checkDisabledState = () => {
        if (!!errorX || !!errorY) {
            return true;
        } else if (id !== 2 && (valueX < 5 || valueX > 4000)) {
            return true;
        } else if (id !== 1 && id !== 5 && (valueY < 5 || valueY > 4000)) {
            return true;
        } else {
            return false;
        }
    };
    const submit = () => {
        saveImage(id)
        handleClose()
    }

    return (
        <div className="modalCoordinate">
            <button className="modalCoordinate-close" onClick={handleClose}>
                <img src={closeBtn} alt="close"/>
            </button>
            <div className="modalCoordinate-blockMain">
                {id !==2 && (

              <div className="modalCoordinate-blockMain-block">
                <label
                    htmlFor="name"
                    className="modalCoordinate-blockMain-block-label"
                >
                    {Languages.getTranslation('X-offset', true)}
                </label>
                <input
                    id="x"
                    name="x"
                    type="number"
                    autoComplete="off"
                    onChange={(event) =>handleChange('x', event)}
                    value={valueX}
                    className="modalCoordinate-blockMain-block-input"

                />
                  {errorX && <div className="modalCoordinate-error">{errorX}</div>}
            </div>
                )}
                {(id !== 1 && id !== 5) && (

                <div className="modalCoordinate-block">
                  <label
                    htmlFor="name"
                    className="modalCoordinate-blockMain-block-label"
                 >
                    {Languages.getTranslation('Y-offset', true)}
                </label>
                  <input
                    id="y"
                    name="y"
                    type="number"
                    autoComplete="off"
                    onChange={(event) =>handleChange('y',event)}
                    value={valueY}
                    className="modalCoordinate-blockMain-block-input"
                  />
                    {errorY && <div className="modalCoordinate-error">{errorY}</div>}

                </div>
                )}

                <div className="modalCoordinate-button">
                  <DefaultBtn
                      title={Languages.getTranslation("form-template", true)}
                      handler={submit}
                      disabled={checkDisabledState()}
                      btnStyle={{height: '40px', width:'115px'}}
                  />
                </div>
         </div>
        </div>

    );
}
export default ModalXYCoordinate