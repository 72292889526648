import React from 'react';
import './style.scss'
import CloseBtn from "../../Buttons/CloseBtn";

const VideoModal = ({show, onHide, video}) => {
  if (!show) {
    return null;
  }
  const url = video && `https://www.youtube.com/embed/${video.videoId}`;
  
  return (
    <div className="modal" onClick={onHide}>
      <div className="close-button">
        <CloseBtn handler={onHide}/>
      </div>
      
      <div className="video-modal-content" onClick={(e) => e.stopPropagation()}>
        <iframe
          width="100%"
          height="100%"
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  )
}

export default VideoModal;