import { createAction } from "@reduxjs/toolkit";
import actionType from "./actionType";

const ConstructionProject = createAction(actionType.Construction);
const setDetail = createAction(actionType.Detail);
const setIsArrowMaterial = createAction(actionType.IsArrowMaterial);
const setIsArrowEdge = createAction(actionType.IsArrowEdge);
const setTreatment = createAction(actionType.Treatment);
const setsceneModel = createAction(actionType.sceneModel);
const errorProject = createAction(actionType.ErrorProject);
const updateDetailsTableMode = createAction(actionType.UpdateDetailsTableMode);
const updateDetailsTableLimits = createAction(actionType.UpdateDetailsTableLimits);
const updateDetailsTableFocusedRow = createAction(actionType.UpdateDetailsTableFocusedRow);
const setDefaultDetailsTableFocusedRow = createAction(actionType.SetDefaultDetailsTableFocusedRow);

export default {
  ConstructionProject,
  setDetail,
  setsceneModel,
  setTreatment,
  errorProject,
  setIsArrowMaterial,
  setIsArrowEdge,
  updateDetailsTableMode,
  updateDetailsTableLimits,
  updateDetailsTableFocusedRow,
  setDefaultDetailsTableFocusedRow
};
