import Db from "../api/Db";
import {empty} from "../helpers/helper";

export default class _Products extends Db {

	constructor() {
		super();
	}

	getProduct(id = 1) {
		return this.getItem('products', id);
	}

	getProductData() {
		return this.getProjectId()
			.then(id => {
				return this.getItem('products', id)
			})

	}

	getProducts() {
		return this.getProduct()
			.then(project => Promise.resolve(project.products))
	}

	updateProduct(id = 1, field, value){
		return this.update('products', id, field, value)
	}

	addProduct(product) {
		if (!empty(product.id)) {
			return this.addItem('products', product).then((pro) => {
				return this.setIndex('products', product.id + 1)
					.then(() => Promise.resolve(pro))
			})
		}
		return this.getIndex('products').then(id => {
			product.id = id
			return this.addItem('products', product)
		})
	}

	deleteProduct(id) {
		return this.removeItem('products', id);
	}

}