import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import "./style.scss";
import Languages from "translation/Languages";
import {errorMessageOperations, errorMessageSelectors} from 'redux/errors'

const Toast = () => {
  const errorMessage = useSelector(errorMessageSelectors.getErrorMessageState)
  const dispatch = useDispatch()

  function ClearButton() {
    const handle = () => {
      toast.dismiss();
      dispatch(errorMessageOperations.removeAllErrors({message: '', drop: true}))
    };

    return (
      <button className="toast-clear" onClick={handle}>
        {Languages.getTranslation("hideAll", true)}
      </button>
    );
  }

  const showValue = errorMessage.length >= 2

  return (
    <>
      {/*{showValue && <ClearButton />}*/}
      <ToastContainer
        style={{ bottom: showValue ? "4em" : "1em" }}
        closeOnClick={true}
        pauseOnHover={true}
        autoClose={2500}
        hideProgressBar={false}
        position={"top-right"}
        draggable={true}
        theme={"colored"}
        limit={window.location.pathname === '/processes'? 5 : 2}
      />
    </>
  );
};

export default Toast;
