const getToken = ({ user }) => {
  return user.user.token
    ? user.user.token.payload.token
    : null;
};

const User = ({ user }) => {
  return user.user.user
    ? user.user.user.payload.user
    : null;
};

const permissions = ({ user }) => {
  return user.user.user ? user.user.user.payload.user.permissions : []
}

export default {
  getToken,
  User,
  permissions
};
