import './style.scss';
import Languages from "../../../../translation/Languages";
import CloseBtn from "../../Buttons/CloseBtn";
import {modalsOperations, modalsSelectors} from "redux/modals";
import {useDispatch, useSelector} from "react-redux";
import DefaultBtn from "../../Buttons/DefaultBtn";
import React, {useEffect, useState} from "react";
import CustomerWarehouseItem from "./CustomerWarehouseItem";
import Requests from "../../../../api/API";
import {userSelectors} from "redux/user";
import {projectSelectors} from "redux/project";
import {useNavigate} from "react-router-dom";
import Loader from "../../Loader";
import {errorMessageOperations} from '../../../../redux/errors'
import {v4 as uuidv4} from "uuid";

const CustomerWarehouse = () => {
	const [materials, setMaterials] = useState([])
	const [chosenMaterials, setChosenMaterials] = useState([])
	const [loader, setLoader] = useState(false)
	const [btnIsDisabled, setBtnIsDisabled] = useState(false)
	const userInfo = useSelector(userSelectors.User)
	const construction = useSelector(projectSelectors.getConstruction);
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const phoneOwner = useSelector(modalsSelectors.getModalIsEdit);
	const materialTypes = ['ЛДСП', 'МДФ','HDF', 'Compact-плита', 'Eurolight', 'Лакобель', 'Фанера', 'Постформинг', 'Угловой элемент','Дзеркало','Скло', "Стеновая панель", "OSB"]

	const getMaterials = async (phone) => {
		setLoader(true)
		const response = await Requests.Materials.getWarehouseMaterials(phone).catch(error => {
			dispatch(errorMessageOperations.switchStateError({message: error.message, type: 'warn', show: true}));
		});

		if (response?.hasOwnProperty('basicInfo')) {
			setMaterials(response.basicInfo)
		} else {
			setMaterials([])
		}
		setLoader(false)
	}

	useEffect(() => {
		const phone = phoneOwner ? phoneOwner : userInfo.phone
		getMaterials(phone)
	}, [])

	useEffect(() => {
		if (materials.length) {
			const materialsWithNewArticle = materials.map((material) => {
				const newArticle = `${material.article}_${material.mc_id}`
				material.article = newArticle
				material.parts = []
				return material
			})
			setMaterials([...materialsWithNewArticle])
		}
	}, [materials.length])

	const close = () => {
		setMaterials([])
		setChosenMaterials([])
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};

	const saveHandler = () => {
		setBtnIsDisabled(true)
		addMaterialsToProject(chosenMaterials)
	}

	const addMaterialsToProject = (arr) => {
		construction.setProjectMaterials([...arr]).then(() => {
				dispatch(errorMessageOperations.switchStateError(
					{message: Languages.getTranslation("material-success", true), type: 'success', show: true}))
				close();
				navigate("/details");
		})
	}

	const setMaterial = (material) => {
		if (chosenMaterials.find((el) => el === material)) {
			setChosenMaterials(chosenMaterials.filter((el) => el !== material));
		} else {
			setChosenMaterials([...chosenMaterials, material]);
		}
	};

	const disabled = !chosenMaterials.length

	return loader ? <Loader/> : <div className='customerWarehouse'>
		<>
			<div className="customerWarehouse__header">
			<span
				className="customerWarehouse__header-title">{Languages.getTranslation('title-customer-warehouse', true)}</span>
				<CloseBtn handler={close}/>
			</div>
			<div className="customerWarehouse__table">
				<div className="customerWarehouse__table-header">
					<div className="customerWarehouse__data-content">
						<div className="customerWarehouse__data">{Languages.getTranslation('height', true)}</div>
						<div className="customerWarehouse__data">{Languages.getTranslation('width', true)}</div>
						<div
							className="customerWarehouse__data customerWarehouse__sheetThickness">{Languages.getTranslation('sheet-thickness', true)}</div>
						<div className="customerWarehouse__data">{Languages.getTranslation('amount', true)}</div>
						<div
							className="customerWarehouse__data customerWarehouse__warehouse">{Languages.getTranslation('warehouse', true)}</div>
						<div className="customerWarehouse__data customerWarehouse__article">{Languages.getTranslation('article', true)}</div>
						<div className="customerWarehouse__data customerWarehouse__name ">{Languages.getTranslation('name', true)}</div>
						<div className="customerWarehouse__data customerWarehouse__name">{Languages.getTranslation('create-time', true)}</div>
					</div>
					<div className="customerWarehouse__select-container" style={{background: 'white'}}>
						<div
							className="customerWarehouse__data customerWarehouse__materialType">{Languages.getTranslation('material-type', true)}</div>
					</div>
				</div>
				<div className="customerWarehouse__table-body">
					{materials.length ? materials.map(material => {
						const uuid = uuidv4();
						return <CustomerWarehouseItem chosenMaterials={chosenMaterials}
																					key={uuid}
																					material={material}
																					setMaterial={setMaterial}
																					materialTypes={materialTypes}/>
					}) : <div
						className='customerWarehouse__not-materials'>{Languages.getTranslation('dont-have-material', true)}</div>}
				</div>
			</div>
			<div className="customerWarehouse__footer">
				<div className="customerWarehouse__btns">
					<DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>
					<DefaultBtn title={Languages.getTranslation('add-to-project', true)} handler={saveHandler}
								disabled={disabled || btnIsDisabled}/>
				</div>
			</div>
		</>
	</div>
}

export default CustomerWarehouse