import { empty, prepareObject } from "../helpers/helper";
import cloneDeep from "lodash.clonedeep";
import Languages from "../translation/Languages";

const newProjectData = {
    name: Languages.getTranslation("new-project", true),
    created: new Date(),
    glue: null
}
export default class Db {
    dbName = 'KmDatabase';
    dbVersion = 9;
    dbObjects = {
        materials : {keyPath: 'index'},
        edges : {keyPath: 'index'},
        details: {keyPath: 'id', autoIncrement: true},
        furnitures: {keyPath: 'index'},
        ids: {keyPath: 'id', autoIncrement: true},
        project: {keyPath: 'id'},
        products: {keyPath: 'id'}
    };

    constructor() {
        const request = indexedDB.open(this.dbName, this.dbVersion);
        request.onupgradeneeded = () => {
            const db = request.result;
            for(const obj in this.dbObjects) {
                if(!db.objectStoreNames.contains(obj)) {
                    const _object = db.createObjectStore(obj, this.dbObjects[obj])
                    switch (obj) {
                        case 'details':
                            _object.createIndex("material_idx", 'material');
                            break;
                    }
                }
            }
            this.addIdsData()
              .then(() => this.addProject())
              .catch(e => console.log(e))
        }
    }

    checkUser(id) {
        return this.getItem('ids', 1)
            .then(data => {
                if(empty(data) || empty(data.user) || data.user !== id) {
                    return this.dropAllData()
                        .then(() => this.setIndex('user', id))
                        .then(() => this.addProject());
                } else {
                    return Promise.resolve();
                }
            })
    }

    getUserId() {
        return this.getItem('ids', 1)
            .then(data => {
                if(!empty(data)) return Promise.resolve(data.user);
                return Promise.resolve(localStorage.getItem('uuid'))
            })
    }

    setIndex(type, val) {
        return this.update('ids', 1,  type, val);
    }

    getIndex(type) {
        let id;
        return this.getItem('ids', 1)
            .then((data) => {
                id = data[type];
                return this.setIndex(type, data[type] + 1)
            })
            .then(() => Promise.resolve(id))
    }

    addIdsData() {
        return this.getUserId()
            .then(id => this.addItem('ids', {
                id: 1,
                materials: 0,
                edges: 0,
                furnitures: 0,
                details: 0,
                user: id,
                project: 0,
                products: 1
            }))
    }

    checkType(type) {
        return type && Object.keys(this.dbObjects).indexOf(type) !== -1
    }

    addItem(type, item) {
        return new Promise((resolve, reject) => {
            if(!this.checkType(type)) {
                reject({Error: 'incorrect query type'})
            }
            if(empty(item) || typeof item !== 'object') {
                reject('incorrect query data')
            }

            const openRequest  = indexedDB.open(this.dbName, this.dbVersion);

            openRequest.onerror = () => reject({Error: openRequest.error});

            openRequest.onsuccess = () => {
                const transaction = openRequest.result.transaction(type, "readwrite");
                const request = transaction.objectStore(type).add(item);

                request.onerror = e => reject({Error: request.error})
                request.onsuccess = () => {
                    resolve(item);
                }
            }
        })
    }

    getItem(type, _id = null) {
        return new Promise((resolve, reject) => {
            if(!this.checkType(type)) {
                reject({Error: 'incorrect query type'})
            }
            const id = Number(_id);
            if(empty(id)) {
                reject('incorrect query id')
            }
            const openRequest  = indexedDB.open(this.dbName, this.dbVersion);

            openRequest.onerror = () => reject({Error: openRequest.error});

            openRequest.onsuccess = () => {
                const transaction = openRequest.result.transaction(type);
                const request = transaction.objectStore(type).get(id);

                request.onerror = e => reject({Error: request.error})
                request.onsuccess = () => {
                    resolve(request.result);
                }
            }
        })
    }

    /**
     * Retrieves an item from IndexedDB by its id.
     *
     * @param {string} type - The name of the object store in the IndexedDB.
     * @param {number} [id=null] - The id of the item to retrieve.
     * @returns {Promise} A promise that resolves with the retrieved item or rejects with an error.
     */
    getItemById(type, id = null) {
        return new Promise((resolve, reject) => {
            if(!this.checkType(type)) {
                reject({Error: 'incorrect query type'})
            }
            // const id = Number(_id);
            // if(!id) {
            //     reject('incorrect query id')
            // }
            const openRequest  = indexedDB.open(this.dbName, this.dbVersion);

            openRequest.onerror = () => reject({Error: openRequest.error});

            openRequest.onsuccess = () => {
                const transaction = openRequest.result.transaction(type);
                const request = transaction.objectStore(type).get(id);

                request.onerror = e => reject({Error: request.error})
                request.onsuccess = () => {
                    resolve(request.result);
                }
            }
        })
    }

    removeItem(type, id) {
        return new Promise((resolve, reject) => {
            if(!this.checkType(type)) {
                reject('incorrect query type')
            }
            const _id = Number(id);
            if(empty(_id)) {
                reject('incorrect query id')
            }
            const openRequest  = indexedDB.open(this.dbName, this.dbVersion);

            openRequest.onerror = () => reject({Error: openRequest.error});

            openRequest.onsuccess = () => {
                const transaction = openRequest.result.transaction(type, "readwrite");
                const request = transaction.objectStore(type).delete(_id);

                request.onerror = e => reject({Error: request.error})
                request.onsuccess = () => {
                    resolve();
                }
            }
        })
    }

    getAllData(type) {
        return new Promise((resolve, reject) => {
            if(!this.checkType(type)) {
                reject('incorrect query type')
            }

            const openRequest  = indexedDB.open(this.dbName, this.dbVersion);

            openRequest.onerror = () => reject({Error: openRequest.error});

            openRequest.onsuccess = () => {
                const transaction = openRequest.result.transaction(type)
                const request = transaction.objectStore(type).getAll()

                request.onerror = e => reject({Error: request.error})

                request.onsuccess = () => {
                    resolve(request.result);
                }
            }

        })
    }

    checkIfData() {
        return new Promise((resolve, reject) => {
            const openRequest  = indexedDB.open(this.dbName, this.dbVersion);

            openRequest.onerror = () => reject({Error: openRequest.error});

            openRequest.onsuccess = () => {
                const transactionM = openRequest.result.transaction('materials');
                const materials = transactionM.objectStore('materials').getAll(null, 1);

                materials.onerror = e => reject({Error: materials.error})
                materials.onsuccess = () => {
                    if(materials.result) {
                        resolve(materials.result)
                    } else {
                        const transactionD = openRequest.result.transaction('details');
                        const details = transactionD.objectStore('details').getAll(null, 1);

                        details.onerror = e => reject({Error: details.error})
                        details.onsuccess = () => {
                            if(details.result) {
                                resolve(details.result)
                            } else {
                                resolve([]);
                            }
                        }
                    }
                }

            }
        })
    }

    update(type, _id = null, field = null, val = null) {
        const id = Number(_id);
        if(!this.checkType(type) || empty(id) || empty(field)) {
            return Promise.reject('Incorrect data');
        }
        let value = val;
        if(typeof val === 'object' && type === 'details') {
            value = prepareObject(val);
        } else {
            value = val;
        }

        return new Promise((resolve, reject) => {
            const openRequest  = indexedDB.open(this.dbName, this.dbVersion);

            openRequest.onerror = () => reject({Error: openRequest.error});

            openRequest.onsuccess = () => {
                const objectStore = openRequest.result
                    .transaction(type, "readwrite")
                    .objectStore(type);
                const request = objectStore.get(id)


                request.onerror = () => reject({Error: request.error});

                request.onsuccess = () => {
                    const data = request.result;
                    if(!empty(data)){
                    data[field] = value;
                    const updateRequest = objectStore.put(data);
                    updateRequest.onsuccess = () => {
                        resolve(data);
                    }
                    updateRequest.onerror = () => reject({ Error: updateRequest.error })
                    }
                }
            }
        })
    }

    getDataByKey(type, key, query) {
        if(!this.checkType(type)) {
            return Promise.reject('incorrect query type')
        }
        return new Promise((resolve, reject) => {
            const openRequest  = indexedDB.open(this.dbName, this.dbVersion);

            openRequest.onerror = () => reject({Error: openRequest.error});

            openRequest.onsuccess = () => {
                const objectStore = openRequest.result
                    .transaction(type)
                    .objectStore(type);
                const usedIndex = key + '_idx'
                const index = objectStore.index(usedIndex)
                const request = index.getAll(query)

                request.onerror = () => reject({Error: request.error});

                request.onsuccess = () => {
                    resolve(request.result);
                }
            }
        })
    }

    /**
     * Retrieves all details.
     *
     * @returns {Promise} A promise that resolves with the details data.
     */
    getAllDetails() {
        return new Promise((resolve, reject) => {
            this.getAllData('details')
                .then(data => resolve(data))
        })
    }

    detDataFromDb() {
        return new Promise((resolve, reject) => {
            Promise.all([
                    this.getAllData('materials'),
                    this.getAllData('edges'),
                    this.getAllData('details'),
                    this.getAllData('furnitures'),
                    this.getAllData('project'),
                    this.getAllData('products')
                ])
                .then(data => {
                    const sorted = {
                            product: [],
                            department: 1,
                            projectData: data[4][0] || {name: null},
                            edges: data[1],
                            materials: data[0],
                            furnitures: data[3],
                            details: data[2],
                            products: data[5]
                    }
                    // console.log('detDataFromDb', sorted)
                    if(!empty(data[0]) || !empty(data[1])) {
                        return Promise.resolve(sorted)
                    } else {
                        return Promise.resolve(null)
                    }
                })
                .then(data => resolve(data))
                .catch(e => reject(e))
        })
    }

    dropTable(type) {
        if(!this.checkType(type)) {
            return Promise.reject({Error: 'incorrect type'});
        }

        if(type === "ids") {
            return Promise.all([
                'materials',
                'edges',
                'furnitures',
                'details',
                'project',
                'products'
            ].map(el => this.update('ids', 1, el, el === "products" ? 1 : 0)))
        }

        return new Promise((resolve, reject) => {
            const openRequest  = indexedDB.open(this.dbName, this.dbVersion);

            openRequest.onerror = () => reject({Error: openRequest.error});

            openRequest.onsuccess = () => {
                const objectStore = openRequest.result
                    .transaction(type, "readwrite")
                    .objectStore(type);

                const request = objectStore.clear();
                request.onerror = () => reject({Error: request.error});

                request.onsuccess = () => resolve();
            }
        })
    }

    addProject(data = null) {
        if (!empty(data)) {
            return this.addItem('project', data).then(() => Promise.resolve(data.id))
        }
        return this.getIndex('project')
          .then(id => {
              newProjectData.id = id;
              return this.addItem('project', {...newProjectData})

          })
          .then((data) => Promise.resolve(data))
          .catch(err => console.log(err))
    }

    dropAllData() {
        return Promise.all(Object.keys(this.dbObjects).map(type => this.dropTable(type)))
    }
    dropMaterialsData() {
        return this.dropTable('materials');
    }

    updateDetailProcessing(detailId, processingId, name, value) {
        this.getItem('details', detailId)
          .then(detail => {
              const tmp_detail = cloneDeep(detail);
              let key;
              const processing = {};
              processing.rects = tmp_detail.rects.find(el => el.id === processingId);
              processing.mills = tmp_detail.mills.find(el => el.id === processingId);
              processing.corners = tmp_detail.corners.find(el => el.id === processingId);
              processing.holes = tmp_detail.holes.find(el => el.id === processingId);
              processing.bevels = tmp_detail.bevels?.find(el => el.id === processingId);

              if(!empty(processing)) {
                  for(let i in processing) {
                      if(!empty(processing[i])) {
                          processing[i][name] = value;
                          key = i;
                          break;
                      }
                  }
              }

              if(!empty(key)) {
                  return this.update('details', detailId, key, tmp_detail[key]);
              } else {
                  return Promise.reject('empty processing')
              }

          })
          .then(() => console.log(1))
          .catch(() => console.log(2))
    }
}