import CloseBtn from "../../Buttons/CloseBtn";
import {useDispatch, useSelector} from "react-redux";
import {modalsOperations, modalsSelectors} from "redux/modals";
import './style.scss';
import Languages from "../../../../translation/Languages";
import {useEffect, useState} from "react";
import ImportErrorModalItem from "./ImportErrorModalItem";
import {useNavigate} from "react-router-dom";

const ImportErrorModal = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const errorObject = useSelector(modalsSelectors.getModalIsEdit);
	const [errors, setErrors] = useState([])

	useEffect(() => {
		if (errorObject.hasOwnProperty('required')) {
			setErrors(Object.entries(errorObject.required))
		}
	}, [])

	const close = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
		navigate('/')
	};

	const transFormErrorText = (text) => {
		if (text === 'not valid xml') {
			return Languages.getTranslation('import-project-error', true)
		} else {
			return text
		}
	}

	return <div className='importErrorModal'>
		<div className="importErrorModal__header">
			<CloseBtn handler={close}/>
		</div>
		<div className="importErrorModal__body">
			<div
				className="importErrorModal__body-text-error">
				{errorObject.hasOwnProperty('text') ? transFormErrorText(errorObject.text) : errorObject.types?.root_detail}</div>
			<div className="importErrorModal__required-list">{errors.length ? errors.map(el =>
				<ImportErrorModalItem error={el}/>) : ''}</div>
		</div>
	</div>
}

export default ImportErrorModal