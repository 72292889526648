export function calculateDirection(object) {
    const dx = object.x2 - object.x1;
    const dy = object.y2 - object.y1;
    const len = Math.sqrt(dx * dx + dy * dy);
    return { x: dy / len, y: -dx / len };
}

export function calculateDirectionForArc(arcObject) {
    const { xc, yc, startAngleRad, endAngleRad } = arcObject;

    const middleAngleRad = (startAngleRad + endAngleRad) / 2;

    const dirX = Math.cos(middleAngleRad);
    const dirY = Math.sin(middleAngleRad);

    const isLargeArc = Math.abs(endAngleRad - startAngleRad) > Math.PI;

    if (isLargeArc) {
        return {x: -dirX, y: -dirY};
    }

    return {x: dirX, y: dirY};
}


export function roundPolygon(draw, pointsHoles, radius) {
    let commands = [];

    for(let i=0; i<pointsHoles.length; i++) {
        let [x,y] = pointsHoles[i];
        let [px,py] = pointsHoles[i-1 >= 0 ? i-1 : pointsHoles.length-1];   // предыдущая точка
        let [nx,ny] = pointsHoles[i+1 < pointsHoles.length ? i+1 : 0];       // следующая точка

        let startAngle = Math.atan2(py - y, px - x);
        let endAngle = Math.atan2(ny - y, nx - x);

        // SVG path command for an arc
        let largeArcFlag = endAngle - startAngle <= Math.PI ? 0 : 1;
        let command = ["A", radius, radius, 0, largeArcFlag, 1, nx, ny].join(" ");
        commands.push(command);
    }

    let pathData = "M" + pointsHoles[0] + " " + commands.join(" ");
    draw.path(pathData).fill("#ffffff").stroke({ color: "#000000", width: 3 });
}

export function calculateInnerCoordinate(holeCoordinate, maxX, maxY, depth, isInner) {
    const { x, y, diam, x_axis, y_axis, side, directionSide } = holeCoordinate;
    let radius = isInner? (diam - depth) / 2 : diam / 2;

    let adjustedX;
    let adjustedY;
    switch (side) {
        case 'right':
            adjustedX = maxX - x;
            break;
        case 'left':
            adjustedX = x;
            break;
        default:
            if(x_axis === 'right'){
                adjustedX = maxX - x;

            }else {
                adjustedX =  x;

            }
            break;
    }

    switch (side) {
        case 'top':
            adjustedY = maxY-y;
            break;
        case 'bottom':
            adjustedY = y;
            break;
        default:
            if(y_axis === 'top'){
                adjustedY = maxY-y;

            }else {
                adjustedY = y;

            }
            break;
    }

    let hole1 = [adjustedX, adjustedY + radius];
    let hole2 = [adjustedX, adjustedY - radius];

    return hole1.join(",") + " " + hole2.join(",");
}

export function calculateSquareCoordinates(square, maxX, maxY) {
    // Extract the necessary properties from the square object
    let { x, y, width, height, x_axis, y_axis, edgeSide } = square;

    // Define half-dimensions based on the edgeSide
    let halfDimension1 = ['left', 'right'].includes(edgeSide) ? height / 2 : width / 2;
    let halfDimension2 = ['left', 'right'].includes(edgeSide) ? width / 2 : height / 2;

    // Variables for adjusted values
    let adjustedX;
    let adjustedY;

    // Adjust x-coordinate based on the x_axis property.
    switch (x_axis) {
        case 'right':
            adjustedX = maxX - x + halfDimension1;
            break;
        case 'left':
        case 'default':
            adjustedX = x + halfDimension1;
            break;
    }

    // Adjust y-coordinate based on the y_axis property.
    switch (y_axis) {
        case 'bottom':
            adjustedY = y + halfDimension2;
            break;
        case 'top':
            adjustedY = maxY - y + halfDimension2;
            break;
        case 'default':
            adjustedY = y + halfDimension2;
            break;
    }

    // Define the four points of the square
    let pt1 = [adjustedX - halfDimension1, adjustedY - halfDimension2];
    let pt2 = [adjustedX + halfDimension1, adjustedY - halfDimension2];
    let pt3 = [adjustedX + halfDimension1, adjustedY + halfDimension2];
    let pt4 = [adjustedX - halfDimension1, adjustedY + halfDimension2];

    // Combine the points into a string
    return pt1.join(",") + " " + pt2.join(",") + " " + pt3.join(",") + " " + pt4.join(",");

}

export function calculateGrooveCoordinates(groove, maxX, maxY) {
    let { x, y, width, height, x_axis, y_axis, _direction, side, r } = groove;
    let adjustedX;
    let adjustedY;

    switch (x_axis) {
        case 'right':
            adjustedX = _direction === "ver" ? maxX - x : maxX - x;

            break;
        case 'left':
        default:
            adjustedX = (_direction === "ver") ? x : x + width;
            break;
    }

    switch (y_axis) {
        case 'top':
            if(side === 'front' || side === "back"){
                adjustedY = maxY - y

            }else if(_direction === "ver"){
                adjustedY = maxY - width
            } else {
                adjustedY = maxY - height
            }
        break;
        case 'bottom':
        default:
             if(side === "top" && _direction === "ver"){
                 adjustedY =  maxY
             } else if(_direction === "hor") {

                 adjustedY =  y
             }else if(_direction === "ver" && maxX===width) {
                 adjustedY =  maxY
             }else{
                 adjustedY =  y + width
             }
    }

    let p1, p2, p3, p4;
    if (_direction === 'ver') {
        p1 = [adjustedX + height, adjustedY];
        p2 = [adjustedX + height, adjustedY - width];
        p3 = [adjustedX , adjustedY - width];
        p4 = [adjustedX , adjustedY];
    } else {
        p1 = [adjustedX, adjustedY  + height];
        p2 = [adjustedX - width, adjustedY  + height];
        p3 = [adjustedX - width, adjustedY];
        p4 = [adjustedX, adjustedY];
    }

    return p1.join(",") + " " + p2.join(",") + " " + p3.join(",") + " " + p4.join(",");
}
