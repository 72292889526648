import Helpers from "../3D/Helpers";
import SizeArrow from "../3D/components/SizeArrow";
import {empty} from "../../helpers/helper";

export default class CompactLock {
  cutout
  _side
  _depth
  _x
  _type
  _width
  _height
  _compactLockType
  constructor(cutout) {
    this.cutout = cutout;
    this._side = cutout._side
    this._depth = cutout._depth
    this._x = cutout._x
    this._y = cutout._y
    this._type = cutout._type
    this._width = cutout._width
    this._height = cutout._height
    this._elements = cutout.elements
    this._compactLockType = cutout.compactLockType

    if(empty(cutout.height)) {
      this._height = 49
    }

    if(empty(cutout.width)) {
      this._width = 20
    }
    this.initRequiredFields()
  }

  initRequiredFields() {
    this.cutout.requiredFields = {
      x: {
        max: this.cutout.detail.l,
        min: 0
      },
      y: {
        max: this.cutout.detail.h - this.height,
        min: 0
      },
      depth: {
        min: 1,
        max: 15
      },
    }
  }

  addParams() {
    // this.cutout.setFormField({name: 'type', value: this.type, type: 'number', label: 'Тип обробки'})
    this.cutout.setFormField({name: 'x', value: this.x, type: 'number', label: 'x (px)'})
    this.cutout.setFormField({name: 'y', value: this.y, type: 'number', label: 'y (py)'})
    this.cutout.setFormField({name:'side', value: this.side, label: 'сторона', type: 'select', variables: [
        { key: "front", value: "Лицьова" },
        { key: "back", value: "Тильна" }
      ], visible: true})
    this.cutout.setFormField({
      name: 'depth', value: this.depth, type: 'number', label: 'глибина'
    })
  }

  get paramsSorting() {
    const sortingArr = ['side', 'y', 'x', 'depth', 'comment']

    return this.cutout.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
  }

  get cutOut() {
    const cutout = {
      color: this.cutout.getMeshColor(),
      height: ['left', "right"].includes(this.cutout._edgeSide) ? 20 : 49,
      width: ['left', "right"].includes(this.cutout._edgeSide) ? 49 : 20,
      elements: [],
      x: this.x,
      y: this.y,
      type: 'compactLock',
      subType: this.cutout.subType,
      edge: this.cutout.edge,
      depth: this.depth,
      id: this.cutout.id,
      side: this.side
    }
    const delta = 10.1
    switch (this.compactLockType) {
      case 3 :
        switch (this.cutout._edgeSide) {
          case 'right':
            cutout.elements.push(Helpers.createLine(60.1, 4.1 - delta, 43.225, 4.1 - delta))
            cutout.elements.push(Helpers.createArc(43.225, 4.1 - delta, 35.1, 0 - delta, 10.1, 35.101, 10.099 - delta, true))
            cutout.elements.push(Helpers.createLine(35.1, 0 - delta, 10.1, 0 - delta))
            cutout.elements.push(Helpers.createArc(10.1, 0 - delta, 10.1, 20.2 - delta, 10.1, 10.1, 10.1 - delta, true))
            cutout.elements.push(Helpers.createLine(10.1, 20.2 - delta, 35.1, 20.2 - delta))
            cutout.elements.push(Helpers.createArc(35.1, 20.2 - delta, 43.225, 16.1 - delta, 10.1, 35, 10 - delta, true))
            cutout.elements.push(Helpers.createLine(43.225, 16.1 - delta, 60.1, 16.1 - delta))
            cutout.elements.push(Helpers.createLine(60.1, 16.1 - delta, 60.1, 4.1 - delta))
            break
          case 'left':
            cutout.elements.push(Helpers.createArc(16.875, 16.1 - delta, 25, 20.2 - delta, 10.1, 25, 10.1 - delta, true))
            cutout.elements.push(Helpers.createLine(25, 20.2 - delta, 50, 20.2 - delta))
            cutout.elements.push(Helpers.createArc(50, 20.2 - delta, 50, 0 - delta, 10.1, 50, 10.1 - delta, true))
            cutout.elements.push(Helpers.createLine(50, 0 - delta, 25, 0 - delta))
            cutout.elements.push(Helpers.createArc(25, 0 - delta, 16.875, 4.1 - delta, 10.1, 25, 10.1 - delta, true))
            cutout.elements.push(Helpers.createLine(16.875, 4.1 - delta, 0, 4.1 - delta))
            cutout.elements.push(Helpers.createLine(0, 4.1 - delta, 0, 16.1 - delta))
            cutout.elements.push(Helpers.createLine(0, 16.1 - delta, 16.875, 16.1 - delta))
            break
          case 'top':
            cutout.elements.push(Helpers.createLine(4.1 - delta, 43.225, 4.1 - delta, 60.1))
            cutout.elements.push(Helpers.createLine(4.1 - delta, 60.1, 16.1 - delta, 60.1))
            cutout.elements.push(Helpers.createLine(16.1 - delta, 60.1, 16.1 - delta, 43.225))
            cutout.elements.push(Helpers.createArc(16.1 - delta, 43.225, 20.2 - delta, 35.1, 10.1, 10.1 - delta, 35.1, true))
            cutout.elements.push(Helpers.createLine(20.2 - delta, 35.1, 20.2 - delta, 10.1))
            cutout.elements.push(Helpers.createArc(20.2 - delta, 10.1, 0 - delta, 10.1, 10.1, 10.1 - delta, 10.1, true))
            cutout.elements.push(Helpers.createLine(0 - delta, 10.1, 0 - delta, 35.1))
            cutout.elements.push(Helpers.createArc(0 - delta, 35.1, 4.1 - delta, 43.225, 10.1, 10.1 - delta, 35.1, true))
            break
          case 'bottom':
            cutout.elements.push(Helpers.createArc(4.1 - delta, 16.875, 0 - delta, 25, 10.1, 10.1 - delta, 25, true))
            cutout.elements.push(Helpers.createLine(0 - delta, 25, 0 - delta, 50))
            cutout.elements.push(Helpers.createArc(0 - delta, 50, 20.2 - delta, 50, 10.1, 10.1 - delta, 50, true))
            cutout.elements.push(Helpers.createLine(20.2 - delta, 50, 20.2 - delta, 25))
            cutout.elements.push(Helpers.createArc(20.2 - delta, 25, 16.1 - delta, 16.875, 10.1, 10.1 - delta, 25, true))
            cutout.elements.push(Helpers.createLine(16.1 - delta, 16.875, 16.1 - delta, 0))
            cutout.elements.push(Helpers.createLine(16.1 - delta, 0, 4.1 - delta, 0))
            cutout.elements.push(Helpers.createLine(4.1 - delta, 0, 4.1 - delta, 16.875))
            break
        }
        break
      case 2 :
        switch (this.cutout._edgeSide) {
          case 'right':
            cutout.elements.push(Helpers.createLine(35.1, 16.1 - delta, 35.1, 4.1 - delta))
            cutout.elements.push(Helpers.createLine(35.1, 4.1 - delta, 18.225, 4.1 - delta))
            cutout.elements.push(Helpers.createArc(18.225, 4.1 - delta, 18.225, 16.1 - delta, 10.1, 10.1, 10.1 - delta, true))
            cutout.elements.push(Helpers.createLine(18.225, 16.1 - delta, 35.1, 16.1 - delta))
            break
          case 'left':
            cutout.elements.push(Helpers.createLine(0, 4.1 - delta, 0, 16.1 - delta))
            cutout.elements.push(Helpers.createLine(0, 16.1 - delta, 16.875, 16.1 - delta))
            cutout.elements.push(Helpers.createArc(16.875, 16.1 - delta, 16.875, 4.1 - delta, 10.1, 25, 10.1 - delta, true))
            cutout.elements.push(Helpers.createLine(16.875, 4.1 - delta, 0, 4.1 - delta))
            break
          case 'top':
            cutout.elements.push(Helpers.createLine(16.1 - delta, 35.1, 16.1 - delta, 18.225))
            cutout.elements.push(Helpers.createArc(16.1 - delta, 18.225, 4.1 - delta, 18.225, 10.1, 10.1 - delta, 10.1, true))
            cutout.elements.push(Helpers.createLine(4.1 - delta, 18.225, 4.1 - delta, 35.1))
            cutout.elements.push(Helpers.createLine(4.1 - delta, 35.1, 16.1 - delta, 35.1))
            break
          case 'bottom':
            cutout.elements.push(Helpers.createLine(4.1 - delta, 0, 4.1 - delta, 16.875))
            cutout.elements.push(Helpers.createArc(4.1 - delta, 16.875, 16.1 - delta, 16.875, 10.1, 10.1 - delta, 25, true))
            cutout.elements.push(Helpers.createLine(16.1 - delta, 16.875, 16.1 - delta, 0))
            cutout.elements.push(Helpers.createLine(16.1 - delta, 0, 4.1 - delta, 0))
            break
        }
        break
      case 1 :
        switch (this.cutout._edgeSide) {
          case 'right':
            cutout.elements.push(Helpers.createLine(6, 16.1 - delta, 22.875, 16.1 - delta))
            cutout.elements.push(Helpers.createArc(22.875, 16.1 - delta, 39.125, 16.1 - delta, 10.1, 31, 10.1 - delta, true))
            cutout.elements.push(Helpers.createLine(39.125, 16.1 - delta, 56, 16.1 - delta))
            cutout.elements.push(Helpers.createLine(56, 16.1 - delta, 56, 4.1 - delta))
            cutout.elements.push(Helpers.createLine(56, 4.1 - delta, 39.125, 4.1 - delta))
            cutout.elements.push(Helpers.createArc(39.125, 4.1 - delta, 22.875, 4.1 - delta, 10.1, 31, 10.1 - delta, true))
            cutout.elements.push(Helpers.createLine(22.875, 4.1 - delta, 6, 4.1 - delta))
            cutout.elements.push(Helpers.createArc(6, 4.1 - delta, 6, 16.1 - delta, 6, 6, 10.1 - delta, true))
            break
          case 'left':
            cutout.elements.push(Helpers.createLine(50, 4.1 - delta, 33.125, 4.1 - delta))
            cutout.elements.push(Helpers.createArc(33.125, 4.1 - delta, 16.875, 4.1 - delta, 10.1, 25, 10.1 - delta, true))
            cutout.elements.push(Helpers.createLine(16.875, 4.1 - delta, 0, 4.1 - delta))
            cutout.elements.push(Helpers.createLine(0, 4.1 - delta, 0, 16.1 - delta))
            cutout.elements.push(Helpers.createLine(0, 16.1 - delta, 16.875, 16.1 - delta))
            cutout.elements.push(Helpers.createArc(16.875, 16.1 - delta, 33.125, 16.1 - delta, 10.1, 25, 10.1 - delta, true))
            cutout.elements.push(Helpers.createLine(33.125, 16.1 - delta, 50, 16.1 - delta))
            cutout.elements.push(Helpers.createArc(50, 16.1 - delta, 50, 4.1 - delta, 6, 50, 10.1 - delta, true))
            break
          case 'top':
            cutout.elements.push(Helpers.createLine(4.1 - delta, 6, 4.1 - delta, 22.875))
            cutout.elements.push(Helpers.createArc(4.1 - delta, 22.875, 4.1 - delta, 39.125, 10.1, 10.1 - delta, 31, true))
            cutout.elements.push(Helpers.createLine(4.1 - delta, 39.125, 4.1 - delta, 56))
            cutout.elements.push(Helpers.createLine(4.1 - delta, 56, 16.1 - delta, 56))
            cutout.elements.push(Helpers.createLine(16.1 - delta, 56, 16.1 - delta, 39.125))
            cutout.elements.push(Helpers.createArc(16.1 - delta, 39.125, 16.1 - delta, 22.875, 10.1, 10.1 - delta, 31, true))
            cutout.elements.push(Helpers.createLine(16.1 - delta, 22.875, 16.1 - delta, 6))
            cutout.elements.push(Helpers.createArc(16.1 - delta, 6, 4.1 - delta, 6, 6, 10.1 - delta, 6, true))
            break
          case 'bottom':
            cutout.elements.push(Helpers.createArc(4.1 - delta, 50, 16.1 - delta, 50, 6, 10.1 - delta, 50, true))
            cutout.elements.push(Helpers.createLine(16.1 - delta, 50, 16.1 - delta, 33.125))
            cutout.elements.push(Helpers.createArc(16.1 - delta, 33.125, 16.1 - delta, 16.875, 10.1, 10.1 - delta, 25, true))
            cutout.elements.push(Helpers.createLine(16.1 - delta, 16.875, 16.1 - delta, 0))
            cutout.elements.push(Helpers.createLine(16.1 - delta, 0, 4.1 - delta, 0))
            cutout.elements.push(Helpers.createLine(4.1 - delta, 0, 4.1 - delta, 16.875))
            cutout.elements.push(Helpers.createArc(4.1 - delta, 16.875, 4.1 - delta, 33.125, 10.1, 10.1 - delta, 25, true))
            cutout.elements.push(Helpers.createLine(4.1 - delta, 33.125, 4.1 - delta, 50))
            break
        }
        break
    }

    this.elements = cutout.elements
    return cutout
  }

  get realData() {
    return {
      height: ['left', "right"].includes(this.cutout._edgeSide) ? this.width : this.height ,
      width: ['left', "right"].includes(this.cutout._edgeSide) ? this.height : this.width ,
      elements: this.elements,
      type: this.type,
      x: this.xCalc,
      y: this.yCalc,
      edgeSide: this.cutout._edgeSide,
      edge: this.cutout.edge,
      depth: this.depth,
      comment: this.cutout.comment,
      id: this.cutout.id,
      side: this.side,
      compactLockType: this.compactLockType,
      dataForConstructor: this.cutout.dataForConstructor
    }
  }

  addSizesArrows() {
    if(!this.cutout.isActive) return false;
    const pos = "left_bottom"
    return SizeArrow.getElementSizeArrow(
      this.x,
      this.y,
      null,
      pos,
      this.cutout.detail
    )
  }

  /*Getters and Setters*/
  get x(){
    return this._x;
  }

  set x (x){
    if(typeof x === 'number'){
      if(!this.cutout.checkRequiredField('x', x)) {
        this._x = x;
        this.cutout.setFormField({name: 'x', value: this.x})
      } else {
        this.cutout.showErrors();
      }
    }
    // this.initRequiredFields()
    this.cutout.renderDetail();
    this.initRequiredFields()

  }

  get y(){
    return this._y;
  }

  set y(y){
    if(typeof y === 'number'){
      if(!this.cutout.checkRequiredField('y', y)) {
        this._y = y;
        this.cutout.setFormField({name: 'y', value: this.y})
      } else {
        this.cutout.showErrors();
      }
    }
    this.cutout.renderDetail()
    this.initRequiredFields()

    // this.initRequiredFields()
  }

  get type() {
    return this._type;
  }

  get xCalc() {
    if (this.x_axis === "right") {
      return this.cutout.detail.l - this.x - this.width
    } else {
      return this.x
    }
  }

  get yCalc() {
    if (this.y_axis === "top") {
      return this.cutout.detail.h - this.y - this.height;
    } else {
      return this.y;
    }
  }

  get elements() {
    return this._elements;
  }

  set elements(value) {
    this._elements = value;
  }

  get side() {
    return this._side;
  }

  set side(side) {
    this._side = side;
    this.cutout.setFormField({name: 'side', value: this.side})
    this.cutout.renderDetail()
  }

  get compactLockType() {
    return this._compactLockType;
  }

  get height() {
    return this._height;
  }

  set height(value) {
    this._height = value;
  }

  get edgeSide() {
    return this._edgeSide;
  }

  set edgeSide(edgeSide) {
    this._edgeSide = edgeSide;
  }

  get depth() {
    return this._depth;
  }

  set depth(depth) {
    this._depth = depth
    this.cutout.setFormField({name: 'depth', value: this.depth})
    this.cutout.renderDetail()
    this.cutout.initRequiredFields()
  }

  get width() {
    return this._width;
  }

  set width(value) {
    this._width = value;
  }

  get y_axis() {
    return this._y_axis;
  }

  get x_axis() {
    return this._x_axis;
  }

  set x_axis(x_axis) {
    this._x_axis = x_axis;
    this.cutout.setFormField({name: 'x_axis', value: this.x_axis})
    this.cutout.renderDetail()
  }

  set y_axis(y_axis) {
    this._y_axis = y_axis;
    this.cutout.setFormField({name: 'y_axis', value: this.y_axis})
    this.cutout.renderDetail()
  }

  /*Features*/
  rotateDetail(direction, l, h) {
    let x, y, height, x_axis, y_axis, width, cx, cy;
    [x , y] = [this.y, this.x];
    [height, width] = [this.width, this.height]
    if(direction) {
      y_axis = (this.x_axis === 'right') ? 'bottom' : 'top';
      x_axis = (this.y_axis === 'bottom') ? 'left' : 'right';
    } else {
      y_axis = (this.x_axis === 'right') ? 'top' : 'bottom';
      x_axis = (this.y_axis === 'bottom') ? 'right' : 'left';
    }
    this._x_axis = x_axis;
    this._y_axis = y_axis;
    this.elements = Helpers.rotateElementsArr(this.elements, this.width, this.height, direction);

    [this.width, this.height] = [width, height]
    this.x = this.cutout.calcX(x);
    this.y = this.cutout.calcY(y);
    this._x_axis = 'left';
    this._y_axis = 'bottom';

    return this.cutout.updateDb();
  }

}
