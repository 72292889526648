import './style.scss';
import Languages from "../../../../translation/Languages";
import DefaultBtn from "../../Buttons/DefaultBtn";
import index from "polygon-clipping";

const SubmitFurnitureModal = ({ submitModalText, close, deleteOneHardware, index }) => {

	const deleteOne = () => {
		deleteOneHardware(index)
	}

	return <div className='modal'>
		<div className='submitModalFurniture'>
			<div className="deleteFurniture__body">
				<div>{Languages.getTranslation('delete-furniture-accept', true)}</div>
				{submitModalText} ?
			</div>
			<div className="deleteFurniture__actions">
				<DefaultBtn
					title={Languages.getTranslation("cancel", true)}
					handler={close}
				/>
				<DefaultBtn
					title={Languages.getTranslation("submit", true)}
					handler={deleteOne}
				/>

			</div>
		</div>
	</div>

}

export default SubmitFurnitureModal