import {empty} from "../../../../../helpers/helper";
import {Tooltip} from "react-tooltip";
import {TooltipPortal} from "../../../TooltipPortal";
import {memo} from "react";
import EdgesHelper from "../../../Modal/EdgesHelper";

const setActiveClass = (edge) => !empty(edge) ? 'detailsTab__edges-item--active' : "";

const Edges = ({detailId, detailMaterial, detailEdges}) => {
    return (
        <>
            <div className="detailsTab__edges pointer" data-tooltip-id={`edgesHelper-tooltip-${detailId}`}>
                <i className={`detailsTab__edges-item detailsTab__edges-top ${setActiveClass(detailEdges.top)}`}></i>
                <i className={`detailsTab__edges-item detailsTab__edges-right ${setActiveClass(detailEdges.right)}`}></i>
                <i className={`detailsTab__edges-item detailsTab__edges-bottom ${setActiveClass(detailEdges.bottom)}`}></i>
                <i className={`detailsTab__edges-item detailsTab__edges-left ${setActiveClass(detailEdges.left)}`}></i>
            </div>
            <TooltipPortal>
                <Tooltip
                    key={`edgesHelper-${detailId}`}
                    className='edgesHelper-tooltip'
                    id={`edgesHelper-tooltip-${detailId}`}
                    place="left"
                    variant='light'
                    zIndex={1000}
                >
                    <EdgesHelper detailMaterial={detailMaterial} detailEdges={detailEdges}/>
                </Tooltip>
            </TooltipPortal>
        </>
    )
};

export default memo(Edges)