import Select from "react-select";
import {customStylesEdges} from "./select-styles";
import Languages from "../../../../../translation/Languages";
import {memo} from "react";
import {CustomOption} from "./CustomSelectOption";

export const Edges = memo(({
																	 filterOptions,
																	 formatOptionLabel,
																	 handleKeyDown,
																	 edges,
																	 changeEdge,
																	 detail,
																	 isDisabled,
																	 selectConfig,
																	 index
																 }) => {

	return (
		<>
			{edges.map(({ref, name, options}) => {
				return (
					<Select key={name}
							{...selectConfig({index, field: name})}
							isDisabled={isDisabled}
							styles={customStylesEdges}
							filterOption={filterOptions}
							formatOptionLabel={formatOptionLabel}
							options={options}
							components={{ Option: CustomOption }}
							noOptionsMessage={() => Languages.getTranslation('edges-not-find-variant', true)}
							name={name}
							isOptionDisabled={(option) => option.disabled}
							onKeyDown={(event) => handleKeyDown(event, name)}
							onChange={(event) => changeEdge(detail, name, event)}
                            defaultValue={options[0]}
							value={options.find((option) => option.value === detail.edges[name])}
							ref={ref}
					/>)
			})}
		</>)
});