import Languages from "../../../../../translation/Languages";
import React, {useEffect, useRef, useState} from "react";
import {toastError} from "../../../../../helpers/toasts";

const ProductsItem = ({index, deleteProduct, product, updateProduct}) => {
  const [name, setName] = useState('')
  const [error, setError] = useState(false)

  const input1 = useRef(null);


  useEffect(() => {
    setName(product.name)
  }, []);

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError(false);
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [error]);

  const handleChange = (e) => {
    setName(e.target.value)
  };

  const onBlurHandler = () => {
    if (!name) {
      toastError(Languages.getTranslation('field-must-be-filled', true))
      setError(true)
      setName(product.name)
      return;
    }
    updateProduct(product.id, 'name', name)
  }

  return <div className="products__table-item">
    <div className="products__table-number products__table-headerStyle">{index + 1}</div>
    <div
      className="products__table-name products__table-headerStyle">
      <input type="text" className={`products__table-input ${error && "errorValidationOnInput"}`} value={name} onChange={(e) => handleChange(e)} onBlur={onBlurHandler}/>
    </div>
    <div className="products__deleteBtn">
      <button className="btn" onClick={() => deleteProduct(product.id, index)}>
        <i className="btn__icon-delete"></i>
      </button>
    </div>
  </div>
};

export default ProductsItem;