import React, {useEffect, useState} from 'react'
import Languages from "../../../../translation/Languages";
import {empty} from "../../../../helpers/helper";
import DefaultBtn from "../../Buttons/DefaultBtn";
import {modalsOperations} from "redux/modals";
import {useDispatch} from "react-redux";
import './style.scss';
import API from "../../../../api/API";
import Errors from "../../../../models/Errors";
const _errors = new Errors([])

const ChooseOwner = () => {
	const [value, setValue] = useState('');
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState(null);
	const dispatch = useDispatch()

	const submit = () => {
		if (!user) {
			_errors.setError(`${Languages.getTranslation("dont-found-user", true)}`, 'error', true)
		return
		}
		dispatch(
			modalsOperations.switchStateForModal({
				show: true,
				type: "CUSTOMER_WAREHOUSE",
				isEdit: user.phone,
			})
		);
	}

	useEffect(() => {
		const searchClient = async () => {
			try {
				const response = await API.Auth.searchUser(value)
				if (response) {
					setUsers(response.data)
				}
			} catch (error) {
				console.log('Failed to search user', error);
			}
		}
		searchClient()
	}, [value]);

	const handleChange = (e) => {
		setValue(e.target.value)
	}

	const close = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};

	const setUserOwner = (user) => {
		setUser(user)
		setValue(user.name)
		setUsers([])
	}

	return (
		<>
			{<div className="saveToOwnerProfile">
				<h2 className="modal-save-project__title">{Languages.getTranslation('connect-to-owner', true)}</h2>
				<div className="modal-save-project__body saveToOwnerProfile__body">
					<div className="modal-save-project__form-control">
						<label
							htmlFor="name"
							className="modal-save-project__form-control-label"
						>
							{Languages.getTranslation('enter-name-or-phone', true)}
						</label>
						<input
							id="name"
							name="name"
							type="text"
							maxLength="21"
							className="modal-save-project__form-control-input"
							onChange={(e) => handleChange(e)}
							value={value}
							autoComplete='off'
						/>
					</div>
					<div className="saveToOwnerProfile__users">
						{!empty(users) ? users.map((user) => <div className="saveToOwnerProfile__user" onClick={() => setUserOwner(user)}>{user.name}</div>) : ''}
					</div>
				</div>
				<div className="modal-save-project__btns saveToOwnerProfile__btns">
					<DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>
					<DefaultBtn title={Languages.getTranslation('join', true)} handler={submit}/>
				</div>
			</div>}
		</>
	)
}
export default ChooseOwner
